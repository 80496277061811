
const ToolGroup = {
  article_and_blogs: {
    title: "Article and Blogs",
    data: [
      {
        category: "Article and Blogs",
        icon: "khudi.jpg",
        title: "templates.article-and-blogs.ai-article-ideas-v2.title",
        description:"templates.article-and-blogs.ai-article-ideas-v2.description",
        subName:"Ai Article Ideas",
        route: "ai-article-ideas",
        isNew: true,
      },
      {
        category: "Article and Blogs",
        icon: "khudi.jpg",
        title: "templates.article-and-blogs.ai-article-intros.title",
        description:
          "templates.article-and-blogs.ai-article-intros.description",
        route: "ai-article-intros",
        isNew: false,
        subName:"Ai Article Intros",
      },
      {
        category: "Article and Blogs",
        icon: "khudi.jpg",
        title: "templates.article-and-blogs.ai-article-outlines-v2.title",
        description:
          "templates.article-and-blogs.ai-article-outlines-v2.description",
        route: "ai-article-outlines-v2",
        isNew: false,
        subName:"Ai Article Outlines V2",
      },
      {
        category: "Article and Blogs",
        icon: "khudi.jpg",
        title: "templates.article-and-blogs.ai-article-outlines.title",
        description:
          "templates.article-and-blogs.ai-article-outlines.description",
        route: "ai-article-outlines",
        isNew: false,
        subName:"Ai Article Outlines",
      },
      {
        category: "Article and Blogs",
        icon: "khudi.jpg",
        title: "templates.article-and-blogs.ai-blog-titles.title",
        description: "templates.article-and-blogs.ai-blog-titles.description",
        route: "ai-blog-title",
        isNew: false,
        subName:"Ai Blog Title",
      },
      {
        category: "Article and Blogs",
        icon: "khudi.jpg",
        title: "templates.article-and-blogs.ai-conclusion-writer.title",
        description:
          "templates.article-and-blogs.ai-conclusion-writer.description",
        route: "ai-conclusion-writer",
        isNew: false,
        subName:"Ai Conclusion Writer",
      },
      {
        category: "Article and Blogs",
        icon: "khudi.jpg",
        title: "templates.article-and-blogs.ai-paragraph-writer.title",
        description:
          "templates.article-and-blogs.ai-paragraph-writer.description",
        route: "ai-paragraph-writer",
        isNew: false,
        subName:"Ai Paragraph Writer",
      },
      {
        category: "Article and Blogs",
        icon: "khudi.jpg",
        title: "templates.article-and-blogs.ai-article-writer-5.0.title",
        description:
          "templates.article-and-blogs.ai-article-writer-5.0.description",
        route: "ai-article-writer",
        isNew: false,
        subName:"Ai Article Writer",
      },
    ],
  },
  ads_and_marketing: {
    title: "Ads and Marketing",
    data: [
      {
        category: "Ads and Marketing",
        icon: "sms.png",
        title: "templates.ads-and-marketing.app-and-sms-notifications.title",
        description:
          "templates.ads-and-marketing.app-and-sms-notifications.description",
        route: "app-and-sms-notifications",
        isNew: false,
        subName:"App And Sms Notification",
      },
      {
        category: "Ads and Marketing",
        icon: "facebook-blue.svg",
        title: "templates.ads-and-marketing.facebook-ads.title",
        description: "templates.ads-and-marketing.facebook-ads.description",
        route: "facebook_ads",
        isNew: false,
        subName:"Facebook Ads",
      },
      {
        category: "Ads and Marketing",
        icon: "ads.png",
        title: "templates.ads-and-marketing.google-ads-description.title",
        description:
          "templates.ads-and-marketing.google-ads-description.description",
        route: "google-ads-description-v2",
        isNew: false,
        subName:"Ads Description v2 ",
      },
      {
        category: "Ads and Marketing",
        icon: "ads.png",
        title: "templates.ads-and-marketing.google-ads-title.title",
        description: "templates.ads-and-marketing.google-ads-title.description",
        route: "google-ads-title-v2",
        isNew: false,
        subName:"Google Ads Title ",
      },
      {
        category: "Ads and Marketing",
        icon: "linkedin.svg",
        title: "templates.ads-and-marketing.linkedin-ad-headlines.title",
        description:
          "templates.ads-and-marketing.linkedin-ad-headlines.description",
        route: "linkedin-ads-headlines",
        isNew: false,
        subName:"Linkedin Ad Headlines",
      },
      {
        category: "Ads and Marketing",
        icon: "linkedin.svg",
        title: "templates.ads-and-marketing.linkedin-ad-description.title",
        description:
          "templates.ads-and-marketing.linkedin-ad-description.description",
        route: "linkedin-ads-descriptions",
        isNew: false,
        subName:"Linkedin Ad Description",
      },
      {
        category: "Ads and Marketing",
        icon: "linkedin.svg",
        title: "templates.ads-and-marketing.linkedin-ad-v2.title",
        description: "templates.ads-and-marketing.linkedin-ad-v2.description",
        route: "linkedin-ads-v2",
        isNew: true,
        subName:"Linkedin Ad V2",
      },
    ],
  },
  general_writing: {
    title: "General Writing",
    data: [
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.personal-bio-v2.title",
        description: "templates.general-Writing.personal-bio-v2.description",
        route: "personal-bio-v2",
        isNew: true,
        subName:"Personal Bio V2",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.text-extender.title",
        description: "templates.general-Writing.text-extender.description",
        route: "text-extender",
        isNew: false,
        subName:"Text Extender",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.company-bios.title",
        description: "templates.general-Writing.company-bios.description",
        route: "company-bio-v2",
        isNew: false,
        subName:"Company Bios",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.quora-answers.title",
        description: "templates.general-Writing.quora-answers.description",
        route: "quora-answers",
        isNew: false,
        subName:"Quora Answers",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.content-shorten.title",
        description: "templates.general-Writing.content-shorten.description",
        route: "content-shorten",
        isNew: false,
        subName:"Content Shorten",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.stories.title",
        description: "templates.general-Writing.stories.description",
        route: "stories",
        isNew: false,
        subName:"Stories",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.bullet-point-answers.title",
        description:
          "templates.general-Writing.bullet-point-answers.description",
        route: "bullet-point-answers",
        isNew: false,
        subName:"Bullet Point Answers",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.passive-to-active.title",
        description: "templates.general-Writing.passive-to-active.description",
        route: "passive-to-active",
        isNew: false,
        subName:"Passive To Active",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.definition.title",
        description: "templates.general-Writing.definition.description",
        route: "definition",
        isNew: false,
        subName:"Definition",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.answers.title",
        description: "templates.general-Writing.answers.description",
        route: "answers",
        isNew: false,
        subName:"Answers",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.email-v2.title",
        description: "templates.general-Writing.email-v2.description",
        route: "email-v2",
        isNew: false,
        subName:"Email V2",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.pros&cons.title",
        description: "templates.general-Writing.pros&cons.description",
        route: "pros-and-cons",
        isNew: false,
        subName:"Pros and Cons",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.email-subjects-lines.title",
        description:
          "templates.general-Writing.email-subjects-lines.description",
        route: "email-subjects-lines",
        isNew: false,
        subName:"Email Subject Lines",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.cold-email-v2.title",
        description: "templates.general-Writing.cold-email-v2.description",
        route: "cold-email-v2",
        isNew: false,
        subName:"Cold Email V2",
      },
      {
        category: "General Writing",
        icon: "khudi.jpg",
        title: "templates.general-Writing.company-mission.title",
        description: "templates.general-Writing.company-mission.description",
        route: "company-mission",
        isNew: false,
        subName:"Company Mission",
      },
    ],
  },
  social_media: {
    title: "Social Media",
    data: [
      {
        category: "Social Media",
        icon: "xtwitter.png",
        title: "templates.social-media.twitter-thread-v2.title",
        description: "templates.social-media.twitter-thread-v2.description",
        route: "twitter-thread-v2",
        isNew: true,
        subName:"Twitter Thread V2",
      },
      {
        category: "Social Media",
        icon: "khudi.jpg",
        title: "templates.social-media.social-media-content-plan.title",
        description:
          "templates.social-media.social-media-content-plan.description",
        route: "social-media-content-plan",
        isNew: false,
        subName:"Socail Media Content Plan",
      },
      {
        category: "Social Media",
        icon: "linkedin.svg",
        title: "templates.social-media.linkedin-posts-v2.title",
        description: "templates.social-media.linkedin-posts-v2.description",
        route: "linkedin-posts-v2",
        isNew: false,
        subName:"Linkedin Posts V2",
      },
      {
        category: "Social Media",
        icon: "instagram.png",
        title: "templates.social-media.instagram-captions.title",
        description: "templates.social-media.instagram-captions.description",
        route: "instagram-captions",
        isNew: false,
        subName:"Instagram Captions",
      },
      {
        category: "Social Media",
        icon: "youtube.png",
        title: "templates.social-media.youtube-description-v2.title",
        description:
          "templates.social-media.youtube-description-v2.description",
        route: "youtube-description-v2",
        isNew: false,
        subName:"Youtube Description V2",
      },
      {
        category: "Social Media",
        icon: "youtube.png",
        title: "templates.social-media.youtube-intro-v2.title",
        description: "templates.social-media.youtube-intro-v2.description",
        route: "youtube-intro-v2",
        isNew: false,
        subName:"Youtube Into V2",
      },
      {
        category: "Social Media",
        icon: "tiktok.png",
        title: "templates.social-media.tiktok-video-script.title",
        description: "templates.social-media.tiktok-video-script.description",
        route: "tiktok-video-script",
        isNew: false,
        subName:"TikTok Video Script",
      },
      {
        category: "Social Media",
        icon: "tiktok.png",
        title: "templates.social-media.tiktok-video-hooks.title",
        description: "templates.social-media.tiktok-video-hooks.description",
        route: "tiktok-video-hooks",
        isNew: false,
        subName:"TikTok Video Hooks",
      },
      {
        category: "Social Media",
        icon: "youtube.png",
        title: "templates.social-media.youtube-ideas.title",
        description: "templates.social-media.youtube-ideas.description",
        route: "youtube-ideas",
        isNew: false,
        subName:"Youtube Ideas",
      },
      {
        category: "Social Media",
        icon: "youtube.png",
        title: "templates.social-media.youtube-outlines.title",
        description: "templates.social-media.youtube-outlines.description",
        route: "youtube-outlines",
        isNew: false,
        subName:"Youtube Outlines",
      },
      {
        category: "Social Media",
        icon: "youtube.png",
        title: "templates.social-media.youtube-titles.title",
        description: "templates.social-media.youtube-titles.description",
        route: "youtube-title",
        isNew: false,
        subName:"Youtube Titles",
      },
    ],
  },
};
export default ToolGroup;
