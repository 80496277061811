import React from "react";
import { useTranslation } from "react-i18next";

const Integration = () => {

    const { t } = useTranslation();
    const tSect = "Settings.integration";

  return (
    <div className="w-full h-[100vh] overflow-y-auto ">
      <div className="w-full min-h-[100vh]  flex flex-col sm:p-8 lg:p-12 pt-[50px] gap-6 rounded-[40px] max-w-7xl mx-auto   ">
        {/***Page Heading**/}
        <div className="flex flex-row gap-4 items-center ">
          <img src="/Icons/settings/setting.svg" />
          <div className="flex flex-col">
            <span className="text-[#101828] ltr:font-InterLight rtl:font-Cairo font-normal text-[16px]">
             {t(`${tSect}.heading-setting`)}
            </span>
            <h2 className="ltr:font-InterLight rtl:font-Cairo font-bold tracking-[1px] text-[20px] ">
             {t(`${tSect}.heading-integration`)}
            </h2>
          </div>
        </div>

        {/**Integration**/}
        <div className="w-full h-auto flex flex-col gap-6">
          {/***Wordpress**/}
          <div className=" h-auto flex sm:flex-col lg:flex-row items-center gap-3 border-[1px] border-[#D0D5DD] rounded-md p-4 justify-between ">
            <div className="flex flex-row gap-3 sm:items-start lg:items-center ">
              <img src="/Icons/settings/wordpress.svg" />
              <div className="flex flex-col gap-1 ">
                <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px]  ">
                 {t(`${tSect}.text-1-wordpress.com`)}
                </span>
                <span className="text-[14px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal   ">
                 {t(`${tSect}.heading-setting`)}
                </span>
              </div>
            </div>
            <button className="sm:w-full lg:w-auto text-white bg-[#1D742C] hover:bg-[#209734] ltr:font-InterLight rtl:font-Cairo font-medium py-2 px-11 rounded-md  ">
            {t(`${tSect}.btn-connect`)}
            </button>
          </div>
          {/***Wordpress.org**/}
          <div className=" h-auto flex sm:flex-col lg:flex-row items-center gap-3 border-[1px] border-[#D0D5DD] rounded-md p-4 justify-between ">
            <div className="flex flex-row gap-3 sm:items-start lg:items-center ">
              <img src="/Icons/settings/wordpress.svg" />
              <div className="flex flex-col gap-1 ">
                <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px]  ">
                 {t(`${tSect}.text-1-wordpress.org`)}
                </span>
                <span className="text-[14px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal   ">
                 {t(`${tSect}.p-1-wordpress.org-1`)}
                </span>
              </div>
            </div>
            <button className="sm:w-full lg:w-auto text-white bg-[#1D742C] hover:bg-[#209734] ltr:font-InterLight rtl:font-Cairo font-medium py-2 px-8 rounded-md  ">
            {t(`${tSect}.btn-install-plugin`)}
            </button>
          </div>
          {/***Wordpress.org**/}
          <div className=" h-auto flex sm:flex-col lg:flex-row items-center gap-3 border-[1px] border-[#D0D5DD] rounded-md p-4 justify-between ">
            <div className="flex flex-row gap-3 sm:items-start lg:items-center ">
              <img src="/Icons/settings/zapier.svg" />
              <div className="flex flex-col gap-1 ">
                <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px]  ">
                {t(`${tSect}.text-1-zapier`)}
                </span>
                <span className="text-[14px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal   ">
                {t(`${tSect}.text-1-zapier`)}
                </span>
              </div>
            </div>
            <button className="sm:w-full lg:w-auto text-white bg-[#1D742C] hover:bg-[#209734] ltr:font-InterLight rtl:font-Cairo font-medium py-2 px-11 rounded-md  ">
             {t(`${tSect}.btn-connect-zapier`)}
            </button>
          </div>
        </div>

        <div className="w-full h-[80px] block"></div>
      </div>
    </div>
  );
};

export default Integration;
