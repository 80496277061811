import React, { useState } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const Policies = () => {
  const [activeButton, setActiveButton] = useState();

  const PoliciesList = [
    "Introduction",
    "Information We Collect",
    "Automatically Collected Information",
    "How We Use Collected Information",
    "Data Security",
    "Third-Party Services",
    "Data Retention",
    "Users' Rights",
    "Changes to the Privacy Policy",
    "Contact Information",
  ];

  const handleButton = (item) => {
    setActiveButton(item);
    scrollToSection(item);
  };

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="w-full  flex flex-col bg-white rounded-t-[50px]  ">
        
      <div className="w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[85%] h-[100%] max-w-6xl  mx-auto flex flex-row justify-between pt-[120px]  ">
      
        {/**Buttons**/}
        <div className="sm:hidden md:block md:w-[30%] gap-8 list-none   ">
          <div className=" flex flex-col gap-8 sticky top-10 mt-20 ">
            {PoliciesList.map((item, index) => {
              return (
                <Link
                  onClick={() => handleButton(item)}
                  key={index}
                  className={`text-[14px]  ${
                    activeButton == item ? "text-black" : "text-gray-400"
                  } hover:text-black font-[300] ltr:font-roboto rtl:font-Cairo tracking-[0.5px] `}
                >
                  {item}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="sm:w-full md:w-[66%] sm:ltr:ml-4 sm:rtl:mr-4  flex flex-col gap-14  ">
          <div  offset={-40} id="Introduction" className="flex flex-col ">
            <h2 className="text-[30px] font-roboto">Introduction</h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              Khudi.Ai is committed to protecting the privacy of our users.
            </p>
          </div>
          <div offset={-40} id="Information We Collect" className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto">Information We Collect</h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              User Provided Information: We may collect personal information
              provided by users, including but not limited to, prompts,
              responses, and any other data necessary for the better functioning
              and services.
            </p>
          </div>
          <div offset={-40} id="Automatically Collected Information" className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto">
              Automatically Collected Information:
            </h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              We may collect certain information automatically, including but
              not limited to, usage data, device information, and log data.
            </p>
          </div>
          <div offset={-40} id="How We Use Collected Information" className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto">
              How We Use Collected Information
            </h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              We use the collected information for the purpose of providing and
              improving our services for users, ensuring the technical
              functionality of our applications, and for analytics
            </p>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              We may use the information to communicate with users, respond to
              their inquiries, and provide customer support.
            </p>
          </div>
          <div offset={-40} id="Data Security" className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto">Data Security</h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              We take reasonable measures to protect the confidentiality and
              security of the collected information. However, no data
              transmission over the internet or storage system can be guaranteed
              to be 100% secure.
            </p>
          </div>
          <div offset={-40}  id="Third-Party Services" className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto">Third-Party Services</h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              Our services may incorporate third-party services or links to
              third-party websites. These third-party services have their own
              privacy policies, and we are not responsible for their practices.
            </p>
          </div>
          <div offset={-40} id="Data Retention" className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto">Data Retention</h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              We will retain user data for as long as necessary to fulfill the
              purposes outlined in this privacy policy unless a longer retention
              period is required or permitted by law.
            </p>
          </div>

          <div offset={-40} id="Changes to the Privacy Policy"  className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto">Users' Rights</h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              Users have the right to access, update, or delete their personal
              information. To exercise these rights, users may contact us at
              info@khudi.ai.
            </p>
          </div>

          <div offset={-40}  id="Contact Information" className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto">
              {" "}
              Changes to the Privacy Policy
            </h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              We may update this privacy policy from time to time to reflect
              changes in our practices. We will notify users of any significant
              changes by posting the updated policy on our website or through
              other means.
            </p>
          </div>
          <div offset={-40} className=" flex flex-col ">
            <h2 className="text-[30px] font-roboto"> Contact Information</h2>
            <p className="text-roboto text-gray-600 font-[300] ">
              {" "}
              If you have any questions or concerns about this privacy policy,
              please contact us atinfo@khudi.ai.
            </p>
          </div>
        </div>
      </div>
      <div className="block h-[100px]" ></div>
    </div>
  );
};

export default Policies;
