import React from "react";
import "./index.css";
import TableItem from "./tableItem";
import Tablepagination from "./pagination";
import { Outlet } from "react-router-dom";

const BrancVoiceHome = () => {
  return (
    <div className="w-full h-auto flex flex-col gap-10  ">
    <Outlet/>
    </div>
  );
};

export default BrancVoiceHome;
