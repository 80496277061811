import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import historyToolAPI from "../services/historyTools"

const initialState = {
  isLoading: false,
  loadingSendPrompt: false,
  tools: [],
  selectedTool:"",
  isModalActive:false,
  message:""
}


export const getHistoryTool = createAsyncThunk("getHistoryTool",async()=>{
    const res = await historyToolAPI.getTools();
    return res;
})
 
export const clearHistoryTool = createAsyncThunk("clearHistoryTool",async(payload)=>{
   const res = await historyToolAPI.clearTool(payload);
   return res;
})


export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers:{
    SearchData: (state, action) => {
     state.tools = action.payload
    },
    getSelectedTool: (state,action) => {
      state.selectedTool = action.payload
    },
     OpenCloseModal : (state,action) => {
      const modal = state.isModalActive
      state.isModalActive = modal == true ? false : true
    }
  },
  extraReducers(builder) {
    builder
    .addCase(getHistoryTool.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getHistoryTool.fulfilled, (state, action) => {
        state.isLoading = true;

        if(action.payload[0] == null){
          state.message = "No Record Found"
          state.tools = action.payload
          state.isLoading = false;
        }
        else{
          state.tools = action.payload
          state.message = ""
          state.isLoading = false;
        }

      })
      .addCase(getHistoryTool.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "Something Went Wrong"
        console.log(action.error);
      })
      .addCase(clearHistoryTool.pending,(state,action)=>{
        state.isLoading = true
      })
      .addCase(clearHistoryTool.fulfilled,(state,action)=>{
        state.message = action.payload
        state.isLoading = false
      })
   
  },
})

export const {SearchData,getSelectedTool,OpenCloseModal } = historySlice.actions

export default historySlice.reducer