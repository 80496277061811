import React, { useState, useEffect, useRef } from "react";
import ItemDrop from "./itemDrop";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";
import ToolGroup from "../Library/Template/data/dataTools";

const SideBarTemplates = ({ isSideBarActive, setSideBarActive, menuRef }) => {
  const sideNavRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");
  const [filterdData, setFilteredData] = useState([]);
  const [isFilterActive, setFilterActive] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      sideNavRef.current && !sideNavRef.current.contains(event.target) &&
     !menuRef.current.contains(event.target)
    ) {
      setSideBarActive(false);
    }
  };

  const handleInput = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    setFilterActive(false);
  };

  const handleSearch = () => {
    const Filter = Object.keys(ToolGroup).map((category, index) =>
      ToolGroup[category]?.data?.filter((item) => {
        return (
          ToolGroup[category].title
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.subName.toLowerCase().includes(searchValue.toLowerCase())
        );
      })
    );

    for (let i = 0; i < Filter.length; i++) {
      if (Filter[i].length > 0) {
        setFilteredData(Filter[i]);
        setFilterActive(true);
      }
    }
  };

  return (
    <div className={` sm:w-full md:w-[250px] md:flex h-full ${isSideBarActive ? "flex " : "hidden"}   sm:z-50 md:z-0  fixed sm:backdrop-blur-sm md:backdrop-blur-0 `} >
    <div
      ref={sideNavRef}
      className={`lg:w-[250px] sm:w-[250px] h-[100vh] bg-[#272828]   flex-col gap-4 pt-0  fixed ltr:left-0 rtl:right-0 flex z-50  ${
        isSideBarActive ? "flex" : "hidden"
      } md:flex `}
    >
      <div
        className={`w-[250px] h-[calc(100vh-45px)] bg-[#272828]  flex-col gap- pt-8   items-center fixed ltr:left-0 rtl:right-0 flex   z-50 transition-all sm:${
          isSideBarActive ? "flex" : "hidden"
        } md:flex `}
      >
        <div className="w-[200px] h-[40px] flex items-center bg-[#344054] border-[1px] border-[#101828] rounded-md px-3 py-1 gap-2 ">
          <img
            src="/Icons/templates/search.svg"
            className="cursor-pointer"
            alt=""
          />
          <input
            type="text"
            value={searchValue}
            placeholder="Search"
            onChange={handleInput}
            onKeyDown={({ key }) => {
              if (key === "Enter") {
                handleSearch();
              }
            }}
            className="w-full outline-none bg-[#344054] text-white placeholder:font-InterLight placeholder:tracking-[0.5px] "
          />
        </div>

        <div className="w-full h-[calc(100%-40px)]  overflow-y-auto scrollbar-thumb-rounded-md  scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-[#272828]    ">
          <div className="w-full h-auto px-6 flex flex-col gap-4 mt-[20px] border-b-[1px] border-[#344054] pb-4 " >
            
          {isFilterActive ? (
            <ItemDrop isFilter={true} tools={filterdData} />
          ) : (
            Object.keys(ToolGroup).map((category, index) => (
              <ItemDrop
                key={index}
                heading={ToolGroup[category].title}
                tools={ToolGroup[category].data}
              />
            ))
          )}
        </div>
        </div>
        <div className="w-full h-[20px] block "></div>
      </div>
    </div>
    </div>
  );
};

export default SideBarTemplates;
