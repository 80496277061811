import React from 'react';
import { useDispatch } from 'react-redux';
import { setIsModalSuccessActive } from '../../../Redux/subscriptionSlice';

const UpgradeSuccessModal = ({ isModalActive }) => {
  const dispatch = useDispatch();

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(setIsModalSuccessActive(false));
    }
  }

  return (
    <div className={`w-full min-h-[100vh] absolute top-0 z-50 bg-gray-400 bg-opacity-60  backdrop-blur-sm flex items-center justify-center p-6 transition-all ${isModalActive ? "scale-100 opacity-100" : "scale-0 pointer-events-none opacity-0 "} `}
      onClick={handleClose}
    >
      <div className='w-[620px] sm:w-[100%] md:w-[620px] h-auto bg-[white] shadow-md rounded-[20px] p-4 ' >
        <div className='w-full h-auto flex flex-col items-center justify-center gap-2' >
          <img src='/Icons/charco-success-subscribe.png' className='h-[281px]' alt="ic-upgrade-success" />
          <h2 className='text-[25px]  font-InterLight font-medium' >You’re already subscribed</h2>
          <p className='text-[15px] sm:text-[12px] md:text-[15px] text-[#667085] font-InterLight font-normal text-center tracking-[0.5px] ' >
            Thank you for subscribing! Your subscription has been processed successfully. We appreciate your decision to choose our services and look forward to serving you. Enjoy your subscription benefits!
          </p>
        </div>
        <div className='w-full flex gap-2'>
          <button className='text-[16px] bg-white hover:bg-[#D0D5DD] border-[1px] border-[#D0D5DD] mt-[20px] p-2 w-full rounded-md font-InterLight font-medium tracking-[0.3px] '>
            Manage Subscriptions
          </button>
          <button className='text-[16px] bg-[#1D742C] hover:bg-[#2b6835] mt-[20px] p-2 w-full rounded-md font-InterLight font-medium text-white tracking-[0.3px] '
            onClick={() => dispatch(setIsModalSuccessActive(false))}
          >
            Back to page
          </button>
        </div>
      </div>
    </div>
  )
}

export default UpgradeSuccessModal
