import React, { useEffect } from "react";

const AnimatedScroll = () => {

  return (
    <div className="w-full inline-flex h-[170px] flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
      <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none  ltr:animate-infinite-scroll-ltr rtl:animate-infinite-scroll-rtl ">
      <img
          src="/Icons/branding/path0 (4).png"
          className="h-[35px] my-0 mx-[40px]  animate "
          alt="ic-brand-andela"
        />
        <img
          src="/Icons/branding/ariga 1.png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-ariga"
        />
        <img
          src="/Icons/branding/whogohost 1 (1).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-whogohost"
        />
        <img
          src="/Icons/branding/korapay 2 (1).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-korapay"
        />
        <img
          src="/Icons/branding/fieldinsight 1 (1).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-fieldinsight"
        />
        <img
          src="/Icons/branding/quickteller 1 (2).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-quickteller"
        />
        <img
          src="/Icons/branding/korapay 2 (1).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-korapay"
        />
        <img
          src="/Icons/branding/path0 (4).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-andela"
        />
        <img
          src="/Icons/branding/ariga 1.png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-ariga"
        />

      </ul>
      <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none  ltr:animate-infinite-scroll-ltr rtl:animate-infinite-scroll-rtl " aria-hidden="true">
      <img
          src="/Icons/branding/path0 (4).png"
          className="h-[35px] my-0 mx-[40px]  animate "
          alt="ic-brand-andela"
        />
        <img
          src="/Icons/branding/ariga 1.png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-ariga"
        />
        <img
          src="/Icons/branding/whogohost 1 (1).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-whogohost"
        />
        <img
          src="/Icons/branding/korapay 2 (1).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-korapay"
        />
        <img
          src="/Icons/branding/fieldinsight 1 (1).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-fieldinsight"
        />
        <img
          src="/Icons/branding/quickteller 1 (2).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-quickteller"
        />
        <img
          src="/Icons/branding/korapay 2 (1).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-korapay"
        />
        <img
          src="/Icons/branding/path0 (4).png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-andela"
        />
        <img
          src="/Icons/branding/ariga 1.png"
          className="h-[35px] my-0 mx-[40px] "
          alt="ic-brand-ariga"
        />
      </ul>
    </div>
  );
};

export default AnimatedScroll;
