import React from 'react'
import Navigation from "./navigation"
import { useRef } from 'react'
import { useEffect  } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";


const SettingSidebar = ({expandSideBar,setExpandSideBar,isSideBarOpen,setIsSideBarOpen}) => {

 const wrapperRef = useRef()
 const { t } = useTranslation();
const tSect = "Settings.sidebar";
const Navigate = useNavigate("")

 useEffect(()=>{
   window.addEventListener("mousedown",handleClickOutSide)
   return () => {
    window.removeEventListener("mousedown",handleClickOutSide)
   }
 },[])

 const handleClickOutSide = (e) =>{ 
   if(wrapperRef.current && !wrapperRef.current.contains(e.target)){
      setIsSideBarOpen(false)
   }
 }

  return (
     <div ref={wrapperRef} className={`${expandSideBar?" md:w-[280px] opacity-100 ":"  md:w-[0px] opacity-0 pointer-events-none "} overflow-y-auto transition-all h-[100vh] bg-[#272828] flex flex-row sm:fixed md:static sm:z-50 md:z-0 ${isSideBarOpen ? "sm:w-[280px] " : "sm:w-0"}    `}>
      <div className='w-[3px] h-full bg-[#667085]  ' >

      </div>
      <div className='w-[calc(100%-3px)] h-full flex flex-col pt-[40px] flex-start p-4 ' >
       {/***Settings Head****/}
       <div 
          className="bg-[#2b2f35] sm:flex lg:hidden h-[40px] w-[100px] cursor-pointer  p-3 gap-4 flex flex-row items-center rounded-md "
          onClick={()=>Navigate("/dashboard")}

        >
          <img src="/Icons/header/back.svg" alt='' />
          <span className="mb-2 text-[14px] text-white tracking-[1px] font-InterLight font-normal">
            Back
          </span>
        </div>
       <div className='w-full h-auto px-2 flex flex-col gap-2  ' >
        <img onClick={()=>setExpandSideBar(false)} src='/Icons/sidebar/arrow-to-side.svg' className='mt-4 w-[12px] h-[10px] cursor-pointer sm:hidden md:block ' />
        <h2 className='text-white text-[25px] mt-[20px] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px]  ' >{t(`${tSect}.heading-sidebar`)}</h2>
        <p className='text-[#EAECF0] ltr:font-InterLight rtl:font-Cairo font-light tracking-[0.5px] ' >{t(`${tSect}.p-sidebar`)}</p>
        <hr className='border-bottom border-[1px] border-[#344054] mt-[20px] ' />
       </div>
       {/***Settings Navigation***/}
       <Navigation/>
      
      </div>
     </div>
  )
}

export default SettingSidebar
