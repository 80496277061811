import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editor.css";
import { SaveEditorContent } from "../../../../Redux/editorSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Spinner } from "../../../../assets/icons/spinner.svg";
import { useTranslation } from "react-i18next";
import { setIsUpgradeActive } from "../../../../Redux/subscriptionSlice";

const KhudiEditor = ({ isEditorActive, setisEditorActive }) => {
  const { t } = useTranslation();
  const tSect1 = "Editor";
  const dispatch = useDispatch();

  const QuillRef = useRef();

  const sessionId = useSelector((state) => state.chats.sessionId);
  const editorContent = useSelector((state) => state.chats.editorContent);
  const isLoading = useSelector((state) => state.editor.loadingSaving);
  const isError = useSelector((state) => state.editor.error);
  const countWordLeft = useSelector((state) => state.profile.countWordLeft);

  const [value, setValue] = useState("");

  const colors = [
    "#000000",
    "#e60000",
    "#ff9900",
    "#ffff00",
    "#008a00",
    "#0066cc",
    "#9933ff",
    "#ffffff",
    "#facccc",
    "#ffebcc",
    "#ffffcc",
    "#cce8cc",
    "#cce0f5",
    "#ebd6ff",
    "#bbbbbb",
    "#f06666",
    "#ffc0cb",
    "#ffff99",
    "#b3ffb3",
    "#c2f0c2",
    "#ffb3ff",
    "#f0f0f0",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ color: colors }], // Add background color
      ["link", "image"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
    // Add more custom modules or configurations as needed
  };

  const formats = [
    "color",
    // Add other formats as needed
  ];

  const defaultColor = "black";

  useEffect(() => {
    setValue(editorContent);
  }, [editorContent]);

  // const module = {
  //   toolbar: toolbarOption,
  // };

  useEffect(() => {

     document.getElementsByClassName("ql-editor")[0].childNodes.forEach((item)=>{
        item.setAttribute("style","color:black")
     })

    QuillRef.current.editor.theme.modules.toolbar.container.style.transition =
      "all 0.5s ";
    QuillRef.current.editor.theme.modules.toolbar.container.style.border =
      "0px";
    QuillRef.current.editor.theme.modules.toolbar.container.style.padding =
      "12px 10px ";
    QuillRef.current.editor.theme.modules.toolbar.container.style.flexWrap =
      "wrap";

    QuillRef.current.editor.theme.modules.toolbar.controls.map((item) => {
      item[1].style.height = "30px";
    });
    QuillRef.current.editor.theme.modules.toolbar.container.style.display =
      "flex";

    QuillRef.current.editor.theme.modules.toolbar.container.childNodes.forEach(
      (element) => {
        element.style.display = "flex";
        element.style.gap = "12px";
        element.style.marginRight = "0px";
      }
    );

    // Editing Area
    QuillRef.current.editingArea.style.border = "0px";
    QuillRef.current.editingArea.style.borderTop = "1px solid #EAECF0";
    QuillRef.current.editingArea.style.marginTop = "20px";
    QuillRef.current.editingArea.style.overflowY = "auto";
    QuillRef.current.editingArea.style.height = "calc(100vh - 240px)";
  }, []);

  const handleChange = () => {
    if (!countWordLeft) {
      dispatch(setIsUpgradeActive(true));
      return;
    } else {
      const payload = {
        content: value,
        sessionId: sessionId,
      };

      if (!sessionId) {
        toast.error(`You can not save without having any chat`, {
          position: "top-right",
        });
      } else {
        dispatch(SaveEditorContent(payload)).then((resp) => {
          resp.error
            ? toast.error(`${isError}`, {
                position: "top-right",
              })
            : toast.success(`Data Saved Successfully`, {
                position: "top-right",
              });
        });
      }
    }
  };

  const handleMinimize = () => {
    setisEditorActive(isEditorActive ? false : true);
  };

  return (
    <div
      className={` ${
        isEditorActive ? "sm:w-full lg:w-[40%]  " : "w-0"
      } transition-all sm:flex md:flex  h-full flex-col shadow-sm pt-6 border-l border-[1px]  sm:absolute top-0  lg:relative sm:z-50  sm:backdrop-blur-sm  lg:bg-white ${
        isEditorActive
          ? "ltr:right-0 rtl:left-0 "
          : "ltr:right-[-9px] rtl:left-[-9px]  "
      }    `}
    >
      <div
        className={` w-full h-full flex flex-col  ${
          isEditorActive ? "visible" : "hidden"
        } bg-white sm:mt-4 sm:pt-4 lg:pt-0  `}
      >
        <div className="w-full sm:h-[30px] l:h-0 flex items-start px-4 sm:flex lg:hidden  ">
          <button
            disabled={isLoading}
            onClick={() => setisEditorActive(false)}
            className="inline-flex flex-row gap-2 cursor-pointer  disabled:cursor-not-allowed  active:scale-105 transition-all "
          >
            <img src="/Icons/editor/chevrons-left.svg" />
            <span className="text-[16px] ltr:font-InterLight rtl:font-Cairo font-medium text-[#1D742C] tracking-[0.5px] ">
              {t(`${tSect1}.editor`)}
            </span>
          </button>
        </div>
        <div className="w-full h-[50px] flex flex-row items-center justify-between mb-2 px-4  ">
          <h2 className="sm:text-[15px] lg:text-[18px] font-semibold ltr:font-InterLight rtl:font-Cairo tracking-[0.7px]  ">
            {/* {t(`${tSect1}.untitled`)} */}
          </h2>
          <span
            onClick={handleChange}
            className="flex flex-row items-center justify-center gap-2 rounded-md bg-[#25292E] bg-opacity-5 p-2 hover:bg-opacity-10 cursor-pointer transition-all active:scale-105 "
          >
            {isLoading ? (
              <div className="m-auto">
                <Spinner className="animate-spin h-[20px] w-[20px]" />
              </div>
            ) : (
              <img src="/Icons/editor/save.svg" />
            )}
            <h2 className="sm:text-[14px] lg:text-[16px] ltr:font-InterLight rtl:font-Cairo text-[#175B22] ">
              {t(`${tSect1}.auto-save`)}
            </h2>
          </span>
        </div>

        <ReactQuill
          ref={QuillRef}
          modules={modules}
          theme="snow"
          value={value}
          onChange={setValue}
          formats={{ color: "black" }}
          placeholder="Type something..."
        />
      </div>
      <div
        onClick={handleMinimize}
        className="px-4 py-2 w-[100px] bg-[#208030] absolute ltr:rotate-[270deg] rtl:rotate-[-270deg]  rounded-t-md  flex flex-row gap-2 items-center justify-center top-[20%] ltr:left-[-70px] rtl:right-[-70px] cursor-pointer transition-all  "
      >
        <span className="text-white ltr:font-InterLight rtl:font-Cairo text-[13px] tracking-[0.9px]  ">
          {isEditorActive ? t(`${tSect1}.minimize`) : t(`${tSect1}.editor`)}
        </span>
        <img
          src={`/Icons/editor/${isEditorActive ? "minimize" : "edit"}.svg`}
        />
      </div>
    </div>
  );
};

export default KhudiEditor;
