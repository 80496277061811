import React, { useEffect, useState ,useRef} from "react";
import SelectItem from "./selectItem";
import { useTranslation } from "react-i18next";


const Select = ({defaultLanguage,defaultFlag,setLanguages}) => {
  
  const wrapperRef = useRef()

  const { t } = useTranslation();
  const tSect = "Settings.preferences";

  const [isSelectActive, setIsSelectActive] = useState(false);
  const [language, setLanguage] = useState({
    flag: defaultFlag,
    lng: defaultLanguage
  });

  const handleSelect = () => {
    setIsSelectActive(isSelectActive ? false : true);
  };

  useEffect(()=>{

    let handler = (e) => {
      if(!wrapperRef.current.contains(e.target)){
        setIsSelectActive(false)
      }
    }

    document.addEventListener("mousedown",handler)

    return()=>{
      document.removeEventListener("mousedown",handler)
    }
  })

 
  return (
    <div ref={wrapperRef} className="w-full h-[40px] relative shadow-sm border-[1px] border-[#D0D5DD] rounded-md px-2  ">
      <div
        onClick={handleSelect}
        className="w-full h-full flex cursor-pointer items-center justify-between "
      >
        <div className="w-full h-full inline-flex items-center gap-2 ">
          <img src={`/Icons/flag/${language.flag}.svg`} alt='' />
          <span className="text-[#667085] text-[16px] font-InterLight font-normal ">
            {language.lng}
          </span>
        </div>
        <img
          src="/Icons/dropdown/expand.svg"
          className={`h-[10px] cursor-pointer transition-all ${
            isSelectActive ? "rotate-180" : "rotate-0"
          } `}
          alt=''
        />
      </div>
      <div
        className={`w-full h-auto border-[1px] bg-[white]  rounded-md z-30 border-[#D0D5DD] flex flex-col gap-2 absolute top-[43px] left-0 transition-all ${
          isSelectActive ? "opacity-100 translate-y-[5px] visible " : "opacity-0 hidden translate-y-[-20px] "
        }  `}
      >
        <SelectItem
          flag={"US"}
          lng={"English"}
          setLanguage={setLanguage}
          language={language}
          setIsSelectActive={setIsSelectActive}
          setLanguage1={setLanguages}
        />
        <SelectItem
          flag={"SA"}
          lng={"Arabic"}
          setLanguage={setLanguage}
          language={language}
          setIsSelectActive={setIsSelectActive}
          setLanguage1={setLanguages}
        />
      </div>
    </div>
  );
};

export default Select;
