import React, { useEffect } from "react";
import ReactGA from "react-ga";
import HeroPolicy from "./hero";
import Policies from "./polices";
import Footer from "../../shared/Footer/footer";

const Policy = () => {
  useEffect(() => {
    ReactGA.pageview("/detail-feature");
  }, []);
  return (
    <div className="w-full min-h-[100vh] block pt-[20px] ">
        <HeroPolicy/>
        <Policies/>
        <Footer/>
      
    </div>
  );
};

export default Policy;
