import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ImageCreationAPI from '../services/image';

const initialState = {
  loadingGenerate: false,
  historyLoading:false,
  images: [],
  imageHistory:[],
  isPopupActive:false,
  imageUrl:"",
  downloadImage:""

}

export const generateImage = createAsyncThunk(`images/create`, async (payload) => {
  const res = await ImageCreationAPI.create(payload);
  return res;
});


export const GetImages = createAsyncThunk ("images",async()=>{
  const res = await ImageCreationAPI.getImages();
  return res;
})



export const imageAISlice = createSlice({
  name: 'imageAI',
  initialState,
  reducers: {
    popupActive: (state, action) => {
      state.isPopupActive = action.payload.Activestate;
      state.imageUrl = action.payload.imageUrl;
    },
    resetGeneratedImages:(state,action) => {
      state.images = ""
    }
  },
  extraReducers(builder) {
    builder
      .addCase(generateImage.pending, (state, action) => {
        state.loadingGenerate = true;
      })
      .addCase(generateImage.fulfilled, (state, action) => {
        const { payload } = action;
        state.images = payload;
        state.loadingGenerate = false;
      })
      .addCase(generateImage.rejected, (state, action) => {
        state.loadingGenerate = false;
        console.log(action.error);
      })
      .addCase(GetImages.pending,(state,action)=>{
        state.historyLoading = true
      })
      .addCase(GetImages.fulfilled,(state,action)=>{
        state.imageHistory = action.payload
        // state.historyLoading = false
      })

      
  },
})
export const {
  popupActive,
  resetGeneratedImages
} = imageAISlice.actions;
export default imageAISlice.reducer