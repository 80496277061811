import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const Promptcards = ({setPromptvalue,desc,isEditorActive}) => {

  const promptRef = useRef();

  const HandlePrompt = () => {
     let value = promptRef.current.innerText
     setPromptvalue(desc)
  }
  const {t} = useTranslation()
  const tPrompt = "Khudi-Chat.prompt-cards";

  return (
    <div onClick={HandlePrompt} className={`cursor-pointer sm:w-[95%] md:w-[90%] lg:w-[90%] ${isEditorActive? "xl:w-[90%]": ""} ${!isEditorActive ? "xl:w-[32%]":""} h-auto p-4 mx-auto bg-white inline-flex gap-x-4 rounded-[8px]  border-[1px] border-[#EAECF0] shadow-sm shadow-[#F2F4F7]   `}>
      <img src="/Icons/layer-blue.svg" className="h-[35px] sm:h-[30px] md:h-[35px] " />
      <div className="inline-flex flex-col gap-2">
        <h3 className="text-[#101828] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-bold tracking-[0.5px]  ">
        {t(`${tPrompt}.card1.heading`)}
        </h3>
        <span className="text-[14px] sm:text-[12px] md:text-[14px]  text-[#667085] leading-5 font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px]" ref={promptRef} >
         {desc}
        </span>
      </div>
    </div>
  );
};

export default Promptcards;
