import React from 'react'
import { useTranslation } from "react-i18next";

const BlogCard = () => {

    const {t} = useTranslation();
    const tSect = "blog.all-blogs-post";

    return (<>
        <div className='bg-transparent grid grid-cols-12 gap-0 md:gap-4'>
            <div className="col-span-12 md:col-span-5 lg:col-span-12">
                <div class="h-52 sm:h-80 md:h-48 lg:h-64 rounded-lg bg-gray-300 mb-4">
                    <img src="https://images.unsplash.com/photo-1542315204-8b368472a4e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Image" className='object-cover h-full w-full rounded-lg' />
                </div>
            </div>
            <div className="col-span-12 md:col-span-7 lg:col-span-12">
                <label class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-sm font-gray">Erzi Hutama 20 Jan 2022</label>
                <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium text-base text-white my-2 line-clamp-2">
                 {t(`${tSect}.name-date`)}
                </h1>
                <p class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-xs leading-5 line-clamp-3 text-white "> {t(`${tSect}.paragraph-blog`)}</p>
                <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.copy-writing`)}</span>
                <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.ai-blog`)}</span>
                <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.ai-copy`)}</span>
            </div>
        </div>
    </>

    )
}

export default BlogCard