import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentPage: "",
  isSideBarActive:"",
  isMinimize:false
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {

    currentPage: (state, action) => {
      state.currentPage = action.payload
    }
    ,

    sideBarActive :(state,action) => {
      state.isSideBarActive = action.payload
    },
    setIsMinimize : (state,action) => {
      state.isMinimize = action.payload
    }

  },
})

export const { sideBarActive, currentPage ,setIsMinimize } = sidebarSlice.actions

export default sidebarSlice.reducer