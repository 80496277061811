import React from "react";
import Header from "../../shared/Header2/header";
import { useTranslation } from "react-i18next";

const HeroSection = () => {

    const {t} = useTranslation();
    const tSect = "detail-feature.hero-section";

  return (
    <div className="w-full h-auto flex flex-col radial-gradient ">
      <div className="w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[85%] h-[100%] max-w-6xl  mx-auto flex flex-col pt-[20px] relative ">
        {/*****Header*****/}
        <Header />
        {/*****Background Pattern*****/}
        <img
          src="/Icons/detail-feature/background-pattern.svg"
          className="absolute top-3 sm:hidden lg:block  lg:left-[80px] xl:left-[210px] pointer-events-none z-10 "
        />
        {/*****Main Content*****/}
        <div className="w-full h-auto flex flex-col items-center justify-center gap-2 pt-[100px] text-center ">
          <div className="inline-flex gap-1 bg-[#FFFFFF] bg-opacity-10 py-2 px-4 rounded-md " >
              <span className="text-white text-[18px] font-Inter font-normal tracking-[0.5px]" >{t(`${tSect}.small-text-1`)}</span>
              <span className="bg-clip-text text-[18px] text-transparent bg-gradient-to-r from-[#62FE76] to-[#0BA5EC] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px]" >{t(`${tSect}.small-text-2`)}</span>
              <span className="text-white text-[18px] font-Inter font-normal tracking-[0.5px] " >{t(`${tSect}.small-text-3`)}</span>
          </div>
          {/*****Landing Text*****/}
          <div className=" inline-flex sm:flex-col lg:flex-row gap-3 sm:gap-1 lg:gap-3 text-center" >
            <span className="text-[72px] sm:text-[50px] md:text-[85px] lg:text-[60px] xl:text-[72px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[2px] " >{t(`${tSect}.write-content`)}</span>
            <span className="text-[72px] sm:text-[35px]  md:text-[65px]  lg:text-[60px] xl:text-[72px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[2px] bg-clip-text  text-transparent bg-gradient-to-b from-[#62fed2] to-[#0BA5EC]  " >{t(`${tSect}.that-convert`)}</span>
          </div>
          <p className="text-[18px] text-[#FCFEFC] font-InterLight ltr:font-InterLight rtl:font-Cairo  font-light tracking-[1px] " >{t(`${tSect}.p-1`)}</p>
          {/*****Dashboard Image*****/}
          <img src="/Icons/khudi-chat-screenshot.png" className="pt-[30px]" />
          <div className="w-full h-[20px] block"> </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
