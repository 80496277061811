import React,{useState} from 'react'
import Sidebar from '../sidebar'
import { useSelector, useDispatch } from "react-redux";
import MiniSideBar from './miniSideBar';
import SettingSidebar from "./settingSidebar"
import Upgrade from "../upgrade/index"
import UpgradeSuccessModal from '../upgrade/upgradeSuccess';
import { Outlet } from 'react-router-dom';

const Setting = () => {

  const isUpgradeActive = useSelector(state => state.subscription.isUpgradeActive);
  const isModalSuccessActive = useSelector(state => state.subscription.isModalSuccessActive);
  const [expandSideBar,setExpandSideBar] = useState(true)
  const [isSideBarOpen,setIsSideBarOpen] = useState(false)

  return (
    <div className='w-full h-[100vh] flex flex-row bg-[#272828] overflow-hidden ' >
     <MiniSideBar setExpandSideBar={setExpandSideBar} expandSideBar={expandSideBar}  />
     <SettingSidebar expandSideBar={expandSideBar} setExpandSideBar={setExpandSideBar} isSideBarOpen={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
     
     <div className={`sm:w-[100%] md:w-[calc(100%-360px)] ${expandSideBar == false ? "md:w-full" : "md:w-[calc(100%-360px)]"}  h-[100vh] bg-white  rounded-t-[20px] `} >
      <div className='w-full h-[60px] bg-[#151718] flex flex-row justify-between items-center px-6 sm:flex md:hidden ' >
        <img onClick={()=>{setIsSideBarOpen(true)}} src='/Icons/header/menu.svg' className='h-[17px] cursor-pointer '/>
        <div className='flex flex-row gap-2 bg-[#25292E] w-[70%] p-2 items-center justify-center rounded-md  '  >
          <img src='/Icons/header/settings.svg'/>
          <h2 className='text-[#61FF76] text-[16px] font-InterLight font-medium tracking-[1px] '>Settings</h2>
        </div>
        <img src='/Icons/header/close.svg' className='bg-[#212936] p-3 rounded-md cursor-pointer hover:scale-110 transition-all' />
      </div> 
      
      
      <Outlet/>
     </div>
     
    {/***Upgrade Pop Up****/}
    {isUpgradeActive &&
        <Upgrade isUpgradeActive={isUpgradeActive} />    
      }
     {isModalSuccessActive &&
        <UpgradeSuccessModal isModalActive={isModalSuccessActive} />
      }   

    </div>
  )
}

export default Setting
