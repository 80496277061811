import { Navigate } from "react-router-dom";
import AuthAPI from "../services/auth";

import LoginPage from "../modules/Authentication/loginPage";
import LoginForm from "../modules/Authentication/loginForm";
import ResetPasswordForm from "../modules/Authentication/resetPasswordForm";
import RegisterPage from "../modules/Authentication/registerPage";
import RegisterForm from "../modules/Authentication/registerForm";
import VerifyEmailForm from "../modules/Authentication/verifyEmailForm";

export const Auth = ({ children }) => {
  const token = localStorage.getItem('token');
  if (token && AuthAPI.isAuthenticated()) return <Navigate to="/dashboard" replace />;
  return children;
}

export const AUTH_ROUTES = [
  {
    path: '/login',
    component: () => <LoginPage><LoginForm /></LoginPage>
  },
  {
    path: '/forgot-password',
    component: () => <LoginPage><ResetPasswordForm /></LoginPage>
  },
  {
    path: '/register',
    component: () => <RegisterPage><RegisterForm /></RegisterPage>
  },
  {
    path: '/verify-email',
    component: () => <RegisterPage><VerifyEmailForm /></RegisterPage>
  },
];