import React, { useState } from "react";
import DefaultPhotos from "./data";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";
import { generateImage, popupActive } from "../../../Redux/imageAISlice";
import { includes } from "lodash";
import GeneratedImage from "./generatedImage";
import PhotoHistory from "./photoHistory";
import { useTranslation } from "react-i18next";


const MasonryLayout = ({ setKeywords ,activeTab }) => {

  const lng = localStorage.getItem("i18nextLng");

  const { t } = useTranslation();
  const tSect = "khudi-Photo";

  const generatedImg = useSelector((state) => state.imageAI.images);
  const isLoading = useSelector((state) => state.imageAI.loadingGenerate);
  const historyLoading = useSelector((state) => state.imageAI.ishistoryLoading);


  const handlePrompt = (item) => {
    if (item.id == 5) {
      setKeywords(
        "woman with a slightly slanted cyberpunk theme facing to the right "
      );
    }
  };



  return (
    <div>
      {isLoading  || historyLoading == true ? (
        <div className="flex justify-center items-center h-[200px]">
          <Spinner className="animate-spin h-10 w-10" />
        </div> 
      ):""}

      {!isLoading && !historyLoading && (
        <>
          {/* <div className="w-full h-[50px]  mt-[15px] grid grid-cols-2 ">
            <div
              onClick={() => handleTab("home")}
              className={`cursor-pointer  border-b ${
                activeTab == "home"
                  ? "border-t-0 border-l-0 border-r-0  border-b hover:border-b-[#1D742C]   border-[#1D742C] border-4 "
                  : " border-0"
              }  flex items-center justify-center ltr:font-InterLight rtl:font-Cairo `}
            >
              {t(`${tSect}.home`)}
            </div>
            <div
              onClick={() => handleTab("history")}
              className={`cursor-pointer border-b ${
                activeTab == "history"
                  ? "border-t-0 border-l-0 border-r-0  border-b hover:border-b-[#1D742C]  border-[#1D742C] border-2  "
                  : " border-0"
              }   flex items-center justify-center ltr:font-InterLight rtl:font-Cairo `}
            >
               {t(`${tSect}.history`)}
            </div>
          </div> */}

          {activeTab == "home" ? (
            <div className="w-full h-auto sm:flex-col lg:columns-4 gap-3 space-y-3 pb-28 pt-10 ">
              {generatedImg?.length > 0 &&
                generatedImg.map((item, index) => (
                  <GeneratedImage
                    key={index}
                    src={item && item.attachmentURL}
                    attachmentName={item.attachmentName}
                  />
                ))}

              {generatedImg?.length === 0 &&
                DefaultPhotos.map((item, index) => {
                  return (
                    <div
                      onClick={() => handlePrompt(item)}
                      key={index}
                      className={`sm:w-full md:w-full lg:w-auto flex items-center justify-center ${
                        item.id == 5
                          ? "cursor-pointer hover:scale-105 transition-all "
                          : " cursor-default "
                      }  `}
                    >
                      <img
                        src={`/media/Masonry/${item.Photo}.svg`}
                        className="w-full"
                        alt=""
                      />
                    </div>
                  );
                })}
            </div>
          ) : (
            <PhotoHistory />
          )}
        </>
      )}
    </div>
  );
};

export default MasonryLayout;
