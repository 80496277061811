import React, { useEffect, useState } from "react";
import SelectBox from "../../../shared/SelectNormal/selectBox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generateImage } from "../../../Redux/imageAISlice";
import { resetGeneratedImages } from "../../../Redux/imageAISlice";
import { getProfile } from "../../../Redux/profileSlice";
import { setIsUpgradeActive } from "../../../Redux/subscriptionSlice";
import "./index.css"

const KhudiPhotoSearch = ({ keywords, setKeywords ,setActiveTab,activeTab }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tSect = "khudi-Photo";
  const loadingGenerate = useSelector((state) => state.imageAI.loadingGenerate);
  const countWordLeft = useSelector((state) => state.profile.countWordLeft);

  const [PixelValue, setPixelValue] = useState("");
  const [photoQuality, setPhotoQuality] = useState("");
  const [NumberValue, setNumberValue] = useState("");
  const [GeneratingSource, setGeneratingSource] = useState("");

  const onChangeKeywords = (e) => {
    setKeywords(e.target.value);
  };

  const handleGenerate = () => {
    if (!countWordLeft) {
      dispatch(setIsUpgradeActive(true));
      return;
    } else {
      if (!keywords) return;

      if (GeneratingSource == "Khudi Generator 2") {
        const payload = {
          chat: keywords,
          NoOfImages: NumberValue,
          imageGenerationSource: "openAiCreateImage",
        };
        dispatch(generateImage(payload)).then((res) => {
          //update count words left
          dispatch(getProfile());
        });
      } else {
        const payload = {
          chat: keywords,
          quality: photoQuality == "HD" ? "hd" : "",
          imageSize: PixelValue,
          NoOfImages: 1,
          imageGenerationSource:
            GeneratingSource == "Khudi Generator 1"
              ? "openAiCreateImageDell3"
              : "openAiCreateImage",
        };
        dispatch(generateImage(payload)).then((res) => {
          //update count words left
          dispatch(getProfile());
        });
      }
    }
  };

  const handleHome = () => {
    setActiveTab("home")
    dispatch(resetGeneratedImages())
  }

  return (
    <div
      className="w-full h-auto rounded-[20px] bg-[gray] bg-cover    "
      style={{
        backgroundImage: `url('/media/background/background.jpeg')`,
        backgroundPosition: "center",
      }}
    >
      <div className="w-full h-full flex flex-col items-center justify-start gap-4 p-6 ">
        {/****Heading Khudi Photo*/}
        <div className="flex flex-col gap-2 items-center justify-center ">
          <h2 className="text-[36px] font-InterLight ltr:font-InterLight  rtl:font-Cairo font-medium text-white text-center">
            {t(`${tSect}.heading`)}
          </h2>
          <h3 className="text-[19px] mt-[20px] font-InterLight ltr:font-InterLight  rtl:font-Cairo font-normal text-[#FFFFFF] tracking-[1px] text-center">
            {t(`${tSect}.paragraph1`)}
          </h3>
        </div>
        {/****Khudi Photo Search  */}
        <div className=" w-full flex sm:flex-col  lg:flex-row gap-4">
          <div className="w-full h-[44px] py-2 px-4 bg-white rounded-md pt-[10px] flex items-center gap-2 ">
            <img src="/Icons/khudiPhoto/cpu.svg" alt="" />
            <input
              type="text"
              placeholder={t(`${tSect}.placeholder`)}
              className=" outline-none w-[70%] h-full font-InterLight ltr:font-InterLight  rtl:font-Cairo font-normal text-[16px] "
              value={keywords}
              onChange={onChangeKeywords}
              onKeyDown={({ key }) => {
                if (key === "Enter" && !loadingGenerate) {
                  handleGenerate();
                }
              }}
            />
          </div>
          <button
            className="px-6 py-3 rounded-md bg-[#1D742C] cursor-pointer flex flex-row gap-2 items-center justify-center 
              hover:enabled:bg-[#175B22]
              disabled:bg-[#98C5A0] disabled:cursor-not-allowed
            "
            disabled={loadingGenerate}
            onClick={handleGenerate}
          >
            <img src="/Icons/khudiPhoto/rocket-white.svg" alt="" />
            <span className="text-[14px] text-white font-InterLight ltr:font-InterLight  rtl:font-Cairo font-semibold tracking-[1.5px] ">
              {t(`${tSect}.btn-generate`)}
            </span>
          </button>
        </div>
        {/***Khudi Filter Select **/}
        <div className="bg-white flex flex-row gap-2 items-center px-4 py-2 rounded-md flex-wrap  ">
          <span className="text-[#667085] font-InterLight ltr:font-InterLight relative  rtl:font-Cairo font-normal tracking-[0.5px] ">
            Square
          </span>

          {GeneratingSource == "Khudi Generator 1" && (
            <SelectBox
              option1={"1024x1024"}
              option2={"1024x1792"}
              setSelectBoxValue={setPixelValue}
              border={"0px"}
              px={"0px"}
              py={"0px"}
              arrow={false}
              underline={true}
              textColor={"#208030"}
              height={"20px"}
              width={"100px"}
            />
          )}
          {GeneratingSource == "Khudi Generator 2" && (
            <SelectBox
              option1={"256x256"}
              option2={"512x512"}
              option3={"1024x1024"}
              setSelectBoxValue={setPixelValue}
              border={"0px"}
              px={"0px"}
              py={"0px"}
              arrow={false}
              underline={true}
              textColor={"#208030"}
              height={"20px"}
              width={"100px"}
            />
          )}
          <span className="text-[#667085] font-InterLight ltr:font-InterLight  rtl:font-Cairo font-normal tracking-[0.5px] ">
            {t(`${tSect}.result`)}
          </span>
          {GeneratingSource == "Khudi Generator 2" ? (
            <SelectBox
              option1={"2"}
              option2={"1"}
              option3={"3"}
              setSelectBoxValue={setNumberValue}
              border={"0px"}
              px={"0px"}
              py={"0px"}
              arrow={false}
              underline={true}
              textColor={"#208030"}
              height={"20px"}
            />
          ) : (
            ""
          )}
          <span className="text-[#667085] font-InterLight ltr:font-InterLight  rtl:font-Cairo font-normal tracking-[0.5px] ">
            {t(`${tSect}.using`)}
          </span>
          <SelectBox
            option1={"Khudi Generator 1"}
            option2={"Khudi Generator 2"}
            setSelectBoxValue={setGeneratingSource}
            border={"0px"}
            px={"0px"}
            py={"0px"}
            arrow={false}
            underline={true}
            textColor={"#208030"}
            height={"20px"}
            width={"160px"}
          />
          <span className="text-[#667085] font-InterLight ltr:font-InterLight  rtl:font-Cairo font-normal tracking-[0.5px] ">
            {t(`${tSect}.Image-Source`)}
          </span>

          {GeneratingSource == "Khudi Generator 1" ? (
            <span className="text-[#667085] font-InterLight ltr:font-InterLight  rtl:font-Cairo font-normal tracking-[0.5px] ">
              {t(`${tSect}.in`)}
            </span>
          ) : (
            ""
          )}

          {GeneratingSource == "Khudi Generator 1" ? (
            <SelectBox
              option1={"Normal"}
              option2={"HD"}
              setSelectBoxValue={setPhotoQuality}
              border={"0px"}
              px={"0px"}
              py={"0px"}
              arrow={false}
              underline={true}
              textColor={"#208030"}
              height={"20px"}
              width={"65px"}
            />
          ) : (
            ""
          )}
          {GeneratingSource == "Khudi Generator 1" ? (
            <span className="text-[#667085] font-InterLight ltr:font-InterLight  rtl:font-Cairo font-normal tracking-[0.5px] ">
              {t(`${tSect}.photo-quality`)}
            </span>
          ) : (
            ""
          )}
        </div>
        {/**Some Promium Text**/}
        <div className="inline-flex  ">
          <p className="text-[#61FF76] text-[14px] font-InterLight ltr:font-InterLight  rtl:font-Cairo font-normal tracking-[1px] ">
            {t(`${tSect}.small-paragraph`)}
          </p>
        </div>
      </div>
      {/**Photo Tabs**/}
      <div className="w-full h-[70px] flex flex-row bg-green-200  rounded-md">
        <div onClick={handleHome} className={`w-[50%] relative tab ${activeTab == "home"?"active-pho-tab":"deactive-pho-tab border-b-[5px] border-gray-200  "}   cursor-pointer h-full flex flex-col items-center justify-center bg-gradient-to-r from-indigo-800 via-black to-indigo-900 `}>
          <h2 className="ltr:font-InterLight rtl:font-Cairo sm:text-[14px] md:text-[16px] font-[500] text-white tracking-wide ">
          {t(`${tSect}.tab1-title`)}
          </h2>
          <p className="ltr:font-InterLight rtl:font-Cairo text-[12px] font-[300] text-white tracking-wide ">
          {t(`${tSect}.tabe1-p`)}
          </p>
        </div>
        <div onClick={()=>setActiveTab("history")} className={`w-[50%] relative tab  ${activeTab == "history"?"active-pho-tab":"deactive-pho-tab border-b-[5px] border-gray-200 "} cursor-pointer h-full flex flex-col items-center justify-center bg-[#232323] `}>
          <h2 className="ltr:font-InterLight rtl:font-Cairo sm:text-[14px] md:text-[16px] font-[500] text-white tracking-wide ">
          {t(`${tSect}.tab2-title`)}
          </h2>
          <p className="ltr:font-InterLight rtl:font-Cairo text-[12px] font-[300] text-white tracking-wide ">
          {t(`${tSect}.tabe2-p`)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default KhudiPhotoSearch;
