import React,{useState} from "react";
import TemplateCards from "../Cards/templateCard";
import { useTranslation } from "react-i18next";

const All = () => {

  const { t } = useTranslation();
  const generalWriting = "templates.general-Writing";
  const socialMedia = "templates.social-media";
  const articleBlog = "templates.article-and-blogs"
  const adsMarketing = "templates.ads-and-marketing"

  return (
    <div className="w-full h-auto flex flex-col gap-4 ">
      <div className="w-full h-auto flex flex-row flex-wrap gap-4 ">
      <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${articleBlog}.ai-article-ideas-v2.title`)}
          desc={t(`${articleBlog}.ai-article-ideas-v2.description`)}
          route={"ai-article-ideas"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${articleBlog}.ai-article-intros.title`)}
          desc={t(`${articleBlog}.ai-article-intros.description`)}
          route={"ai-article-intros"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${articleBlog}.ai-article-outlines-v2.title`)}
          desc={t(`${articleBlog}.ai-article-outlines-v2.description`)}
          route={"ai-article-outlines-v2"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${articleBlog}.ai-article-outlines.title`)}
          desc={t(`${articleBlog}.ai-article-outlines.description`)}
          route={"ai-article-outlines"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${articleBlog}.ai-blog-titles.title`)}
          desc={t(`${articleBlog}.ai-blog-titles.description`)}
          route={"ai-blog-title"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${articleBlog}.ai-conclusion-writer.title`)}
          desc={t(`${articleBlog}.ai-conclusion-writer.description`)}
          route={"ai-conclusion-writer"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${articleBlog}.ai-paragraph-writer.title`)}
          desc={t(`${articleBlog}.ai-paragraph-writer.description`)}
          route={"ai-paragraph-writer"}
          isNew={false}
        />
         <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${articleBlog}.ai-article-writer-5.0.title`)}
          desc={t(`${articleBlog}.ai-article-writer-5.0.description`)}
          route={"ai-article-writer"}
          isNew={false}
        />
        <TemplateCards
          Icon={"sms.png"}
          title={t(`${adsMarketing}.app-and-sms-notifications.title`)}
          desc={t(`${adsMarketing}.app-and-sms-notifications.description`)}
          route={"app-and-sms-notifications"}
          isNew={false}
        />
        <TemplateCards
          Icon={"facebook-blue.svg"}
          title={t(`${adsMarketing}.facebook-ads.title`)}
          desc={t(`${adsMarketing}.facebook-ads.description`)}
          route={"facebook_ads"}
          isNew={false}
        />
        <TemplateCards
          Icon={"ads.png"}
          title={t(`${adsMarketing}.google-ads-description.title`)}
          desc={t(`${adsMarketing}.google-ads-description.description`)}
          route={"google-ads-description-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"ads.png"}
          title={t(`${adsMarketing}.google-ads-title.title`)}
          desc={t(`${adsMarketing}.google-ads-title.description`)}
          route={"google-ads-title-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"linkedin.svg"}
          title={t(`${adsMarketing}.linkedin-ad-headlines.title`)}
          desc={t(`${adsMarketing}.linkedin-ad-headlines.description`)}
          route={"linkedin-ads-headlines"}
          isNew={false}
        />
        <TemplateCards
          Icon={"linkedin.svg"}
          title={t(`${adsMarketing}.linkedin-ad-description.title`)}
          desc={t(`${adsMarketing}.linkedin-ad-description.description`)}
          route={"linkedin-ads-descriptions"}
          isNew={false}
        />
        <TemplateCards
          Icon={"linkedin.svg"}
          title={t(`${adsMarketing}.linkedin-ad-v2.title`)}
          desc={t(`${adsMarketing}.linkedin-ad-v2.description`)}
          route={"linkedin-ads-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.personal-bio-v2.title`)}
          desc={t(`${generalWriting}.personal-bio-v2.description`)}
          route={"personal-bio-v2"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.text-extender.title`)}
          desc={t(`${generalWriting}.text-extender.description`)}
          route={"text-extender"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.company-bios.title`)}
          desc={t(`${generalWriting}.company-bios.description`)}
          route={"company-bio-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"quora.png"}
          title={t(`${generalWriting}.quora-answers.title`)}
          desc={t(`${generalWriting}.quora-answers.description`)}
          route={"quora-answers"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.content-shorten.title`)}
          desc={t(`${generalWriting}.content-shorten.description`)}
          route={"content-shorten"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.stories.title`)}
          desc={t(`${generalWriting}.stories.description`)}
          route={"stories"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.bullet-point-answers.title`)}
          desc={t(`${generalWriting}.bullet-point-answers.description`)}
          route={"bullet-point-answers"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.passive-to-active.title`)}
          desc={t(`${generalWriting}.passive-to-active.description`)}
          route={"passive-to-active"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.definition.title`)}
          desc={t(`${generalWriting}.definition.description`)}
          route={"definition"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.answers.title`)}
          desc={t(`${generalWriting}.answers.description`)}
          route={"answers"}
          isNew={false}
        />
        <TemplateCards
          Icon={"email.png"}
          title={t(`${generalWriting}.email-v2.title`)}
          desc={t(`${generalWriting}.email-v2.description`)}
          route={"email-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.pros&cons.title`)}
          desc={t(`${generalWriting}.pros&cons.description`)}
          route={"pros-and-cons"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.rewrite-with-keywords.title`)}
          desc={t(`${generalWriting}.rewrite-with-keywords.description`)}
          route={"rewrite-with-keywords"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.email-subjects-lines.title`)}
          desc={t(`${generalWriting}.email-subjects-lines.description`)}
          route={"rewrite-with-keywords"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.cold-email-v2.title`)}
          desc={t(`${generalWriting}.cold-email-v2.description`)}
          route={"cold-email-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${generalWriting}.company-mission.title`)}
          desc={t(`${generalWriting}.company-mission.description`)}
          route={"company-mission"}
          isNew={false}
        />

        <TemplateCards
          Icon={"xtwitter.png"}
          title={t(`${socialMedia}.twitter-thread-v2.title`)}
          desc={t(`${socialMedia}.twitter-thread-v2.description`)}
          route={"twitter-thread-v2"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${socialMedia}.social-media-content-plan.title`)}
          desc={t(`${socialMedia}.social-media-content-plan.description`)}
          route={"social-media-content-plan"}
          isNew={false}
        />
        <TemplateCards
          Icon={"linkedin.svg"}
          title={t(`${socialMedia}.linkedin-posts-v2.title`)}
          desc={t(`${socialMedia}.linkedin-posts-v2.description`)}
          route={"linkedin-posts-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"instagram.png"}
          title={t(`${socialMedia}.instagram-captions.title`)}
          desc={t(`${socialMedia}.instagram-captions.description`)}
          route={"instagram-captions"}
          isNew={false}
        />
        <TemplateCards
          Icon={"bird.png"}
          title={t(`${socialMedia}.twitter-tweets.title`)}
          desc={t(`${socialMedia}.twitter-tweets.description`)}
          route={"twitter-tweets"}
          isNew={false}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${socialMedia}.youtube-description-v2.title`)}
          desc={t(`${socialMedia}.youtube-description-v2.description`)}
          route={"youtube-description-v2"}
          isNew={true}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${socialMedia}.youtube-intro-v2.title`)}
          desc={t(`${socialMedia}.youtube-intro-v2.description`)}
          route={"youtube-intro-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"tiktok.png"}
          title={t(`${socialMedia}.tiktok-video-script.title`)}
          desc={t(`${socialMedia}.tiktok-video-script.description`)}
          route={"tiktok-video-script"}
          isNew={false}
        />
        <TemplateCards
          Icon={"tiktok.png"}
          title={t(`${socialMedia}.tiktok-video-hooks.title`)}
          desc={t(`${socialMedia}.tiktok-video-hooks.description`)}
          route={"tiktok-video-hooks"}
          isNew={false}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${socialMedia}.youtube-ideas.title`)}
          desc={t(`${socialMedia}.youtube-ideas.description`)}
          route={"youtube-ideas"}
          isNew={false}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${socialMedia}.youtube-outlines.title`)}
          desc={t(`${socialMedia}.youtube-outlines.description`)}
          route={"youtube-outlines"}
          isNew={true}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${socialMedia}.youtube-titles.title`)}
          desc={t(`${socialMedia}.youtube-titles.description`)}
          route={"youtube-title"}
          isNew={false}
        />
      </div>
      <div className="w-full h-[10px] block"></div>
    </div>
  );
};

export default All;
