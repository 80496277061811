import { useEffect } from "react";
import AuthAPI from "../../services/auth";

const Logout = () => {
  useEffect(() => {
    AuthAPI.logout();
  },[])
  return <></>
}

export default Logout