import React, { useEffect, useState, useRef } from "react";
import Option from "./option";

const SelectBox = ({
  option1,
  option2,
  option3,
  setSelectBoxValue,
  border,
  px,
  py,
  arrow,
  underline,
  textColor,
  height,
  width,
}) => {
  const wrapperRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [SelectValue, setSelectValue] = useState(option1);

  const handleDropDown = () => {
    setIsOpen(isOpen ? false : true);
  };

  useEffect(() => {
    setSelectBoxValue(SelectValue);
  }, [SelectValue]);

  useEffect(() => {
    let handler = (e) => {
      if (!wrapperRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div
      ref={wrapperRef}
      className={`bg-white  h-auto relative w-auto `}
     
    >
      <div
        onClick={handleDropDown}
        className={`w-full h-full cursor-pointer flex items-center  justify-between rounded-md border-[${border}] px-[${px}] py-[${py}] `}
      >
        <span
          className={`text-[${textColor}] font-InterLight font-normal tracking-[1px]  ${
            underline ? "underline" : ""
          }  `}
        >
          {SelectValue}
        </span>
        {arrow && (
          <svg
            className={` transition-all ${!isOpen ? "rotate-0" : "rotate-180"}`}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5 7.5L10 12.5L15 7.5"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </div>
      <div
       style={{ width: width }}
        className={` p-2 h-auto  transition-all   ${
          isOpen ? "  opacity-100 scale-100 " : "  opactiy-0  scale-0 "
        } py-2 flex-col gap-2 bg-white shadow-sm border-[1px] rounded-md absolute top-[45px] left-[-2px] z-40`}
      >
        <Option
          option={option1}
          SelectValue={SelectValue}
          setSelectValue={setSelectValue}
          setIsOpen={setIsOpen}
          textColor={textColor}
        />
        {option2 && (
          <Option
            option={option2}
            SelectValue={SelectValue}
            setSelectValue={setSelectValue}
            setIsOpen={setIsOpen}
            textColor={textColor}
          />
        )}
        {option3 && (
          <Option
            option={option3}
            SelectValue={SelectValue}
            setSelectValue={setSelectValue}
            setIsOpen={setIsOpen}
            textColor={textColor}
          />
        )}
      </div>
    </div>
  );
};

export default SelectBox;
