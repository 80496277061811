import axios from 'axios';
import { API_URL, TIMEOUT } from './constants';

let HEADERS = {'Content-Type': 'application/json'};

// Default API Service
class ApiService {
  constructor({ baseURL = API_URL, timeout = TIMEOUT, headers = HEADERS }) {
    const client = axios.create({
      baseURL,
      timeout,
      headers,
    });

    this.token = this.setHeaders();

    client.interceptors.response.use(this.handleSuccess, this.handleError);
    this.client = client;
  }

  setHeaders(tokens) {
    const newHeaders = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + tokens
    }
    
    const client = axios.create({
      baseURL: API_URL,
      timeout: TIMEOUT,
      headers: newHeaders,
    });

    this.client = client;
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    return Promise.reject(error);
  }

  get(path, config) {
    return this.client.get(path, config).then(response => response.data);
  }

  post(path, payload, param = null) {
    return this.client.post(path, payload, param).then(response => response.data);
  }

  put(path, payload, param = null) {
    return this.client.put(path, payload, param).then(response => response.data);
  }

  patch(path, payload) {
    return this.client.patch(path, payload).then(response => response.data);
  }

  delete(path, payload = {}) {
    return this.client.delete(path, payload).then(response => response.data);
  }
}

export default ApiService;
