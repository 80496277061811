import React from 'react'
import FeatureHeader from './components/FeatureHeader'
import ArticleAndBlog from './components/ArticleAndBlog'
import Footer from '../../shared/Footer/footer'
import CreateContent from '../Pricing/Components/createContent'
import Section4 from "../../shared/Section4/section4"
import Header from '../../shared/Header2/header'
import { useTranslation } from "react-i18next";

const Feature = () => {

  const { t } = useTranslation();
  const tSect = "feature.Cards";

  

  const TitleArray=[t(`${tSect}.heading-articel-blog`),t(`${tSect}.heading-ecommerce-product-desc`),t(`${tSect}.heading-ads-marketing`),t(`${tSect}.heading-other-feature`)]
  return (
    <div className='w-full min-h-[100vh] block bg-[black] pt-[20px] ' >
      <div className='w-full h-auto block bg-black' >
        <div className='w-[80%] sm:w-[90%] md:w-[80%] max-w-7xl mx-auto '>
          <Header/>
          <FeatureHeader />
        </div>
      </div>

      <div className='w-full h-auto block bg-[#0A0D0E]  rounded-t-[60px] ' >
        <div className='w-[80%] sm:w-[90%] md:w-[80%] max-w-7xl mx-auto '>
          {TitleArray.map((title)=>(
            <ArticleAndBlog title={title}/>
          ))}
        </div>
      </div>
      <div className='w-full h-auto block  ' >
         <Section4 />
      </div>

      <div className='w-full h-auto block '>
        <Footer />
      </div>
    </div>
  )
}

export default Feature