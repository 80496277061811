import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HeaderTemplate = ({ isSideBarActive, setSideBarActive ,menuRef }) => {

  const navigate = useNavigate();
  const goBack = () => {
    navigate('/library');
  }

  const countWordLeft = useSelector(state => state.profile.countWordLeft);

  const handleSideBar = () => {
    setSideBarActive(
      isSideBarActive ? false : true
    )
  }

  return (
    <div className='w-full h-[60px] bg-[#151718] flex flex-row gap-2 px-4 ' >
      <div className='w-[35%] sm:w-[15%] md:w-[50%] lg:w-[35%] h-full flex items-center gap-4 ' >
        <div 
          className="bg-[#25292E] sm:hidden md:flex h-[40px] cursor-pointer hover:bg-[#2b2f35] p-3 gap-4 flex flex-row items-center rounded-md "
          onClick={goBack}
        >
          <img src="/Icons/header/back.svg" alt='' />
          <span className="text-[14px] text-white tracking-[1px] font-InterLight font-normal ">
            Back
          </span>
        </div>
        <div className=" ">
          {/* <img src="/Icons/arrow.svg" alt='' />
          <span className="text-[14px] text-white tracking-[1px] font-InterLight font-normal ">
            Create New Workspace
          </span>
          <img src="/Icons/chevron-right.svg" alt='' /> */}
        </div>
        <div ref={menuRef} className='p-2  sm:flex md:hidden inline-flex items-center rounded-md cursor-pointer ' >
          <img onClick={handleSideBar} src='/Icons/header/menu.svg' className=' hover:scale-105 transition-all ' alt='' />
        </div>
      </div>
      <div className='w-[30%] sm:w-[70%] md:w-[50%] lg:w-[30%] h-full flex items-center sm:justify-center md:justify-end lg:justify-center gap-2 ' >
        <div className=' ' >
          {/* <img src='/Icons/header/maximize.svg' className=' hover:scale-105 transition-all ' alt='' /> */}
        </div>
        <div className=' ' >
          {/* <img src='/Icons/header/message-square.svg' className=' hover:scale-105 transition-all ' alt='' /> */}
        </div>
        <div className='' >
          {/* <img src='/Icons/header/layout.svg' className=' hover:scale-105 transition-all ' alt='' /> */}
        </div>
      </div>
      <div className='w-[35%] sm:w-[15%] md:w-[50%] lg:w-[35%] sm:flex md:hidden lg:flex  h-full flex items-center justify-items  justify-end ' >
        <div className="bg-[#1D2939] h-[40px] sm:hidden lg:flex p-3 gap-2 flex flex-row items-center rounded-md ">
          <img src="/Icons/current.svg" alt='' />
          <span className="text-[16px] font-InterLight font-bold tracking-[1px]  bg-clip-text text-transparent bg-gradient-to-r from-[#62FE76] to-[#0BA5EC] ">
            {countWordLeft}
          </span>
          <span className="text-[14px] text-[#61FF76] tracking-[1px] font-InterLight font-light  ">
            Words Left
          </span>
        </div>
        <div className='p-2 sm:flex md:hidden inline-flex items-center rounded-md cursor-pointer ' >
        <div 
          className="bg-[#25292E] sm:flex md:flex h-[40px] w-[100px] cursor-pointer hover:bg-[#2b2f35] p-3 gap-4 flex flex-row items-center rounded-md "
          onClick={goBack}
        >
          <img src="/Icons/header/back.svg" alt='' />
          <span className="text-[14px] text-white tracking-[1px] font-InterLight font-normal ">
            Back
          </span>
        </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderTemplate
