import React, { useEffect, useState } from 'react'
import RecentBlogs from "./Components/RecentBlogs"
import AllBolgs from './Components/AllBolgs'
import Header from './Components/Header'
import Footer from '../../shared/Footer/footer'
import ReactPaginate from 'react-paginate';
import Pagination from './Components/Pagination'
import Language from '../Language'
import NavBar from '../../shared/Header2/header'

const Blogs = ({isSideBarActive,setisSideBarActive}) => {

  return (
    <div className='w-full min-h-[100vh] block ' >
       <div className='w-full h-auto block bg-black pt-[20px] ' >
          <div className='w-[80%] sm:w-[90%] md:w-[80%] max-w-7xl mx-auto '>
            <NavBar/>
            <Header setisSideBarActive={setisSideBarActive} />
          </div>
      </div>
       <div className='w-full h-auto block bg-[#0A0D0E] pt-[20px]' >
        <div className='w-[80%] sm:w-[90%] md:w-[80%] max-w-7xl mx-auto ' >
          <RecentBlogs />
          <AllBolgs />
        </div>
       </div>
      <div className='w-full h-auto block bg-[#000000]' >
        <Pagination />
      </div>
      <div className='px-6 sm:px-16 md:px-16 lg:px-16' style={{ background: "#0A0D0E" }}>
        <Footer />
      </div>

    </div>

  )
}

export default Blogs