import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { checkout } from "../../../Redux/TogglerSlice";
import { useDispatch, useSelector } from "react-redux";

import PackageAPI from "../../../services/packages";



const ChargesCard2 = () => {

  const Navigate = useNavigate();
  const { t } = useTranslation();
  const tSect = "pricing.charges-card-2";
  const dispatch = useDispatch();

  const Billed = useSelector((state) => state.toggler.Billed);

  const [loading, setLoading] = useState();
  const [error, setErrors] = useState(false);
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("i18nextLng");


  // const Package = async (payload) => {
  //   const res = await PackageAPI.subscribePackage(payload);
  //   window.location.replace(res.redirectUrl);
  // };

  const handlePayment = async (subscriptionId) => {
    const payload = {
      subscriptionId: subscriptionId,
      language: lang,
    };

     if(token){
      try{
        setLoading(true);
        const res = await PackageAPI.subscribePackage(payload);
        window.location.replace(res.redirectUrl);
      }catch(err){
        setLoading(false);
      }
     }
     else{
      dispatch(checkout(true))
      Navigate("/register")
     }
    
  };

  return (
    <>
      <div class="relative  p-4 p-6     border-green-800 p-4 rounded-lg  lg:border-green-0 md:border-green-800  lg:w-[400px] ">
        <h2 class="text-3xl font-bold text-white pt-2 ltr:font-InterLight rtl:font-Cairo ">
          {t(`${tSect}.text-custom`)}
        </h2>
        <p class="text-sm dark-gray leading-6 mt-2 ltr:font-InterLight rtl:font-Cairo ">
          {t(`${tSect}.paragraph-1`)}
        </p>
        <p class="text-base text-white leading-6 mt-6">
          <span class="text-5xl font-bold ">
            {t(
              `${tSect}.${Billed == "monthly" ? "text-free" : "pricingYearly"}`
            )}

          </span>
          <sub class="text-sm">
            {t(`${tSect}.${Billed == "monthly" ? "monthly" : "yearly"}`)}
          </sub>
        </p>

        <p class="text-sm dark-gray leading-6 mt-6 ltr:font-InterLight rtl:font-Cairo ">
          {t(`${tSect}.p1`)}
        </p>
        <p class="text-sm text-green-400 leading-6 mt-2 ltr:font-InterLight rtl:font-Cairo">
          {" "}
          {t(`${tSect}.p2`)}
        </p>

        <div class="flex justify-center items-center my-8">
          <button
           onClick={
            Billed == "monthly"
              ? () => handlePayment("64667237c04e1457f169f2d7")
              : () => handlePayment("646672e0c04e1457f169f2dd")
          }
            className="flex w-full pricing-btn-color  items-center gap-2 justify-center border pricing-border font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-lg leading-6 rounded-lg py-2 "
          >
             {loading ? (
              <svg
                className="animate-spin m-auto h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <>
                <MdOutlineRocketLaunch /> {t(`${tSect}.sigin`)}
              </>
            )}
          </button>
        </div>

        <p class="flex items-center text-sm pricing-chat-clr mt-2 py-2">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">
            {t(`${tSect}.line1`)}
          </span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">
            {t(`${tSect}.line2`)}
          </span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">
            {t(`${tSect}.line3`)}
          </span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">
            {t(`${tSect}.line4`)}
          </span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">
            {t(`${tSect}.line5`)}
          </span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">
            {t(`${tSect}.line6`)}
          </span>
        </p>
      </div>
    </>
  );
};
export default ChargesCard2;
