import React from 'react'
import { useTranslation } from "react-i18next";

const BlogCardLarge = () => {

    const {t} = useTranslation();
    const tSect = "blog.recent-blogs";

    return (
        <div className='bg-transparent'>
            <div class="h-52 sm:h-96 md:h-[30rem] lg:h-96 rounded-lg bg-gray-300 mb-4">
                <img src="https://images.unsplash.com/photo-1661956600654-edac218fea67?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1636&q=80" alt="Image" className='object-cover h-full w-full rounded-lg' />
            </div>
            <label class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-base text-gray-400">{t(`${tSect}.large-blog-name`)}</label>
            <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium text-2xl text-white my-4 line-clamp-2">
            </h1>{t(`${tSect}.large-blog-heading`)}
            <p class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-base leading-5 line-clamp-3 text-gray-400 ">{t(`${tSect}.large-blog-paragraph`)}</p>
            <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.large-copy-writing`)}</span>
            <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.large-ai-blog`)}</span>
            <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.large-ai-copy`)}</span>
        </div>
    )
}


export default BlogCardLarge