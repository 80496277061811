import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setIsUpgradeActive } from "../../../Redux/subscriptionSlice";

const MiniSideBar = ({ setExpandSideBar, expandSideBar }) => {
  const dispatch = useDispatch()
  const Navigate = useNavigate()
  const profile = useSelector((state) => state.profile);

  const handleUpgrade = () => {
    dispatch(setIsUpgradeActive(true));
  }

  const handleSideBar = () => {
    setExpandSideBar(!expandSideBar)
  }

  return (
    <div className='w-[80px] h-[100vh] bg-[#151718] flex flex-col gap-8 pt-[35px] items-center overflow-y-auto sm:hidden md:flex ' >
      <img onClick={handleSideBar} src='/Icons/sidebar/menu-white.svg' className='h-[15px] cursor-pointer' alt='' />
      <img onClick={() => Navigate("/dashboard")} src='/Icons/sidebar/home.svg' className='h-[24px] cursor-pointer ' alt='' />
      <img onClick={() => Navigate("/library")} src='/Icons/sidebar/library.svg' className='h-[24px] cursor-pointer ' alt='' />
      <img onClick={() => Navigate("/dashboard")} src='/Icons/sidebar/history.svg' className='h-[21px] cursor-pointer ' alt='' />
      <img onClick={() => Navigate("/khudi-photo")} src='/Icons/sidebar/photo.svg' className='h-[21px] cursor-pointer ' alt='' />
      <img onClick={() => Navigate("/chat")} src='/Icons/sidebar/chat.svg' className='h-[21px] cursor-pointer ' alt='' />
      <hr className='border-bottom border-[#344054] border-[1px] w-[50%]' />
      <div className="relative">
        <img
          src={
            profile?.photoProfile
              ? profile.photoProfile
              : "/media/Ellipse 14.png"
          }
          className=" h-[35px] relative rounded-full"
          alt="pic-profile"
        />
        <img
          src="/Icons/dot.png "
          className="rounded-full absolute top-7 right-[-1px]"
          alt="dot-status"
        />
      </div>
      <div className=' w-full flex items-center justify-center ltr:border-l-[10px] ltr:border-[#61FF76] rtl:border-l-[10px] rtl:border-[#61FF76] rounded-[10px]  ' >
        <img src='/Icons/sidebar/settings-active.svg' className='bg-[#667085] bg-opacity-20 p-2 rounded-md cursor-pointer ' alt='' />
      </div>
      <img onClick={handleUpgrade} src='/Icons/sidebar/current.svg' className='bg-[#027A48] p-2 rounded-md cursor-pointer' alt='' />
    </div>
  )
}

export default MiniSideBar
