import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { popupActive } from "../../../Redux/imageAISlice";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";

const GeneratedImage = ({ index, src, attachmentName }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);

  const handlePopup = (index) => {
    const payload = {
      imageUrl: src,
      Activestate: true,
    };

    dispatch(popupActive(payload));
  };

  const handleDownload = async () => {
    setLoading(true);
    const API_URL =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_SERVER_URL
        : process.env.REACT_APP_PRODUCTION_SERVER_URL;

    const url = `${API_URL}image/download?attachmentName=${attachmentName}`;

    try {
      const response = await fetch(url);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Create a Blob from the response data
      const blob = await response.blob();
      // Create a temporary link and trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = attachmentName;
      link.click();
      setLoading(false);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <div
      key={index}
      className={`sm:w-full md:w-full lg:w-auto flex items-center justify-center  relative   `}
    >
      <img
        src={src}
        className="w-full  cursor-pointer rounded-md "
        onClick={() => handlePopup(index)}
        alt=""
      />
      <div className="w-full  h-[30px] absolute top-0 transition-opacity duration-300      ">
        {isLoading ? (
          <Spinner className="animate-spin  h-6 w-6 ltr:float-right rtl:float-left mr-2 mt-1 " />
        ) : (
          <img
            src="/Icons/down-image.svg"
            onClick={handleDownload}
            className=" h-[30px]  hover:scale-110 ltr:float-right transition-all rtl:float-left mr-2 mt-1 cursor-pointer "
          />
        )}
      </div>
    </div>
  );
};

export default GeneratedImage;
