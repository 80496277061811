import api from '../config/api';
const TOKEN = localStorage.getItem('token');
const header = TOKEN ? {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + TOKEN
} : { 'Content-Type': 'application/json' };

const API = new api({ headers: header });

// import { API } from "./api";

const UserAPI = {
  getProfile: (config) => API.get(`user/profile`, config),
  updatePassword: (payload) => API.post(`/user/update-password`, payload),
  updateName: (payload) => API.post(`/user/update-profile`, payload),
}

export default UserAPI;