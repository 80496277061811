import React from "react";
import ReviewSideCard from "./reviewSideCard";
import ReviewMidCard from "./reviewMidCard";
import { useTranslation } from "react-i18next";

const Review = () => {
  const { t } = useTranslation();
  const tSect = "pricing.review";

  return (
    <div class="mt-20">
      {/* <label class="font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] font-medium text-white px-4 py-1 text-lg rounded-lg bg-white bg-opacity-10  flex-inline justify-center">
      {t(`${tSect}.unlock-limit`)}
        <span class="text-blue-400"> {t(`${tSect}.New Features`)}</span>
      </label> */}

      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cairo  font-semibold  text-white text-4xl sm:text-4xl md:text-4xl lg:text-4xl tracking-[1px] mb-8 mt-4">
        {t(`${tSect}.p1`)}
      </h1>

      <div class="flex flex-col  md:flex-col lg:flex-row   lg:space-x-4  md:space-y-4 space-y-4 ">
        <div class="flex-auto lg:w-20 space-x-4 ">
          <div class="flex flex-col space-y-4">
            <div>
              {" "}
              <ReviewSideCard
                userName={t(`${tSect}.user1.card-user-name`)}
                position={t(`${tSect}.user1.position`)}
                heading1={t(`${tSect}.user1.heading1`)}
                paragraph={t(`${tSect}.user1.paragraph`)}
                brand="/Icons/dp/asana.svg"
              />
            </div>
            <div>
              {" "}
              <ReviewSideCard
                userName={t(`${tSect}.user2.card-user-name`)}
                position={t(`${tSect}.user2.position`)}
                heading1={t(`${tSect}.user2.heading1`)}
                paragraph={t(`${tSect}.user2.paragraph`)}
                brand="/Icons/dp/intercom.svg"
              />
            </div>
            <div>
              {" "}
              <ReviewSideCard
                userName={t(`${tSect}.user3.card-user-name`)}
                position={t(`${tSect}.user3.position`)}
                heading1={t(`${tSect}.user3.heading1`)}
                paragraph={t(`${tSect}.user3.paragraph`)}
                brand="/Icons/dp/jira.svg"
              />
            </div>
          </div>
        </div>
        <div class="flex-auto lg:w-60 space-x-4  ">
          <div class="flex flex-col space-y-4 ltr:m-0 rtl:mr-3 ">
            <div>
              <ReviewMidCard
                userName={t(`${tSect}.user7.card-user-name`)}
                position={t(`${tSect}.user7.position`)}
                heading1={t(`${tSect}.user7.heading1`)}
                paragraph={t(`${tSect}.user7.paragraph`)}
                brand="/Icons/dp/mailchimp.svg"
                
              />
            </div>
            <div>
              <ReviewMidCard
                userName={t(`${tSect}.user8.card-user-name`)}
                position={t(`${tSect}.user8.position`)}
                heading1={t(`${tSect}.user8.heading1`)}
                paragraph={t(`${tSect}.user8.paragraph`)}
                brand="/Icons/dp/workflowy.svg"
              />
            </div>
            <div>
              <ReviewMidCard
                userName={t(`${tSect}.user9.card-user-name`)}
                position={t(`${tSect}.user9.position`)}
                heading1={t(`${tSect}.user9.heading1`)}
                paragraph={t(`${tSect}.user9.paragraph`)}
                brand="/Icons/dp/jira.svg"
              />
            </div>
          </div>
        </div>
        <div class="flex-auto lg:w-20 space-x-4 ">
          <div class="flex flex-col space-y-4">
            <div>
              <ReviewSideCard
                userName={t(`${tSect}.user4.card-user-name`)}
                position={t(`${tSect}.user4.position`)}
                heading1={t(`${tSect}.user4.heading1`)}
                paragraph={t(`${tSect}.user4.paragraph`)}
                brand="/Icons/dp/zapier.svg"
              />
            </div>
            <div>
              <ReviewSideCard
                userName={t(`${tSect}.user5.card-user-name`)}
                position={t(`${tSect}.user5.position`)}
                heading1={t(`${tSect}.user5.heading1`)}
                paragraph={t(`${tSect}.user5.paragraph`)}
                brand="/Icons/dp/zendesk.svg"
              />
            </div>
            <div>
              <ReviewSideCard
                userName={t(`${tSect}.user6.card-user-name`)}
                position={t(`${tSect}.user6.position`)}
                heading1={t(`${tSect}.user6.heading1`)}
                paragraph={t(`${tSect}.user6.paragraph`)}
                brand="/Icons/dp/loom.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Review;
