import React from "react";
import { useTranslation } from "react-i18next";

const Introduction = () => {

  const {t} = useTranslation();
  const tSect = "blog-details.introduction";

  return (
    <div>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario  font-normal text-base md:text-base text-[#d0d5dd] lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-4">
      {t(`${tSect}.paragraph1`)}

      </p>

      <div class="w-720 h-px bg-gray-400 mt-6"></div>
      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold text-2xl text-white pt-8">
      {t(`${tSect}.heading`)}
      </h1>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	lg:text-xl  leading-relaxed text-base leading-6 dark-gray pt-5">
      {t(`${tSect}.intro-p-1`)}
        <span class="underline">diam curabitur</span> 
        {t(`${tSect}.intro-p-2`)}
      </p>

      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-5">
       {t(`${tSect}.intro-p-3`)}
        <span class="underline"> Eget diam </span> 
        {t(`${tSect}.intro-p-4`)}
      </p>

      <div class="mt-8 h-64 sm:h-[30rem] md:h-[30rem] lg:h-[30rem]  ">
        <img
          src="https://images.unsplash.com/photo-1542315204-8b368472a4e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          class="h-full w-full object-cover"
          alt="Image description"
        />
      </div>
      <p class="font-normal font-InterLight ltr:font-InterLight rtl:font-Cario text-xs leading-5 mt-4 text-[#d0d5dd] ">
      {t(`${tSect}.intro-p-5`)}
        <span class="underline text-[#d0d5dd] "> {t(`${tSect}.intro-p-6`)}</span>
      </p>

      <div className="flex items-center mt-8">
        <div className="border-l-2 border-green-500 pl-4">
          <p className="font-InterLight ltr:font-InterLight rtl:font-Cario  font-medium italic light-white text-white text-xl sm:text-3xl line-height-44 ml-0 sm:ml-4 pt-2">
          {t(`${tSect}.intro-p-7`)}
          </p>
          <p className="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal font-normal text-[text-[#d0d5dd]] text-base md:text-base	  lg:text-xl leading-relaxed dark-gray leading-6 pt-5 pl-2">
          {t(`${tSect}.intro-p-8`)}

          </p>
        </div>
      </div>

      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base	lg:text-xl text-[#d0d5dd] leading-relaxed text-base leading-6 dark-gray pt-2 mt-4">
      {t(`${tSect}.intro-p-9`)}
      </p>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base	lg:text-xl text-[#d0d5dd] leading-relaxed text-base leading-6 dark-gray pt-3">
      {t(`${tSect}.intro-p-10`)}
      </p>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base	lg:text-xl text-[#d0d5dd] leading-relaxed text-base leading-6 dark-gray pt-3">
      {t(`${tSect}.intro-p-11`)}
      </p>
    </div>
  );
};
export default Introduction;
