import { configureStore } from '@reduxjs/toolkit'
import sidebarSlice from './Redux/sidebarSlice'
import chatsSlice from './Redux/chatsSlice'
import profileSlice from './Redux/profileSlice'
import subscriptionSlice from './Redux/subscriptionSlice'
import librarySlice from './Redux/librarySlice'
import imageAISlice from './Redux/imageAISlice'
import historySlice from './Redux/historySlice'
import contactSlice from './Redux/contactSlice'
import TogglerSlice from "./Redux/TogglerSlice"
import editorSlice from './Redux/editorSlice'
import brandVoiceSlice from './Redux/brandVoiceSlice'
import teamSlice from './Redux/teamSlice'
export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    chats: chatsSlice,
    profile: profileSlice,
    subscription: subscriptionSlice,
    library: librarySlice,
    imageAI: imageAISlice,
    history: historySlice,
    contact:contactSlice,
    toggler:TogglerSlice,
    editor:editorSlice,
    brandVoice:brandVoiceSlice,
    team:teamSlice
  },
})
