import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetBrandVoiceLength } from "../../../Redux/brandVoiceSlice";
import PageButtons from "./pageButtons";
import { useTranslation } from "react-i18next";

const Tablepagination = ({ handlePage, page, BrandVoice }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tSect = "brandVoice.brand-voice-home";

  useEffect(() => {
    dispatch(GetBrandVoiceLength());
  }, [BrandVoice]);

  const DataLength = useSelector((state) => state.brandVoice.brandvoiceLength);
  const [buttons, setButtons] = useState([]);

  const length = DataLength && DataLength.numberOfBrandVoices;
  const divide = length > 0 ? Math.ceil(length / 5) : 1;

  const generateButtons = () => {
    const newButtons = [];

    for (let i = 1; i <= divide; i++) {
      newButtons.push(i);
    }

    setButtons(newButtons);
  };

  useEffect(() => {
    generateButtons();
  }, [length]);

  console.log(buttons);

  return (
    <div className=" w-full  flex sm:flex-col md:flex-row items-center sm:justify-center md:justify-between sm:h-[80px]  md:h-[50px]  opacity-100 px-5  z-30 gap-2    ">
      <div>
        <p className=" rtl:font-Cairo text-[14px] text-black ">
          1-5 {t(`${tSect}.of`)} {DataLength && DataLength.numberOfBrandVoices}
        </p>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={() => handlePage("Previous")}
          className={`  rtl:font-Cairo text-center text-[12px] rounded-lg border-[1px] border-[#EAECF0] px-[14px] py-[6px] hover:bg-[#e4e1e142] transition-all active:scale-110 `}
        >
         {t(`${tSect}.pagination-btn-previous`)}
        </button>
        {buttons.length > 0 &&
          buttons.map((item,index) => {
            return (
              <PageButtons
              key={index} 
              handlePage={handlePage}
              item={item}
              page={page}
              />
            );
          })}
       
        <button
          onClick={() => handlePage("Next")}
          className=" rtl:font-Cairo  text-center text-[12px] rounded-lg border-[1px] border-[#EAECF0] px-[14px] py-[6px] hover:bg-[#e4e1e142] transition-all active:scale-110 "
        >
           {t(`${tSect}.pagination-btn-next`)}
        </button>
      </div>
    </div>
  );
};

export default Tablepagination;
