import React from 'react'
import Select from '../../../../shared/Select/select'
import { useState } from 'react'
import { useTranslation } from "react-i18next";


const Prefrences = () => {
  
      const { t } = useTranslation();
      const tSect = "Settings.preferences";

    const [systemLanguage,setSystemLanguage] = useState("English")
    const [generationLanguage,setGenerationLanguage] = useState("English")

  return (
   <div className='w-full  h-[100vh] overflow-y-auto' >
     <div className='w-full min-h-[100vh] overflow-y-auto flex flex-col sm:p-8 lg:p-12 pt-[50px] gap-6 rounded-[40px]  max-w-7xl mx-auto' >
      <div className='flex flex-row gap-4 items-center ' >
         <img src='/Icons/settings/setting.svg'  />
         <div className='flex flex-col' >
            <span className='text-[#101828] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-[16px]' >{t(`${tSect}.heading-setting`)}</span>
            <h2 className="font-InterLight ltr:font-InterLight rtl:font-Cairo font-bold tracking-[1px] text-[20px] " >{t(`${tSect}.heading-preferences`)}</h2>
         </div>
      </div>
      {/****Language****/}
      <div className='flex flex-col gap-4 items-start justify-start mt-[10px] ' >
        <img src='/Icons/settings/language.svg' className='h-[20px]' />
        <h2 className='text-[#344054] text-[24px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px] ' >{t(`${tSect}.heading-languages`)}</h2>
      </div>
      {/***Input Box***/}
      <div className='flex flex-row sm:flex-col lg:flex-row sm:gap-8 lg:gap-16 mt-[30px]  ' >
        <div className=' w-[40%] sm:w-full lg:w-[40%] flex flex-col gap-2' >
            <h2 className='text-[#344054] text-[15px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ' >{t(`${tSect}.system-language`)}</h2>
            <Select
             
             defaultFlag={"US"}
             defaultLanguage={"English"}
             setLanguages={setSystemLanguage}
            />
            <p className='text-[#98A2B3] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ' >{t(`${tSect}.set-default-language`)}</p>
        </div>
        <div className=' w-[40%] sm:w-full lg:w-[40%] flex flex-col gap-2' >
            <h2 className='text-[#344054] text-[15px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ' >{t(`${tSect}.generation-language`)}</h2>
            <Select
           
             defaultFlag={"US"}
             defaultLanguage={"English"}
             setLanguages={setGenerationLanguage}
            />
             <p className='text-[#98A2B3] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ' >{t(`${tSect}.set-default--generation`)}</p>
        </div>
      </div>
    </div>
   </div>
  )
}

export default Prefrences
