import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import AuthAPI from "../../services/auth";
import Alert from "../../components/Alert";
import { useSelector } from "react-redux";

const LoginForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tSect = "auth.login";

  const IsCheckout = useSelector((state) => state.toggler.isCheckout);

  const [values, setValues] = useState({ password: "", email: "" });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = values;
    const payload = { email, password };
    try {
      const res = await AuthAPI.userLogin(payload);
      setErrors(false);
      if (res.token) {
        const path = IsCheckout ? "/pricing" : "/dashboard";
        AuthAPI.setSession(res.token, path);
      } else {
        navigate(`/verify-email`, { state: { email, password } });
      }
    } catch (error) {
      setLoading(false);
      setValues((prev) => ({ ...prev, password: "" }));
      setErrors(true);
      console.log("error", error?.response?.data?.error);
    }
  };

  const handleGoogleSignIn = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      const payload = {
        token: tokenResponse.code,
      };
      try {
        const res = await AuthAPI.googleSignIn(payload);
        setErrors(false);
        const path = IsCheckout ? "/pricing" : "/dashboard";
        AuthAPI.setSession(res.token, path);
      } catch (error) {
        setLoading(false);
        setValues((prev) => ({ ...prev, password: "" }));
        setErrors(true);
        console.log("error", error?.response?.data?.error);
      }
    },
    onError: (error) => {
      console.log(error);
      setErrors(true);
    },
  });

  return (
    <div className="w-full h-auto gap-[30px] md:w-[650px] bg-black flex flex-col px-[44px] py-[24px] ">
      <div className="sm:flex lg:hidden flex-row mr-8 mt-[8px] absolute top-2 right-4   ">
        <img
          onClick={() => window.location.replace("/") }
          title="Home"
          src="/Icons/home-white.png"
          className="h-[25px] active:scale-95 transition-all hover:scale-110 cursor-pointer  "
        />
      </div>
      <div className="w-full h-full flex flex-col gap-2 justify-center">
        <img
          src="/Icons/Khudi-logo-white.png"
          alt="logo-khudi"
          className="w-[141px]"
        />
        <div className="w-full h-[15%]  flex flex-col justify-center gap-1 ">
          <span className="text-[30px] font-InterLight font-[600] text-white tracking-wider ">
            {t(`${tSect}.heading`)}
          </span>
          <span className="text-[12px] font-InterLight font-[400] text-[#D0D5DD] tracking-wider ">
            {t(`${tSect}.subheading`)}
          </span>
        </div>

        {errors && (
          <Alert
            message={t(`${tSect}.error-email-password`)}
            closeAction={() => setErrors(false)}
          />
        )}

        <form className="w-full h-[35%] flex flex-col ">
          <div className="w-full h-[100%] flex flex-col gap-2 ">
            <label className="text-[14px] font-InterLight font-[500] tracking-wider text-white">
              {t(`auth.email-label`)}
            </label>
            <input
              value={values.email}
              onChange={({ target }) =>
                setValues({ ...values, email: target.value })
              }
              type="email"
              placeholder={t(`auth.email-placeholder`)}
              className="py-3 px-3 outline-none border-[1px] rounded-lg border-[#344054] text-[13px] text-[#98A2B3] focus:border-[#296B32] bg-[#16191A]"
            />

            <label className="text-[14px] font-InterLight font-[500] tracking-wider text-white">
              {t(`${tSect}.password-label`)}
            </label>
            <input
              value={values.password}
              onChange={({ target }) =>
                setValues({ ...values, password: target.value })
              }
              type="password"
              placeholder={t(`${tSect}.password-placeholder`)}
              className="py-3 px-3 outline-none border-[1px] rounded-lg border-[#344054] text-[13px] text-[#98A2B3] focus:border-[#296B32] bg-[#16191A]"
            />

            <div className="w-full h-[12%]  flex items-center justify-end  ">
              <span
                className="text-[14px] text-[#296B32] font-InterLight font-[400] tracking-wide cursor-pointer hover:underline"
                onClick={() => navigate("/forgot-password")}
              >
                {t(`${tSect}.forgot-password`)}
              </span>
            </div>

            <button
              onClick={handleLogin}
              className="py-3 px-3  bg-[#296B32] text-white rounded-lg text-[15px] font-InterLight font-[400] tracking-wider cursor-pointer 
                hover:enabled:bg-[#175B22]
                disabled:bg-[#98C5A0] disabled:cursor-not-allowed
              "
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin m-auto h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                t(`action.sign-in`)
              )}
            </button>
          </div>
        </form>

        <div className="w-full h-[96px] flex flex-col gap-3 items-center">
          <div className="w-full h-[30%] flex items-center justify-center">
            <span className="text-[#667085]">{t(`${tSect}.sign-in-with`)}</span>
          </div>
          <div className="flex flex-row items-center justify-center gap-4">
            <div
              className="w-[46px] h-[46px] flex flex-row items-center justify-center bg-[#34405480] rounded-[8px] cursor-pointer hover:bg-[#535b6880]"
              onClick={handleGoogleSignIn}
            >
              <img
                src="/Icons/Google svg.png"
                className="h-[22px]"
                alt="ic-google"
              />
            </div>
            <div id="signUpDiv"></div>
          </div>
        </div>

        <div className="w-full h-[5%]  flex items-center justify-center gap-x-2 text-[#667085]">
          <span>{t(`${tSect}.new-to-khudi`)}</span>
          <span
            onClick={() => navigate("/register")}
            className="text-[#296B32] font-InterLight font-[500] tracking-wide cursor-pointer "
          >
            {" "}
            {t(`action.sign-up`)}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
