import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const Navigation = () => {

    const [activePage,setActivePage] = useState("preferences")

    const { t } = useTranslation();
    const tSect = "Settings.sidebar";

  return (
    <div className='w-full h-auto flex flex-col gap-4 pt-[40px]  ' >
     {/* <NavLink
      to="/settings/prefrences"
      className={({isActive})=> isActive? setActivePage("preferences") : " "}

      >
      <div className={`flex flex-row gap-2 items-center justify-start  ${activePage == "preferences"? "bg-[#667085]" : "" } bg-opacity-20 p-3 rounded-md `} >
        <img src={`/Icons/sidebar/${activePage == "preferences"?"settings-active":"settings"}.svg`}/>
        <span className={` ${activePage == "preferences"? "text-[#61FF76]" : "text-[#667085]" } text-[16px] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] `} >{t(`${tSect}.btn-preferences`)}</span>
      </div>  
     </NavLink> */}

     <NavLink
      to="/settings/profile-details"
      className={({isActive})=> isActive? setActivePage("profile-details") : " "}

      >
      <div className={`flex flex-row gap-2 items-center justify-start  ${activePage == "profile-details"? "bg-[#667085]" : "" } bg-opacity-20 p-3 rounded-md `} >
        <img src={`/Icons/sidebar/${activePage == "profile-details"?"user-active":"user"}.svg`}/>
        <span className={` ${activePage == "profile-details"? "text-[#61FF76]" : "text-[#667085]" } text-[16px] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] `} >{t(`${tSect}.btn-profile-details`)}</span>
      </div>  
     </NavLink>

     <NavLink
      to="/settings/plans-and-billing"
      className={({isActive})=> isActive? setActivePage("plans-billing") : " "}

      >
      <div className={`flex flex-row gap-2 items-center justify-start  ${activePage == "plans-billing"? "bg-[#667085]" : "" } bg-opacity-20 p-3 rounded-md `} >
        <img src={`/Icons/sidebar/${activePage == "plans-billing"?"credit-card-active":"credit-card"}.svg`}/>
        <span className={` ${activePage == "plans-billing"? "text-[#61FF76]" : "text-[#667085]" } text-[16px] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] `} >{t(`${tSect}.btn-plans-billing`)}</span>
      </div>  
     </NavLink>

     <NavLink
      to="/settings/team"
      className={({isActive})=> isActive? setActivePage("team") : " "}

      >
      <div className={`flex flex-row gap-2 items-center justify-start  ${activePage == "team"? "bg-[#667085]" : "" } bg-opacity-20 p-3 rounded-md `} >
        <img src={`/Icons/sidebar/${activePage == "team"?"team-active":"team"}.svg`}/>
        <span className={` ${activePage == "team"? "text-[#61FF76]" : "text-[#667085]" } text-[16px] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] `} >{t(`${tSect}.btn-team`)}</span>
      </div>  
     </NavLink>

     {/* <NavLink
      to="/settings/integration"
      className={({isActive})=> isActive? setActivePage("integration") : " "}

      >
      <div className={`flex flex-row gap-2 items-center justify-start  ${activePage == "integration"? "bg-[#667085]" : "" } bg-opacity-20 p-3 rounded-md `} >
        <img src={`/Icons/sidebar/${activePage == "integration"?"integration-active":"integration"}.svg`}/>
        <span className={` ${activePage == "integration"? "text-[#61FF76]" : "text-[#667085]" } text-[16px] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] `} >{t(`${tSect}.btn-integration`)}</span>
      </div>  
     </NavLink> */}
     
     {/* <NavLink
      to="/settings/api-dashboard"
      className={({isActive})=> isActive? setActivePage("api-dashboard") : " "}

      >
      <div className={`flex flex-row gap-2 items-center justify-start  ${activePage == "api-dashboard"? "bg-[#667085]" : "" } bg-opacity-20 p-3 rounded-md `} >
        <img src={`/Icons/sidebar/${activePage == "api-dashboard"?"dashboard-active":"dashboard"}.svg`}/>
        <span className={` ${activePage == "api-dashboard"? "text-[#61FF76]" : "text-[#667085]" } text-[16px] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] `} >{t(`${tSect}.btn-api-dashboard`)}</span>
      </div>  
     </NavLink> */}
     <div className='w-full h-[10px] block' >

    </div>
    </div>
  )
}

export default Navigation

