import React, { useState,useEffect } from "react";
import Sidebar from "./sidebar";
import Upgrade from "./upgrade/index"
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useSearchParams , useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UpgradeSuccessModal from "./upgrade/upgradeSuccess";
import { setIsMinimize } from "../../Redux/sidebarSlice";
import { setIsModalSuccessActive } from "../../Redux/subscriptionSlice";
import { getProfile } from "../../Redux/profileSlice";
import Ai_Images_Popup from "./Khudi Photo/photoPopup"
import ReactGA from "react-ga"

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate()
  const profile = useSelector((state) => state.profile);
  const lng = localStorage.getItem("i18nextLng");
  const sidebarStatus = localStorage.getItem("dashboardSideBar");
  const currentPage = useSelector((state) => state.sidebar.currentPage);
  const isMinimize = useSelector((state)=>state.sidebar.isMinimize)
  const isChat = currentPage === "chat"

  const [isSideBarOpen, setIsSideBarOpen] = useState(sidebarStatus);
  // const [isMinimize,setIsMinimise] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();

  //A State To Show upgrade Popup when user has no words
  const isUpgradeActive = useSelector(state => state.subscription.isUpgradeActive);
  const isModalSuccessActive = useSelector(state => state.subscription.isModalSuccessActive);

  useEffect(() => {
    const subs = searchParams.get("subscription");
    if(subs === 'success'){
      dispatch(setIsModalSuccessActive(true));
    }else{
      dispatch(setIsModalSuccessActive(false));
    }

    if(currentPage == "chat"){
      dispatch(setIsMinimize(false))
    }
  },[searchParams, dispatch,currentPage]);

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);

  },[])

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch])

  const handleMenu = () => {
    localStorage.setItem("dashboardSideBar", true);
    setIsSideBarOpen(true);
  };

  const { t } = useTranslation();
  const tSect = "dashboard.header";

  const handleLanguage = () => {
    const path = window.location.pathname
   if(lng == "ar"){
     window.location.href = `${path}?lng=en`
    localStorage.setItem("i18nextLng","en");
   }
   else{
    window.location.href = `${path}?lng=ar`
    localStorage.setItem("i18nextLng","ar");
   }
};

  return (
    <div className="w-full min-h-[100vh] flex bg-[#151718] overflow-hidden  ">
      <Sidebar
        isSideBarOpen={isSideBarOpen}
        setIsSideBarOpen={setIsSideBarOpen}
        // isMinimize={isMinimize}
        // setIsMinimise={setIsMinimise}
      />
      <div className={` ${isMinimize?"md:w-[calc(100%-80px)]":"md:w-[calc(100%-280px)]"} sm:w-[100%]  h-screen transition-all ltr:sm:ml-0 ${isMinimize ?" rtl:mr-0 ltr:md:ml-[80px] rtl:md:mr-[80px] ":"rtl:mr-0 ltr:md:ml-[280px] rtl:md:mr-[280px] "}   flex flex-col  `}>
        {/**Header dashboard***/}
        <div className="w-full sm:w-[96%] mx-auto h-[60px] flex flex-row items-center gap-2 px-[5px] ">
          <div className="w-[50%] md:w-[100%] lg:w-[60%] xl:w-[50%] h-full gap-2 flex sm:hidden md:flex flex-row items-center  ">
            <div onClick={()=>Navigate("/dashboard")} className="bg-[#25292E] h-[40px] cursor-pointer p-3 gap-2 flex flex-row items-center rounded-md ">
              <img src="/Icons/chevron-right.svg" alt="ic-chev-r" className="rotate-180" />
              <span className="text-[14px] text-white tracking-[1px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ">
                {t(`${tSect}.back`)}
              </span>
            </div>
            {/* <div className="bg-[#25292E] h-[40px] p-3 gap-2 flex flex-row items-center rounded-md ">
              <img src="/Icons/arrow.svg" alt="ic-arrow" />
              <span className="text-[14px] text-white tracking-[1px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ">
                {isChat ? t(`${tSect}.prompt-library`) : t(`${tSect}.create-workspace`)}
              </span>
              <img src="/Icons/chevron-right.svg" alt="ic-chev-r" />
            </div> */}
            {/* <div className="bg-[#25292E] h-[40px] p-3 gap-2 flex flex-row items-center rounded-md ">
              <img src={`/Icons/${isChat ? "emoji-happy" : "upload-cloud"}.svg`} alt="ic-plugin" />
              <span className="text-[14px] text-white tracking-[1px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ">
                {isChat ? t(`${tSect}.plugin`) : t(`${tSect}.bulk-upload`)}
              </span>
            </div> */}
          
          </div>
          <div className="w-[50%]  lg:w-[40%] xl:w-[50%] sm:hidden lg:flex h-full gap-2 flex items-center justify-end pr-[0px] ">
            <div className="bg-[#1D2939] h-[40px] p-3 gap-2 flex flex-row items-center rounded-md ">
              <img src="/Icons/current.svg" alt="ic-current" />
              <span className="text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-bold tracking-[1px]  bg-clip-text text-transparent bg-gradient-to-r from-[#62FE76] to-[#0BA5EC] ">
                {profile.countWordLeft}
              </span>
              <span className="text-[14px] text-[#61FF76] tracking-[1px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light  ">
                {t(`${tSect}.words-left`)}
              </span>
            </div>
            <div  onClick={handleLanguage}  className="text-center p-3  rounded-md cursor-pointer hover:scale-105 ">
              <img src="/Icons/globe-white.png" className="h-[25px]" />
            </div>
            
          </div>
          <div className="w-[100%] flex sm:flex md:hidden flex-row justify-between gap-6 ">
            <img
              onClick={handleMenu}
              src="/Icons/menu.svg"
              className=" cursor-pointer hover:scale-105 "
              alt="ic-menu"
            />
            <div className="inline-flex items-center flex-row gap-3 bg-[#25292E] px-14 py-2 rounded-md   ">
              <img src="/Icons/home.svg" className="h-[23px]" alt="ic-home" />
              <span className="text-[16px]  text-[#61FF76] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.9px] ">
              {t(`dashboard.Home-Dashboard.dashboard`)}
              </span>
            </div>
            <div className="flex  gap-2 flex-row-reverse " >
            <img
              src={profile.photoProfile ? profile.photoProfile : "/media/Ellipse 14.png"}
              className="h-[40px] rounded-full"
              alt="ic-avatar"
            />
            <div onClick={handleLanguage} className="text-center p-3 rounded-md cursor-pointer hover:scale-105 ">
              <img src="/Icons/globe-white.png" className="h-[25px]" />
            </div>
            </div>

          </div>

        </div>
        <div className="w-full h-[calc(100%-60px)] bg-white rounded-t-[20px] flex  overflow-y-auto ">
          <Outlet />      
        </div>
      </div>
      {/***Upgrade Pop Up****/}
      {isUpgradeActive &&
        <Upgrade isUpgradeActive={isUpgradeActive} />    
      }
      {/***Success Upgrade Pop Up****/}
      {isModalSuccessActive &&
        <UpgradeSuccessModal isModalActive={isModalSuccessActive} />
      }

       <Ai_Images_Popup/>
    
      
    </div>
  );
};

export default DashboardLayout;
