import api from '../config/api';

const TOKEN = localStorage.getItem('token');
const header = TOKEN ? {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + TOKEN
} : { 'Content-Type': 'application/json' };

const API = new api({ headers: header });

const ChatAPI = {
  sendChat: (payload) => API.post(`chat`, payload),
  historyList: (params) => API.get(`chat/history`, {params}),
  historyDetail: (params) => API.get(`chat/history-detail`, {params}),
  clearHistory: () => API.put(`chat/history-clear`),
  save_file_content : (payload) => API.post('chat/save-file-content',payload)
}

export default ChatAPI;