import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImPower } from "react-icons/im";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PackageAPI from "../../../services/packages";
import { checkout } from "../../../Redux/TogglerSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useGoogleLogin } from "@react-oauth/google";
import AuthAPI from "../../../services/auth";

const BusinessCard = () => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const tSect = "pricing.business-card";
  const dispatch = useDispatch();

  const Billed = useSelector((state) => state.toggler.Billed);

  const [loading, setLoading] = useState();
  const [error, setErrors] = useState(false);
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("i18nextLng");

  const Package = async (payload) => {
    const res = await PackageAPI.subscribePackage(payload);
    console.log(res.message);

    // if(res.error){
    //   // toast.error(res.error, {
    //   //   position: toast.POSITION.TOP_LEFT
    //   // });
    // }

    window.location.replace(res.redirectUrl);
  };

  const handlePayment = async (subscriptionId) => {
    const payload = {
      subscriptionId: subscriptionId,
      language: lang,
    };

    if (token) {
      try {
        setLoading(true);
        const res = await PackageAPI.subscribePackage(payload);
        window.location.replace(res.redirectUrl);
      } catch (err) {
        setLoading(false);
        toast.error(err, {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    } else {
      dispatch(checkout(true));
      Navigate("/register");
    }
  };

  return (
    <>
      <div class="relative border border-green-800 business-card-active p-4 rounded-lg p-6 lg:w-[450px] ">
        <div class="absolute -top-4 right-10 h-8 w-50 font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium text-white px-4 py-1 text-base rounded-lg btn-business bg-opacity-10  flex-inline justify-center">
          {t(`${tSect}.plans-pricing`)}
        </div>
        <h2 class="text-3xl font-bold text-white pt-2">
          {t(`${tSect}.heading1`)}
        </h2>
        <p class="text-sm dark-gray leading-6 mt-2">
          {t(`${tSect}.paragraph-1`)}
        </p>
        <p class="text-base text-white leading-6 mt-6">
          <span class="text-5xl font-bold ">
            {t(`${tSect}.${Billed == "monthly" ? "pricing" : "pricingYearly"}`)}
          </span>
          <sub class="text-sm">
            {t(`${tSect}.${Billed == "monthly" ? "monthly" : "yearly"}`)}
          </sub>
        </p>

        <p class="text-sm dark-gray leading-6 mt-2">{t(`${tSect}.p1`)}</p>
        <p class="text-sm text-green-400 leading-6 mt-2">
          {t(`${tSect}.20k-words`)}
        </p>

        <div class="flex justify-center items-center my-8">
          <button
            onClick={
              Billed == "monthly"
                ? () => handlePayment("646671c0c04e1457f169f2d5")
                : () => handlePayment("646672c5c04e1457f169f2db")
            }
            className="flex w-full  items-center justify-center border pricing-border pricing-btn-color gap-2 font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-lg leading-6 rounded-lg py-2 "
          >
            {loading ? (
              <svg
                className="animate-spin m-auto h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <>
                {" "}
                <MdOutlineRocketLaunch /> {t(`${tSect}.try-now`)}
              </>
            )}
          </button>
        </div>

        {/* <p class="text-lg text-white leading-6 mt-2 ltr:font-InterLight rtl:font-Cairo ">
        {t(`${tSect}.ecommerce-product`)}
          <span className="ltr:font-InterLight rtl:font-Cairo" >
            <br /> {t(`${tSect}.description`)}
          </span>
        </p> */}

        <p class="flex items-center text-sm text-green-500 mt-2 py-2">
          <ImPower />
          <span class="px-2">{t(`${tSect}.line1`)}</span>
        </p>
        <p class="flex items-center text-sm text-green-500 py-2">
          <ImPower />
          <span class="px-2">{t(`${tSect}.line2`)}</span>
        </p>
        <p class="flex items-center text-sm text-green-500 py-2">
          <ImPower />
          <span class="px-2">{t(`${tSect}.line3`)}</span>
        </p>
        <p class="flex items-center text-sm text-green-500 py-2">
          <ImPower />
          <span class="px-2">{t(`${tSect}.line4`)}</span>
        </p>
        <p class="flex items-center text-sm text-green-500 py-2">
          <ImPower />
          <span class="px-2">{t(`${tSect}.line5`)}</span>
        </p>
        <p class="flex items-center text-sm text-green-500 py-2">
          <ImPower />
          <span class="px-2">{t(`${tSect}.line6`)}</span>
        </p>
        <p class="flex items-center text-sm text-green-500 py-2">
          <ImPower />
          <span class="px-2">{t(`${tSect}.line7`)}</span>
        </p>
      </div>
    </>
  );
};
export default BusinessCard;
