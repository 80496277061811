import React from "react";

const Option = ({ option, SelectValue, setSelectValue,setIsOpen,textColor }) => {
  const handleOption = () => {
    setSelectValue(option);
    setIsOpen(false)
  };

  return (
    <div
      onClick={handleOption}
      className="w-full h-[30px] cursor-pointer flex items-center justify-center  "
    >
      <span
        className={`${
          SelectValue == option ? `text-[${textColor}]` : "text-[#667085]"
        } hover:text-[#208030] font-InterLight font-normal tracking-[1px] text-[15px] text-center `}
      >
        {option}
      </span>
    </div>
  );
};

export default Option;
