import React,{useState} from 'react'
import Tesseract from 'tesseract.js';
import { createWorker } from 'tesseract.js';
import {readImagetoText,setIsTyping} from "../../../../Redux/chatsSlice"
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

const ReadImage = ({}) => {

  const { t } = useTranslation();
  const tPrompt = "Khudi-Chat.chat-bar";

    const dispatch = useDispatch()

    const handleImage = (event) => {
        const file = event.target.files[0]
        console.log(file)
        dispatch(setIsTyping(true));
        const data = {
            u :"file",
            content : `${t(`${tPrompt}.message-read-image-1`)} ${file.name}. ${t(`${tPrompt}.message-read-image-2`)}`,
            isloading : true
          }
        dispatch(readImagetoText(data))

        handleReadImage(file)
    }

    const handleReadImage = async (file) => {
        const worker = await createWorker('eng');
        const ret = await worker.recognize(file);
        await worker.terminate();
        const data = {
            u :"image",
            content : ret.data.text,
            isloading : false
          }
        dispatch(readImagetoText(data))
        setIsTyping(false)
    }

  return (
    <div className='flex flex-row gap-3 relative ' >
       <img src="/Icons/image.svg" className="h-[22px]" />
       <h2 className="text-[#1D742C] text-[14px]  ltr:font-InterLight rtl:font-Cairo font-[400]  " >{t(`${tPrompt}.title-upload`)}</h2>
        <input
            type="file"
            className="w-[120px] absolute opacity-0  "
            onChange={handleImage}
            accept="image/*" 
          />
    </div>
  )
}

export default ReadImage
