import React,{useEffect} from 'react'
import HeroSection from "./heroSection"
import Features from './features'
import Tools from './tools'
import Footer from "../../shared/Footer/footer"
import ReactGA from "react-ga"

const DetailFeature = () => {

  useEffect(()=>{
    ReactGA.pageview("/detail-feature");

  },[])

  return (
    <div className='w-full min-h-[100vh] block '>
      <HeroSection/>
      <Features/>
      <Tools/>
      <Footer/>
       
    </div>
  )
}

export default  DetailFeature
