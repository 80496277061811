import api from '../config/api';
const TOKEN = localStorage.getItem('token');
const header = TOKEN ? {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + TOKEN
} : { 'Content-Type': 'application/json' };

const API = new api({ headers: header });

const PackageAPI = {
  getPackages: (params) => API.get(`subscription/package-words-list`, { params }),
  subscribePackage: (payload) => API.post(`subscription/checkout-words`, payload),
}

export default PackageAPI;
