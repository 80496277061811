import React, { useRef, useState } from "react";
import { useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch , useSelector } from "react-redux";
import {sideBarActive} from "../../Redux/sidebarSlice"
import { Link } from "react-router-dom";

import DropDown from "./dropdown";

const SideBar = () => {
  const Navigate = useNavigate()
  const sideNavRef = useRef();
  const userId = localStorage.getItem("token");
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const tSect = "home.sidebar";
  const userProfile = JSON.parse(localStorage.getItem("user-profile"))

  const sideBarStatus = useSelector((state) => state.sidebar.isSideBarActive);

  const [isDropDownActice,setIsDropDownActive] = useState(false)
  const [isResourcesActive,setIsResourcesActive] = useState(false)
  const [SideBar,setSideBar] = useState(sideBarStatus)
  const [Language,setLanguage] = useState("العربية")

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
      dispatch(sideBarActive(false))
      localStorage.setItem("sidebar", false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(sideBarActive(false))
  };

  const lng = localStorage.getItem("i18nextLng")

  useEffect(()=>{
    if(lng == "ar"){
      setLanguage("العربية")
    }
    else if(lng == "en"){
      setLanguage("English")
    }
  },[])

  const handleLanguage = () => {
    const path = window.location.pathname
   if(lng == "ar"){
     window.location.href = `${path}?lng=en`
    localStorage.setItem("i18nextLng","en");
   }
   else{
    window.location.href = `${path}?lng=ar`
    localStorage.setItem("i18nextLng","ar");
   }
    
  }

  const handleButtons = (path) =>{
    Navigate(path)
    dispatch(sideBarActive(false))
  }

  

  return (
    <>
      <div
        className={`w-[100%] h-[100vh] scrollbar-none overflow-y-auto fixed top-0 left-0 z-[100] backdrop-blur-sm`}
        style={{ display: sideBarStatus == true ? "block" : "none" }}
      >
        <div
          ref={sideNavRef}
          className={`w-[90%] h-auto bg-[#0A0D0E] flex flex-col `}
        >
          <div className="w-full h-[60px] px-3  flex flex-row items-center justify-between ">
            <img onClick={()=>handleButtons("/")}  src="/Icons/Khudi Logo 1.svg" className="h-[30px] cursor-pointer " />
          </div>
          <div className="w-full h-auto flex flex-col px-3 border-t-[1px] border-b-[1px] border-[#101828] py-8">
            <ul className="w-full h-full flex flex-col gap-6 ">
              {/****Drop Down Feature ***/}
             <div className="w-full h-auto inline-flex flex-col gap-2  " >
               <div onClick={()=>handleButtons("/detail-feature")} className=" cursor-pointer w-full h-[20px] inline-flex flex-row items-center justify-between  ">
                  <span className="text-white font-InterLight font-normal text-[16px]">
                  {t(`${tSect}.features`)}
                  </span>
               </div>
             
             </div>
             <div className="w-full h-auto inline-flex flex-col gap-2  " >
               <div onClick={()=>handleButtons("/pricing")} className=" cursor-pointer w-full h-[20px] inline-flex flex-row items-center justify-between  ">
                  <span className="text-white font-InterLight font-normal text-[16px]">
                  {t(`${tSect}.pricing`)}
                  </span>
               </div>
              
             </div>
             
              <li onClick={()=>handleButtons("/contact")} className=" cursor-pointer w-full inline-flex flex-row items-center justify-between  ">
                <span  className="text-white font-InterLight font-normal text-[16px]">
                {t(`${tSect}.contact-us`)}
                </span>
              </li>
              
            </ul>
          </div>
          <div className=" px-3 py-2 w-full h-auto flex flex-col gap-4 items-center ">
            {userId ? (
              <div className="w-full h-[70px] flex items-center justify-between">
                <div className="inline-flex p-2 items-center gap-2">
                  <div className="w-[45px] h-[45px] bg-gray-800 rounded-full ">
                    <img
                      src={userProfile?.photoProfile}
                      className="w-full h-full rounded-full "
                    />
                  </div>
                  <div className="px-1 inline-flex flex-col  gap-1 ">
                    <span className="text-[#FCFCFD] text-[16px] font-InterLight font-medium tracking-[1px] ">
                      {userProfile?.fullName}
                    </span>
                    <span className="text-[#D0D5DD] text-[16px] font-InterLight font-light tracking-[1px] ">
                     {userProfile?.email}
                    </span>
                  </div>
                </div>
                <div className="inline-flex justify-end ">
                  <img
                    src="/Icons/log-out.svg"
                    className="cursor-pointer"
                    onClick={handleLogout}
                  />
                </div>
              </div>
            ) : 
            <div className="w-full h-auto flex flex-col gap-4 pt-[15px]" >
              <Link to="/login" className="w-full h-[50px] inline-flex items-center justify-center bg-[#1D742C] rounded-md text-white font-InterLight font-medium tracking-[1px] " >
              {t(`${tSect}.sign-in`)}
              </Link>

              <Link to="/register" className="w-full h-[45px] inline-flex items-center justify-center border-[1px] border-white rounded-md text-white font-InterLight font-medium tracking-[1px] " >
              {t(`${tSect}.sign-up`)}
              </Link>
           </div>
            }
           {
            userId &&
            <button onClick={()=>Navigate("/dashboard")} className="w-full py-3 rounded-md text-white cursor-pointer ltr:font-InterLight rtl:font-Cairo  border-[1px] border-[#475467] " > 
            Dashboard
           </button>
          
           }
           
            <div className="w-full h-[50px] flex items-center justify-center ">
              <span onClick={handleLanguage} className="text-[18px] ltr:font-InterLight rtl:font-Cairo font-semibold text-white cursor-pointer ">
              {Language == "English" ? "العربية" :"English" }
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
