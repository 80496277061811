import React from "react";
import TemplateCards from "../Cards/templateCard";
import { useTranslation } from "react-i18next";

const GeneralWriting = () => {
  const { t } = useTranslation();
  const tSect = "templates.general-Writing";

  return (
    <div className="w-full h-auto flex flex-col  gap-4 ">
      <div className="w-full h-auto flex flex-row flex-wrap gap-4 ">
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.personal-bio-v2.title`)}
          desc={t(`${tSect}.personal-bio-v2.description`)}
          route={"personal-bio-v2"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.text-extender.title`)}
          desc={t(`${tSect}.text-extender.description`)}
          route={"text-extender"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.company-bios.title`)}
          desc={t(`${tSect}.company-bios.description`)}
          route={"company-bio-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"quora.png"}
          title={t(`${tSect}.quora-answers.title`)}
          desc={t(`${tSect}.quora-answers.description`)}
          route={"quora-answers"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.content-shorten.title`)}
          desc={t(`${tSect}.content-shorten.description`)}
          route={"content-shorten"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.stories.title`)}
          desc={t(`${tSect}.stories.description`)}
          route={"stories"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.bullet-point-answers.title`)}
          desc={t(`${tSect}.bullet-point-answers.description`)}
          route={"bullet-point-answers"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.passive-to-active.title`)}
          desc={t(`${tSect}.passive-to-active.description`)}
          route={"passive-to-active"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.definition.title`)}
          desc={t(`${tSect}.definition.description`)}
          route={"definition"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.answers.title`)}
          desc={t(`${tSect}.answers.description`)}
          route={"answers"}
          isNew={false}
        />
        <TemplateCards
          Icon={"email.png"}
          title={t(`${tSect}.email-v2.title`)}
          desc={t(`${tSect}.email-v2.description`)}
          route={"email-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.pros&cons.title`)}
          desc={t(`${tSect}.pros&cons.description`)}
          route={"pros-and-cons"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.rewrite-with-keywords.title`)}
          desc={t(`${tSect}.rewrite-with-keywords.description`)}
          route={"rewrite-with-keywords"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.email-subjects-lines.title`)}
          desc={t(`${tSect}.email-subjects-lines.description`)}
          route={"email-subjects-lines"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.cold-email-v2.title`)}
          desc={t(`${tSect}.cold-email-v2.description`)}
          route={"cold-email-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.company-mission.title`)}
          desc={t(`${tSect}.company-mission.description`)}
          route={"company-mission"}
          isNew={false}
        />
      </div>
      <div className="w-full h-[10px] block"></div>
    </div>
  );
};

export default GeneralWriting;
