import React from "react";
import { useTranslation } from "react-i18next";

const UpgradeTeam = () => {
  const { t } = useTranslation();
  const tSect = "Settings.team";

  return (
    <>
      <div className="flex flex-row gap-4 items-center sm:p-8 lg:p-12 pt-[50px] ">
        <img src="/Icons/settings/setting.svg" />
        <div className="flex flex-col">
          <span className="text-[#101828] ltr:font-InterLight rtl:font-Cairo font-normal text-[16px]">
            {t(`${tSect}.heading-setting`)}
          </span>
          <h2 className="ltr:font-InterLight rtl:font-Cairo font-bold tracking-[1px] text-[20px] ">
            {t(`${tSect}.heading-team`)}
          </h2>
        </div>
      </div>
      {/***Team Page Content***/}
      <div className="w-full h-auto flex flex-col items-center justify-center">
        <img src="/Icons/settings/team-page.svg" className="h-[300px]" />
        <div className="flex flex-col items-center justify-center mt-[30px] gap-2 text-center ">
          <span className="text-[20px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ">
            {t(`${tSect}.head-team-not-available`)}
          </span>
          <span className="text-[14px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ">
            {t(`${tSect}.p-upgrade-plan-1`)} <br />
            {t(`${tSect}.p-upgrade-plan-2`)}
          </span>
          <button className=" w-full text-[16px] text-white bg-[#1D742C] hover:bg-[#2ca240] ltr:font-InterLight rtl:font-Cairo font-normal p-3 rounded-md tracking-[0.5px] ">
            {t(`${tSect}.btn-upgrade-plan`)}
          </button>
        </div>
      </div>
    </>
  );
};

export default UpgradeTeam;
