import { useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
// import "./App.css";
// import microPhoneIcon from "./imtext.png";
// import ai from "/Icons/ai.png"
function SpeechTotxt({
  setInputValue,
  inputValue,
  updateInputRows,
  textAreaRef,
}) {
  const { transcript, resetTranscript } = useSpeechRecognition();

  const [storeTranscript, setStroingTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [audio] = useState(new Audio("/sounds/click.mp3"));

  const microphoneRef = useRef(null);

  useEffect(
    () => {
      if (transcript) {
        setInputValue(transcript);
        updateInputRows(textAreaRef.current);
      } else {
        setInputValue(storeTranscript);
        updateInputRows(textAreaRef.current);
      }
    },
    [transcript],
    [storeTranscript]
  );

  useEffect(() => {
    if (inputValue == "") {
      resetTranscript();
      setStroingTranscript("");
    }
  }, [!inputValue]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return (
      <div className="mircophone-container">
        Browser is not Support Speech Recognition.
      </div>
    );
  }
  const handleListing = () => {
    audio.play();
    setIsReset(false);
    setIsListening(true);
    // microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
    });
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  };
  const stopHandle = () => {
    audio.play();

    setStroingTranscript(transcript);
    SpeechRecognition.abortListening();
    // setIsReset(true);
    setIsListening(false);
    // microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  };


  return (
    <div className="microphone-wrapper absolute ltr:right-[75px] rtl:left-[75px] ">
      <div className="mircophone-container">
        {isListening ? (
          <button className=" cursor-pointer " onClick={stopHandle}>
            <span class="material-symbols-outlined">mic_off</span>
          </button>
        ) : (
          <div
            className="microphone-icon-container cursor-pointer "
            ref={microphoneRef}
            onClick={handleListing}
          >
            <span class="material-symbols-outlined">mic</span>
          </div>
        )}
      </div>
    </div>
  );
}
export default SpeechTotxt;
