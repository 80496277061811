import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { setIsUpgradeActive } from "../../../../Redux/subscriptionSlice";
import { useDispatch } from "react-redux";

const PlansBilling = () => {
  const dispatch = useDispatch();
  const [activeBtn, setActiveBtn] = useState("monthly");

  const { t } = useTranslation();
  const tSect = "Settings.plans-billing";
  const pricing = "pricing";

  const handleUpgradePlan = () => {
    dispatch(setIsUpgradeActive(true));
  };

  return (
    <div className="w-full h-[100vh] overflow-y-auto  ">
      <div className="w-full min-h-[100vh] flex flex-col sm:p-6 lg:p-12 pt-[50px] gap-6 rounded-[40px]    ">
        {/***Page Heading**/}
        <div className="flex flex-row gap-4 items-center ">
          <img src="/Icons/settings/setting.svg" />
          <div className="flex flex-col">
            <span className="text-[#101828] ltr:font-InterLight rtl:font-Cairo font-normal text-[16px]">
              {t(`${tSect}.heading-setting`)}
            </span>
            <h2 className="ltr:font-InterLight rtl:font-Cairo font-bold tracking-[1px] text-[20px] ">
              {t(`${tSect}.heading-page`)}
            </h2>
          </div>
        </div>

        <div className="w-full h-auto p-6 bg-[#F9FAFB] border-[1px]  border-[#EAECF0] flex flex-col rounded-lg ">
          {/***Banner Page***/}
          <img
            src="/Icons/settings/billing-banner.svg"
            className="lg:rounded-[20px]"
          />

          {/****Upgrade Account*****/}
          <div className="w-full h-auto flex flex-col mt-[40px]  ">
            <div className="w-full h-auto flex flex-col items-center justify-center ">
              <h2 className="text-[26px]  text-[#101828] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px]  ">
                {t(`${tSect}.upgrade-account`)}
              </h2>
              <div className="max-w-sm w-full relative mt-10 mx-auto border-lg border-first flex sm:flex-col lg:flex-row sm:items-center sm:justify-center lg:justify-between p-1 sm:rounded-md lg:rounded-full ">
                <span
                  onClick={() => {
                    setActiveBtn("monthly");
                  }}
                  class={`ltr:font-InterLight rtl:font-Cairo ltr:ltr:font-InterLight rtl:font-Cairo rtl:font-Cairo font-medium cursor-pointer  text-base ms-3 tracking-[1px] px-3 py-2 border-radius-100 ${
                    activeBtn == "monthly"
                      ? "bg-black-dark text-white "
                      : "text-[#296B32]"
                  } `}
                >
                  {t(`${tSect}.btn-billed-monthly`)}
                </span>
                <span
                  onClick={() => {
                    setActiveBtn("yearly");
                  }}
                  class={` tracking-[0.5px] ltr:font-InterLight rtl:font-Cairo ltr:ltr:font-InterLight rtl:font-Cairo rtl:font-Cairo cursor-pointer font-medium  text-base  px-3 py-2 border-radius-100 ${
                    activeBtn == "yearly"
                      ? "bg-black-dark text-white "
                      : "text-[#296B32]"
                  } `}
                >
                  {t(`${tSect}.btn-billed-yearly`)}
                  <span className="text-green tracking-[0.5px] mx-1 ">
                    {t(`${tSect}.btn-off`)}
                  </span>
                </span>
              </div>
            </div>
          </div>

          {/**Plans**/}
          <div className="w-full h-auto flex sm:flex-col lg:flex-row mt-[60px] sm:gap-10 lg:gap-4 justify-between ">
            {/**Free Card Plan**/}
            <div className="w-[32%] sm:w-full lg:w-[32%] h-auto flex flex-col gap-6 p-4   ">
              {/**Heading-Plan**/}
              <div className="flex flex-col gap-4">
                <h2 className="text-[#101828] text-[25px] ltr:font-InterLight rtl:font-Cairo font-medium">
                  {t(`${tSect}.heading-free-trial`)}
                </h2>
                <p className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                  {t(`${tSect}.p-free-trial-1`)} <br />{" "}
                  {t(`${tSect}.p-free-trial-2`)}
                </p>
              </div>
              {/**Plan-Type***/}
              <div className="flex flex-col gap-1">
                <h2 className="text-[#175B22] text-[35px] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px] ">
                  {t(`${tSect}.heading-Free`)}
                </h2>
                <p className="text-[#175B22] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                  {t(`${tSect}.p-forever`)}
                </p>
              </div>
              {/***Paragraph desc**/}
              <div className="flex flex-col gap-1">
                <p className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                  {t(`${tSect}.p-try-out-all-1`)} <br />{" "}
                  {t(`${tSect}.p-try-out-all-2`)}
                </p>
                <div className="flex flex-row gap-4">
                  <p className="text-[#175B22] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                    {t(`${tSect}.p-10k-words`)}
                  </p>
                  <p className="text-[#175B22] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                    {t(`${tSect}.p-1-user`)}
                  </p>
                </div>
              </div>
              {/***Button***/}
              <button className="w-full h-auto flex flex-row gap-4 border-[1px] border-[#D0D5DD] p-2 rounded-md items-center justify-center ">
                <img src="/Icons/settings/rocket.svg" />
                <span className="text-[18px] text-[#6AAA74] ltr:font-InterLight rtl:font-Cairo font-medium ">
                  {t(`${tSect}.btn-try-now`)}
                </span>
              </button>
              {/***Table Element***/}
              <div className="w-full h-auto flex flex-col gap-3 mt-[10px] ">
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.charges-card-1.line1`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.charges-card-1.line2`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.charges-card-1.line3`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.charges-card-1.line4`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.charges-card-1.line5`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.charges-card-1.line7`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.charges-card-1.line6`)}
                  </span>
                </span>
              </div>
            </div>
            {/**Business Card Plan**/}
            <div className="w-[32%] sm:w-full lg:w-[32%] h-auto flex flex-col gap-6 p-4 bg-[#1D2939]  rounded-[10px] relative ">
              {/**Heading-Plan**/}
              <div className="flex flex-col gap-4">
                <h2 className="text-[#E1F2E4] text-[25px] ltr:font-InterLight rtl:font-Cairo font-medium">
                  {t(`${tSect}.heading-business-trial`)}
                </h2>
                <p className="text-[#E1F2E4] text-[14px] ltr:font-InterLight rtl:font-Cairo font-light  ">
                  {t(`${tSect}.p-business-trial`)}
                </p>
              </div>
              {/**Plan-Type***/}
              <div className="flex flex-col gap-1">
                <h2 className="text-[#E1F2E4] text-[35px] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px] ">
                  {activeBtn == "monthly"?t(`${tSect}.heading-price`):t(`${tSect}.business-yearly`)}
                  <sub className="text-[18px] text-[#A0ACA2] font-light ">
                    {activeBtn == "monthly"?t(`${tSect}.per-month`):t(`${tSect}.yearly`)}
                  </sub>
                </h2>
              </div>
              {/***Paragraph desc**/}
              <div className="flex flex-col gap-1">
                <p className="text-[#FCFEFC] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal tracking-[1px] ">
                  {t(`${tSect}.p-business-try-out-all-1`)} <br />{" "}
                  {t(`${tSect}.p-business-try-out-all-2`)}
                </p>
                <div className="flex flex-row gap-4">
                  <p className="text-[#61FF76] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                    {t(`${tSect}.p-20k-words`)}
                  </p>
                  <p className="text-[#61FF76] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                    {t(`${tSect}.p-business-1-user`)}
                  </p>
                </div>
              </div>
              {/***Button***/}
              <button
                onClick={handleUpgradePlan}
                className="w-full h-auto flex flex-row gap-4 bg-[#45B554] hover:bg-[#38e44fb5] p-2 rounded-md items-center justify-center "
              >
                <span className="text-[18px] text-white ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px] ">
                  {t(`${tSect}.btn-upgrade-now`)}
                </span>
              </button>
              {/***Table Element***/}
              <div className="w-full h-auto flex flex-col gap-3 mt-[10px] ">
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/current.svg" />
                  <span className="text-[#45B554] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.business-card.line1`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/current.svg" />
                  <span className="text-[#45B554] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.business-card.line2`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/current.svg" />
                  <span className="text-[#45B554] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.business-card.line3`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/current.svg" />
                  <span className="text-[#45B554] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.business-card.line4`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/current.svg" />
                  <span className="text-[#45B554] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.business-card.line5`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/current.svg" />
                  <span className="text-[#45B554] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.business-card.line6`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/current.svg" />
                  <span className="text-[#45B554] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${pricing}.business-card.line7`)}
                  </span>
                </span>
              </div>
              {/****Premium Text****/}
              <div className="bg-[#296B32] flex flex-row gap-2 w-auto p-2 px-4 rounded-md absolute top-[-20px] ltr:right-4 rtl:left-4 ">
                <img src="/Icons/settings/curly-arrow.svg" />
                <span className="text-[16px] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px] text-white ">
                  {t(`${tSect}.most-popular`)}
                </span>
              </div>
            </div>
            {/****Custom */}
            <div className="w-[32%] sm:w-full lg:w-[32%] h-auto flex flex-col gap-6 p-4   ">
              {/**Heading-Plan**/}
              <div className="flex flex-col gap-4">
                <h2 className="text-[#101828] text-[25px] ltr:font-InterLight rtl:font-Cairo font-medium">
                  {t(`${tSect}.text-custom`)}
                </h2>
                <p className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                  {t(`${tSect}.paragraph-1`)} <br />{" "}
                  {t(`${tSect}.p-custom-trial-2`)}
                </p>
              </div>
              {/**Plan-Type***/}
              <div className="flex flex-col gap-1">
                <h2 className="text-[#175B22] text-[35px] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px] ">
                {activeBtn == "monthly"?t(`${tSect}.pro-monthly`):t(`${tSect}.pro-yearly`)}
                  <sub className="text-[18px] text-[#A0ACA2] font-light ">
                    {activeBtn == "monthly"?t(`${tSect}.per-month`):t(`${tSect}.yearly`)}
                  </sub>
                </h2>
              </div>
              {/***Paragraph desc**/}
              <div className="flex flex-col gap-1">
                <p className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                  {t(`${tSect}.p1`)} <br />{" "}
                  {t(`${tSect}.p-custom-try-out-all-2`)}
                </p>
                <div className="flex flex-row gap-4">
                  <p className="text-[#175B22] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal  ">
                    {t(`${tSect}.p2`)}
                  </p>
                </div>
              </div>
              {/***Button***/}
              <button
                onClick={handleUpgradePlan}
                className="w-full h-auto  hover:shadow-md flex flex-row gap-4 border-[1px] border-[#4D9959] p-2 rounded-md items-center justify-center "
              >
                <span className="text-[18px] text-[#175B22] ltr:font-InterLight rtl:font-Cairo font-medium ">
                  {t(`${tSect}.sigin`)}
                </span>
              </button>
              {/***Table Element***/}
              <div className="w-full h-auto flex flex-col gap-3 mt-[10px] ">
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${tSect}.line1`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${tSect}.line2`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${tSect}.line3`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${tSect}.line4`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${tSect}.line5`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${tSect}.line6`)}
                  </span>
                </span>
                <span className="flex flex-row gap-4 mt-[10px] ">
                  <img src="/Icons/settings/list-style.svg" />
                  <span className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
                    {t(`${tSect}.line7`)}
                  </span>
                </span>
              </div>
            </div>
          </div>

          {/*****Note Text*/}
          <div className="w-full h-auto bg-[#EAECF0] p-6 text-center flex-col flex gap-3 rounded-lg mt-[30px] ">
            <span className="text-[14px] text-[#101828] ltr:font-InterLight rtl:font-Cairo leading-[25px] ">
              {t(`${tSect}.footer-p-1`)}
            </span>

            <span className="text-[14px] text-[#101828] ltr:font-InterLight rtl:font-Cairo  ">
              {t(`${tSect}.footer-p-2`)}
            </span>
            <span className="text-[14px] text-[#101828] ltr:font-InterLight rtl:font-Cairo  ">
              {t(`${tSect}.footer-p-3`)}
            </span>
          </div>
        </div>
        <div className="w-full h-[30px] block"></div>
      </div>
    </div>
  );
};

export default PlansBilling;
