import React, { useState, useEffect } from "react";
// import { debounce } from 'lodash';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {useDispatch} from "react-redux"
import TemplateCards from "./Template/Cards/templateCard";
import {librarySearch} from  "../../../Redux/TogglerSlice"
import ReactGA from "react-ga"

const Library = () => {
  const navigate = useNavigate();

  const [Input,setInput] = useState("")

  const lng = localStorage.getItem("i18nextLng");
  const activePage = localStorage.getItem("ActiveBtn")
  const { t } = useTranslation();
  const tSect = "Library.Library-tool-sect-1";
  const dispatch = useDispatch()

  useEffect(() => {
    ReactGA.pageview("/library/all");
  }, []);



  const handleBtn = (btn) =>{
    localStorage.setItem("ActiveBtn",btn)
  }

  const handleSearch = (event) => { 
    const {value} = event.target
    setInput(value)
    dispatch(librarySearch(value))
  }

  


  return (
    <div className="w-full h-full flex flex-col gap-y-4   pt-6 overflow-hidden  ">
      {/*****Library Heading And Search Bar Section****/}
      <div className="w-full h-auto flex flex-row sm:flex-col lg:flex-row gap-4 px-6 ">
        {/**Library Heading**/}
        <div className="w-[50%] sm:w-[100%] lg:w-[50%] h-full inline-flex items-center gap-2  ">
          <img src="/Icons/3-layers.svg" alt="" />
          <h2 className="ml-2 font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold text-[22px] tracking-[1px] text-[#101828]  ">
            {t(`${tSect}.heading-library`)}
            <span className="ml-2 text-[#175B22] ">
              {" "}
              {t(`${tSect}.khudi-ai`)}
            </span>
          </h2>
        </div>
        {/**Library Search Bar**/}
        {/* <div className="w-[50%] sm:w-[100%] lg:w-[50%] h-full inline-flex items-center gap-2 justify-end sm:justify-start lg:justify-end ">
          <div className="w-[70%] sm:w-[80%] lg:w-[70%] py-2 px-4 shadow-sm border border-[#EAECF0] rounded-md inline-flex items-center gap-3 ">
            <img src="/Icons/search.svg" className="h-[18px]" alt="" />
            <input
              type="text"
              placeholder={t(`${tSect}.place-holder`)}
              className="w-[90%] outline-none"
              value={Input}
              onChange={handleSearch}
            />
          </div>
          <img
            src="/Icons/badge-yellow.svg"
            className="h-[40px] cursor-pointer"
            alt=""
          />
        </div> */}
      </div>
      {/***Library Navigation Bar****/}

      <div className="w-full h-auto mt-[20px] px-6 ">
        <div class="overflow-x-auto whitespace-nowrap scrollbar-none bg-[#F2F4F7] flex items-center p-2 rounded-md  ">
          <div className="flex h-[40px]  ">
            <Link
              to="/library/all"
              onClick={() => {
                handleBtn("all");
               
              }}
              className={`px-2 py-4 inline-flex items-center text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.6px] rounded-md ${
                activePage == "all" ? "text-white" : "text-[#667085]"
              }  ${activePage == "all" ? "bg-[#208030]" : ""} `}
            >
              {t(`${tSect}.btn-all-template`)}
            </Link>
            <Link
              to="/library/new"
              onClick={() => {
                handleBtn("new");
              }}
              className={`px-2 py-4 inline-flex items-center text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.6px] rounded-md ${
                activePage == "new" ? "text-white" : "text-[#667085]"
              }  ${activePage == "new" ? "bg-[#208030]" : ""} `}
            >
              {t(`${tSect}.btn-new`)}
            </Link>
            <Link
              to="/library/article-and-blogs"
              onClick={() => {
                handleBtn("article_and_blogs");
              }}
              className={`px-2 py-4 inline-flex items-center text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.6px] rounded-md ${
                activePage == "article_and_blogs"
                  ? "text-white"
                  : "text-[#667085]"
              }  ${activePage == "article_and_blogs" ? "bg-[#208030]" : ""} `}
            >
              {t(`${tSect}.btn-article-blogs`)}
            </Link>
            <Link
              to="/library/ads-and-marketing"
              onClick={() => {
                handleBtn("ads_and_marketing");
              }}
              className={`px-2 py-4 inline-flex items-center text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.6px] rounded-md ${
                activePage == "ads_and_marketing"
                  ? "text-white"
                  : "text-[#667085]"
              }  ${
                activePage == "ads_and_marketing" ? "bg-[#208030]" : ""
              } `}
            >
              {t(`${tSect}.btn-ads-marketing`)}
            </Link>
            <Link
              to="/library/general-writing"
              onClick={() => {
                handleBtn("general_writing");
              }}
              className={`px-2 py-4 inline-flex items-center text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.6px] rounded-md ${
                activePage == "general_writing"
                  ? "text-white"
                  : "text-[#667085]"
              }  ${activePage == "general_writing" ? "bg-[#208030]" : ""} `}
            >
              {t(`${tSect}.btn-general-writing`)}
            </Link>
            <Link
              to="/library/ecommerce"
              onClick={() => {
                handleBtn("Ecommerce");
              }}
              className={`px-2 py-4 inline-flex items-center text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.6px] rounded-md ${
                activePage == "Ecommerce" ? "text-white" : "text-[#667085]"
              }  ${activePage == "Ecommerce" ? "bg-[#208030]" : ""} `}
            >
              {t(`${tSect}.btn-ecommerce`)}
            </Link>
            <Link
              to="/library/social-media"
              onClick={() => {
                handleBtn("social_media");
              }}
              className={`px-2 py-4 inline-flex items-center text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.6px] rounded-md ${
                activePage == "social_media" ? "text-white" : "text-[#667085]"
              }  ${activePage == "social_media" ? "bg-[#208030]" : ""} `}
            >
              {t(`${tSect}.btn-social-media`)}
            </Link>
            <Link
              to="/library/others"
              onClick={() => {
                handleBtn("Others");
              }}
              className={`px-2 py-4 inline-flex items-center text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.6px] rounded-md ${
                activePage == "Others" ? "text-white" : "text-[#667085]"
              }  ${activePage == "Others" ? "bg-[#208030]" : ""} `}
            >
              {t(`${tSect}.btn-others`)}
            </Link>
          </div>
        </div>
      </div>
      {/***Library Card Sections ****/}
      <div className="w-full h-[100%] flex sm:overflow-auto lg:overflow-hidden sm:px-6 lg:px-6 ">
        <div className="w-full h-full flex flex-wrap gap-4  lg:overflow-y-auto ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Library;
