import React, { useEffect, useState } from "react";
import SelectBox from "../../../shared/SelectNormal/selectBox";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// import { ImpulseSpinner } from "react-spinners-kit";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";
import {
  getHistoryTool,
  clearHistoryTool,
  SearchData,
} from "../../../Redux/historySlice";
import Tool from "./tool";

const HistoryTable = () => {
  const [Sort, setSort] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { t } = useTranslation();
  const tSect = "History.History-Table";
  const dispatch = useDispatch();
  const history = useSelector((state) => state.history.tools);
  const isLoading = useSelector((state) => state.history.isLoading);
  const message = useSelector((state) => state.history.message);


  useEffect(() => {
    dispatch(getHistoryTool());
  }, []);

  const handleDeleteMultiple = () => {
    const payload = {
      libraryToolHistoryIds: selectedIds,
    };

    dispatch(clearHistoryTool(payload)).then((res) => {
      //update the tool history
      dispatch(getHistoryTool());
      setSelectedIds([]);
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    const filter = history.filter((item) => {
      return item.documentName.toLowerCase().includes(value.toLowerCase());
    });

    if (!value == "") {
      dispatch(SearchData(filter));
    } else {
      dispatch(getHistoryTool());

      
    }
  };

  return (
    <div className="w-full  flex flex-col mt-[40px] gap-10   ">

     
      {/****History Search and Sort*/}
      <div className="w-full flex sm:flex-col lg:flex-row  sm:items-start sm:gap-4 lg:gap-2  lg:items-center justify-between  ">
        <h2 className="ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px]">
          {t(`${tSect}.text-history-data`)}
        </h2>
        <div className=" sm:w-full lg:w-auto flex sm:flex-col md:flex-row gap-4 sm:justify-between lg:justify-start ">
          {/**Search Box**/}
          <div className="px-2 py-1 flex flex-row items-center  gap-2 border-[1px] border-gray-200 rounded-md shadow-sm ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M15.75 15.75L12.4875 12.4875M14.25 8.25C14.25 11.5637 11.5637 14.25 8.25 14.25C4.93629 14.25 2.25 11.5637 2.25 8.25C2.25 4.93629 4.93629 2.25 8.25 2.25C11.5637 2.25 14.25 4.93629 14.25 8.25Z"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <input
              value={searchQuery}
              onChange={handleSearch}
              type="text"
              placeholder={t(`${tSect}.input-history`)}
              className="lg:w-[400px] sm:w-[250px] rtl:font-Cairo outline-none placeholder:text-[15px] placeholder:text-[#667085] "
            />
          </div>
          {selectedIds?.[0] ? (
            <button 
              disabled={isLoading}
              onClick={handleDeleteMultiple}
              className={` bg-red-600 px-6 py-1 disabled:bg-red-500 disabled:cursor-not-allowed  text-white inline-flex items-center gap-1 ltr:font-InterLight rtl:font-Cairo tracking-normal rounded-md active:bg-red-400  ${!isLoading ? "hover:bg-red-500" : "hover:bg-red-500"} `}
            >
              {
                !isLoading  ?
                <Spinner className="animate-spin  h-4 w-4 ltr:float-right rtl:float-left mr-2 mt-1 " />
                :
                <>
                <span class="material-symbols-outlined text-[18px] ">delete</span>
              {t(`${tSect}.btn-delete`)}
                </>
              }
              
            </button>
          ) : (
            ""
          )}
          {/* <SelectBox
            option1={t(`${tSect}.sort`)}
            option2={t(`${tSect}.filter`)}
            setSelectBoxValue={setSort}
            border={"1px"}
            px={"15px"}
            py={"16px"}
            height={"40px"}
            width={"120px"}
            arrow={true}
            underline={false}
            textColor={"black"}
          /> */}
        </div>
      </div>

     
      {/****History Table*****/}
      <div class="flex min-h-[430px] flex-col overflow-x-auto overflow-y-hidden  relative  ">
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center absolute top-0 left-0 z-20 bg-white   ">
          <Spinner className="animate-spin  h-6 w-6 ltr:float-right rtl:float-left mr-2 mt-1 " />
        </div>
      )}
      {
        history && history.length == 0 && 
        <div className="w-full h-full flex flex-col gap-4 items-center justify-center absolute top-0 left-0 z-20 bg-white   ">
          <img src="/Icons/no-record.svg"/>
          <span className="rtl:font-Cairo" > {t(`${tSect}.empty-text`)}</span>
       </div>
      }
          {/* {!isLoading && <LoadingSkeleton />} */}
          <table class="w-full h-auto text-sm text-left rtl:text-right    ">
            <thead class="text-[12px] tracking-[0.7px] text-[#667085] ltr:font-InterLight rtl:font-Cairo  bg-[#F9FAFB] ">
              <tr className="border-[1px] border-[#EAECF0]">
              <th scope="col" class="px-6 py-3 font-[500] whitespace-nowrap ">
                <input className="text-gray-200  " type="checkbox" />
               </th>
                <th scope="col" class="px-6 py-3 font-[500] whitespace-nowrap ">
                {t(`${tSect}.title`)}
                </th>
                <th scope="col" class="px-6 py-3 font-[500] whitespace-nowrap ">
                {t(`${tSect}.toolName`)}
                </th>
                <th scope="col" class="px-6 py-3 font-[500] whitespace-nowrap ">
                {t(`${tSect}.creation-date`)}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 font-[500] whitespace-nowrap ltr:float-right rtl:float-left ltr:mr-[50px] rtl:ml-[50px] "
                >
               
                </th>
              </tr>
            </thead>
            <tbody>
              {history && history.length > 0
                && history.map((item, index) => {
                    return (
                      <Tool
                      item={item}
                      setSelectedIds={setSelectedIds}
                      selectedIds={selectedIds}
                   />
                    );
                  })
               }
            </tbody>
          </table>
        </div>
    </div>
  );
};

export default HistoryTable;
