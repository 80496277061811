import { API } from "./api";


const TeamAPI = {
  addSubUSer: (payload) => API.post(`user/add-subuser`, payload),
  getSubUser:() => API.get(`user/get-subuser`),
  updateSubUser:(payload)=>API.post(`user/update-subuser-words`,payload),
  deleteSubUser:(payload)=>API.post(`user/delete-subuser`,payload),
  getUserById:(params)=>API.get(`user/get-subuser-by-id?subUserId=${params}`)
}

export default TeamAPI;

