import React,{useRef,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import Wrapper from './wrapper'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const Resources_DropDowns = ({isDropDownActive,resourcesRef,setIsDropDownActive}) => {

  const {t} = useTranslation();
  const tSect = "home.header";
  const Navigate = useNavigate()

  const menuRef = useRef()

  useEffect(()=>{

    let handler = (e) => {
      if(!resourcesRef.current.contains(e.target) && !menuRef.current.contains(e.target)){
        setIsDropDownActive(false)
      }
    }

    document.addEventListener("mousedown",handler)

    return()=>{
      document.removeEventListener("mousedown",handler)
    }
  })
    
  return (
    <div ref={menuRef} className={`w-[230px] sm:hidden md:hidden lg:flex transition-all  lg:${isDropDownActive ? "scale-100 opacity-100" : " scale-50 opacity-0 pointer-events-none"}  bg-[#0E0F11] absolute top-[60px] ltr:left-[384px] rtl:right-[424px]  rounded-md shadow-md flex-row `} style={{zIndex:"100"}}  >
      <div className='w-[100%] h-auto flex flex-col gap-2 p-6  ' >
        <div onClick={()=>{Navigate('/contact')}}  className={`w-full hover:bg-[#18191B] h-[30px] flex flex-row items-center p-4 gap-3 rounded-md cursor-pointer `}>
          <span className="material-symbols-outlined text-[#61FF76]  ">contact_page</span>
          <span className='text-white ltr:font-InterLight rtl:font-cairo font-medium' >{t(`${tSect}.contact-us`)}</span>  
        </div>     
        <div onClick={()=>{Navigate('/blog')}} className={`w-full hover:bg-[#18191B] h-[30px] flex flex-row items-center p-4 gap-3 rounded-md cursor-pointer `}>
          <span className="material-symbols-outlined text-[#61FF76]  ">newspaper</span>
          <span className='text-white ltr:font-InterLight rtl:font-cairo font-medium' >{t(`${tSect}.blog-page`)}</span>  
        </div>     
        <div onClick={()=>{Navigate('/blog-details')}} className={`w-full hover:bg-[#18191B] h-[30px] flex flex-row items-center p-4 gap-3 rounded-md cursor-pointer `}>
          <span className="material-symbols-outlined text-[#61FF76]  ">newspaper</span>
          <span className='text-white ltr:font-InterLight rtl:font-cairo font-medium' >{t(`${tSect}.blog-detail`)}</span>  
        </div>     
        <div onClick={()=>{Navigate('/detail-feature')}} className={`w-full hover:bg-[#18191B] h-[30px] flex flex-row items-center p-4 gap-3 rounded-md cursor-pointer `}>
          <span className="material-symbols-outlined text-[#61FF76]  ">featured_play_list</span>
          <span className='text-white ltr:font-InterLight rtl:font-cairo font-medium' >{t(`${tSect}.detail-feature`)}</span>  
        </div>     
        <div onClick={()=>{Navigate('/pricing')}} className={`w-full hover:bg-[#18191B] h-[30px] flex flex-row items-center p-4 gap-3 rounded-md cursor-pointer `}>
          <span className="material-symbols-outlined text-[#61FF76]  ">credit_card</span>
          <span className='text-white ltr:font-InterLight rtl:font-cairo font-medium' >{t(`${tSect}.pricing`)}</span>  
        </div>     
       
      </div>
    </div>
  )
}

export default Resources_DropDowns
