import React, { useEffect, useState,useCallback } from "react";
import Review from "./Components/review";
import Footer from "../../shared/Footer/footer";
import Charges from "./Components/charges";
import PricingHeader from "./Components/pricingHeader";
import Section4 from "../../shared/Section4/section4";
import Header from "../../shared/Header2/header";
import ReactGA from "react-ga"



const Pricing = () => {

  useEffect(()=>{
    ReactGA.pageview("/pricing");

  },[])


  return (
    <>
      <div  className="w-full min-h-[100vh] block bg-[#000000] pt-[20px] " >
          <div className="w-[80%] sm:w-[90%] md:w-[80%] max-w-7xl mx-auto" >
           <Header />
            <PricingHeader />
            <Charges />
            <Review />
          </div>
          <div className="w-full h-auto block mt-[20px]" >
          <Section4/>
          </div>
          <Footer />
      </div>
    </>
  );
};
export default Pricing;
