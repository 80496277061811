import { Navigate } from "react-router-dom";
import AuthAPI from "../services/auth";

import Library from "../modules/Dashboard2/Library/index";
import DashboardLayout from "../modules/Dashboard2/index";
import HomeDashboard from "../modules/Dashboard2/DashboardHome/index";
import Chat from "../modules/Dashboard2/Chat/index";
import KhudiPhoto from "../modules/Dashboard2/Khudi Photo/index";

///Brand Voice
import BrandVoice from "../modules/Dashboard2/BrandVoice";
import BrancVoiceHome from "../modules/Dashboard2/BrandVoice/brandVoice";
import VoiceHome from "../modules/Dashboard2/BrandVoice/VoiceHome";
import CreateBrandVoice from "../modules/Dashboard2/BrandVoice/create";
import EditBrandVoice from "../modules/Dashboard2/BrandVoice/editBrandVoice";

import Template from "../modules/Dashboard2/Template/index";
import SocialMedia from "../modules/Dashboard2/Template/Modals/socialMedia";
import AiArticle from "../modules/Dashboard2/Template/Modals/Article_And_Blogs/aiArticle";
import Ai_Blog_Titles from "../modules/Dashboard2/Template/Modals/Article_And_Blogs/ai-blog-titles";
import Ai_Paragraph_Writer from "../modules/Dashboard2/Template/Modals/Article_And_Blogs/ai-paragraph-writer";
import Ai_Article_Ideas from "../modules/Dashboard2/Template/Modals/Article_And_Blogs/ai-article-ideas";
import Ai_Article_Outlines_v2 from "../modules/Dashboard2/Template/Modals/Article_And_Blogs/ai-article-outlines-v2";
import Ai_Article_Intros from "../modules/Dashboard2/Template/Modals/Article_And_Blogs/ai-article-intros";
import Ai_Article_Outlines from "../modules/Dashboard2/Template/Modals/Article_And_Blogs/ai-article-outlines";
import Ai_Conclusion_Writer from "../modules/Dashboard2/Template/Modals/Article_And_Blogs/ai-conclusion-writer";
import Facebook_Ads from "../modules/Dashboard2/Template/Modals/Ads_And_Marketing/facebook-ads";
import LinkedIn_Ads_V2 from "../modules/Dashboard2/Template/Modals/Ads_And_Marketing/linkedIn-ads-v2";
import Google_Ads_Title_V2 from "../modules/Dashboard2/Template/Modals/Ads_And_Marketing/google-ads-title-v2";
import Google_Ads_Description_V2 from "../modules/Dashboard2/Template/Modals/Ads_And_Marketing/google-ads-description-v2";
import App_Sms_Notification from "../modules/Dashboard2/Template/Modals/Ads_And_Marketing/app-and-sms-notifications";
import Linkedin_Ad_Descriptions from "../modules/Dashboard2/Template/Modals/Ads_And_Marketing/linkedin-ads-descriptions";
import Linkedin_Ad_Headlines from "../modules/Dashboard2/Template/Modals/Ads_And_Marketing/linkedin-ad-headlines";
import Personal_Bio_v2 from "../modules/Dashboard2/Template/Modals/General Writing/personalBiov2";
import TextExtender from "../modules/Dashboard2/Template/Modals/General Writing/textExtender";
import Company_Bio_v2 from "../modules/Dashboard2/Template/Modals/General Writing/companyBiov2";
import Quora_Answers from "../modules/Dashboard2/Template/Modals/General Writing/quoraAnswers";
import Content_Shorten from "../modules/Dashboard2/Template/Modals/General Writing/content-shorten";
import Stories from "../modules/Dashboard2/Template/Modals/General Writing/stories";
import BulletPointAnswers from "../modules/Dashboard2/Template/Modals/General Writing/bulletPointAnswers";
import Passive_To_Active from "../modules/Dashboard2/Template/Modals/General Writing/passiveToActive";
import Definition from "../modules/Dashboard2/Template/Modals/General Writing/definition";
import Answers from "../modules/Dashboard2/Template/Modals/General Writing/answers";
import Emails_V2 from "../modules/Dashboard2/Template/Modals/General Writing/emailV2";
import Pros_And_Cons from "../modules/Dashboard2/Template/Modals/General Writing/prosAndCons";
import Rewrite_With_Keywords from "../modules/Dashboard2/Template/Modals/General Writing/rewriteWithKeywords";
import Email_Subjects_Lines from "../modules/Dashboard2/Template/Modals/General Writing/emailSubjectLines";
import Cold_Email_v2 from "../modules/Dashboard2/Template/Modals/General Writing/coldEmailv2";
import Company_Mission from "../modules/Dashboard2/Template/Modals/General Writing/companyMission";
import Twitter_Thread_v2 from "../modules/Dashboard2/Template/Modals/SocialMedia/twitterThreadv2";
import Social_Media_Content_Plan from "../modules/Dashboard2/Template/Modals/SocialMedia/socialMediaContentPlan";
import Linkedin_Posts_v2 from "../modules/Dashboard2/Template/Modals/SocialMedia/linkedinPostV2";
import Instagram_Captions from "../modules/Dashboard2/Template/Modals/SocialMedia/instagramCaptions";
import Twitter_Tweets from "../modules/Dashboard2/Template/Modals/SocialMedia/twiterTweets";
import Youtube_Description_V2 from "../modules/Dashboard2/Template/Modals/SocialMedia/youtubeDescriptionV2";
import Youtube_Intro_V2 from "../modules/Dashboard2/Template/Modals/SocialMedia/youtubeIntroV2";
import TikTok_Video_Script from "../modules/Dashboard2/Template/Modals/SocialMedia/tiktokVideoScript";
import TikTok_Video_Hooks from "../modules/Dashboard2/Template/Modals/SocialMedia/tiktokVideoHooks";
import Youtube_Ideas from "../modules/Dashboard2/Template/Modals/SocialMedia/youtubeIdeas";
import Youtube_Outlines from "../modules/Dashboard2/Template/Modals/SocialMedia/youtube-outlines";
import Youtube_Title from "../modules/Dashboard2/Template/Modals/SocialMedia/youtube-titles";

import ArticleAndBlog from "../modules/Dashboard2/Library/Template/Template Category/articleAndBlog";
import All from "../modules/Dashboard2/Library/Template/Template Category/all";
import AdsMarketingTemplate from "../modules/Dashboard2/Library/Template/Template Category/adsMarketingTemplate";
import GeneralWriting from "../modules/Dashboard2/Library/Template/Template Category/generalWriting";
import Social_Media from "../modules/Dashboard2/Library/Template/Template Category/socialMedia";
import New from "../modules/Dashboard2/Library/Template/Template Category/new";
import Ecommerce from "../modules/Dashboard2/Library/Template/Template Category/ecommerce";
import Others from "../modules/Dashboard2/Library/Template/Template Category/other";

import Setting from "../modules/Dashboard2/Settings";
import Prefrences from "../modules/Dashboard2/Settings/pages/prefrences";
import ProfileDetails from "../modules/Dashboard2/Settings/pages/profileDetails";
import PlansBilling from "../modules/Dashboard2/Settings/pages/plansBilling";
import Team from "../modules/Dashboard2/Settings/pages/Team/index";
import Integration from "../modules/Dashboard2/Settings/pages/integration";
import ApiDashboard from "../modules/Dashboard2/Settings/pages/apiDashboard";
import History from "../modules/Dashboard2/History";
import { create } from "lodash";

export const Protected = ({ children }) => {
  const token = localStorage.getItem("token");
  if (token && AuthAPI.isAuthenticated()) {
    return children;
  }
  return <Navigate to="/login" replace />;
};

//Dashboard Routes
export const PROTECTED_ROUTES = [
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        component: HomeDashboard,
      },
      {
        path: "khudi-photo",
        component: KhudiPhoto,
      },
      {
        path: "chat",
        component: Chat,
      },
      {
        path: "/library",
        component: Library,
        children: [
          {
            path: "article-and-blogs",
            component: ArticleAndBlog,
          },
          {
            path: "all",
            component: All,
          },
          {
            path: "ads-and-marketing",
            component: AdsMarketingTemplate,
          },
          {
            path: "general-writing",
            component: GeneralWriting,
          },
          {
            path: "social-media",
            component: Social_Media,
          },
          {
            path: "new",
            component: New,
          },
          {
            path: "ecommerce",
            component: Ecommerce,
          },
          {
            path: "others",
            component: Others,
          },
        ],
      },
      {
        path: "history",
        component: History,
      },
      {
        path: "*",
        component: BrandVoice,
        children: [
          {
            path: "brand-voice",
            component: BrancVoiceHome,
            children:[
              {
                path: "*",
                component: VoiceHome,
              },
              {
                path: "create",
                component: CreateBrandVoice,
              },
              {
                path: "edit/:id",
                component: EditBrandVoice,
              }
            ]
           
          }
          
        ],
      },
    ],
  },
  {
    path: "/templates",
    component: Template,
    children: [
      {
        path: "social-media",
        component: SocialMedia,
      },
      {
        path: "ai-article-writer",
        component: AiArticle,
      },
      {
        path: "ai-blog-title",
        component: Ai_Blog_Titles,
      },
      {
        path: "ai-paragraph-writer",
        component: Ai_Paragraph_Writer,
      },
      {
        path: "ai-article-ideas",
        component: Ai_Article_Ideas,
      },
      {
        path: "ai-article-outlines-v2",
        component: Ai_Article_Outlines_v2,
      },
      {
        path: "ai-article-intros",
        component: Ai_Article_Intros,
      },
      {
        path: "ai-article-outlines",
        component: Ai_Article_Outlines,
      },
      {
        path: "ai-conclusion-writer",
        component: Ai_Conclusion_Writer,
      },
      {
        path: "facebook_ads",
        component: Facebook_Ads,
      },
      {
        path: "linkedIn-ads-v2",
        component: LinkedIn_Ads_V2,
      },
      {
        path: "google-ads-title-v2",
        component: Google_Ads_Title_V2,
      },
      {
        path: "google-ads-description-v2",
        component: Google_Ads_Description_V2,
      },
      {
        path: "app-and-sms-notifications",
        component: App_Sms_Notification,
      },
      {
        path: "linkedin-ads-descriptions",
        component: Linkedin_Ad_Descriptions,
      },
      {
        path: "linkedin-ads-headlines",
        component: Linkedin_Ad_Headlines,
      },
      {
        path: "personal-bio-v2",
        component: Personal_Bio_v2,
      },
      {
        path: "text-extender",
        component: TextExtender,
      },
      {
        path: "company-bio-v2",
        component: Company_Bio_v2,
      },
      {
        path: "quora-answers",
        component: Quora_Answers,
      },
      {
        path: "content-shorten",
        component: Content_Shorten,
      },
      {
        path: "stories",
        component: Stories,
      },
      {
        path: "bullet-point-answers",
        component: BulletPointAnswers,
      },
      {
        path: "passive-to-active",
        component: Passive_To_Active,
      },
      {
        path: "definition",
        component: Definition,
      },
      {
        path: "answers",
        component: Answers,
      },
      {
        path: "email-v2",
        component: Emails_V2,
      },
      {
        path: "pros-and-cons",
        component: Pros_And_Cons,
      },
      {
        path: "rewrite-with-keywords",
        component: Rewrite_With_Keywords,
      },
      {
        path: "email-subjects-lines",
        component: Email_Subjects_Lines,
      },
      {
        path: "cold-email-v2",
        component: Cold_Email_v2,
      },
      {
        path: "company-mission",
        component: Company_Mission,
      },
      {
        path: "twitter-thread-v2",
        component: Twitter_Thread_v2,
      },
      {
        path: "social-media-content-plan",
        component: Social_Media_Content_Plan,
      },
      {
        path: "linkedin-posts-v2",
        component: Linkedin_Posts_v2,
      },
      {
        path: "instagram-captions",
        component: Instagram_Captions,
      },
      {
        path: "twitter-tweets",
        component: Twitter_Tweets,
      },
      {
        path: "youtube-description-v2",
        component: Youtube_Description_V2,
      },
      {
        path: "youtube-intro-v2",
        component: Youtube_Intro_V2,
      },
      {
        path: "tiktok-video-script",
        component: TikTok_Video_Script,
      },
      {
        path: "tiktok-video-hooks",
        component: TikTok_Video_Hooks,
      },
      {
        path: "youtube-ideas",
        component: Youtube_Ideas,
      },
      {
        path: "youtube-outlines",
        component: Youtube_Outlines,
      },
      {
        path: "youtube-title",
        component: Youtube_Title,
      },
    ],
  },
  {
    path: "/settings",
    component: Setting,
    children: [
      {
        path: "prefrences",
        component: Prefrences,
      },
      {
        path: "profile-details",
        component: ProfileDetails,
      },
      {
        path: "plans-and-billing",
        component: PlansBilling,
      },
      {
        path: "team",
        component: Team,
      },
      {
        path: "integration",
        component: Integration,
      },
      // ,
      // {
      //   path:"api-dashboard",
      //   component : ApiDashboard
      // }
    ],
  },
];
