import React from "react";
import HistoryTable from "./historyTable";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EditModal from "./editModal";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";

const History = () => {
  const { t } = useTranslation();
  const tSect = "History.topSection";

  // const isLoading = useSelector((state) => state.history.isLoading);
  const isLoading = true;

  //max-w-[1600px] mx-auto

  return (
    <div className={`w-full h-[100vh] sm:p-8 lg:px-12 pt-6   `}>
       {/* {isLoading && (
        <div className="w-full h-full flex items-center justify-center absolute top-0 left-0 z-20 bg-gray-300 bg-opacity-30  ">
          <Spinner className="animate-spin  h-6 w-6 ltr:float-right rtl:float-left mr-2 mt-1 " />
        </div>
      )} */}
      {/***Page Title and Worspace template***/}
      <div className="w-full h-auto flex flex-col gap-8 ">
        {/***Page Title*/}
        <div className="flex flex-row gap-2 items-center ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M27.9997 10.6667V28H3.99967V10.6667M13.333 16H18.6663M1.33301 4H30.6663V10.6667H1.33301V4Z"
              stroke="#175B22"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h2 className="ltr:font-InterLight rtl:font-Cairo text-black font-bold sm:text-[18px] lg:text-[20px] tracking-[1px] ">
            {t(`${tSect}.heading`)}
          </h2>
        </div>
        {/***WorkSpaces***/}
        {/* <div className="w-full h-auto flex sm:flex-col lg:flex-row gap-4  ">
          <div className="px-6 py-4  flex flex-row justify-between gap-2 border-[1px] border-gray-200 items-start rounded-md ">
            <div className="flex flex-row gap-2 items-start" >
              <img src="/Icons/history/breafcase.svg" className="p-1 bg-[#0086c91e] rounded-md " />
              <div className="flex flex-col" >
                <h2 className="ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px] text-[15px] " >{t(`${tSect}.erzi-workspace`)}</h2>
                <span className="font-medium ltr:font-InterLight rtl:font-Cairo text-[#667085] text-[14px] " >{t(`${tSect}.paragraph-erzi`)}</span>
              </div>
            </div>
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
             <path d="M9 18L15 12L9 6" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="px-6 py-4  flex flex-row justify-between gap-2 border-[1px] border-gray-200 items-start rounded-md ">
            <div className="flex flex-row gap-2 items-start" >
              <img src="/Icons/history/breafcase.svg" className="p-1 bg-[#0086c91e] rounded-md " />
              <div className="flex flex-col" >
                <h2 className="ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px] text-[15px] " >{t(`${tSect}.hutama-workspace`)}</h2>
                <span className="font-medium ltr:font-InterLight rtl:font-Cairo text-[#667085] text-[14px] " >{t(`${tSect}.paragraph-hutama`)}</span>
              </div>
            </div>
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
             <path d="M9 18L15 12L9 6" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div> */}
      </div>
      {/***History Table**/}
     
      
      <HistoryTable />
      <EditModal />
      <div className="w-full h-[50px]  block"></div>
    </div>
  );
};

export default History;
