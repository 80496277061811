import React from "react";
import BusinessCard from "./BuinessCard";
import ChargesCard1 from "./chargesCard1";
import ChargesCard2 from "./chargesCard2";

const Charges = () => {
  return (
    <>
      <div class="flex flex-col lg:flex-row md:flex-col justify-between">
        <ChargesCard1 />
        <BusinessCard />
        <ChargesCard2 />
      </div>
    </>
  );
};
export default Charges;
