import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LibraryToolsAPI from '../services/libraryTools';
import ParameterAPI from '../services/parameter';

const initialState = {
  loadingTypes: false,
  loadingTools: false,
  loadingToolsGrouped: false,
  loadingDetail: false,
  groups: [],
  tools: [],
  toolsGrouped: [],
  activeGroup: '',
  selectedTool: null,
  loadingSendPrompt: false,
  results: null,
}

export const getLibraryGroups = createAsyncThunk('parameter/library-tools', async () => {
  const params = { param: 'libraryToolsParameter' };
  const res = await ParameterAPI.getParameter(params);
  return res;
});



export const getLibraryTools = createAsyncThunk('library-tools', async (params) => {
  const res = await LibraryToolsAPI.getList(params);
  return res;
})

export const getLibraryToolsGrouped = createAsyncThunk(`library-tools-grouped`, async (params) => {
  const res = await LibraryToolsAPI.getListGroupByType(params);
  return res;
});

export const getToolDetail = createAsyncThunk('library-tools/detail', async (params) => {
  const res = await LibraryToolsAPI.getDetail(params);
  return res;
})

export const sendPrompt = createAsyncThunk(`library-tools/send`, async(payload) => {
  const res = await LibraryToolsAPI.sendPrompt(payload);
  return res;
});


export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setActiveGroup: (state, action) => {
      state.activeGroup = action.payload;
    },
    setSelectedTool: (state, action) => {
      state.selectedTool = action.payload;
    },
    resetTools:(state,action) =>{
      state.results = null
    }
  },
  extraReducers(builder) {
    builder

      .addCase(getLibraryGroups.pending, (state, action) => {
        state.loadingTypes = true;
      })
      .addCase(getLibraryGroups.fulfilled, (state, action) => {
        const { payload } = action;
        state.groups = payload;
        state.loadingTypes = false;
      })
      .addCase(getLibraryGroups.rejected, (state, action) => {
        state.loadingTypes = false;
        console.log(action.error);
      })
      .addCase(getLibraryTools.pending, (state, action) => {
        state.loadingTools = true;
      })
      .addCase(getLibraryTools.fulfilled, (state, action) => {
        const { payload } = action;
        state.tools = payload;
        state.loadingTools = false;
      })
      .addCase(getLibraryTools.rejected, (state, action) => {
        state.loadingTools = false;
        console.log(action.error);
      })
      .addCase(getLibraryToolsGrouped.pending, (state, action) => {
        state.loadingToolsGrouped = true;
      })
      .addCase(getLibraryToolsGrouped.fulfilled, (state, action) => {
        const { payload } = action;
        state.toolsGrouped = payload;
        state.loadingToolsGrouped = false;
      })
      .addCase(getLibraryToolsGrouped.rejected, (state, action) => {
        state.loadingToolsGrouped = false;
        console.log(action.error);
      })
      .addCase(sendPrompt.pending, (state, action) => {
        state.loadingSendPrompt = true;
      })
      .addCase(sendPrompt.fulfilled, (state, action) => {
        const { payload } = action;
        console.log(payload);
        state.results = payload;
        state.loadingSendPrompt = false;
      })
      .addCase(sendPrompt.rejected, (state, action) => {
        state.loadingSendPrompt = false;
        console.log(action.error);
      })
      .addCase(getToolDetail.pending, (state, action) => {
        state.loadingDetail = true;
      })
      .addCase(getToolDetail.fulfilled, (state, action) => {
        const { payload } = action;
        state.selectedTool = payload;
        state.loadingDetail = false;
      })
      .addCase(getToolDetail.rejected, (state, action) => {
        state.loadingDetail = false;
        console.log(action.error);
      })
  },
})

export const {
  setActiveGroup,
  setSelectedTool,
  resetTools
} = librarySlice.actions;

export default librarySlice.reducer