import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch ,useSelector } from "react-redux";
import { updateUserPassword } from "../../../../../Redux/profileSlice";
import { toast } from "react-toastify";

const UserPassword = () => {
  const { t } = useTranslation();
  const tSect = "Settings.profile-Details";
  const dispatch = useDispatch();
  const password = useRef()
  const newPassword = useRef()

  const isLoading = useSelector((state) => state.profile.loadingPassword);
  const status = useSelector((state) => state.profile.status);

  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePassword = (e) => {
    e.preventDefault();
    
    if (formData.newPassword == formData.confirmPassword) {
      const payload = {
        newPassword: formData.newPassword,
      };
      dispatch(updateUserPassword(payload))
      .then(()=> {
       status && toast.success(`Password Updated Successfully`, {
            position: "top-right",
          })
          setFormData({...formData,newPassword:""})
          password.current.value = ""
          setFormData({...formData,confirmPassword:""})
          newPassword.current.value = ""
      })

    } else {
      toast.warn(`${"Please Confirm Your Password"}`, {
        position: "top-right",
      });
    }
  };

  const disabled =  !formData.newPassword ||!formData.confirmPassword

  return (
    <div className="w-full h-auto flex flex-col gap-4 items-start mt-[10px]">
      <img src="/Icons/settings/keys.svg" className="h-[18px]" />
      <h2 className="text-[24px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-normal ">
        {t(`${tSect}.heading-Email-pass`)}
      </h2>
      {/* <label className="text-[16px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
        {t(`${tSect}.heading-old-password`)}
      </label>
      <input
        type="text"
        name="oldPassword"
        placeholder={t(`${tSect}.input-enter-old-pass`)}
        onChange={handleInput}
        className=" sm:w-full lg:w-[80%] outline-none shadow-sm p-2 border-[1px] border-[#D0D5DD] rounded-md text-black font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] "
      /> */}
      <div className="w-full flex sm:flex-col lg:flex-row gap-4  ">
        <div className=" flex flex-col gap-2 sm:w-[100%] lg:w-[38%] ">
          <label className="text-[16px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
            {t(`${tSect}.heading-new-password`)}
          </label>
          <input
            type="password"
            ref={newPassword}
            name="newPassword"
            placeholder={t(`${tSect}.input-enter-new-pass`)}
            onChange={handleInput}
            className=" outline-none shadow-sm p-2 border-[1px] border-[#D0D5DD] rounded-md text-black font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] "
          />
        </div>
        <div className=" flex flex-col gap-2 sm:w-[100%] lg:w-[38%] ">
          <label className="text-[16px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
            {t(`${tSect}.heading-confirm-pass`)}
          </label>
          <input
            type="password"
            ref={password}
            name="confirmPassword"
            placeholder={t(`${tSect}.input-confirm-new-pass`)}
            onChange={handleInput}
            className=" outline-none shadow-sm p-2 border-[1px] border-[#D0D5DD] rounded-md text-black font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] "
          />
        </div>
        <div className="flex flex-col gap-2 sm:w-[100%] lg:w-[17%]  items-start justify-end  ">
          <button
            onClick={handlePassword}
            disabled={disabled || isLoading }
            className="w-full text-white lg:w-[145px] sm:w-full cursor-pointer disabled:cursor-not-allowed bg-[#1D742C] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] rounded-md p-[12px] text-[14px] hover:bg-[#1b902e]  "
          >
            {
              isLoading ? <span>Loading...</span> :  t(`${tSect}.btn-change-pass`)
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserPassword;
