import React from "react";
import Header from "../../shared/Header2/header";
import { useTranslation } from "react-i18next";

const HeroPolicy = () => {
  // const { t } = useTranslation();
  // const tSect = "detail-feature.hero-section";

  return (
    <div className="w-full h-auto flex flex-col radial-gradient ">
      <div className="w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[85%] h-[100%] max-w-6xl  mx-auto flex flex-col pt-[20px] relative ">
        {/*****Header*****/}
        
        <Header />
        {/*****Background Pattern*****/}
        <img
          src="/Icons/detail-feature/background-pattern.svg"
          className="absolute top-3 sm:hidden lg:block opacity-70  lg:left-[80px] xl:left-[210px] pointer-events-none z-10 "
        />
        {/*****Main Content*****/}
        <div className="w-full h-[340px] flex flex-col items-center justify-center gap-2 pt-[20px] text-center ">
          {/*****Landing Text*****/}
          <div className=" inline-flex flex-row gap-3 gap-3 text-center">
            <span className="sm:text-[50px] md:text-[62px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[2px] ">
              Privacy
            </span>
            <span className="sm:text-[50px] md:text-[62px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[2px] bg-clip-text  text-transparent bg-gradient-to-b from-[#62fed2] to-[#0BA5EC]  ">
              Policy
            </span>
          </div>
          <p className="text-[18px] text-[#FCFEFC] font-InterLight ltr:font-InterLight rtl:font-Cairo  font-light tracking-[1px] ">
            Updated on 29-01-2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroPolicy;
