import React from 'react'
import { useTranslation } from "react-i18next";

const VerticaleBlogCard = ({img}) => {

    const {t} = useTranslation();
    const tSect = "blog.recent-blogs";

    return (
        <div class="grid grid-cols-12 gap-0 md:gap-4 lg:gap-4 bg-transparent">
            <div className="col-span-12 md:col-span-5 lg:col-span-5">
                <div class="h-52 sm:h-80 md:h-48 lg:h-48 rounded-lg bg-gray-300 mb-3">
                    <img src={img} alt="Image" className='object-fill h-full w-full rounded-lg' />
                </div>
            </div>
            <div className="col-span-12 md:col-span-7 lg:col-span-7">
                <label class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-sm text-gray-300">{t(`${tSect}.name-date`)}</label>
                <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium text-base text-white my-2 line-clamp-2">
                 {t(`${tSect}.heading-blog`)}
                </h1>
                <p class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-xs leading-5  line-clamp-3 text-gray-400 ">{t(`${tSect}.paragraph-blog`)}</p>
                <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.copy-writing`)}</span>
                <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.ai-blog`)}</span>
                <span class="font-medium text-xs border border-gray-300 text-white inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">{t(`${tSect}.ai-copy`)}</span>  </div>
        </div>
    )
}

export default VerticaleBlogCard