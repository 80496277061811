import React from 'react'

const PageButtons = ({handlePage,item,page}) => {
  return (
    <button
    onClick={() => handlePage(item)}
    className={`text-center text-[12px]  rounded-lg border-[1px] border-[#EAECF0] px-[14px] py-[6px] ${
      page == item
        ? "bg-[#1D742C] hover:bg-[#1d742ca1] text-white "
        : "hover:bg-[#e4e1e142] "
    }  transition-all active:scale-110 `}
  >
    {item}
  </button>
  )
}

export default PageButtons