import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Features = () => {
  const [activeBtn, setActiveBtn] = useState("try-words");
  const {t} = useTranslation();
  const tSect = "detail-feature.features";

  const Navigate = useNavigate()

  return (
    <div className="w-full h-auto bg-gradient-to-b from-[#0B0E0F] to-[#000000] flex flex-col gap-2 ">
      <div className="w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[85%] h-[100%] max-w-6xl  mx-auto flex flex-col gap-4 pt-[40px]  ">
        {/****Navigation Area****/}
        <div className="w-full h-auto flex flex-row sm:flex-col md:flex-row lg:flex-row items-center justify-center gap-4">
          <div className="inline-flex sm:w-full md:w-auto flex-row gap-2 items-center justify-center py-3 px-6 border-[1px] border-[#1D2939] rounded-md ">
            <img src="/Icons/detail-feature/cpu.svg" />
            <span className="text-[16px] text-[#45B554] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium ">
            {t(`${tSect}.see-all`)}
            </span>
          </div>
          <div  onClick={()=>Navigate("/pricing")} className="inline-flex cursor-pointer sm:w-full md:w-auto flex-row gap-2 items-center justify-center py-3 px-6 border-[1px] border-[#1D2939] rounded-md ">
            <img src="/Icons/detail-feature/double-rings.svg" />
            <span className="text-[16px] text-[#45B554] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium ">
            {t(`${tSect}.see-pricing`)}
            </span>
          </div>
          <div onClick={()=>Navigate("/dashboard")} className="inline-flex cursor-pointer sm:w-full md:w-auto flex-row gap-2 items-center justify-center py-3 px-6 bg-[#1D742C] rounded-md ">
            <img src="/Icons/detail-feature/current.svg" />
            <span className="text-[16px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
            {t(`${tSect}.lets-try`)}
            </span>
          </div>
        </div>
        {/***Wrapper1****/}
        <div className="w-full h-auto bg-[#141B28] mt-[70px] rounded-md sm:p-6 md:p-8 relative  flex flex-row sm:flex-col lg:flex-col xl:flex-row  sm:gap-8 xl:gap-2 ">
          {/**Left-Wrapper**/}
          <img
            src="/Icons/detail-feature/background-pattern.svg"
            className="absolute top-[4.5rem] left-[-50px] h-[331px]  pointer-events-none z-10 "
          />
          <div className="w-[55%] sm:w-[100%] lg:w-[100%] xl:w-[55%] h-auto flex flex-col gap-2 items-start   ">
            <div className="inline-flex gap-1 bg-[#FFFFFF] bg-opacity-10 py-2 px-4 rounded-md ">
              <span className="text-white text-[18px] sm:text-[15px] md:text-[18px] font-Inter font-normal tracking-[0.5px]">
              {t(`${tSect}.khudi-ai`)}
              </span>
              <span className="bg-clip-text text-[18px] sm:text-[15px] md:text-[18px] text-transparent bg-gradient-to-r from-[#62FE76] to-[#0BA5EC] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px]">
              {t(`${tSect}.copy-writing`)}
              </span>
              <span className="text-white text-[18px] sm:text-[15px] md:text-[18px] font-Inter font-normal tracking-[0.5px] ">
              {t(`${tSect}.feature`)}
              </span>
            </div>
            <h2 className="text-[#FCFEFC] text-[27px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[2px] ">
            {t(`${tSect}.button-highly`)} <br /> {t(`${tSect}.effective`)}
            </h2>
            <p className=" text-[18px] text-[#FCFEFC] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] pt-[10px] ">
            {t(`${tSect}.advance-ai`)} 
            </p>
            <div className="w-full h-auto flex flex-wrap gap-2 gap-y-4 pt-[20px] ">
              <div className=" w-[45%] sm:w-full md:w-[45%] inline-flex items-start justify-center sm:justify-start xl:justify-start flex-row gap-3 ">
                <img src="/Icons/detail-feature/credit-card.svg" />
                <p className="text-[#61FF76] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
                {t(`${tSect}.customizable-image`)} 
                </p>
              </div>
              <div className=" w-[45%] sm:w-full md:w-[45%] inline-flex items-start justify-center sm:justify-start xl:justify-start flex-row gap-3 ">
                <img src="/Icons/detail-feature/layout.svg" />
                <p className="text-[#61FF76] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
                {t(`${tSect}.user-friendly`)}
                </p>
              </div>
              <div className=" w-[45%] sm:w-full md:w-[45%] inline-flex items-start justify-start sm:justify-start xl:justify-start flex-row gap-3 ">
                <img src="/Icons/detail-feature/3-layers.svg" />
                <p className="text-[#61FF76] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
                {t(`${tSect}.diversity`)}
                </p>
              </div>
              <div className=" w-[45%] sm:w-full md:w-[45%] inline-flex items-start justify-center sm:justify-start xl:justify-start flex-row gap-3 ">
                <img src="/Icons/detail-feature/gift.svg" />
                <p className="text-[#61FF76] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
                {t(`${tSect}.credit-call-little`)}
                </p>
              </div>
            </div>
          </div>
          {/*****Image****/}
          <img
            src="/Icons/khudi-photo-screenshot.png"
            className="w-[45%] sm:w-[100%] lg:w-[100%] xl:w-[55%]  h-auto "
          />
          {/* </div> */}
        </div>
        {/* <div className="w-full h-auto flex flex-row sm:flex-col lg:flex-row pt-[60px] gap-8 ">
          <div className="w-[45%] sm:w-full lg:w-[45%]  h-auto flex flex-col sm:p-0 md:p-8 ">
            <div className="bg-[#101828] p-6 flex flex-col rounded-md gap-y-4  ">
              <h2 className="text-[27px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-[500] tracking-[2px] ">
              {t(`${tSect}.left-btn-highly`)} <br/> {t(`${tSect}.left-btn-effective`)}
              </h2>
              <p className="text-[15px] text-[#E7F5E9] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] ">
              {t(`${tSect}.left-btn-p`)}
              </p>
              <div className="flex flex-row sm:flex-col md:flex-row p-2 items-center gap-4 justify-between sm:rounded-md md:rounded-full border-[1px] border-[#344054] pt-[10px] ">
                <span
                  onClick={() => setActiveBtn("all-feature")}
                  className={`text-[#296B32] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium text-[16px] lg:text-[14px] 2xl:text-[16px]  cursor-pointer px-3 py-2 rounded-full ${activeBtn == "all-feature"?"bg-[#1D2939] text-[#61FF76] ":""} `}
                >
                 {t(`${tSect}.left-see-feature`)}
                </span>
                <div
                  onClick={() => setActiveBtn("try-words")}
                  className={`inline-flex flex-row gap-2 px-3 py-2 rounded-full cursor-pointer ${activeBtn == "try-words"?"bg-[#1D2939] text-[#61FF76] ":""}  `}
                >
                  <span className="text-[#61FF76] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium text-[16px] lg:text-[14px] 2xl:text-[16px] ">
                  {t(`${tSect}.left-10k-words`)}
                  </span>
                  <img src="/Icons/detail-feature/arrow.svg" />
                </div>
              </div>
            </div>
          </div>
         
          <div className="w-[55%] sm:w-full lg:w-[55%] h-auto flex flex-row gap-8 pt-8" >
              <div className="w-[100px] h-[670px] mt-[160px] ml-[20px] relative sm:hidden md:flex " >
                <div className="w-[4px] h-full bg-[#1D2939]   " >
                </div>
                <img src="/Icons/detail-feature/green-circle.svg" className="h-[50px] z-50 absolute top-0 ltr:translate-x-[-23px] rtl:translate-x-[23px] translate-y-[-26px] " />
                <img src="/Icons/detail-feature/green-circle.svg" className="h-[50px] z-50 absolute bottom-0 ltr:translate-x-[-23px] rtl:translate-x-[23px] translate-y-[16px] " />
              </div>
              <div className="w-[90%] sm:w-[100%] md:w-[90%] h-auto  flex flex-col gap-[100px] " >
               <div className="w-full h-auto flex flex-col gap-2" >
                 <span className="bg-[#222425] text-[20px] text-[#61FF76] font-InterLight ltr:font-InterLight rtl:font-Cairo p-2 w-[100px] inline-flex items-center justify-center rounded-t-[12px] rounded-br-[12px] rounded-bl-[0px]  " >{t(`${tSect}.right-step-1`)}</span>
                 <span className="text-[30px] text-[#FCFEFC] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[500] " >{t(`${tSect}.right-btn-highly`)}<br/>{t(`${tSect}.right-btn-click`)}</span>
                 <p className="text-[18px] text-[#FCFEFC] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] " >{t(`${tSect}.right-btn-p`)}</p>
                 <img src="/Icons/detail-feature/admin-image.svg" className="mt-[20px]" />
               </div>
               <div className="w-full h-auto flex flex-col gap-2" >
               <span className="bg-[#222425] text-[20px] text-[#61FF76] font-InterLight ltr:font-InterLight rtl:font-Cairo p-2 w-[100px] inline-flex items-center justify-center rounded-t-[12px] rounded-br-[12px] rounded-bl-[0px]  " >{t(`${tSect}.right-step-1`)}</span>
                 <span className="text-[30px] text-[#FCFEFC] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[500] " >{t(`${tSect}.right-btn-highly`)}<br/>{t(`${tSect}.right-btn-click`)}</span>
                 <p className="text-[18px] text-[#FCFEFC] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] " >{t(`${tSect}.right-btn-p`)}</p>
                 <img src="/Icons/detail-feature/admin-image.svg" className="mt-[20px]" />
               </div>
              </div>
          </div>
        </div> */}
        <div className="w-full h-[50px] block " >

        </div>
      </div>
    </div>
  );
};

export default Features;
