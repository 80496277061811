import React from 'react';
import { useDispatch } from 'react-redux';
import { getChatDetail } from '../../Redux/chatsSlice';

const Chat = ({ chat, id }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(getChatDetail(id));
  }

  return (
    <div
      className='w-full px-4 py-4 bg-[#212936] inline-flex items-center justify-start cursor-pointer '
      onClick={handleClick}
    >
      <p className='text-[#FCFCFD] text-[12px] font-InterLight font-medium tracking-[0.5px] truncate' >{chat}</p>
    </div>
  )
}

export default Chat
