import React, { useState } from "react";
import Header from "../../shared/Header2/header";
import Footer from "../../shared/Footer/footer";
import { useTranslation } from "react-i18next";
import { useDispatch , useSelector } from "react-redux";
import {SendComplain} from "../../Redux/contactSlice"
import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg";
import { toast } from "react-toastify";

const ContactPage = () => {
  const { t } = useTranslation();
  const tSect = "contact";
  const dispatch = useDispatch()

  const isLoading  = useSelector((state)=>state.contact.loadingSendComplain)
  const message = useSelector((state)=>state.contact.message)

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    complaint: "",
  });

  const handleInput = (event) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSend  = () =>{

    const payload = {
      name:formData.name,
      email:formData.email,
      description:formData.complaint
    }

    dispatch(SendComplain(payload)).then((resp)=>{
      message &&  toast.success(`${message}`, {
        position: "top-right",
      });
    })

  }

  const disabled = !formData.name || !formData.email || !formData.complaint

  return (
    <div className="bg-black w-screen min-h-[100vh]   ">
      <div className="sm:w-[95%] md:w-[90%] lg:w-[85%] xl:w-[80%] mx-auto max-w-7xl pt-[20px] ">
        <Header />
        <div className="flex sm:px-0 py-14 items-center justify-center  mt-20 font-InterLight ltr:font-InterLight rtl:font-Cairo   flex-col-reverse xs:flex-col-reverse xs:px-10 md:flex-row md:px-0 lg:flex-row lg:px-0 xl:flex-row xl:px-0 2xl:flex-row 2xl:px-0">
          {/* Contact Us Information Start */}

          {/* <div className="px-8 py-10 mx-4 sm:w-auto xs:w-auto h-2/3 text-white bg-[#0A0D0E] font-InterLight ltr:font-InterLight rtl:font-Cairo   md:w-72 lg:w-[30%] xl:w-[30%] ">
            <div>
              <h2 className=" text-3xl mb-4">{t(`${tSect}.sect-1.contact-us`)}</h2>
              <p className="text-sm flex flex-wrap">
              {t(`${tSect}.sect-1.paragraph`)}
              </p>


              <div className=" mt-8">
                <div className="inline-flex gap-2">
                  <img className="w-5 mr-4" src="/Icons/email.svg"/>
                  <h3 className="text-xl">{t(`${tSect}.sect-1.chat-to-us`)}</h3>
                </div>
                <div className="ml-9 text-xs">
                  <p className="my-2 flex flex-wrap">
                  {t(`${tSect}.sect-1.p-chat`)}
                  </p>
                  <p className="px-2 py-1 bg-[#272727] w-28 rounded-md">
                    {t(`${tSect}.sect-1.email-chat`)}
                  </p>
                </div>
              </div>


              <div className=" mt-8">
                <div className="inline-flex gap-2">
                  <img className="w-5 mr-4" src="/Icons/location.svg" alt="" />
                  <h3 className="text-xl">{t(`${tSect}.sect-1.office`)}</h3>
                </div>
                <div className="ml-9 text-xs">
                  <p className="my-2 flex flex-wrap">
                  {t(`${tSect}.sect-1.p-1-office`)}
                  </p>
                  <p className="px-2 py-1 bg-[#272727] w-auto rounded-md flex flex-wrap">
                  {t(`${tSect}.sect-1.p-2-office`)}
                  </p>
                </div>
              </div>


              <div className=" mt-8">
                <div className="inline-flex gap-2">
                  <img className="w-5 mr-4" src="/Icons/contact.svg" alt="" />
                  <h3 className="text-xl">{t(`${tSect}.sect-1.phone`)}</h3>
                </div>
                <div className="ml-9 text-xs">
                  <p className="my-2">{t(`${tSect}.sect-1.p-phone`)}</p>
                  <p className="px-2 py-1 bg-[#272727] w-28 rounded-md">
                  {t(`${tSect}.sect-1.phone-number`)}
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="text-white px-5 w-auto overflow-hidden py-5 sm:px-10 xs:px-10 md:px-10 md:w-9/12 lg:w-[68%] xl:w-[68%]">
            <h2 className=" text-3xl">{t(`${tSect}.sect-2.heading`)}</h2>
            {/* <span className="flex text-sm mt-4 flex-wrap">
              <p className="text-sm ltr:mr-1 rtl:ml-1 ">{t(`${tSect}.sect-2.p-1`)}</p>
              <p className="text-[#61FF76]">{t(`${tSect}.sect-2.p-2`)}</p>
            </span> */}

            {/* Inputs   */}

            <div className="py-8">
              <p className="text-sm mb-1">{t(`${tSect}.sect-2.name`)}</p>
              <input
                className="w-full px-2 py-2 bg-[#16191A] border-[0.5px] border-[#344054] rounded outline-none placeholder:text-[#98A2B3] placeholder:text-sm"
                type="text"
                name="name"
                onChange={handleInput}
                placeholder={t(`${tSect}.sect-2.placeholder-name`)}
              />
            </div>
            <div className="pb-8">
              <p className="text-sm mb-1">{t(`${tSect}.sect-2.email`)}</p>
              <input
                className="w-full px-2 py-2 bg-[#16191A] border-[0.5px] border-[#344054] rounded outline-none placeholder:text-[#98A2B3] placeholder:text-sm"
                type="email"
                name="email"
                onChange={handleInput}
                placeholder={t(`${tSect}.sect-2.placeholder-email`)}
              />
            </div>

            <div>
              <p className="text-sm mb-1">{t(`${tSect}.sect-2.how-help`)}</p>
              <input
                name="complaint"
                onChange={handleInput}
                className="w-full px-2 pb-36 bg-[#16191A] border-[0.5px] border-[#344054] rounded outline-none placeholder:text-[#98A2B3] placeholder:text-sm"
                placeholder={t(`${tSect}.sect-2.placeholder-help`)}
              />
            </div>

            {/* Inputs End */}

            {/* Services (CheckBoxes) */}

            {/* <div className="mt-6">
              <p>{t(`${tSect}.sect-2.services`)}</p>
              <div className="flex mt-4 flex-wrap">
                <ul className="mr-7">
                  <li className="flex gap-2 pb-4">
                    <input type="checkbox" />
                    <p className="ml-2 text-sm -mt-[1px]">{t(`${tSect}.sect-2.tool-khudi-1`)}</p>
                  </li>
                  <li className="flex gap-2 pb-4">
                    <input type="checkbox" />
                    <p className="ml-2 text-sm -mt-[1px]">{t(`${tSect}.sect-2.chatbot-khudi-1`)}</p>
                  </li>
                  <li className="flex gap-2 pb-4">
                    <input type="checkbox" />
                    <p className="ml-2 text-sm -mt-[1px]">{t(`${tSect}.sect-2.chatbot-khudi-2`)}</p>
                  </li>
                  <li className="flex gap-2 pb-4">
                    <input type="checkbox" />
                    <p className="ml-2 text-sm -mt-[1px]">{t(`${tSect}.sect-2.chatbot-khudi-3`)}</p>
                  </li>
                </ul>
                <ul>
                  <li className="flex gap-2 pb-4">
                    <input type="checkbox" />
                    <p className="ml-2 text-sm -mt-[1px]">{t(`${tSect}.sect-2.tool-khudi-1`)}</p>
                  </li>
                  <li className="flex gap-2 pb-4">
                    <input type="checkbox" />
                    <p className="ml-2 text-sm -mt-[1px]">{t(`${tSect}.sect-2.chatbot-khudi-3`)}</p>
                  </li>
                  <li className="flex gap-2 pb-4">
                    <input type="checkbox" />
                    <p className="ml-2 text-sm -mt-[1px]">{t(`${tSect}.sect-2.chatbot-khudi-3`)}</p>
                  </li>
                  <li className="flex gap-2 pb-4">
                    <input type="checkbox" />
                    <p className="ml-2 text-sm -mt-[1px]">{t(`${tSect}.sect-2.chatbot-khudi-3`)}</p>
                  </li>
                </ul>
              </div>
              
            </div> */}
             <button disabled={disabled} onClick={handleSend} className="bg-[#296B32] enabled:bg-[#3fa54d] disabled:bg-[#296b32cd] disabled:cursor-not-allowed  cursor-pointer  w-full my-5 py-2 rounded-md">           
               {
                isLoading ? 
                <div className="flex items-center justify-center" >
                  <Spinner/>
                </div>
                :t(`${tSect}.sect-2.btn-send`)
               }
            </button>
          </div>

          {/*  Input_Wrapper Ends  */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
