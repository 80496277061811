import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ItemDrop = ({ heading, tools ,isFilter }) => {
  
  const { t } = useTranslation();
  const Navigate = useNavigate()

  const [isDropDownActive, setIsDropDownActive] = useState(tools?.length > 0);

  const handleDropDown = () => {
    setIsDropDownActive((prev) => !prev);
  };

  const handlePage = (page) => {
    Navigate(`/templates/${page}`)
  }


  const path = window.location.pathname
  const trimmedUrl = path.substring(11); 
  
  return (
    <div className="w-full h-auto flex flex-col ">
    <div
      onClick={handleDropDown}
      className="w-full h-auto cursor-pointer inline-flex items-center flex-row  justify-between   "
    >
      <div className="inline-flex items-center gap-3">
        <span className="text-[14px] text-[white] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
          {
            isFilter ? tools[0].category : heading
          }
        </span>
        <span className="px-2 py-1 mr-2 bg-[#344054] rounded-md inline-flex items-center text-white text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal ">
          {tools.length}
        </span>
      </div>
      <img
        src="/Icons/expand.svg"
        className={`h-[10px] float-right transition-all ${
          isDropDownActive ? "rotate-180" : "rotate-0"
        } `}
        alt=""
      />
    </div>
    <div
      className={`w-full h-auto flex flex-col gap-1 mt-[10px] ${
        isDropDownActive ? "h-[auto]" : "hidden"
      }  `}
    >
      { tools?.map((tool, index) => (
        <div
          key={index}
          className={`w-full h-[30px] inline-flex items-center gap-2 cursor-pointer`}
          onClick={()=>handlePage(tool.route)}
        >
          <img
            src="/Icons/templates/arrow-gray.svg"
            className="h-[18px] ltr:rotate-0 rtl:rotate-180  "
            alt=""
          />
          <img src={`/Icons/templates/${trimmedUrl == tool.route?"document-active":"document-gray"}.svg`} alt="" />
          <span
            className={`text-[13px] ltr:font-InterLight rtl:font-Cairo font-medium  ${trimmedUrl == tool.route ? "text-[#61FF76]" : "text-[#667085]" }
            
          `}
          >
            {t(`${tool.title}`)}
          </span>
        </div>
      ))}
    </div>
    </div>
  
  );
};

export default ItemDrop;
