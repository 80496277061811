import React from "react";
import { useTranslation } from "react-i18next";

const ArticleCard = () => {

  const {t} = useTranslation();
  const tSect = "blog-details.related-articles";

  return (
    <div className="bg-transparent grid grid-cols-3 gap-0 md:gap-4">
      <div className="col-span-3 md:col-span-1 lg:col-span-3">
        <div class="h-52 sm:h-80 md:h-48 lg:h-64 rounded-lg bg-gray-300 mb-4">
          <img
            src="https://images.unsplash.com/photo-1542315204-8b368472a4e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            alt="Image"
            className="object-fill h-full w-full rounded-lg"
          />
        </div>
      </div>
      <div className="col-span-3 md:col-span-2 lg:col-span-3">
        <label class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-sm text-[#d0d5dd]">
        {t(`${tSect}.user-details`)}
        </label>
        <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-medium text-base text-[#d0d5dd] my-2 line-clamp-2">
        {t(`${tSect}.heading1`)}
        </h1>
        <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-xs  text-[#d0d5dd] line-clamp-3">
        {t(`${tSect}.paragraph1`)}
        </p>
        <span class="font-medium text-xs border border-gray-300 text-gray-300 inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">
        {t(`${tSect}.btn1`)}
        </span>
        <span class="font-medium text-xs border border-gray-300 text-gray-300 inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">
        {t(`${tSect}.btn2`)}
        </span>
        <span class="font-medium text-xs border border-gray-300 text-gray-300 inline-block text-center py-0.5 px-2 rounded-full mt-3 me-3">
        {t(`${tSect}.btn3`)}
        </span>
      </div>
    </div>
  );
};

export default ArticleCard;
