import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetBrandVoicesNames,
  setbrandVoiceID,
} from "../../../../Redux/brandVoiceSlice";
import Radio from "./radio";
import { ReactComponent as Spinner } from "../../../../assets/icons/spinner.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const BrandVoice = () => {
  const dispatch = useDispatch();
  const modalRef = useRef();
  const Navigate = useNavigate();

  const { t } = useTranslation();
  const tPrompt = "Khudi-Chat.chat-bar"

  const brandVoiceName = useSelector(
    (state) => state.brandVoice.brandVoiceNames
  );
  const isLoading = useSelector(
    (state) => state.brandVoice.isBrancVoiceNamesLoading
  );

  const [isPopUpActive, setIsPopupActive] = useState(false);
  //Brand Voice ID
  const [selectedValue, setSelectedValue] = useState({});


  useEffect(() => {
    dispatch(setbrandVoiceID(selectedValue));
  }, [selectedValue]);

  useEffect(() => {
    dispatch(GetBrandVoicesNames());
    setSelectedValue({
      Name: `${t(`${tPrompt}.brand-voice`)}`,
      Id: 0,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsPopupActive(false);
    }
  };

  const handleNoBrandVoice = () => {
    setSelectedValue({
      Name: `${t(`${tPrompt}.no-brand-voice`)}`,
      Id: 0,
    });
    setIsPopupActive(false);
  };


  return (
    <div ref={modalRef} className=" relative ">
      <button
        onClick={() => setIsPopupActive((prev) => !prev)}
        className="flex flex-row gap-2 flex-shrink-0"
      >
        <img src="/Icons/brand-voice.svg" />
        <h2 className="text-[#1D742C] text-[14px]  ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide">
          {brandVoiceName &&brandVoiceName.length > 0  && selectedValue.Id == 0 ? `${t(`${tPrompt}.no-brand-voice`)}` : selectedValue.Name  }
        </h2>
      </button>
      <div
        className={` ${
          isPopUpActive
            ? "opacity-100 translate-y-[-5px] "
            : "opacity-0 pointer-events-none translate-y-[54px] -z-20 "
        } transition-all w-[220px] h-auto bg-[#2B2C2E]  rounded-md absolute  bottom-[45px] left-[-30px]  `}
      >
        <ul className=" list-style-none flex flex-col  ">
          {/**Brand Voice Navigate**/}
          <li
            onClick={() => Navigate("/brand-voice")}
            className="px-3 py-3 flex gap-2 items-center border-b-[1px] border-gray-400 hover:bg-[#1515157c] rounded-t-md  "
          >
            <img src="/Icons/brandVoice/brandvoice-green.svg" />
            <span className="text-white text-[14px]   rtl:font-Cairo">{t(`${tPrompt}.brand-voice`)}</span>
          </li>
          {/**Brand Voices **/}
          <li className=" rounded-t-md py-2 min-h-auto max-h-[200px] overflow-y-auto scrollbar-none  flex       ">
            <div className="flex flex-col w-full  ">
              {isLoading && (
                <div className="w-full h-full flex items-center justify-center">
                  <Spinner />
                </div>
              )}
              {brandVoiceName && brandVoiceName.length > 0
                ? brandVoiceName.map((item, index) => {
                    return (
                      <Radio
                        key={index}
                        voiceName={item.voiceName}
                        id={item._id}
                        setIsPopupActive={setIsPopupActive}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                      />
                    );
                  })
                : !isLoading && (
                    <>
                      <div className="flex flex-row gap-2   px-3 py-3 ">
                        <img src="/Icons/brandVoice/Checkbox.svg" />
                        <span className="text-white text-[14px] rtl:font-Cairo ">
                        {t(`${tPrompt}.no-brand-voice`)}
                        </span>
                      </div>
                      <div className="text-white text-[11px]  px-3 rtl:font-Cairo  ">
                      {t(`${tPrompt}.message-brand-voice`)}
                      </div>
                    </>
                  )}
            </div>
          </li>
          {/**Create or De-Select **/}
          <li className=" border-t-[1px] border-gray-400 hover:bg-[#1515157c]  flex flex-row gap-2 px-3 py-4 items-center overflow-y-auto ">
            {brandVoiceName && brandVoiceName.length > 0 ? (
              <div
                onClick={brandVoiceName.length > 0 && handleNoBrandVoice}
                className="flex flex-row gap-2"
              >
                <input
                  type="radio"
                  value={""}
                  checked={selectedValue.Id == 0}
                  className="bg-green-600 border-[1px] border-green-300 pointer-events-none "
                />
                <span className="text-white text-[14px] rtl:font-Cairo "> {t(`${tPrompt}.no-brand-voice`)}</span>
              </div>
            ) : (
              <div
                onClick={() => Navigate("/brand-voice/create")}
                className="flex flex-row gap-2"
              >
                <span className="text-white text-[14px] rtl:font-Cairo ">
                {t(`${tPrompt}.create-brand-voice`)}
                </span>
                <img src="/Icons/brandVoice/plus-circle.svg" />
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BrandVoice;
