import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsUpgradeActive  } from '../../../Redux/subscriptionSlice';
import PackageAPI from '../../../services/packages';
import { TYPE_PACKAGE } from '../../../config/constants';
import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';
import { ReactComponent as StackLogo } from '../../../assets/icons/square-3-stack-3d.svg';
import { useTranslation } from "react-i18next";

const { MONTHLY, YEARLY } = TYPE_PACKAGE;

const PlanChoice = ({ data, className, lang, activePlan, onClick }) => {
  return (
    <div className={`${className} relative`} onClick={onClick}>
      {data.popular &&
        <span className='absolute top-[-12px] right-0 px-3 py-1 inline-flex gap-1 items-center bg-[#FFF1F3E4] text-[#C01048] text-[12px] font-InterLight rounded-full '>
          <StackLogo className='h-4 w-4' />
          Most Popular
        </span>
      }
      <h2 className='text-[15px] text-[#101828] font-semibold  font-InterLight tracking-[0.5px]' >{data.name[lang]}</h2>
      {data.price ?
        <h2 className='text-[16px] text-[#B42318] font-InterLight font-semibold tracking-[0.5px] ' >
          {`$${data.price}/${activePlan === MONTHLY ? 'Month' : 'Year'}`}
          {data.discount ?
            <span className='ml-1 text-[12px] text-[#101828] font-InterLight font-normal line-through'>{`$${data.discount}/${activePlan === MONTHLY ? 'Month' : 'Year'}`}</span>
            :
            <></>
          }
        </h2>
        :
        <h2 className='text-[16px] text-[#475467] font-InterLight font-semibold tracking-[0.5px] ' >Free</h2>
      }
      <p className='text-[12px] text-[#667085] font-InterLight font-normal tracking-[0.5px] ' >{data.desc[lang]}</p>
    </div>
  )
}

const Upgrade = ({ isUpgradeActive }) => {
  const dispatch = useDispatch();
  const lang = localStorage.getItem('i18nextLng');

  const { t } = useTranslation();
  const tSect = "upgrade";

  // const subscriptionPackage = useSelector((state)=>state.subscription.package)

  const [activePlan, setActivePlan] = useState(MONTHLY);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const getPackages = useCallback(async () => {

    const params = { type: activePlan };
    try {
      setLoading(true);
      const res = await PackageAPI.getPackages(params);
      setPackages(res);
      setSelectedPlan('');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("failed to get package", err);
      setSelectedPlan('');
      setPackages([]);
    }
  }, [activePlan])
  useEffect(() => {
    getPackages();
  }, [getPackages]);

  const [selectedPlan, setSelectedPlan] = useState('');
  const handleClickPlan = (item) => {
    if(!item.currentPackage)
      setSelectedPlan(item.id);
  }

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(setIsUpgradeActive(false));
    }
  }

  const handlePayment = async() => {
    const payload = {
      subscriptionId: selectedPlan,
      language: lang
    };
    try{
      setLoading(true);
      const res = await PackageAPI.subscribePackage(payload);
      window.location.replace(res.redirectUrl);
    }catch(err){
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <div className={`w-full min-h-[100vh] absolute top-0 z-50 bg-gray-400 bg-opacity-60  backdrop-blur-sm flex items-center justify-center p-6 transition-all ${isUpgradeActive ? "scale-100 opacity-100" : "scale-0 pointer-events-none opacity-0 "} `}
      onClick={handleClose}
    >
      <div className='w-[550px] sm:w-[100%] md:w-[550px] h-auto bg-[white] shadow-md rounded-[20px] p-4 ' >
        {/***"Section1"***/}
        <div className='w-full h-auto flex flex-col items-center justify-center gap-2' >
          <img src='/Icons/Charco Mobile Life.svg' className='h-[127px]' alt="ic-upgrade-plan" />
          <h2 className='text-[25px]  font-InterLight font-medium' >Upgrade Your Plan</h2>
          <p className='text-[15px] sm:text-[12px] md:text-[15px] text-[#667085] ltr:font-InterLight rtl:font-Cairo font-normal text-center tracking-[0.5px] ' >
          {t(`${tSect}.main-paragraph`)}
          </p>
          <div className=' p-1  border-[1px] border-[#D0D5DD] flex flex-row gap-2 justify-between rounded-full ' >
            <span onClick={() => { setActivePlan(MONTHLY) }} className={`cursor-pointer text-[15px] sm:text-[12px] md:text-[15px] px-4 py-2 rounded-full  text-[#296B32] ltr:font-InterLight rtl:font-Cairo font-medium ${activePlan === MONTHLY ? "text-[white] bg-[#296B32] " : "text-[#296B32] bg-[white] "} `} >{t(`${tSect}.billed-monthly`)}</span>

            <h2 onClick={() => { setActivePlan(YEARLY) }} className={`inline-flex gap-2 cursor-pointer rounded-full px-4 py-2 text-[15px] sm:text-[12px] md:text-[15px] text-[#296B32] ltr:font-InterLight rtl:font-Cairo font-medium ${activePlan === YEARLY ? "text-[white] bg-[#296B32]" : "text-[#296B32] bg-[white]"}  `} >
            {t(`${tSect}.billed-yearly`)}
              <span className={`text-[#61FF76] ${activePlan === YEARLY ? "text-[#61FF76]" : "text-[#296B32]"} font-InterLight font-medium`}  >{t(`${tSect}.off`)}</span>
            </h2>
          </div>
        </div>
        {loading ?
          <div className='flex justify-center mt-5'>
            <Spinner className='animate-spin h-8 w-8' />
          </div>
          :
          <>
            {packages.map((item, idx) => {
              if (idx === (packages.length - 1)) {
                return (
                  <div key={item.id} className={`w-full h-auto flex flex-row gap-1 mt-[20px] border-[1px] rounded-md shadow-sm ${selectedPlan === item.id ? 'border-[#1D742C]' : 'border-[#EAECF0]'}`}>
                    <PlanChoice
                      className={`w-full h-auto p-4 flex flex-col gap-2 ${item.currentPackage ? 'bg-[#EAECF0]' : 'cursor-pointer'} `}
                      data={item}
                      lang={lang}
                      activePlan={activePlan}
                      onClick={() => handleClickPlan(item)}
                    />
                  </div>
                )
              } else if (idx % 2 === 0) {
                return (
                  <div key={item.id} className='w-full h-auto flex flex-row sm:flex-col md:flex-row  gap-1 sm:gap-4 md:gap-1 mt-[50px] justify-between ' >
                    <PlanChoice
                      className={`sm:w-full md:w-auto h-auto border-[1px] flex flex-col gap-3 p-3 rounded-md shadow-sm ${item.currentPackage ? 'bg-[#EAECF0]' : 'cursor-pointer'} ${selectedPlan === item.id ? 'border-[#1D742C]' : 'border-[#EAECF0]'}`}
                      data={item}
                      lang={lang}
                      activePlan={activePlan}
                      onClick={() => handleClickPlan(item)}
                    />
                    <PlanChoice
                      className={`sm:w-full md:w-auto h-auto border-[1px] flex flex-col gap-3 p-3 rounded-md shadow-sm ${packages[idx + 1].currentPackage ? 'bg-[#EAECF0]' : 'cursor-pointer'} ${selectedPlan === packages[idx + 1].id ? 'border-[#1D742C]' : 'border-[#EAECF0]'}`}
                      data={packages[idx + 1]}
                      lang={lang}
                      activePlan={activePlan}
                      onClick={() => handleClickPlan(packages[idx + 1])}
                    />
                  </div>
                )
              }
              return <Fragment key={idx}></Fragment>;
            })}
          </>
        }
        {/***Button Continue Payment**/}
        <button className='text-[16px] bg-[#1D742C] hover:enabled:bg-[#2b6835] disabled:bg-[#98C5A0] disabled:cursor-not-allowed mt-[20px] p-2 w-full rounded-md font-InterLight font-medium text-white tracking-[0.3px] ' 
          onClick={handlePayment}
          disabled={!selectedPlan}
        >
          {loading ?
            <div className='flex justify-center'>
              <Spinner className='animate-spin h-5 w-5' />
            </div> : "Let's continue payment"
          }
        </button>
      </div>
    </div>
  )
}

export default Upgrade
