import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TeamAPI from "../services/team";

const initialState = {
  addPopupActive: false,
  editPopupActive: false,
  isAddUserLoading: false,
  isGetUserLoading: false,
  isUpdateUserLoading: false,
  getUserData: "",
  addedMemberName: "",
  memberId: "",
  toastMessage: {
    title: "",
    message: "",
  },
  deleteAlert: false,
  isDeleteLoading:false,
  userByIdData:{}
};

export const AddSubUser = createAsyncThunk(
  `user/add-subuser`,
  async (payload) => {
    const res = await TeamAPI.addSubUSer(payload);
    return res;
  }
);

export const GetSubUser = createAsyncThunk(`user/get-subuser`, async () => {
  const res = await TeamAPI.getSubUser();
  return res;
});

export const UpdateSubUser = createAsyncThunk(
  `user/update-subuser`,
  async (payload) => {
    const res = await TeamAPI.updateSubUser(payload);
    return res;
  }
);

export const DeleteSubUser = createAsyncThunk(`user/delete-subuser`,async(payload)=>{
  const res = await TeamAPI.deleteSubUser(payload);
  return res;
})

export  const GetUserById = createAsyncThunk(`user/get-subuser-by-id`,async(params)=>{
  const res = await TeamAPI.getUserById(params);
  return res;
})

export const teamSlice = createSlice({
  name: "Team",
  initialState,
  reducers: {
    setisAddPopupActive: (state, action) => {
      state.addPopupActive = !state.addPopupActive;
    },
    addedMember: (state, action) => {
      state.addedMemberName = action.payload;
    },
    setisEditPopupActive: (state, action) => {
      state.editPopupActive = !state.editPopupActive;
    },
    setMemberId: (state, action) => {
      state.memberId = action.payload;
    },
    setToastMessage: (state, action) => {
      state.toastMessage = action.payload;
    },
    setDeleteAlert: (state, action) => {
      state.deleteAlert = !state.deleteAlert;
    },
  },
  extraReducers(builder) {
    builder
      /**Add User**/
      .addCase(AddSubUser.pending, (state, action) => {
        state.isAddUserLoading = true;
      })
      .addCase(AddSubUser.fulfilled, (state, action) => {
        state.isAddUserLoading = false;
      })
      .addCase(AddSubUser.rejected, (state, action) => {
        state.isAddUserLoading = false;
      })

      /**Get User**/

      .addCase(GetSubUser.pending, (state, action) => {
        state.isGetUserLoading = true;
      })
      .addCase(GetSubUser.fulfilled, (state, action) => {
        state.isGetUserLoading = false;
        state.getUserData = action.payload;
      })
      .addCase(GetSubUser.rejected, (state, action) => {
        state.isAddUserLoading = false;
      })

      .addCase(GetUserById.pending,(state,action)=>{
        state.userByIdData = " "
      })
      .addCase(GetUserById.fulfilled, (state, action) => {
          state.userByIdData = action.payload
       })
      
      /**Update Sub User**/
      .addCase(UpdateSubUser.pending, (state, action) => {
        state.isUpdateUserLoading = true;
      })
      .addCase(UpdateSubUser.fulfilled, (state, action) => {
        state.isUpdateUserLoading = false;
      })
      .addCase(UpdateSubUser.rejected, (state, action) => {
        state.isUpdateUserLoading = false;
      })
      /****Delete Sub User */
      .addCase(DeleteSubUser.pending, (state, action) => {
        state.isDeleteLoading = true;
      })
      .addCase(DeleteSubUser.fulfilled, (state, action) => {
        state.isDeleteLoading = false;
      })
      .addCase(DeleteSubUser.rejected, (state, action) => {
        state.isDeleteLoading = false;
      })
  },
});

export const {
  setisAddPopupActive,
  addedMember,
  setisEditPopupActive,
  setMemberId,
  setToastMessage,
  setDeleteAlert
} = teamSlice.actions;

export default teamSlice.reducer;
