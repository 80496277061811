import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserAPI from "../services/users";
import { STATUS } from "../config/constants";
import UserProfile from "../services/userProfile";

const { IDLE, PENDING, SUCCESS, FAILED } = STATUS;
const userProfile = new UserProfile().getUserProfile();

const initialState = {
  email: userProfile ? userProfile.email : "email@mail.com",
  fullName: userProfile ? userProfile.fullName : "Full Name",
  photoProfile: userProfile ? userProfile.photoProfile : "",
  countWordLeft: userProfile ? userProfile.countWordLeft : 1000,
  wordLimit: userProfile ? userProfile.wordLimit : 1000,
  status: "",
  loadingName:false,
  loadingPassword:false
};

export const getProfile = createAsyncThunk("profile/get", async (config) => {
  const res = await UserAPI.getProfile(config);
  return res;
});

export const updateUserPassword = createAsyncThunk('profile/update-password', async (payload) => {
  const res = await UserAPI.updatePassword(payload);
  return res;
});

export const updateUserName = createAsyncThunk('profile/update-user-name', async (payload) => {
  const res = await UserAPI.updateName(payload);
  return res;
});

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.status = PENDING;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = SUCCESS;
        const { payload } = action;
        state.email = payload.email;
        state.fullName = payload.fullName;
        state.photoProfile = payload.photoProfile;
        state.countWordLeft = payload.countWordLeft;
        state.wordLimit = payload.wordLimit;
        //set local storage
        const userProfile = JSON.stringify(payload);
        localStorage.setItem("user-profile", userProfile);
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.status = FAILED;
         /**Update Password**/
      })
      .addCase(updateUserPassword.pending, (state, action) => {
        state.loadingPassword = true;
        state.status = ""
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        state.loadingPassword = false;
        state.status = action.payload
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.loadingPassword = false;
        // state.status = "Something went wrong"
      })
      /**Update Email**/
      .addCase(updateUserName.pending, (state, action) => {
        state.loadingName = true;
        state.status = ""
      })
      .addCase(updateUserName.fulfilled, (state, action) => {
        state.loadingName = false;
        state.status = action.payload
      })
      .addCase(updateUserName.rejected, (state, action) => {
        state.loadingName = false;
        // state.status = "Something went wrong"
      })
  },
});


export default profileSlice.reducer;
