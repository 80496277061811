import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";

const Header = () => {
   
    const {t} = useTranslation();
    const tSect = "blog.landing";

    return (
        <div className='py-20 w-full bg-black flex justify-center items-center'>
            <div>
                <label class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal  text-center text-green-200  text-xl sm:text-xl md:text-2xl lg:text-2xl flex justify-center">{t(`${tSect}.text-blog`)}</label>
                <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cairo  font-semibold text-center text-white text-3xl sm:text-4xl md:text-5xl lg:text-5xl tracking-tighter my-4">{t(`${tSect}.landing-heading1`)}
                    <span class="font-InterLight ltr:font-InterLight rtl:font-Cairo  font-semibold text-center text-transparent  text-3xl sm:text-4xl md:text-5xl lg:text-5xl tracking-tighter bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text">{t(`${tSect}.landing-heading2`)}</span>
                </h1>
                <h2 class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal  text-sm sm:text-sm emd:text-base lg:text-base text-center text-green-50 ">{t(`${tSect}.parag-subscribe-to-blog`)}</h2>
                 <div className='w-[40%] sm:w-[90%] lg:w-[40%] mt-[50px] border-[1px] px-1 py-1 rounded-md flex gap-2 items-center mx-auto' >
                  <input type='text' placeholder={t(`${tSect}.search-blog`)} className='w-full h-full py-2 bg-black outline-none text-white '  />
                  <button className='px-6 py-2 bg-[#0d3614] rounded-md text-white font-InterLight ltr:font-InterLight rtl:font-CairoLight font-normal ' >{t(`${tSect}.btn-subscribe`)}</button>
                 </div>
            </div>

        </div>
    )
}

export default Header