import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { popupActive } from "../../../Redux/imageAISlice";

const Ai_Images_Popup = () => {
  const isImagePopupActive = useSelector(
    (state) => state.imageAI.isPopupActive
  );
  const Image = useSelector((state) => state.imageAI.imageUrl);
  const images = useSelector((state) => state.imageAI.images);

  const imageRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // This Function Will Handle Sidebar closing
  const handleClickOutside = (event) => {
    if (imageRef.current && !imageRef.current.contains(event.target)) {
      dispatch(popupActive(false));
    }
  };

  return (
    <div
      className={`w-full h-full transition duration-300    ${
        isImagePopupActive
          ? "  ease-out scale-100 opacity-100"
          : "  ease-in scale-0 opacity-0"
      }  absolute z-50 backdrop-blur-lg flex items-center justify-center`}
    >
      {/*Main Photo Wrapper**/}
      <div ref={imageRef} className="h-[90%] ">
        <img src={Image && Image} className="w-full sm:h-auto lg:h-full " />
      </div>
    </div>
  );
};

export default Ai_Images_Popup;
