import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Footer = () => {

  const Navigate = useNavigate()

  const { t } = useTranslation();
  const tSect = "home.footer";

  return (
    <div className={`w-full min-h-[600px] bg-[#0A0D0E] flex`}>
      <div className="w-[80%] sm:w-[90%] md:w-[90%] lg:w-[85%] xl:w-[80%] max-w-7xl  min-h-full flex flex-col mx-auto pt-[70px] ">
        <div className="w-full min-h-[150px] sm:mb-[15px] md:mb-0 flex flex-row sm:flex-col md:flex-row sm:gap-[40px] ">
          <div className="w-[60%] h-full flex flex-col gap-2 sm:w-full md:w-[60%] ">
            <span className="text-[#FFFFFF] text-[27px] font-InterLight ltr:font-InterLight rtl:font-Cairo ltr:font-InterLight ltr:font-InterLight rtl:font-Cairo rtl:font-Cairo font-medium tracking-[1px] ">
              {t(`${tSect}.title`)}
            </span>
            <p className="text-[#98C5A0] text-[18px] font-InterLight ltr:font-InterLight rtl:font-Cairo ltr:font-InterLight ltr:font-InterLight rtl:font-Cairo rtl:font-Cairo font-[300]    ">
              {t(`${tSect}.subtitle`)}
            </p>
          </div>
          <div className="w-[40%] h-full flex justify-end sm:w-full md:w-[60%] sm:justify-start md:justify-end ">
            <div onClick={()=>Navigate("/dashboard")} className="w-[200px] h-[60px] cursor-pointer rounded-md  bg-gradient-to-r from-[#62FE76] to-[#0BA5EC] p-[2px] ">
              <div className="w-full h-full bg-[#0A0D0E] rounded-md flex items-center justify-center gap-2 ">
                <img src="/Icons/rocket.gif" className="h-[35px]" alt="ic-rocket"/>
                <span className="text-[19px] font-InterLight ltr:font-InterLight rtl:font-Cairo ltr:font-InterLight ltr:font-InterLight rtl:font-Cairo rtl:font-Cairo font-semibold  bg-clip-text text-transparent bg-gradient-to-r from-[#62FE76] to-[#0BA5EC] ">
                  {t(`${tSect}.try-it-now`)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full min-h-[200px] gap-[80px] sm:gap-[20px] md:gap-[60px] lg:gap-[80px]  flex flex-row  py-12 border-t-[#296B32] border-[1px]  border-b-[#296B32] border-l-0 border-r-0 sm:flex-wrap md:flex-nowrap sm:justify-center md:justify-start ">
          <div className="w-[100%] sm:w-[100%] md:w-[32%] h-full sm:h-auto  flex flex-col gap-4 mx-auto items-center justify-center text-center  ">
            <img src="/Icons/khudi-white.png" className="w-[130px]" alt="ic-khudi-white"/>
            <span className="text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo ltr:font-InterLight ltr:font-InterLight rtl:font-Cairo rtl:font-Cairofont-light tracking-[1px]">
              {t(`${tSect}.desc-under-logo`)}{" "}
            </span>
          </div>
          {/* <div className="w-[15%] sm:w-[28%] md:w-[15%]  sm:h-auto flex flex-col gap-3 sm:mt-[10px] md:mt-0 ">
            <label className="text-[14px] text-[#81FF91] font-InterLight ltr:font-InterLight rtl:font-Cairo ltr:font-InterLight ltr:font-InterLight rtl:font-Cairo rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.resources`)}
            </label>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo ltr:font-InterLight ltr:font-InterLight rtl:font-Cairo rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.overview`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo ltr:font-InterLight ltr:font-InterLight rtl:font-Cairo rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.features`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo ltr:font-InterLight ltr:font-InterLight rtl:font-Cairo rtl:font-Cairotracking-[1px] ">
              {t(`${tSect}.solutions`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.tutorials`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.pricing`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.releases`)}
            </span>
          </div>
          <div className="w-[15%] sm:w-[28%] md:w-[15%]  sm:h-auto h-full flex flex-col gap-3 sm:mt-[10px] md:mt-0 ">
            <label className="text-[14px] text-[#81FF91] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.features`)}
            </label>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.about-us`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.careers`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.press`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.news`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.media-kit`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.contact`)}
            </span>
          </div>
          <div className="w-[15%] sm:w-[28%] md:w-[15%] h-full sm:h-auto  flex flex-col gap-3 sm:mt-[10px] md:mt-0 ">
            <label className="text-[14px] text-[#81FF91] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.pricing`)}
            </label>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.blog`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.newsletter`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.events`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.help-centre`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.tutorials`)}
            </span>
            <span className="text-[16px] sm:text-[14px] md:text-[16px] text-[#98C5A0] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.support`)}
            </span>
          </div> */}
        </div>
        <div className="w-full h-[80px] bg-[#0A0D0E] flex flex-row sm:flex-col md:flex-row sm:gap-[12px] md:gap-0 sm:mt-[15px] md:mt-0  ">
          <div className="w-[40%] sm:w-full md:w-[40%] h-full sm:h-auto md:h-full flex  items-center sm:justify-center md:justify-start ">
            <span className="text-[#95FFA3] text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] ">
              {t('copyright')}
            </span>
          </div>
          {/* <div className="w-[60%] sm:w-full md:w-[60%] h-full sm:h-auto md:h-full flex items-center justify-end gap-4 sm:justify-center md:justify-end">
            <img src="/Icons/socialIcons/twitter.png" className="h-[25px]" alt="ic-twitter"/>
            <img src="/Icons/socialIcons/linkedIn.png" className="h-[25px]" alt="ic-linkedin"/>
            <img src="/Icons/socialIcons/facebook.png" className="h-[25px]" alt="ic-facebook"/>
            <img src="/Icons/socialIcons/github.png" className="h-[25px]" alt="ic-github"/>
            <img
              src="/Icons/socialIcons/Vector (13).png"
              className="h-[25px]"
              alt="ic-angellist"
            />
            <img
              src="/Icons/socialIcons/Vector (14).png"
              className="h-[25px]"
              alt="ic-dribbble"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
