import React from "react";

const Wrapper = ({heading,paragraph,icon}) => {
  return (
    <div className={`w-[48%]  hover:bg-[#18191B] h-[78px] flex flex-row items-start p-4 gap-3 rounded-md cursor-pointer `}>
      <img src={`/Icons/dropdown/${icon}.svg`} />
      <div className="inline-flex  gap-1 flex-col">
        <h2 className="text-[15px] text-[#FFFFFF] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px] ">
          {heading}
        </h2>
        <span className="text-[#D0D5DD] text-[12px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px]  ">
          {paragraph}
        </span>
      </div>
    </div>
  );
};

export default Wrapper;
