import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Select from "../../../../shared/Select/select";
import { TypeAnimation } from 'react-type-animation';
import { sendPrompt } from "../../../../Redux/librarySlice";
import { getProfile } from "../../../../Redux/profileSlice";
import { LANGUAGE } from "../../../../config/constants";
import { ReactComponent as Spinner } from '../../../../assets/icons/spinner.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast} from 'react-toastify';



const { ARABIC, ENGLISH } = LANGUAGE;

const SocialMedia = () => {

  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();
  const lng = localStorage.getItem('i18nextLng');

  const countWordLeft = useSelector(state => state.profile.countWordLeft);
  const selectedTool = useSelector(state => state.library.selectedTool);
  const results = useSelector(state => state.library.results);
  const isLoading = useSelector(state => state.library.loadingSendPrompt);
  const loadingDetail = useSelector(state => state.library.loadingDetail);


  const [formData, setFormData] = useState({
    title: "",
    recipient: "",
    recipientPosition: "",
    count: "",
  });
  const [resultLng, setResultLng] = useState(ENGLISH);
  // Put Put Langugae count
  const [Value,setValue] = useState(1)

  const [outputLng1, setOutputLng1] = useState('English');
  const [outputLng2, setOutputLng2] = useState('Arabic');
  const [message,setMessage] = useState("Lorem ipsum dolor sit amet consectetur. Natoque aenean imperdiet diam nunc sed sit. Ut nisl ullamcorper est tempus.Lorem ipsum dolor sit amet consectetur. Natoque aenean imperdiet diam nunc sed sit. Ut nisl ullamcorper est tempus.Lorem ipsum dolor sit amet consectetur. Natoque aenean imperdiet diam nunc sed sit. Ut nisl ullamcorper est tempus.Lorem ipsum dolor sit amet consectetur. Natoque aenean imperdiet diam nunc sed sit. Ut nisl ullamcorper est tempus STRICTLY KEEP THE ANSWER SHORT AND SUMMARISE) .")


  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const lngConvert = (lng) => {
    if (lng === 'English') return ENGLISH;
    if (lng === 'Arabic') return ARABIC;
    return ENGLISH;
  }

  const handleGenerate = (e) => {
    e.preventDefault();

    const outputLanguage = [
      lngConvert(outputLng1),
      lngConvert(outputLng2)
    ]
    const payload = {
      ...formData,
      toolsId: selectedTool.id,
      outputLanguage
    };
    dispatch(sendPrompt(payload)).then(res => {
      //update count words left 
      dispatch(getProfile());
    });
  };

  const handleSelectResultLng = (lng) => {
    setResultLng(lng)
  }

  const Copied = () => {

    toast.success(`${"Response Copy To Clipboard! "}`,{
      position: 'top-right',
   })
  }
  
  const HandleInput = (e) => {

    if (e.target.value.length < 2 && e.target.value <=5  ){
      setValue(e.target.value)
    }

  }


  
  const disabled = !formData.title
    || !formData.recipient
    || !formData.recipientPosition
    || !outputLng1
    || !outputLng2;



  return (
    <div className="w-[calc(100%-250px)] sm:w-full md:w-[calc(100%-250px)] sm:h-auto lg:h-full flex flex-row sm:ml-0 md:ml-[250px] sm:flex-col lg:flex-row sm:overflow-y-auto lg:overflow-hidden  ">
      {loadingDetail ?
        <div className='m-auto'><Spinner className='animate-spin h-10 w-10' /></div> :
        <>
          {/****Template Form*****/}
          <div className="w-[40%] sm:w-[95%] lg:w-[40%] mx-auto sm:h-auto lg:h-full sm:border-0  lg:border-r-[2px] border-[#EAECF0] pt-6 px-6  lg:overflow-y-auto ">
            {/***Heading***/}
            <div className="w-full h-[auto] flex flex-row gap-2">
              <div className="w-[40px] h-[40px] min-w-[40px] mt-4 inline-flex items-center justify-center rounded-md bg-[#E0F2FE]">
                <img src={`/Icons/${selectedTool?.icon}`} className="h-[20px]" alt='' />
              </div>
              <div className="inline-flex  flex-col">
                <h2 className="text-[#101828] text-[24px] font-InterLight font-semibold tracking-[0.2px]">
                  {selectedTool?.title?.[lng]}
                </h2>
                <p className="text-[#101828] text-[14px] font-InterLight font-normal tracking-[0.6px]">
                  {selectedTool?.desc?.[lng]}
                </p>
              </div>
            </div>
            {/***Form***/}
            <form className="w-full h-auto flex flex-col gap-7 mt-[20px] ">
              <div className="w-full h-auto flex flex-col gap-2 ">
                <h3>
                  <span className="text-[#B42318] text-[14px] ">*</span>
                  <span className="text-[16px] text-[#344054] font-InterLight font-medium tracking-[0.5px] ">
                    Title
                  </span>
                </h3>
                <input
                  onChange={handleInput}
                  type="text"
                  name="title"
                  value={formData.title}
                  placeholder="Document title"
                  className=" shadow-sm border-[1px] border-[#D0D5DD] outline-none py-2 px-2 rounded-md placeholder:text-[#667085] placeholder:font-InterLight  placeholder:tracking-[0.5px] placeholder:font-medium "
                />
              </div>
              <div className="w-full h-[45px] rounded-md cursor-pointer flex flex-row items-center justify-center bg-[#EAECF0]  gap-2 p-2 ">
                <img src="/Icons/templates/current.svg" alt='' />
                <h2 className="inline-flex gap-2">
                  <span className="text-[16px] text-[#344054] font-InterLight font-bold tracking-[1px] ">
                    {countWordLeft}
                  </span>
                  <span className="text-[16px] text-[#667085] font-InterLight font-light tracking-[1px] ">
                    Words Left
                  </span>
                </h2>
              </div>
              <div className="w-full h-auto flex flex-col gap-2 ">
                <h3>
                  <span className="text-[#B42318] text-[14px] ">*</span>
                  <span className="text-[16px] text-[#344054] font-InterLight font-normal tracking-[0.5px] ">
                    Recipient
                  </span>
                </h3>
                <input
                  onChange={handleInput}
                  type="text"
                  name="recipient"
                  value={formData.recipient}
                  placeholder="Christian Ronaldo"
                  className=" shadow-sm border-[1px] border-[#D0D5DD] outline-none py-2 px-2 rounded-md placeholder:text-[#667085] placeholder:font-InterLight  placeholder:tracking-[0.5px] placeholder:font-regular "
                />
              </div>
              <div className="w-full h-auto flex flex-col gap-2 ">
                <h3>
                  <span className="text-[#B42318] text-[14px] ">*</span>
                  <span className="text-[16px] text-[#344054] font-InterLight font-normal tracking-[0.5px] ">
                    Recipient Position
                  </span>
                </h3>
                <input
                  onChange={handleInput}
                  type="text"
                  name="recipientPosition"
                  value={formData.recipientPosition}
                  placeholder="Founder or CEO"
                  className=" shadow-sm border-[1px] border-[#D0D5DD] outline-none py-2 px-2 rounded-md placeholder:text-[#667085] placeholder:font-InterLight  placeholder:tracking-[0.5px] placeholder:font-regular "
                />
              </div>
              <div className="w-full h-auto flex flex-row gap-2">
                <div className="w-[45%] h-auto flex flex-col gap-2  ">
                  <h3>
                    <span className="text-[#B42318] text-[14px] ">*</span>
                    <span className="text-[16px] text-[#344054] font-InterLight font-normal tracking-[0.5px] ">
                      Output Language 
                    </span>
                  </h3>
                  <Select defaultLanguage={"English"} defaultFlag={"US"} setLanguages={setOutputLng1} />
                </div>
                <div className="w-[45%] h-auto flex flex-col gap-2  ">
                  <h3>
                    <span className="text-[16px] text-[#344054] font-InterLight font-normal tracking-[0.5px] ">
                      Outputs
                    </span>
                  </h3>
                    <input className=" p-2 outline-none w-full h-[40px] relative  shadow-sm border-[1px] border-[#D0D5DD] rounded-md text-[16px] font-InterLight font-normal " type="number" onChange={HandleInput}  value={Value} max={5} min={1} maxLength={1}   /> 
                </div>
              </div>
             
              <button
                onClick={handleGenerate}
                className="w-full h-[45px] cursor-pointer flex items-center justify-center bg-[#1D742C] gap-2 rounded-md
                  hover:enabled:bg-[#175B22]
                  disabled:bg-[#98C5A0] disabled:cursor-not-allowed
                "
                disabled={isLoading || disabled}
              >
                <img src="/Icons/templates/curly-arrow.svg" alt='' />
                <span className="text-white font-InterLight font-medium tracking-[0.5px] ">
                  Generate
                </span>
              </button>
              <div className="w-full h-[10px] block"></div>
            </form>
          </div>
          {/****Template Chat*****/}
          <div className="w-[60%] sm:w-[95%] lg:w-[60%] mx-auto sm:h-auto lg:h-full  flex flex-col   " >
            <div className="w-full h-auto flex flex-col gap-2 p-4 pt-8 " >
              <div className="w-full h-[60px] flex flex-col gap-1 " >
                <h2 className="text-[#101828] text-[18px] font-InterLight font-semibold tracking-[0.5px]  " >Results</h2>
                <p className="text-[#101828] text-[14px] font-normal font-InterLight tracking-[0.2px] " >For optimal outcomes, attempt multiple iterations.</p>
              </div>
             
            </div>
            <div className="w-full h-auto flex flex-col gap-2 overflow-y-auto p-4 pt-4 " >
              {/*****Chat Box*/}
              {results &&
                <div className="w-full h-auto flex flex-col rounded-md bg-[#EAECF0] p-3" >
                  <div className="w-full h-[40px] flex flex-row justify-between gap-2  " >
                    <div className="w-[40%] flex flex-row items-center ">
                      <h3 className="text-[#475467] text-[12px] font-InterLight font-normal">Just Now</h3>
                    </div>
                    <div className="w-[60%] flex flex-row items-center justify-end gap-3 " >
                      <img src="/Icons/templates/capture.svg" className="cursor-pointer hover:scale-110 transition-all " alt='' />
                      <img src="/Icons/templates/heart.svg" className="cursor-pointer hover:scale-110 transition-all " alt='' />
                      <img src="/Icons/templates/edit.svg" className="cursor-pointer hover:scale-110 transition-all " alt='' />
                      <img src="/Icons/templates/delete.svg" className="cursor-pointer hover:scale-110 transition-all " alt='' />
                    </div>
                  </div>
                  <div className="w-full h-auto flex pt-[10px] " >
                    <p className="text-[#344054] text-[14px] font-InterLight font-normal whitespace-pre-wrap" >
                      {/* {resultLng === ENGLISH && results?.[0]}
                      {resultLng === ARABIC && results?.[1]} */}
                    </p>
                  </div>
                </div>
              }
              {isLoading &&
                <div className="w-full h-auto flex flex-col rounded-md bg-[#EAECF0] p-3" >
                  <div className="w-full h-auto flex pt-[10px] " >
                    <p className="text-[#344054] text-[14px] font-InterLight font-normal " >
                      <TypeAnimation
                        sequence={['...', 500, '', 500]}
                        repeat={Infinity}
                        speed={40}
                      />
                    </p>
                  </div>
                </div>
              }
              <div className="w-full h-auto flex flex-col rounded-md bg-[#EAECF0] p-3" >
                <div className="w-full h-[40px] flex flex-row justify-between gap-2  " >
                  <div className="w-[40%] flex flex-row items-center ">
                    <h3 className="text-[#475467] text-[12px] font-InterLight font-normal">2 Minutes Ago</h3>
                  </div>
                  <div className="w-[60%] flex flex-row items-center justify-end gap-3 " >
                    <CopyToClipboard text={message} onCopy={() => setCopied(true)}   >         
                      <img src="/Icons/templates/capture.svg" className="cursor-pointer hover:scale-110 active:scale-125 transition-all " alt='' onClick={Copied} />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="w-full h-auto flex pt-[10px] " >
                  <p className="text-[#344054] text-[14px] font-InterLight font-normal " >
                    {message}
                  </p>
                </div>
              </div>
              <div className="w-full h-[30px] block" >

              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default SocialMedia;
