import { API } from "./api";


const BrandVoiceAPI = {
  saveBrandVoice: (payload) => API.post(`save-brand-voices`, payload),
  getBrandVoiceByOffSet:(payload) => API.get(`get-brand-voices-by-offset?page=${payload.page}&pageSize=${payload.pageSize}`),
  brandvoiceById:(payload) => API.get(`get-brand-voices-by-id?voiceId=${payload.voiceId}`),
  updateBrandVoice:(payload) => API.put(`update-brand-voices`,payload),
  deleteBrandVoice:(payload) =>API.put(`delete-brand-voices`,payload),
  getbrandVoicesNames:() => API.get(`get-brand-voices-names`),
  getBrandVoiceLength:() => API.get(`get-brand-voices-length`)
}

export default BrandVoiceAPI;

