import React from 'react'

const Radio = ({voiceName,id,setSelectedValue,selectedValue,setIsPopupActive}) => {

  const handleRadioClick = () => {


    setSelectedValue({
      Name:voiceName,
      Id:id
    });
    setIsPopupActive(false);
  };

  return (
    <div onClick={handleRadioClick} className='px-3 py-3 flex flex-row gap-2 hover:bg-[#1515157c]  ' >
        <input checked={selectedValue.Id == id}  value={id} type='radio' className='bg-green-600 border-[1px] border-green-300 pointer-events-none ' />
        <label  className='text-white text-[12px] radio-container ltr:font-InterLight rtl:font-Cairo tracking-[0.4px]  pointer-events-none ' >{voiceName}</label>
    </div>
  )
}

export default Radio