import React from "react";
import { useTranslation } from "react-i18next";

const SoftwareAndTools = () => {

  const {t} = useTranslation();
  const tSect = "blog-details.software";

  return (
    <div >
      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold text-2xl text-white pt-7">
      {t(`${tSect}.heading`)}
      </h1>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-3">
      {t(`${tSect}.paragraph`)}
      </p>
     
    </div>
  );
};
export default SoftwareAndTools;
