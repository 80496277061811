import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  GetbrandvoiceById,
  UpdateBrandVoices,
  resetBrandVoiceById,
} from "../../../Redux/brandVoiceSlice";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";
import { useTranslation } from "react-i18next";
import { setIsUpgradeActive } from "../../../Redux/subscriptionSlice";

const EditBrandVoice = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const params = useParams();
  const lng = localStorage.getItem("i18nextLng");

  const { t } = useTranslation();
  const tSect = "brandVoice.edit-brand-voice";

  const { id } = params;

  const brandVoiceData = useSelector(
    (state) => state.brandVoice.brandVoiceByID
  );
  const isLoading = useSelector(
    (state) => state.brandVoice.isupdateBrandVoiceLoading
  );
  const isError = useSelector((state) => state.brandVoice.errorSaveVoice);
  const countWordLeft = useSelector((state) => state.profile.countWordLeft);

  const [editBrandVoice, setEditBrandVoice] = useState({
    voiceName: "",
    voiceDetails: "",
  });

  useEffect(() => {
    const payload = {
      voiceId: id,
    };
    //On Component Mount We will Reset the State Firsa and then we will call the APi GetbrandvoiceById
    dispatch(resetBrandVoiceById());
    dispatch(GetbrandvoiceById(payload));
  }, []);

  useEffect(() => {
    if (brandVoiceData) {
      setEditBrandVoice({
        voiceName: brandVoiceData.voiceName || "",
        voiceDetails: brandVoiceData.voiceDetails || "",
      });
    }
  }, [brandVoiceData]);

  let str = editBrandVoice.voiceDetails.split(" ");

  const handleInput = (e) => {
    const { value, name } = e.target;
    setEditBrandVoice({
      ...editBrandVoice,
      [name]: value,
    });
  };

  const handleSave = () => {
    const payload = {
      voiceId: id,
      voiceName: editBrandVoice.voiceName,
      voiceDetails: editBrandVoice.voiceDetails,
    };

    if (!countWordLeft) {
      dispatch(setIsUpgradeActive(true));
      return;
    } else {
      dispatch(UpdateBrandVoices(payload)).then((resp) => {
        if (resp.error) {
          return;
        } else {
          Navigate("/brand-voice");
          setEditBrandVoice({ voiceDetails: "", voiceName: "" });
        }
      });
    }
  };

  const disable = !editBrandVoice.voiceDetails || !editBrandVoice.voiceName;

  return (
    <div className="w-full h-auto flex flex-col relative  ">
      {/**Header Create Brand Voice */}
      <div className="w-full flex flex-row gap-4 sticky top-[-10px] bg-white py-3  pt-8">
        <div
          onClick={() => Navigate("/brand-voice")}
          className="flex flex-row gap-2 cursor-pointer  "
        >
          <img src="/Icons/brandVoice/chevron-right.svg" />
          <span className="ltr:font-InterLight rtl:font-Cairo text-[14px] ">
            {t(`${tSect}.head-title1`)}
          </span>
        </div>
        <div className="flex flex-row gap-2 cursor-pointer  ">
          <img src="/Icons/brandVoice/chevron-right.svg" />
          <span className="ltr:font-InterLight rtl:font-Cairo text-[14px] text-[#196325ef] tracking-[0.4px] ">
            {t(`${tSect}.head-title2`)}
          </span>
        </div>
      </div>
      {/**Create Brand Voice Contet Form**/}
      <div className="w-full h-auto flex flex-col mt-[40px] gap-6 ">
        {/**Header Form **/}
        <div className="w-full h-auto flex flex-col gap-2">
          <h2 className="ltr:font-InterLight rtl:font-Cairo text-[20px] font-[600] tracking-[0.6px] ">
            {t(`${tSect}.title-edit-brand-voice`)}
          </h2>
          <p className="text-[12px] text-[#667085] ">
            {t(`${tSect}.p-update-brand-voice`)}
          </p>
        </div>
        {/**Form Inputs Name **/}
        <div className="w-full h-auto flex flex-col gap-4 input-create ">
          <label className="text-[14px] text-[#344054] uppercase ltr:font-InterLight rtl:font-Cairo tracking-[0.5px]  label-create  ">
            {t(`${tSect}.name-of-brand-voice`)}
          </label>
          <input
            onChange={handleInput}
            value={editBrandVoice.voiceName}
            name="voiceName"
            placeholder={t(`${tSect}.placeholder`)}
            className="  border-[1px] border-[#D0D5DD] px-4 py-2  outline-none  rounded-md focus:border-1  focus:border-[#196325ef]   "
          />
        </div>
        {/**Form Inputs Content **/}
        <div className="w-full h-auto flex flex-col gap-2  ">
          <div className="input-create w-full flex flex-col gap-4   ">
            <label className="text-[14px] text-[#344054] uppercase ltr:font-InterLight rtl:font-Cairo tracking-[0.5px]  label-create  ">
              {t(`${tSect}.content-to-analyze`)}
            </label>
            <textarea
              onChange={handleInput}
              name="voiceDetails"
              placeholder={t(`${tSect}.placeholder-content-analyze`)}
              className=" h-[200px] overflow-y-auto  border-[1px] border-[#D0D5DD] px-4 py-2  outline-none  rounded-md focus:border-1  focus:border-[#196325ef] "
              value={editBrandVoice.voiceDetails}
            />
          </div>
          <div className="w-full h-[30px] flex items-center justify-end ">
            <span className="text-[#667085] text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[0.5px]">
              {str.length > 1 ? str.length : "0"} {t(`${tSect}.words`)}
            </span>
          </div>
        </div>
        {/**Buttons**/}
        <div className="w-full h-auto flex flex-col items-center justify-center gap-4 ">
          {isError && (
            <p className="ltr:font-InterLight rtl:font-Cairo  text-[14px] tracking-[0.4px] text-red-600 ">
              {lng == "en" ? isError.en : isError.ar}
            </p>
          )}
          <button
            disabled={disable}
            onClick={handleSave}
            className={`w-full sm:w-full lg:w-full ${
              isLoading ? "bg-[#175b22b1]" : "bg-[#175B22]"
            } ${
              isLoading ? "" : "hover:bg-[#027A48]"
            }     disabled:cursor-not-allowed transition-all flex flex-row gap-2 items-center justify-center rounded-md py-3  `}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <img src="/Icons/brandVoice/save.svg" />
                <span className="text-white text-[14px] ltr:font-InterLight rtl:font-Cairo tracking-[0.5px]">
                  {t(`${tSect}.update-brand-voice`)}
                </span>
              </>
            )}
          </button>
        </div>
      </div>
      {/***Extra Space***/}
      <div className="w-full h-[50px] block"></div>
    </div>
  );
};

export default EditBrandVoice;
