import React, { useEffect, useState } from "react";
import TableItem from "./tableItem";
import Tablepagination from "./pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetBrandVoicebyOffSet } from "../../../Redux/brandVoiceSlice";
import LoadingSkeleton from "./loadingSkeleton";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";
import { useTranslation } from "react-i18next";

const VoiceHome = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { t } = useTranslation();
  const tSect = "brandVoice.brand-voice-home";


  const profile = JSON.parse(localStorage.getItem("user-profile"));
  const BrandVoice = useSelector((state) => state.brandVoice.brandVoices);
  const isLoading = useSelector(
    (state) => state.brandVoice.isBrandVoicesLoading
  );
  const DataLength = useSelector((state) => state.brandVoice.brandvoiceLength);

  const [page, setPage] = useState(1);

  useEffect(() => {
    const payload = {
      page: page,
      pageSize: 5,
    };

    dispatch(GetBrandVoicebyOffSet(payload));
  }, [page]);

  const handlePage = (Pagenumber, data) => {
    if (Pagenumber == "Previous") {
      if (page > 1) {
        setPage(page - 1);
      } else {
        return;
      }
    } else if (Pagenumber == "Next") {
      //let suppose  10/5 = 2
      const maxPages = Math.ceil(DataLength.numberOfBrandVoices / 5);
      console.log(maxPages);
      setPage(page < maxPages ? page + 1 : maxPages);
      // BrandVoice.length > 0 && setPage(page + 1);
    } else {
      setPage(Pagenumber);
    }
  };

  return (
    <div className="w-full  flex flex-col gap-6    ">
      {/**Header Page**/}
      <div className="w-full  h-auto flex flex-row items-center sm:flex-col md:flex-row sm:justify-start md:justify-between pt-8  ">
        {/**Brand Voice Logo**/}
        <div className="flex flex-row gap-6 items-center ">
          <img src="/Icons/brandVoice/brandvoice.svg" className=" h-[32px]" />
          <h2 className=" sm:text-[16px] md:text-[22px] ltr:font-InterLight rtl:font-Cairo font-[600] tracking-[0.7px]  ">
          {t(`${tSect}.brand-voice-name`)}
          </h2>
        </div>
        <div
          onClick={() => Navigate("/brand-voice/create")}
          className=" sm:w-full md:w-auto flex flex-row gap-2 px-4 py-2 cursor-pointer rounded-md bg-[#1D742C] items-center justify-center relative create "
        >
          <img src="/Icons/brandVoice/plus.svg" />
          <h2 className=" text-white text-[14px] ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[0.7px]  ">
          {t(`${tSect}.btn-create-brand-voice`)}
          </h2>
        </div>
      </div>

      <div className=" flex flex-col gap-2  mt-[20px] relative  shadow-md sm:rounded-lg     ">
      
      {DataLength && DataLength.numberOfBrandVoices == 0  && (
          <div className="w-full h-full flex flex-col gap-4 items-center justify-center absolute top-0 left-0 z-20 bg-white   ">
            <img src="/Icons/no-record.svg" />
            <span className="ltr:font-InterLight rtl:font-Cairo" > {t(`${tSect}.empty-text-snippet`)}</span>
          </div>
        )}
        <div class="flex min-h-[430px] flex-col overflow-x-auto overflow-y-hidden   ">
          {/* {!isLoading && <LoadingSkeleton />} */}
          <table class="w-full h-auto text-sm text-left rtl:text-right    ">
            <thead class="text-[12px] tracking-[0.7px] text-[#667085] ltr:font-InterLight rtl:font-Cairo  bg-[#F9FAFB] ">
              <tr className="border-[1px] border-[#EAECF0]">
                <th scope="col" class="px-6 py-3 font-[500] whitespace-nowrap ">
                {t(`${tSect}.table-name`)}
                </th>
                <th scope="col" class="px-6 py-3 font-[500] whitespace-nowrap ">
                {t(`${tSect}.table-brand-voice`)}
                </th>
                <th scope="col" class="px-6 py-3 font-[500] whitespace-nowrap ">
                {t(`${tSect}.table-added-by`)}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 font-[500] whitespace-nowrap ltr:float-right rtl:float-left ltr:mr-[50px] rtl:ml-[50px] "
                >
                {t(`${tSect}.action`)}
                </th>
              </tr>
            </thead>
            <tbody>
              {BrandVoice &&
                !isLoading &&
                BrandVoice.length > 0 &&
                BrandVoice.map((item, index) => {
                  return (
                    <TableItem
                      name={item.voiceName}
                      brandVoice={item.voiceDetails}
                      addedBy={profile.fullName}
                      photo={profile.photoProfile}
                      userId={item.userId}
                      voiceId={item._id}
                      setPage={setPage}
                      page={page}
                    />
                  );
                })}

            </tbody>
          </table>
        </div>
        <div className=" bg-white    ">
          <Tablepagination
            handlePage={handlePage}
            page={page}
            BrandVoice={BrandVoice}
          />
        </div>
      </div>
      <div className="w-full h-[40px] block "></div>
    </div>
  );
};

export default VoiceHome;
