import React from 'react'
import { Outlet } from 'react-router-dom'

const BrandVoice = () => {
  return (
    <div className='w-full h-full  flex px-6   ' >
        <Outlet/>
    </div>
  )
}

export default BrandVoice