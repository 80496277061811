import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ChatAPI from "../../services/chat";
import Chat from "./chat";
import { newChats, getChatHistory ,chatBarText } from '../../Redux/chatsSlice';
import { ReactComponent as Spinner } from '../../assets/icons/spinner.svg';
import { useTranslation } from "react-i18next";
import { AttachedfileContent } from "../../Redux/chatsSlice";


const HistoryChat = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.chats.historyLoading);
  const chatHistoryList = useSelector((state) => state.chats.chatHistory);
  const [keyword, setKeyword] = useState("");

  const { t } = useTranslation();
  const tSect = "dashboard.sidebar";

  useEffect(() => {
    dispatch(getChatHistory(keyword));
  }, [dispatch, keyword]);

  const onChangeKeyword = (e) => {
    const { value } = e.target;
    setKeyword(value);
  }

  const handleNewChat = () => {
    dispatch(newChats());
  }

  const handleClearChat = async () => {
    try {
      await ChatAPI.clearHistory();
      setKeyword("");
      dispatch(getChatHistory(keyword));
      dispatch(newChats());
    } catch (err) {
      console.log(err);
    }
  }

  
  

  return (
    <div className="w-full h-auto flex flex-col gap-4 ">
      <div className="w-full h-auto flex items-center justify-center">
        <span
          className="cursor-pointer text-[#61FF76] text-[14px] rounded-md font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium inline-flex items-center justify-center flex-row gap-3 tracking-[1px] border-[1px] border-[#61FF76] w-full h-[40px] p-2 "
          onClick={handleNewChat}
        >
          {t(`${tSect}.new-chat`)}
          <img src="/Icons/sidebar/create.svg" alt="ic-create" />
        </span>
      </div>
      <div className="w-full h-auto max-h-[350px] overflow-y-auto overflow-x-hidden  flex flex-col rounded-md scrollbar-none ">
        <div className="w-full h-auto flex flex-col bg-[#293037]  px-4 py-2 gap-2 ">
          <div className="w-full h-[40px] inline-flex items-center gap-2 ">
            <img src="/Icons/sidebar/chat-white.svg" alt="ic-chat" />
            <span className="text-[14px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px] ">
            {t(`${tSect}.history-chat`)}
            </span>
          </div>
          <div className="w-full inline-flex items-center overflow-x-hidden flex-row gap-1 py-[6px] px-3 bg-[#3D424B] border-[1px] border-[#4D9959] rounded-md">
            <img src="/Icons/sidebar/search-white.svg" alt="ic-search" />
            <input
              type="text"
              placeholder={t(`${tSect}.placeholder`)}
              className=" px-1 outline-none bg-[#3D424B] w-[90%] text-white placeholder:text-[14px] placeholder:font-InterLight ltr:font-InterLight rtl:font-Cairo placeholder:font-medium placeholder:text-[#D0D5DD] "
              onChange={onChangeKeyword}
            />
          </div>
        </div>
        <div className="w-full h-auto max-h-[250px] flex flex-col divide-y divide-[#344054]  ">
          {loading &&
            <div className='w-full px-4 py-4 bg-[#212936] inline-flex items-center justify-start' >
              <div className="m-auto"><Spinner /></div>
            </div>
          }
          {!loading &&
            <>
              {(chatHistoryList.length > 0) ?
                chatHistoryList.map((item) => {
                  return <Chat key={item.id} chat={item.mainTitle} id={item.id} />;
                }) :
                <div className='w-full px-4 py-4 bg-[#212936] inline-flex items-center justify-start' >
                  <p className='text-[#FCFCFD] text-[12px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ' >No history found.</p>
                </div>
              }
            </>
          }
        </div>
      </div>
      <div onClick={handleClearChat} className="w-full h-[40px] active:scale-105 transition-all mt-[20px] inline-flex items-center  gap-2 border-b-[1px] border-[#344054] ">
        <img src="/Icons/sidebar/trash.svg" className="cursor-pointer" alt="ic-trash" />
        <span
          className=" cursor-pointer  text-[12px] text-[#D0D5DD] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] "
          
        >
           {t(`${tSect}.clear`)}
        </span>
      </div>
    </div>
  );
};

export default HistoryChat;
