import React from "react";
import { useTranslation } from "react-i18next";
import UserName from "./User Info/useName";
import UserPassword from "./User Info/userPassword";

const ProfileDetails = () => {
  const { t } = useTranslation();
  const tSect = "Settings.profile-Details";

  return (
    <div className="w-full h-[100vh] overflow-y-auto ">
      <div className="w-full min-h-[100vh]  flex flex-col sm:p-8 lg:p-12 pt-[50px] gap-6 rounded-[40px]  ">
        {/***Page Heading**/}
        <div className="flex flex-row gap-4 items-center ">
          <img src="/Icons/settings/setting.svg" />
          <div className="flex flex-col">
            <span className="text-[#101828] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-[16px]">
              {t(`${tSect}.heading-settings`)}
            </span>
            <h2 className="font-InterLight ltr:font-InterLight rtl:font-Cairo font-bold tracking-[1px] text-[20px] ">
              {t(`${tSect}.heading-profile-detail`)}
            </h2>
          </div>
        </div>

        {/***Personal Information***/}
        <div className=" w-full h-auto flex flex-col gap-4 items-start mt-[10px] ">
          <img src="/Icons/settings/user.svg" className="h-[20px]" />
          <h2 className="text-[24px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-normal ">
            {t(`${tSect}.heading-personal-Info`)}
          </h2>
          {/****Input First Name***/}
        <UserName />
        <UserPassword />
        </div>
        {/****Email Password***/}

        <div className="w-full h-[120px] block "></div>
      </div>
    </div>
  );
};

export default ProfileDetails;
