import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthAPI from "../../services/auth";
import Alert from "../../components/Alert";
import { useSelector } from "react-redux";

const VerifyEmailForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tSect = "auth.verify-email";

  const IsCheckout = useSelector((state) => state.toggler.isCheckout);

  const [values, setValues] = useState({
    email: state?.email,
    verifyCode: '',
    password: state?.password,
    verified: false,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (!state?.email || !state?.password) {
      IsCheckout ? navigate('/pricing'):navigate("/login");
    }
  }, [navigate, state]);

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, verifyCode } = values;
    const payload = { email, verifyCode };
    try {
      const res = await AuthAPI.userVerifyEmail(payload);
      setValues(prev => ({ ...prev, verified: true }));
      setErrors('');
     const path = IsCheckout ? navigate('/pricing'):navigate("/login");
      AuthAPI.setSession(res.token,path);
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      setValues(prev => ({ ...prev, verifyCode: '' }));
      setErrors(t(`${tSect}.error-verify`));
      console.log('error', error?.response?.data?.error);
    }
  }

  return (
    <div className="w-full min-h-[100vh] md:w-[650px] bg-black flex flex-col px-[44px] py-[24px] ">
      <div className="w-full h-full flex flex-col gap-2 justify-center">
        <img src="/Icons/Khudi-logo-white.png" alt="logo-khudi" className="w-[141px]" />
        <div className="w-full h-[20%]  flex flex-col justify-center gap-3 mb-5">
          <span className="text-[30px] font-InterLight font-[600] text-white tracking-wider ">
            {t(`${tSect}.heading`)}
          </span>
          {!values.verified ? (
            <>
              <span className="text-[14px] font-InterLight font-[400] text-white tracking-wuder ">
                {t(`${tSect}.subheading-1`)}
              </span>
              <div className="w-fit flex bg-[#ffffff1a] rounded-[8px] gap-2 px-4 py-1">
                <div className="mt-[1px] text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                </div>
                <span className="text-[18px] font-InterLight font-[400] text-white tracking-wider ">
                  {t(`${tSect}.subheading-2`)} {values.email}
                </span>
              </div>
            </>) :
            <span className="text-[14px] font-InterLight font-[400] text-white tracking-wuder ">
              {t(`${tSect}.success-heading`)}
              <br />{t(`${tSect}.success-subheading`)}
            </span>
          }
        </div>

        {errors &&
          <Alert
            message={errors}
            closeAction={() => setErrors(false)}
          />
        }

        {!values.verified &&
          <>
            <form className="w-full h-[18%] flex flex-col">
              <div className="w-full h-[100%] flex flex-col gap-2 ">
                <label className="text-[14px] font-InterLight font-[500] tracking-wider text-white">
                  {t(`${tSect}.code-label`)}
                </label>
                <input
                  value={values.verifyCode}
                  onChange={({ target }) => setValues({ ...values, verifyCode: target.value })}
                  type="password"
                  placeholder={t(`${tSect}.code-placeholder`)}
                  className="py-3 px-3 outline-none border-[1px] rounded-lg border-[#344054] text-[13px] text-[#98A2B3] focus:border-[#296B32] bg-[#16191A]"
                />

                <button
                  onClick={handleVerifyEmail}
                  className="mt-3 py-3 px-3  bg-[#296B32] text-white rounded-lg text-[15px] font-InterLight font-[400] tracking-wider cursor-pointer 
                    hover:enabled:bg-[#175B22]
                    disabled:bg-[#98C5A0] disabled:cursor-not-allowed
                  "
                  disabled={loading}
                >
                  {loading ?
                    <svg className="animate-spin m-auto h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    : t(`${tSect}.verify-account`)}
                </button>
              </div>
            </form>

            <div className="w-full h-[5%]  flex items-center justify-center gap-x-2 text-[#667085]">
              <span
                onClick={() => navigate("/login")}
                className="text-[#296B32] font-InterLight font-[500] tracking-wide cursor-pointer "
              >
                {" "}
                {t(`action.back-to-login`)}{" "}
              </span>
            </div>
          </>
        }

      </div>
    </div>
  );
};

export default VerifyEmailForm;
