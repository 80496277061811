import UserAPI from './users';

export default class UserProfile {
  
  setUserProfile = async(token) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return await UserAPI.getProfile({headers})
      .then(res => {
        const userProfile = JSON.stringify(res);
        localStorage.setItem('user-profile', userProfile);
        return res;
      })
      .catch(err => {console.log(err)});
  }

  getUserProfile = () => {
    const userProfile = localStorage.getItem('user-profile');
    // const userProfiledecode = Buffer.from(userProfile, 'base64').toString();
    return JSON.parse(userProfile);
  }

  // setSession(key, val) {
  //   // const userProfileEncode = Buffer.from(val).toString('base64')
  //   localStorage.setItem(key, val);
  // }

  // getSession = (key) =>{
  //   localStorage.getItem(key);
  // }
}
