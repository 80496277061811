export const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_SERVER_URL : process.env.REACT_APP_PRODUCTION_SERVER_URL;
export const TIMEOUT = process.env.REACT_APP_API_TIMEOUT ? process.env.REACT_APP_API_TIMEOUT : 120000;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const I18NEXUS_API_KEY = process.env.REACT_APP_I18NEXUS_API_KEY;

export const STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
}

export const TYPE_PACKAGE = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
}

export const LANGUAGE = {
  ARABIC: 'ar',
  ENGLISH: 'en',
}