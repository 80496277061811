import React from "react";

const SelectItem = ({flag, lng, setLanguage,setIsSelectActive,setLanguage1}) => {

  const handleLanguage = () => {
    setLanguage({
      ...setLanguage,
      flag: flag,
      lng: lng,
    });
    setIsSelectActive(false)
    setLanguage1(lng)
  };

  return (
    <div
      onClick={handleLanguage}
      className="w-full h-[30px] flex items-center gap-3 px-2 py-4 bg-white  hover:bg-[#d0d5dd35] cursor-pointer"
    >
      <img src={`/Icons/flag/${flag}.svg`} alt='' />
      <span className="text-[#667085] text-[16px] font-InterLight font-normal ">
        {lng}
      </span>
    </div>
  );
};

export default SelectItem;
