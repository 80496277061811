import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendChat,
  getChatHistory,
  setSessionId,
  removelastChat,
  sendChatError
} from "../../../Redux/chatsSlice";
import { setIsUpgradeActive } from "../../../Redux/subscriptionSlice";
import { getProfile } from "../../../Redux/profileSlice";
import { v4 as uuidv4 } from "uuid";

const Regenerate = () => {
  const dispatch = useDispatch();

  const chats = useSelector((state) => state.chats.chats);
  const chatSessionId = useSelector((state) => state.chats.sessionId);
  const countWordLeft = useSelector((state) => state.profile.countWordLeft);
  const isLoading = useSelector((state) => state.chats.isLoading);
  const isTyping = useSelector((state) => state.chats.isTyping);


  const handleRegenerate = () => {
    if (!countWordLeft) {
      dispatch(setIsUpgradeActive(true));
      return;
    }

    const filter = chats.filter((item) => {
      return item.u == "me";
    });

    const ChatValue = filter[filter.length - 1].content;

    dispatch(removelastChat());

    const uuid = uuidv4();

    const payload = {
      sessionId: uuid,
      chat: ChatValue,
    };
    dispatch(sendChat(payload)).then((res) => {
      //get count words left
      dispatch(getProfile());
    });
  };

  return (
    <div className="w-full h-[50px] absolute top-[-70px] ltr:left-[30px] rtl:right-[30px]  flex items-center justify-center flex-col gap-2 ">
      <p className="text-red-500 text-[12px] ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
        There was an error generating a response
      </p>
      <button
        onClick={handleRegenerate}
        disabled={isLoading || isTyping }
        className="px-4 py-2 bg-[#208030] disabled:bg-[#208030bb] disabled:cursor-not-allowed disabled:active:scale-100 rounded-md cursor-pointer active:scale-105 transition-all flex flex-row gap-2 items-center justify-center "
      >
        <img className="h-[15px]" src="/Icons/retry-white.svg" />
        <span className=" text-white ltr:font-InterLight rtl:font-Cairo tracking-[0.5px]  ">
          Regenerate
        </span>
      </button>
    </div>
  );
};

export default Regenerate;
