import React, { useRef, useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteBrandVoices } from "../../../Redux/brandVoiceSlice";
import { GetBrandVoicebyOffSet } from "../../../Redux/brandVoiceSlice";
import { setIsUpgradeActive } from "../../../Redux/subscriptionSlice";
import { useTranslation } from "react-i18next";


const TableItem = ({
  name,
  brandVoice,
  addedBy,
  photo,
  voiceId,
  setPage,
  page,
}) => {
  const menuRef = useRef();
  const expandRef = useRef();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const tSect = "brandVoice.brand-voice-home";

  const [isModalActive, setisModalActive] = useState(false);

  const countWordLeft = useSelector((state) => state.profile.countWordLeft);


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !expandRef.current.contains(event.target)
    ) {
      setisModalActive(false);
    }
    if (menuRef.current && menuRef.current.contains(event.target)) {
    }
  };

  let trimedVoice = "";

  const truncatedWords = (text) => {
    const words = text.split(" ");

    const truncated = words.slice(0, 6);

    const joinedWords = truncated.join(" ");

    const displayText = words.length > 6 ? joinedWords + "..." : joinedWords;

    trimedVoice = displayText;
  };

  truncatedWords(brandVoice);

  const handleModal = () => {
    setisModalActive((prevIsModalActive) => !prevIsModalActive);
  };

  const handleEdit = () => {
    Navigate(`/brand-voice/edit/${voiceId}`);
  };

  const handleDelete = () => {
    const payload = {
      voiceId: voiceId,
    };

    if (!countWordLeft) {
      dispatch(setIsUpgradeActive(true));
    }

    dispatch(DeleteBrandVoices(payload)).then(() => {
      const payload = {
        page: page,
        pageSize: 5,
      };

      dispatch(GetBrandVoicebyOffSet(payload));
    });
  };

  return (
    <tr class="bg-white border-[1px] border-[#EAECF0] whitespace-nowrap h-[50px]   ">
      <td
        scope="row"
        class="px-6 py-4 text-[#101828] font-medium  whitespace-nowrap "
      >
        {name}
      </td>
      <td class="px-6 py-4 text-[#667085] font-medium ">{trimedVoice}</td>
      <td class="px-6 py-4 text-[#101828] font-medium ">
        <div className="flex flex-row flex-shrink-0 gap-2 items-center ">
          <img src={photo} className="h-[25px] rounded-full " />
          <h2>{addedBy}</h2>
        </div>
      </td>
      <td class="px-6 py-4 text-[#101828] font-medium relative   ">
        <img
          ref={expandRef}
          onClick={handleModal}
          src="/Icons/brandVoice/more-vertical.svg"
          className="ltr:float-right rtl:float-left ltr:mr-[50px] rtl:ml-[50px]  hover:bg-gray-200 p-1 rounded-full  cursor-pointer "
        />
        <div
          ref={menuRef}
          className={` 
             ${
               isModalActive
                 ? "opacity-100 w-[200px]   "
                 : "opacity-0 w-0  pointer-events-none "
             } z-50  w-[200px]  transition-all  bg-[#2B2C2E]  flex flex-col items-center   absolute ltr:right-[85px] rtl:left-[85px] top-[40px] rounded-md   `}
        >
          <div
            onClick={handleEdit}
            className=" w-full flex flex-row items-center justify-start gap-2 hover:bg-[#232324] rounded-md  cursor-pointer py-3 px-2  "
          >
            <img
              src="/Icons/brandVoice/Edit Property.svg"
              className="h-[18px]"
            />
            <span className="text-white ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[0.5px] ">
            {t(`${tSect}.btn-edit-brand`)}
            </span>
          </div>
          <div
            onClick={handleDelete}
            className="w-full flex flex-row items-center justify-start gap-2  hover:bg-[#232324] rounded-md cursor-pointer py-3 px-2 hrLine relative   "
          >
            <img src="/Icons/brandVoice/trash-2.svg" className="h-[18px]" />
            <span className="text-white ltr:font-InterLight rtl:font-Cairo  font-[300] border-top-[1px] border-white tracking-[0.5px] ">
            {t(`${tSect}.btn-delete-brand-voice`)}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TableItem;
