import React from "react";
import { useRef , useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OpenCloseModal } from "../../../Redux/historySlice";

const EditModal = ({ item }) => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);


  const isActive = useSelector((state) => state.history.isModalActive);
  const tool = useSelector((state) => state.history.selectedTool);

  const handleClose = () => {
    dispatch(OpenCloseModal());
  };

  const content = tool.content;

//   useEffect(() => {

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };

//   }, []);

//   const handleClickOutside = (event) => {
//     if (modalRef.current && !modalRef.current.contains(event.target)) {
//       dispatch(OpenCloseModal());
//     }
//   };



  return (
    <div
      className={` flex ${isActive ? "opacity-100 z-50 " : "opacity-0  -z-10 hidden " } w-full h-[100vh]  backdrop-blur-sm bg-gray-800 bg-opacity-50 fixed left-0 top-0 items-center justify-center `}
    >
    
      <div ref={modalRef}  class="flex p-4 w-[600px] min-h-auto max-h-[70%]  overflow-hidden ">
          <div class=" h-[full] text-black bg-white rounded-lg shadow">
            <div class="flex items-center  justify-between p-4 md:p-5 border-b rounded-t">
              <h3 class="text-xl text-black font-semibold ">
                {tool && tool.documentName}
              </h3>
              <button
                type="button"
                onClick={handleClose} 
                class="text-gray-400 bg-transparent transition-all hover:scale-105  hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only" >Close modal</span>
              </button>
            </div>
            <div class=" h-[calc(100%-73px)] p-4 md:p-5 space-y-4 overflow-y-auto whitespace-pre-wrap ">
              <p class="text-base leading-relaxed  ltr:font-InterLight rtl:font-Cairo  text-gray-500">
                {
                    content
                }
              </p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default EditModal;
