import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ChatAPI from "../services/chat";
import { createWorker } from 'tesseract.js';

const lng = localStorage.getItem("i18nextLng")

const initialState = {
  sessionId: "",
  chatHistory: [],
  chats: [],
  editorContent: "",
  isChatting: false,
  isLoading: false,
  isTyping: false,
  historyLoading: false,
  chatDetailLoading: false,
  AttachedFile: "",
  AttachFileName: "",
  chatBarContent: "",
  sendChatError: "",
  isReading:false,
};

export const sendChat = createAsyncThunk(
  "chats/sendChat",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await ChatAPI.sendChat(payload);
      return res;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getChatHistory = createAsyncThunk(
  `chats/history`,
  async (keyword) => {
    const params = { keyword };
    const res = await ChatAPI.historyList(params);
    return res;
  }
);

export const getChatDetail = createAsyncThunk(
  "chats/history-detail",
  async (id) => {
    const payload = { id };
    const res = await ChatAPI.historyDetail(payload);
    return res;
  }
);

export const saveFileContent = createAsyncThunk(
  "chats/save-file-content",
  async (payload) => {
    const res = await ChatAPI.save_file_content(payload);
    return res;
  }
  
);

export const chatSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setIsChatting: (state, action) => {
      
      state.isChatting = action.payload;
    },
    setIsTyping: (state, action) => {
      state.isChatting = true
      state.isTyping = action.payload;
    },
    setDataChat: (state, action) => {
      state.setDataChat = action.payload;
    },
    clearDataChat: (state) => {
      state.chats = [];
    },
    addChat: (state, action) => {
      state.chats = [...state.chats, action.payload];
    },
    newChats: (state, action) => {
      state.sendChatError = " ";
      state.sessionId = "";
      state.isChatting = false;
      state.isTyping = false;
      state.AttachedFile = "";
      state.AttachFileName = "";
      state.editorContent = action.payload;
      state.chats = [];
    },

    chatBarText: (state, action) => {
      state.chatBarContent = action.payload;
    },
    removelastChat: (state, action) => {
      state.chats.pop();
      state.sendChatError = " ";
    },
    readImagetoText:  (state,action)  => {

      const chat = {
        u :action.payload.u,
        content : action.payload.content
      }
      state.chats = [...state.chats,action.payload] 
      state.isLoading = action.payload.isloading

    },
    readDocument:(state,action) => {
      const fileName = action.payload;
      state.AttachFileName = fileName
    },
    
  },
  extraReducers(builder) {
    builder
      .addCase(sendChat.pending, (state, action) => {
        state.isChatting = true;
        state.isLoading = true;
        state.isTyping = true;
      })
      .addCase(sendChat.fulfilled, (state, action) => {
        state.isLoading = false;
        const newChat = {
          u: "khudi",
          content: action.payload.content,
        };
        state.chats = [...state.chats, newChat];
        state.sendChatError = " ";
      })
      .addCase(sendChat.rejected, (state, action) => {
        state.isLoading = false;
        state.isTyping = false;
        let content = ` ${lng == "ar" ? "خطأ حدث خطأ ما." : "Error Something went wrong."} `;
        state.sendChatError = content;
        if (action.payload?.error) {
          content = action.payload.error;
        }
        const newChat = {
          u: "khudi",
          content,
        };
        state.chats = [...state.chats, newChat];
      })
      .addCase(getChatDetail.pending, (state, action) => {
        state.isChatting = true;
        state.chatDetailLoading = true;
      })
      .addCase(getChatDetail.fulfilled, (state, action) => {
        const { payload } = action;
        state.chatDetailLoading = false;
        state.editorContent = payload.editorContent;
        //mapping chat detail
        const dataChat = [];

        payload.chatLogData.forEach((item) => {
          if (item.chat === "FileType") {
            dataChat.push({
              u: "file",
              content: item.content,
            });
          } else if (item.chat !== "FileType") {
            dataChat.push({
              u: "me",
              content: item.chat,
            });
            dataChat.push({
              u: "khudi",
              content: item.content,
            });
          }
        });

        state.sessionId = payload.sessionId;
        state.chats = dataChat;
      })
      .addCase(getChatDetail.rejected, (state, action) => {
        state.chatDetailLoading = false;
        state.sessionId = "";
        state.chats = [];
      })
      .addCase(getChatHistory.pending, (state, action) => {
        state.historyLoading = true;
      })
      .addCase(getChatHistory.fulfilled, (state, action) => {
        state.historyLoading = false;
        state.chatHistory = action.payload;
      })
      .addCase(getChatHistory.rejected, (state, action) => {
        state.historyLoading = false;
        state.chatHistory = [];
      })

      .addCase(saveFileContent.pending, (state, action) => {
        state.isChatting = true;
        const fileContent = {
          u: "file",
          content:`${lng == "ar" ? "تحميل الملف الخاص بك" : " Uploading your file" } ${state.AttachFileName} `,
        };
        state.chats = [...state.chats, fileContent];
      })
      .addCase(saveFileContent.fulfilled, (state, action) => {
        const fileContent = {
          u: "file",
          content:
            ` ${lng == "ar" ? "ملفك جاهز للدردشة، شكرًا لك على سعة صدرك! " : "Your file is ready to chat, Thanks for your patience!"} `,
        };
        state.chats = [...state.chats, fileContent];
      })
      .addCase(saveFileContent.rejected, (state, action) => {
        // state.sendChatError = "Error Something went wrong.";
        const fileContent = {
          u: "file",
          content: ` ${lng  == "ar" ? "حدث خطأ أثناء معالجة ملفك" : "There is an error while processing ur file" } `,
        };
        state.chats = [...state.chats, fileContent];
      });
  },
});

export const {
  setSessionId,
  setIsChatting,
  setIsTyping,
  clearDataChats,
  addChat,
  newChats,
  AttachedfileContent,
  resetEditor,
  chatBarText,
  removelastChat,
  sendChatError,
  readImagetoText,
  readDocument,
  setbrandVoiceID
} = chatSlice.actions;

export default chatSlice.reducer;
