import React from 'react'
import fileIcon from "../../../assets/featureIcons/file.svg"
import current from "../../../assets/featureIcons/current.svg"
import { useTranslation } from "react-i18next";

const FeatureCard = () => {

    const { t } = useTranslation();
    const tSect = "feature.Cards";

    return (
        <div className='rounded-lg p-6 preview-card-border bg-dark'>
            <div className='flex justify-between items-center'>
                <button className="w-[3.25rem] h-[3.25rem] bg-white bg-opacity-20 rounded-lg hover:bg-opacity-30">
                    <img src={fileIcon} alt="file icon" className='ms-[5.5px]' />
                </button>
                <div className='flex justify-center items-center cursor-pointer'>
                    <span class="font-InterLightLight ltr:font-InterLight rtl:font-Cairo  font-normal font-medium text-sm text-green whitespace-pre me-2">{t(`${tSect}.heading-articel-blog`)}</span>
                    <img src={current} alt="icon" />
                </div>
            </div>
            <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal font-medium text-lg light-green mt-6 mb-4">{t(`${tSect}.write-content`)}</h1>
            <p class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-sm leading-5">{t(`${tSect}.paragraph`)}</p>
        </div>
    )
}

export default FeatureCard