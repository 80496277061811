import React from 'react'
import { useTranslation } from "react-i18next";

const FeatureHeader = () => {

    const {t} = useTranslation();
    const tSect = "feature.landing";

    return (
        <div className='py-20 w-full bg-black flex justify-center'>
            <div>
                <label class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal text-white text-lg tracking-[1px] ">{t(`${tSect}.Khudi-AI-Feature`)}</label>
                <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold ltr:text-left trl:text-right text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl my-4 line-height-90 tracking-[1px] leading-10 ">{t(`${tSect}.landing-text1`)}
                    <span class="font-InterLight ltr:font-InterLight rtl:font-Cairo  font-semibold text-left text-transparent  text-3xl sm:text-4xl md:text-5xl lg:text-6xl tracking-tighter bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text">{t(`${tSect}.landing-text2`)}</span>
                </h1>
                <div class="flex flex-wrap justify-left items-center mt-5 gap-3 ">
                    <button className='btn me-3 sm:me-4  mt-3  md:me-10 sm:mt-4 font-InterLight font-normal text-white '>
                      <span class="font-InterLight ltr:font-InterLight rtl:font-Cairo  font-semibold text-left text-transparent tracking-tighter bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text">{t(`${tSect}.articel-blog`)}</span>
                    </button>
                    <label class="font-InterLightLight ltr:font-InterLight rtl:font-Cairo font-normal text-white px-6 border-second py-3 text-base rounded-lg near-black flex-inline justify-center me-3 sm:me-4  mt-3  md:me-10 sm:mt-4">{t(`${tSect}.ecommerce-product`)}</label>
                    <label class="font-InterLightLight ltr:font-InterLight rtl:font-Cairo font-normal text-white px-6 border-second py-3 text-base rounded-lg near-black flex-inline justify-center me-3 sm:me-4   mt-3  md:me-10 sm:mt-4">{t(`${tSect}.ads-marketing`)}</label>
                    <label class="font-InterLightLight ltr:font-InterLight rtl:font-Cairo font-normal text-white px-6 border-second py-3 text-base rounded-lg near-black flex-inline justify-center  me-3 sm:me-4  mt-3 md:me-10 sm:mt-4">{t(`${tSect}.other-features`)}</label>
                </div>
            </div>
        </div>
    )
}

export default FeatureHeader