import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./scrollbar.css"

const Navigation = ({ setIsSideBarOpen, isMinimize }) => {
  const [activePage, setPage] = useState("home");
  const navigate = useNavigate()

  const { t } = useTranslation();
  const tSect = "dashboard.sidebar";

  console.log(window.location.pathname)

 useEffect(()=>{
  if(window.location.pathname == "/library"){
    navigate("/library/all")
 }
 },[])

  return (
    <div className={`w-[90%] flex flex-col gap-5  ${isMinimize ? "mt-0 h-auto   " : "mt-[40px] "} `}>
      <NavLink
        onClick={() => {
          setIsSideBarOpen(false);
        }}
        to="/dashboard"
        className={({ isActive }) => (isActive ? setPage("home") : "")}
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: `${activePage === "home" ? "rgba(102, 112, 133, 0.2)" : ""
            }`,
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "20px",
          paddingLeft: `${!isMinimize ? "10px " : "0px"}`,
          backgroundOpacity: "20",
          borderLeft: `${!isMinimize && activePage === "home"
            ? "10px solid #61FF76 "
            : "10px solid rgba(102, 112, 133, 0)"
            }`,
        }}
      >
        <div className={`w-full h-full  ml-auto flex items-center gap-2 ${isMinimize ? "ltr:pl-[0px] rtl:pr-[10px] " : "ltr:pl-[10px] rtl:pr-[10px]"}  `}>
          <img
            src={`/Icons/sidebar/${activePage === "home" ? "home-active" : "home"
              }.svg`}
            alt=''
          />
          <span
            className={`text-[15px] ${isMinimize ? "hidden" : "visible"} font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wider ${activePage === "home" ? "text-[#61FF76]" : "text-[#A0B1AE]"
              }`}
          >
            {t(`${tSect}.dashboard`)}
          </span>
        </div>
      </NavLink>
      <NavLink
        onClick={() => {
          setIsSideBarOpen(false);
        }}
        to="/library/all"
        className={({ isActive }) => (isActive ? setPage("library") : "")}
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: `${activePage === "library" ? "rgba(102, 112, 133, 0.2)" : ""
            }`,
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "20px",
          backgroundOpacity: "20",
          paddingLeft: `${!isMinimize ? "10px" : "0px"}`,
          borderLeft: `${!isMinimize && activePage === "library"
            ? "10px solid #61FF76 "
            : "10px solid rgba(102, 112, 133, 0) "
            }`,
        }}
      >
        <div className={`w-full h-full  ml-auto flex items-center gap-2 ${isMinimize ? "ltr:pl-[0px] rtl:pr-[10px] " : "ltr:pl-[10px] rtl:pr-[10px]"} `}>
          <img
            src={`/Icons/sidebar/${activePage === "library" ? "library-active" : "library"
              }.svg`}
            alt=''
          />
          <span
            className={`text-[15px] ${isMinimize ? "hidden" : "visible"} font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wider ${activePage === "library" ? "text-[#61FF76]" : "text-[#667085]"
              }  `}
          >
            {t(`${tSect}.library-tool`)}
          </span>
        </div>
      </NavLink>
      <NavLink
        onClick={() => {
          setIsSideBarOpen(false);
        }}
        to="/history"
        className={({ isActive }) => (isActive ? setPage("history") : "")}
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: `${activePage === "history" ? "rgba(102, 112, 133, 0.2)" : ""
            }`,
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "20px",
          backgroundOpacity: "20",
          paddingLeft: `${!isMinimize ? "10px" : "0px"}`,
          borderLeft: `${!isMinimize && activePage === "history"
            ? "10px solid #61FF76 "
            : "10px solid rgba(102, 112, 133, 0) "
            }`,
        }}
      >
        <div className={`w-full h-full  ml-auto flex items-center gap-2 ${isMinimize ? "ltr:pl-[0px] rtl:pr-[10px] " : "ltr:pl-[10px] rtl:pr-[10px]"} `}>
          <img
            src={`/Icons/sidebar/${activePage === "history" ? "history-active" : "history"
              }.svg`}
            alt=''
          />

          <span
            className={`text-[15px] ${isMinimize ? "hidden" : "visible"} font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wider ${activePage === "history" ? "text-[#61FF76]" : "text-[#667085]"
              }  `}
          >
            {t(`${tSect}.history`)}
          </span>
        </div>
      </NavLink>
      <NavLink
        onClick={() => {
          setIsSideBarOpen(false);
        }}
        to="/khudi-photo"
        className={({ isActive }) => (isActive ? setPage("khudi-photo") : "")}
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: `${activePage === "khudi-photo" ? "rgba(102, 112, 133, 0.2)" : ""
            }`,
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "20px",
          backgroundOpacity: "20",
          paddingLeft: `${!isMinimize ? "10px" : "0px"}`,
          borderLeft: `${!isMinimize && activePage === "khudi-photo"
            ? "10px solid #61FF76 "
            : "10px solid rgba(102, 112, 133, 0) "
            }`,
        }}
      >
        <div className={`w-full h-full  ml-auto flex items-center gap-2 ${isMinimize ? "ltr:pl-[0px] rtl:pr-[10px] " : "ltr:pl-[10px] rtl:pr-[10px]"} `}>
          <img
            src={`/Icons/sidebar/${activePage === "khudi-photo" ? "photo-active" : "photo"
              }.svg`}
            alt=''
          />
          <span
            className={`text-[15px] ${isMinimize ? "hidden" : "visible"} font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wider ${activePage === "khudi-photo" ? "text-[#61FF76]" : "text-[#667085]"
              }  `}
          >
            {t(`${tSect}.khudi-photo`)}
          </span>
        </div>
      </NavLink>
      <NavLink
        onClick={() => {
          setIsSideBarOpen(false);
        }}
        to="/chat"
        className={({ isActive }) => (isActive ? setPage("chat") : "")}
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: `${activePage === "chat" ? "rgba(102, 112, 133, 0.2)" : ""
            }`,
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "20px",
          backgroundOpacity: "20",
          paddingLeft: `${!isMinimize ? "10px" : "0px"}`,
          borderLeft: `${!isMinimize && activePage === "chat"
            ? "10px solid #61FF76 "
            : "10px solid rgba(102, 112, 133, 0) "
            }`,
        }}
      >
        <div className={`w-full h-full  ml-auto flex items-center gap-2 ${isMinimize ? "ltr:pl-[0px] rtl:pr-[10px] " : "ltr:pl-[10px] rtl:pr-[10px]"} `}>
          <img
            src={`/Icons/sidebar/${activePage === "chat" ? "chat" : "chat"}.svg`}
            alt=''
          />
          <span
            className={`text-[15px] ${isMinimize ? "hidden" : "visible"} font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wider ${activePage === "chat" ? "text-[#61FF76]" : "text-[#667085]"
              }  `}
          >
            {t(`${tSect}.chat`)}
          </span>
        </div>
      </NavLink>
      <hr className="w-full  border-[1px] border-[#344054] "></hr>
      
          <NavLink
            onClick={() => {
              setIsSideBarOpen(false);
            }}
            to="/brand-voice"
            className={({ isActive }) => (isActive ? setPage("brand-voice") : "")}
            style={{
              width: "100%",
              height: "40px",
              backgroundColor: `${activePage === "brand-voice" ? "rgba(102, 112, 133, 0.2)" : ""
                }`,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "20px",
              backgroundOpacity: "20",
              paddingLeft: `${!isMinimize ? "10px" : "0px"}`,
              borderLeft: `${!isMinimize && activePage === "brand-voice"
                ? "10px solid #61FF76 "
                : "10px solid rgba(102, 112, 133, 0) "
                }`,
            }}
          >
            <div className={`w-full h-full  ml-auto flex items-center gap-2 ${isMinimize ? "ltr:pl-[0px] rtl:pr-[10px] " : "ltr:pl-[10px] rtl:pr-[10px]"} `}>
              <img
                src={`/Icons/brandvoice/${activePage === "brand-voice" ? "brandvoice-active" : "brandvoice"
                  }.svg`}
                alt=''
              />
              <span
                className={`text-[15px] ${isMinimize ? "hidden" : "visible"} font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wider ${activePage === "brand-voice" ? "text-[#61FF76]" : "text-[#667085]"
                  }  `}
              >
                {t(`${tSect}.brand-voice`)}
              </span>
            </div>
          </NavLink>
          
    </div>
  );
};

export default Navigation;
