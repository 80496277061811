import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Ecommerce = () => {
  const { t } = useTranslation();
  const tSect = "templates.ecommerce.releasing-in-feature";

  const Navigate = useNavigate();

  const handleButton = () => {
    Navigate("/library/all")
    localStorage.setItem("ActiveBtn","all")
  }

  return (
    <div className="w-full h-auto flex flex-col flex-wrap gap-4 items-center justify-center ">
      <div className="w-auto h-auto flex flex-col">
        <img
          src="/Icons/templates/releasing.svg"
          className=" sm:h-[250px] 2xl:h-[350px]"
        />
        <div className="w-full h-auto flex flex-col text-center items-center justify-center mt-[10px]  ">
          <h2 className="ltr:font-InterLight rtl:font-Cairo sm:text-[25px] 2xl:text-[30px] text-[#344054]  ">
            {t(`${tSect}.heading1`)}
          </h2>
          <h2 className="ltr:font-InterLight sm:w-[85%] lg:w-[60%] rtl:font-Cairo font-normal sm:text-[14px] lg:text-[14px] text-[#344054]  ">
            {t(`${tSect}.description`)}
          </h2>
          <button onClick={handleButton} className="bg-[#1D742C] sm:w-[70%] lg:w-[50%] ltr:font-InterLight rtl:font-Cairo  p-2 text-white tracking-[0.5px] mt-[30px] rounded-md hover:bg-[#175B22] active:bg-[#1D742C] ">
            {t(`${tSect}.btn`)}
          </button>
        </div>
      </div>
      <div className="w-full h-[10px] block"></div>
    </div>
  );
};

export default Ecommerce;
