import React from "react";
import { useNavigate } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { useTranslation } from "react-i18next";

const ChargesCard1 = () => {

  const Navigate = useNavigate()
    const {t} = useTranslation();
    const tSect = "pricing.charges-card-1";

  return (
    <>
      <div class="relative  p-4 p-6     border-green-800 p-4 rounded-lg  lg:border-green-0 md:border-green-800 lg:w-[400px] ">
        <h2 class="text-3xl font-bold text-white pt-2 ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.text-custom`)}</h2>
        {/* <p class="text-sm dark-gray leading-6 mt-2 ltr:font-InterLight rtl:font-Cairo ">
        {t(`${tSect}.paragraph-1`)}
        </p> */}
        <p class="text-base text-white leading-6 mt-6">
          <span class="text-5xl font-bold ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.text-free`)}</span>
          <sub class="text-sm bottom-[5px] ltr:left-[5px] rtl:right-[5px] ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.monthly`)}</sub>
        </p>

        <p class="text-sm dark-gray leading-6 mt-6">
        {t(`${tSect}.p1`)}
        </p>
        <p class="text-sm text-green-400 leading-6 mt-2 ltr:font-InterLight rtl:font-Cairo "> {t(`${tSect}.p2`)}</p>

        <div class="flex justify-center items-center my-8">
          <button onClick={()=>Navigate("/register")} className="flex w-full pricing-btn-color ltr:font-InterLight rtl:font-Cairo  items-center justify-center border gap-2 pricing-border font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo  text-lg leading-6 rounded-lg py-2 ">
            <MdOutlineRocketLaunch />
            {t(`${tSect}.sigin`)}
          </button>
        </div>

   

        <p class="flex items-center text-sm pricing-chat-clr mt-2 py-2 ltr:font-InterLight rtl:font-Cairo ">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.line1`)}</span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2 ltr:font-InterLight rtl:font-Cairo ">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.line2`)}</span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2 ltr:font-InterLight rtl:font-Cairo ">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.line3`)}</span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2 ltr:font-InterLight rtl:font-Cairo ">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.line4`)}</span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2 ltr:font-InterLight rtl:font-Cairo ">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.line5`)}</span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2 ltr:font-InterLight rtl:font-Cairo ">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.line6`)}</span>
        </p>
        <p class="flex items-center text-sm pricing-chat-clr py-2 ltr:font-InterLight rtl:font-Cairo ">
          <GoPrimitiveDot />
          <span class="px-2 ltr:font-InterLight rtl:font-Cairo ">{t(`${tSect}.line7`)}</span>
        </p>
      </div>
    </>
  );
};
export default ChargesCard1;
