import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isUpgradeActive: false,
  isModalSuccessActive: false,
}

export const subscriptionSlice = createSlice({
  name: 'subsciption',
  initialState,
  reducers: {
    setIsUpgradeActive: (state, action) => {
      state.isUpgradeActive = action.payload
    },
    setIsModalSuccessActive: (state, action) => {
      state.isModalSuccessActive = action.payload
    }
  },
})

export const { setIsUpgradeActive, setIsModalSuccessActive } = subscriptionSlice.actions

export default subscriptionSlice.reducer

// final commit
