import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EditorAPI from '../services/editor';
const initialState = {
  loadingSaving: false,
  content:"",
  error:""

}

export const SaveEditorContent = createAsyncThunk(`save-editor-content`, async (payload) => {
  const res = await EditorAPI.saveContent(payload);
  return res;
});

export const EditorSlice = createSlice({
  name: 'Editor',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(SaveEditorContent.pending, (state, action) => {
        state.loadingSaving = true
      })
      .addCase(SaveEditorContent.fulfilled, (state, action) => {
        const { payload } = action;
        state.content = payload
        state.loadingSaving = false
      })
      .addCase(SaveEditorContent.rejected, (state, action) => {
        state.error = "Error While Saving Your Data"
        state.loadingSaving = false
      })
  },
})
export default EditorSlice.reducer