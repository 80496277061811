import { ReactComponent as Spinner } from '../../assets/icons/spinner.svg';
import { ImpulseSpinner } from "react-spinners-kit";


const PageLoading = () => {
  return (
    <div className='flex w-full h-[100vh] justify-center items-center gap-1'>
      <div className='bg-no-repeat w-[430px] h-[215px] flex-shrink-0 gap-3 flex items-center justify-center flex-col pt-[20px] ' style={{backgroundImage:`url(Icons/loader/${"Back-Cover.svg"})`}}  >
        <img src="Icons/Khudi Logo 1.svg" className='w-[180px] ' />
        <ImpulseSpinner
         size={70}
        />
      </div>
    </div>
  )
}

export default PageLoading;