import React, { useEffect, useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { useSelector, useDispatch } from "react-redux";
import { setIsTyping } from "../../../Redux/chatsSlice";
import { ReactComponent as Spinner } from "../../../assets/icons/spinner.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Regenerate from "./regenerate";

const ChatMessage = ({ u, message  }) => {
  const [value, setValue] = useState(message);
  const [copied, setCopied] = useState(false);
  const isLoading = useSelector((state) => state.chats.isLoading);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  });

  const Copied = () => {
    toast.success(`${"Response Copy To Clipboard! "}`, {
      position: "top-right",
    });
  };

  


  const photoProfile = useSelector((state) => state.profile.photoProfile);
  if (u === "me") {
    return (
      <div className="w-full h-auto flex flex-row justify-start gap-2 p-[20px] pl-[10%] pr-[10%]  ">
        <img
          src={photoProfile ? photoProfile : "/media/Ellipse 14.png"}
          className="h-[40px] rounded-full"
          alt="avatar-me"
        />
        <p className="w-auto h-auto pt-2  ltr:font-InterLight rtl:font-Cairo font-normal text-[14px] text-[#101828] tracking-[0.5px] whitespace-pre-wrap">
         {message}
        </p>
      </div>
    );
  } else if( u == "khudi") {
    //u === khudi
    return (
      <div className="w-full h-auto flex flex-col justify-start relative gap-2 bg-[#80808017] p-[20px] pl-[10%] pr-[10%] ">
        <div className="w-full h-auto flex gap-6 flex-row">
          <img
            src="/Icons/Khudi-Avatar.svg"
            className="h-[40px]"
            alt="avatar-khudi"
            title="Khudi Bot "
          />
          <p
            ref={messagesEndRef}
            className="w-auto h-auto active:scale-105 transition-all  ltr:font-InterLight rtl:font-Cairo font-InterLight font-normal text-[14px] text-[#101828] tracking-[0.5px] whitespace-pre-wrap"
          >
            {message}
          </p>
        </div>
        <div className="w-full  pr-4 flex  items-end justify-end  ">
          {!isLoading && (
            <CopyToClipboard text={message} onCopy={() => setCopied(true)}>
              <img
                src="/Icons/templates/capture.svg"
                className=" cursor-pointer p-1 transition-all  h-[30px] active:scale-125  "
                title="Copy To Clipboard"
                onClick={Copied}
              />
            </CopyToClipboard>
          )}
        </div>
       
      </div>
    );
  }

  else if (u === "file") {
    return (
      <div className="w-full h-auto flex flex-row items-center justify-start gap-2 p-[20px] pl-[10%] pr-[10%]  ">
          <img
            src="/Icons/document.png"
            className="h-[25px] "
            alt="avatar-me"
          />
          <p className="w-auto h-auto  ltr:font-InterLight rtl:font-Cairo font-normal text-[14px] text-[#101828] tracking-[0.5px] whitespace-pre-wrap">
          {message}
          </p>
      </div>
    );
  }
  else if( u == "image") {
    //u === khudi
    return (
      <div className="w-full h-auto flex flex-col justify-start relative gap-2 bg-[#80808017] p-[20px] pl-[10%] pr-[10%] ">
        <div className="w-full h-auto flex gap-6 flex-row">
          <img
            src="/Icons/Khudi-Avatar.svg"
            className="h-[40px]"
            alt="avatar-khudi"
            title="Khudi Bot "
          />
          <p
            ref={messagesEndRef}
            className="w-auto h-auto active:scale-105 transition-all font-InterLight font-normal text-[14px] text-[#101828] tracking-[0.5px] whitespace-pre-wrap"
          >
            {message}
          </p>
        </div>
        <div className="w-full  pr-4 flex  items-end justify-end  ">
          {!isLoading && (
            <CopyToClipboard text={message} onCopy={() => setCopied(true)}>
              <img
                src="/Icons/templates/capture.svg"
                className=" cursor-pointer p-1 transition-all  h-[30px] active:scale-125  "
                title="Copy To Clipboard"
                onClick={Copied}
              />
            </CopyToClipboard>
          )}
        </div>
       
      </div>
    );
  }
  
};

const ChatSection = ({ dataChat = [] , SectionHeight }) => {
  const dispatch = useDispatch();
  const isTyping = useSelector((state) => state.chats.isTyping);
  const isLoading = useSelector((state) => state.chats.isLoading);
  const chatDetailLoading = useSelector(
    (state) => state.chats.chatDetailLoading
  );

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [dataChat]);

  return (
    <div style={{height:`calc(100% - ${SectionHeight}px)`}} className={`w-full  flex-col overflow-y-auto sm:scrollbar-none `}>
      <div className="w-full h-full flex flex-col pt-10 ">
        {chatDetailLoading ? (
          <div className="m-auto">
            <Spinner className="animate-spin h-10 w-10" />
          </div>
        ) : (
          <>
            {dataChat.map((dc, idx, { length }) => {


              // Histroy Chat
              if (idx === length - 1 && dc.u !== "me" && isTyping) {
                return (
                  <ChatMessage
                    key={idx}
                    u={dc.u}
                    filelogdata={dc.filelogdata}
                    message={
                      <TypeAnimation
                        sequence={[
                          dc.content,
                          () => {
                            dispatch(setIsTyping(false));
                          },
                        ]}
                        speed={99}
                      />
                    }
                  />
                );
              }
              return <ChatMessage key={idx}  u={dc.u} message={dc.content} />;
            })}
            
            {isLoading && (
              // IF CHAT IS TYPING
              <ChatMessage
                u="khudi"
                message={
                  <TypeAnimation
                    sequence={["...", 500, "", 500]}
                    repeat={Infinity}
                    speed={40}
                  />
                }
              />
            )}
            <div
              className="w-full h-[30px] flex shrink-0 "
              ref={messagesEndRef}
            ></div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatSection;
