import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  sendChat,
  setSessionId,
  addChat,
  getChatHistory,
  chatBarText,
} from "../../../Redux/chatsSlice";
import { getProfile } from "../../../Redux/profileSlice";
import { setIsUpgradeActive } from "../../../Redux/subscriptionSlice";
import { AttachedfileContent } from "../../../Redux/chatsSlice";
import ChatFeatures from "./chatFeatues";
import Regenerate from "./regenerate";
import SpeechTotxt from "./Text-to-Speech/SpeechTotxt";

const ChatBar = ({
  promptValue,
  isEditorActive,
  setSectionHeight,
  SectionHeight,
}) => {
  const { t } = useTranslation();
  const tPrompt = "Khudi-Chat.chat-bar";

  const sendRef = useRef();
  const chatBarRef = useRef();
  const textAreaRef = useRef();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.chats.isLoading);
  const isTyping = useSelector((state) => state.chats.isTyping);
  const chatSessionId = useSelector((state) => state.chats.sessionId);
  const countWordLeft = useSelector((state) => state.profile.countWordLeft);
  const FileContent = useSelector((state) => state.chats.AttachedFile);
  const FileName = useSelector((state) => state.chats.AttachFileName);
  const chatError = useSelector((state) => state.chats.sendChatError);
  const brandVoice = useSelector((state) => state.brandVoice.brandVoiceId);

  const [inputValue, setInputValue] = useState();
  const [inputRows, setInputRows] = useState(1);

  const handleInput = (event) => {
    setInputValue(event.target.value);
    updateInputRows(event.target);
    setSectionHeight(chatBarRef.current.offsetHeight);
  };

  const handleSend = () => {
    const ChatValue = FileContent ? FileContent + " " + inputValue : inputValue;

    if (!countWordLeft) {
      dispatch(setIsUpgradeActive(true));
      return;
    }

    dispatch(
      addChat({
        u: "me",
        content: ChatValue,
      })
    );
    if (!chatSessionId) {
      const uuid = uuidv4();
      dispatch(setSessionId(uuid));
      const payload = {
        sessionId: uuid,
        chat: ChatValue,
        voiceId: brandVoice.Id !== 0 ? brandVoice.Id : "",
      };
      dispatch(sendChat(payload)).then((res) => {
        //get history
        dispatch(getChatHistory(""));
        //get count words left
        dispatch(getProfile());
      });
    } else {
      const payload = {
        sessionId: chatSessionId,
        chat: ChatValue,
        voiceId: brandVoice.Id !== 0 ? brandVoice.Id : "",
      };
      dispatch(sendChat(payload)).then((res) => {
        //get count words left
        dispatch(getProfile());
      });
    }
    setInputValue("");
    setInputRows(1);
    setSectionHeight(110);
  };

  useEffect(() => {
    if (promptValue !== "") {
      setInputValue(promptValue);
    }
  }, [promptValue]);

  const updateInputRows = (target) => {
    const textareaLineHeight = 18; // Adjust this based on your textarea's styling
    const minRows = 1;
    const maxRows = 7;

    const previousRows = target.rows;
    target.rows = minRows; // reset the number of rows in the textarea

    const currentRows = Math.floor(
      (target.scrollHeight - textareaLineHeight) / textareaLineHeight
    );

    if (currentRows === previousRows) {
      target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      target.rows = maxRows;
      target.scrollTop = target.scrollHeight;
    }

    setInputRows(currentRows < maxRows ? currentRows : maxRows);
  };

  const handleEnter = (event) => {
    if (!(isLoading || isTyping)) {
      if (inputValue) {

        if (event.key == "Enter" && !event.shiftKey) {
          event.preventDefault();
          handleSend();
        }
      }
    }
  };

 

  return (
    <div
      ref={chatBarRef}
      className="w-[calc(100%-30px)] sm:w-[calc(100%-28px)] md:w-[calc(100%-30px)]  sm:fixed ltr:ml-[13px] rtl:mr-[13px] bottom-[10px]  md:absolute  z-30 bg-white  "
    >
      <div className="w-[100%] relative h-[100%] bg-[#296B32] bg-opacity-20 rounded-[10px] flex flex-col  ">
        <div className="w-full h-[45px] flex flex-row gap-2 ">
          <div className="w-[100%] sm:w-full lg:w-[50%] p-2 h-full inline-flex  items-center sm:justify-between lg:justify-start ">
            <ChatFeatures />
          </div>
        </div>
        <div
          className={`w-full flex flex-row items-end justify-between px-3 py-2 gap-3  bg-[#F8F9FA] rounded-[10px] border-[1px] border-[#E4E4E4] overflow-y-auto `}
        >
          <textarea
            ref={textAreaRef}
            onChange={handleInput}
            value={inputValue}
            id="textArea"
            placeholder={t(`${tPrompt}.placeholder`)}
            rows={inputRows}
            onKeyDown={handleEnter}
            className={`bg-[#F8F9FA]  empty-input p-2 resize-none w-full h-full ltr:pr-16 rtl:pl-16 pt-2 chatbar   overflow-y-auto   outline-none placeholder:text-[16px] placeholder:text-[#667085] ltr:placeholder:font-InterLight rtl:placeholder:font-Cairo ltr:font-InterLight rtl:font-Cairo placeholder:tracking-[0.5px]`}
          ></textarea>

          <SpeechTotxt
            textAreaRef={textAreaRef}
            updateInputRows={updateInputRows}
            setInputValue={setInputValue}
            inputValue={inputValue}
          />
          <button
            ref={sendRef}
            onClick={handleSend}
            className="cursor-pointer bg-[#1D742C] disabled:cursor-not-allowed absolute ltr:right-[22px] rtl:left-[20px] text-white px-[12px] py-[10px] scale-90 rounded-[10px] inline-flex items-center justify-center gap-2 text-[15px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px]
                hover:enabled:bg-[#175B22]
                disabled:bg-[#98C5A0] 
              "
            disabled={!inputValue || isLoading || isTyping}
          >
            <img src="/Icons/send.svg" className="h-[20px]" alt="ic-send" />
          </button>
        </div>
      </div>

      {chatError == "Error Something went wrong." ? <Regenerate /> : ""}
    </div>
  );
};

export default ChatBar;
