import React from "react";
import fb from "../../../../assets/fb.svg";
import { useTranslation } from "react-i18next";

const ContentMenu = () => {

  const {t} = useTranslation();
  const tSect = "blog-details.landing";

  return (
    <div class="mr-4">
      <div class="w-720 h-px bg-gray-400 mt-3"></div>
      <div className="my-8">

      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold text-base	 text-green-500 mt-5 pb-2">
      {t(`${tSect}.table-of-contents`)}
      </h1>
      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold  text-base text-[#d0d5dd]  dark-gray pt-2 pb-1">
      {t(`${tSect}.introduction`)}      </h1>
      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold  text-base text-[#d0d5dd]	 dark-gray pt-2 pb-1">
      {t(`${tSect}.softwares-tools`)}
      </h1>
      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold  text-base text-[#d0d5dd]	 dark-gray pt-2 pb-1">
      {t(`${tSect}.other-resources`)}
      </h1>
      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold  text-base  text-[#d0d5dd] dark-gray pt-2 pb-1">
      {t(`${tSect}.conclusion`)}
      </h1>
      </div>
      <div class="w-720 h-px bg-gray-400 mt-3"></div>
    </div>
  );
};
export default ContentMenu;
