import { isExpired } from "react-jwt";
import api from '../config/api';
import UserProfile from "./userProfile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const userProfile = new UserProfile();

const TOKEN = localStorage.getItem('token');
const header = TOKEN ? {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + TOKEN
} : { 'Content-Type': 'application/json' };

const API = new api({ headers: header });

const AuthAPI = {
  userLogin: (payload) => API.post(`auth/login`, payload),
  userRegistration: (payload) => API.post(`auth/registration`, payload),
  userVerifyEmail: (payload) => API.put(`auth/account/verification`, payload),
  userForgotPassword: (payload) => API.post(`auth/forgot-password`, payload),
  userChangePassword: (payload) => API.put(`auth/change-password`, payload),
  googleSignIn: (payload) => API.post(`auth/google-sign`, payload),
  isAuthenticated: () => {
    const token = localStorage.getItem('token');
    const isTokenExpired = isExpired(token);

    if (isTokenExpired) {
      AuthAPI.removeSession(); 
      // Call the method using the object name
      return false;
    }
    return true;
  },
  setSession: (token,path) => {
    localStorage.setItem('token', token);
    userProfile.setUserProfile(token).then(res => {
      setTimeout(() => {

        if(path){ 
          window.location.replace(path);
        }
        else{
          window.location.replace('/dashboard');
        }
        
      }, 1000);
    }).catch(err => console.log(err));
  },
  logout: () => {
    // Clear access token from local storage
    AuthAPI.removeSession(); // Call the method using the object name
    window.location.replace('/login');
  },
  removeSession: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user-profile');
  }
}

export default AuthAPI;
