import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  Billed:"yearly",
  isCheckout:false,
  LibrarySearch:"",
}

export const TogglerSlice = createSlice({
  name: 'Toggler',
  initialState,
  reducers: {

    Pricing: (state, action) => {
      state.Billed = action.payload
    }
    ,
    checkout:(state,action)=>{
      state.isCheckout = action.payload
    },
    librarySearch:(state,action) =>{
      state.LibrarySearch = action.payload
    }, 

  },
})

export const { Pricing,checkout,librarySearch } = TogglerSlice.actions

export default TogglerSlice.reducer