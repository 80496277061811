import React from "react";
import RelatedArticle from "./Components/relatedArticle";
import TableOfContent from "./Components/TableOfContent/index";
import TipsAndTricks from "./Components/tipsAndTricks";
import Header from "../../shared/Header2/header";
import Footer from "../../shared/Footer/footer";
import NavBar from "../NavBar";

const BlogDetail = () => {
  return (
    <div className="w-full min-h-[100vh] block bg-[#0A0D0E] pt-[20px] " >
      <div className="w-[80%] sm:w-[90%] md:w-[80%] max-w-7xl mx-auto " >
        <Header/>
        <TipsAndTricks />
        <TableOfContent />
        <RelatedArticle />
        <Footer/>
      </div>
    </div>
  );
};
export default BlogDetail;
