import React from 'react'
import  { useEffect, useState, useRef } from "react";
import * as pdfjsLib from "pdfjs-dist";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  AttachedfileContent,
  readDocument,
  saveFileContent,
  getChatHistory,
  setSessionId,
} from "../../../../Redux/chatsSlice";
import { v4 as uuidv4 } from "uuid";
import "../index.css"
import { useTranslation } from "react-i18next";

const UploadDocument = () => {

    const dispatch = useDispatch();
  const InputRef = useRef();
  const popupRef = useRef();
  const ExtractContentRef = useRef();
  const { t } = useTranslation();
  const tPrompt = "Khudi-Chat.chat-bar";

  const sessionId = useSelector((state) => state.chats.sessionId);

  const [FileContent, setFileContent] = useState();
  const [fileName, setFileName] = useState("");
  const [isPopUpActive, setIsPopUpActive] = useState(false);

  const ExtractContent = async (e) => {
    setFileContent("");
    setFileName("");

    const file = e.target.files[0];

    setFileName(file.name);

    const fileExtension = file.name.split(".").pop();

    if (fileExtension.toLowerCase() == "pdf") {
      pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

      const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));
      loadingTask.promise.then(function (pdf) {
        const numPages = pdf.numPages;
        let fullText = "";

        // Loop through all pages
        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
          pdf.getPage(pageNumber).then(function (page) {
            page.getTextContent().then(function (textContent) {
              const textItems = textContent.items;
              let text = "";

              // Concatenate the text items to get the text content of the current page
              for (let i = 0; i < textItems.length; i++) {
                text += textItems[i].str + " ";
              }

              fullText += text;

              // If this is the last page, set the file content
              if (pageNumber === numPages) {
                setFileContent(fullText);
                // This will log the combined text content of all pages to the console
              }
            });
          });
        }
      });
    } else if (fileExtension.toLowerCase() == "txt") {
      const reader = new FileReader();
      reader.onload = function (event) {
        const fileText = event.target.result;
        setFileContent(fileText);
      };
      reader.readAsText(file);
    } else if (
      fileExtension.toLowerCase() !== "pdf" ||
      fileExtension.toLowerCase() !== "txt"
    ) {
      toast.error(`Invalid file type. Please upload a .txt or .pdf file.`, {
        position: "top-right",
      });
    }
  };

  // Function to reset the input element
  const handleReset = () => {
    if (ExtractContentRef.current) {
      ExtractContentRef.current.value = "";
      ExtractContentRef.current.type = "file";
    }
  };

  useEffect(() => {
    if (FileContent && sessionId) {
      const payload = {
        sessionId: sessionId,
        fileLogData: {
          fileType: "file",
          alertMessage: `${t(`${tPrompt}.message-upload-doc-1`)} ${fileName} ${t(`${tPrompt}.message-upload-doc-2`)}`,
          content: FileContent,
        },
      };
      dispatch(readDocument(fileName));
      dispatch(saveFileContent(payload));
      dispatch(getChatHistory(""));

      handleReset();
    } else if (FileContent && !sessionId) {
      const uuid = uuidv4();
      dispatch(setSessionId(uuid));

      const payload = {
        sessionId: uuid,
        fileLogData: {
          fileType: "file",
          alertMessage: `${t(`${tPrompt}.message-upload-doc-1`)} ${fileName} ${t(`${tPrompt}.message-upload-doc-2`)}`,
          content: FileContent,
        },
      };

      dispatch(readDocument(fileName));
      dispatch(saveFileContent(payload));
      dispatch(getChatHistory(""));

      handleReset();
    }
  }, [FileContent]);

  return (
    <div className='flex flex-row flex-shrink-0 gap-2 relative' >
        <img src="/Icons/file-text.svg" />
          <h2 className="text-[#1D742C] text-[14px]  ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide  ">
          {t(`${tPrompt}.upload-document`)}
          </h2>
          <input
            type="file"
            ref={ExtractContentRef}
            className="w-[150px] absolute opacity-0  "
            onChange={ExtractContent}
          />
    </div>
  )
}

export default UploadDocument