import React from "react";
import Promptcards from "./promptCards"
import { useTranslation } from "react-i18next";

const ChatLanding = ({ setPromptvalue, isEditorActive ,SectionHeight }) => {

  console.log(SectionHeight)

  const { t } = useTranslation();
  const tSect1 = "Khudi-Chat.sect-1";
  const tPrompt = "Khudi-Chat.prompt-cards";

  return (
     <div style={{height:`calc(100% - ${SectionHeight}px)`}} className="w-full  flex flex-col gap-2 overflow-y-auto sm:scrollbar-none   "> 
      <div className='w-full h-full flex flex-col gap-y-4  px-6 pt-10 ' >
        {/***Landing Page Heading Text And Logos***/}
        <div className="w-full h-auto inline-flex flex-col gap-y-2 items-center justify-center ">
          <img src="/Icons/KhudiBot.svg" className="h-[130px] sm:h-[100px] lg:[130px] 2xl:[150px] " />
          <h2
            className="text-[32px] sm:text-[25px] lg:text-[32px] 2xl:text-[36px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold text-transparent  
          bg-clip-text bg-gradient-to-t from-[#175B22] to-[#58E86B] tracking-[1px]  "
          >
           {t(`${tSect1}.heading`)}
          </h2>

          {/* <div className="py-3 px-2 h-auto inline-flex items-center flex-row gap-x-4 bg-[#EAF5EC] rounded-md " >
            <img src="/Icons/retry.svg" className="h-[30px] sm:h-[25px] md:h-[30px] " />
            <span className="text-[#1D742C] sm:text-[14px] md:text-[16px]  font-InterLight ltr:font-InterLight rtl:font-Cairo font  tracking-[0.6px] " >{t(`${tSect1}.paragraph`)}</span>
          </div> */}
        </div>
        {/****Landing Page some Custom Prompt*****/}
        <div className={`w-full h-auto flex sm:flex-col lg:flex-col   ${isEditorActive ? " xl:flex-col " : " xl:flex-row "} sm:gap-2 lg:gap-x-4 mt-[30px]   `} >
          {/****Landing Page some Custom Prompt Card*****/}
          <Promptcards
            desc={t(`${tPrompt}.card1.paragraph`)}
            setPromptvalue={setPromptvalue}
            isEditorActive={isEditorActive}
          />
          <Promptcards
            desc={t(`${tPrompt}.card2.paragraph`)}
            setPromptvalue={setPromptvalue}
            isEditorActive={isEditorActive}
          />
          <Promptcards
            desc={t(`${tPrompt}.card3.paragraph`)}
            setPromptvalue={setPromptvalue}
            isEditorActive={isEditorActive}
          />
          <div className={`w-full h-[20px] block sm:flex  ${isEditorActive ? "xl:flex" : "xl:hidden"}`} ></div>
        </div>
      </div>
    </div>
  );
};

export default ChatLanding;
