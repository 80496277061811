import React from "react";
import TemplateCards from "../Cards/templateCard";
import { useTranslation } from "react-i18next";

const Social_Media = () => {
  const { t } = useTranslation();
  const tSect = "templates.social-media";

  return (
    <div className="w-full h-auto flex flex-col flex-wrap gap-4 ">
      <div className="w-full h-auto flex flex-row flex-wrap gap-4">
        <TemplateCards
          Icon={"xtwitter.png"}
          title={t(`${tSect}.twitter-thread-v2.title`)}
          desc={t(`${tSect}.twitter-thread-v2.description`)}
          route={"twitter-thread-v2"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.social-media-content-plan.title`)}
          desc={t(`${tSect}.social-media-content-plan.description`)}
          route={"social-media-content-plan"}
          isNew={false}
        />
        <TemplateCards
          Icon={"linkedin.svg"}
          title={t(`${tSect}.linkedin-posts-v2.title`)}
          desc={t(`${tSect}.linkedin-posts-v2.description`)}
          route={"linkedin-posts-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"instagram.png"}
          title={t(`${tSect}.instagram-captions.title`)}
          desc={t(`${tSect}.instagram-captions.description`)}
          route={"instagram-captions"}
          isNew={false}
        />
        <TemplateCards
          Icon={"bird.png"}
          title={t(`${tSect}.twitter-tweets.title`)}
          desc={t(`${tSect}.twitter-tweets.description`)}
          route={"twitter-tweets"}
          isNew={false}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${tSect}.youtube-description-v2.title`)}
          desc={t(`${tSect}.youtube-description-v2.description`)}
          route={"youtube-description-v2"}
          isNew={true}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${tSect}.youtube-intro-v2.title`)}
          desc={t(`${tSect}.youtube-intro-v2.description`)}
          route={"youtube-intro-v2"}
          isNew={false}
        />
        <TemplateCards
          Icon={"tiktok.png"}
          title={t(`${tSect}.tiktok-video-script.title`)}
          desc={t(`${tSect}.tiktok-video-script.description`)}
          route={"tiktok-video-script"}
          isNew={false}
        />
        <TemplateCards
          Icon={"tiktok.png"}
          title={t(`${tSect}.tiktok-video-hooks.title`)}
          desc={t(`${tSect}.tiktok-video-hooks.description`)}
          route={"tiktok-video-hooks"}
          isNew={false}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${tSect}.youtube-ideas.title`)}
          desc={t(`${tSect}.youtube-ideas.description`)}
          route={"youtube-ideas"}
          isNew={false}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${tSect}.youtube-outlines.title`)}
          desc={t(`${tSect}.youtube-outlines.description`)}
          route={"youtube-outlines"}
          isNew={false}
        />
        <TemplateCards
          Icon={"youtube.png"}
          title={t(`${tSect}.youtube-titles.title`)}
          desc={t(`${tSect}.youtube-titles.description`)}
          route={"youtube-title"}
          isNew={false}
        />
      </div>

      <div className="w-full h-[10px] block"></div>
    </div>
  );
};

export default Social_Media;
