import LandingSection from "./LandingSection/landingSection";
import Section2 from "../../shared/Section2/section2"
import Section3 from "./Section3/section3"
import Section4 from "../../shared/Section4/section4"
import Footer from "../../shared/Footer/footer"
import SideBar from "../../shared/sidebar/sidebar"

const Homepage = ({isSideBarActive,setisSideBarActive}) => {

  return (
          <div className="w-full min-h-[100vh] flex flex-col" >
            {/* <SideBar /> */}
            <LandingSection setisSideBarActive={setisSideBarActive}  />
            <Section2/>
            <Section3/>
            <Section4/>
            <Footer/>

          </div>
  );
};

export default Homepage;