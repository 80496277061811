import React, { useState ,useRef } from 'react'
import HeaderTemplate from "./header"
import SideBarTemplates from "./sidebar"
import { Outlet } from 'react-router-dom'
import { useEffect } from 'react';

const Template = () => {
 
  const menuRef = useRef()

  const [isSideBarActive,setSideBarActive] = useState(false)

  return (
    <div className='w-full h-[100vh] flex flex-col bg-white ' >
      <HeaderTemplate
       setSideBarActive={setSideBarActive}
       isSideBarActive={isSideBarActive}
       menuRef={menuRef}
      />
      <div className='w-full h-[calc(100%-60px)] flex flex-row'>
      <SideBarTemplates
      isSideBarActive={isSideBarActive} 
      setSideBarActive={setSideBarActive}
      menuRef={menuRef}
      /> 
      <Outlet/>  
      </div>
    </div>
  )
}

export default Template
