import React from "react";
import TemplateCards from "../Cards/templateCard";
import { useTranslation } from "react-i18next";


const ArticleAndBlog = () => {

  const { t } = useTranslation();
  const tSect = "templates.article-and-blogs";

  return (
    <div className="w-full h-auto flex flex-col  ">
      <div className="w-full h-auto flex flex-row flex-wrap gap-4 ">
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.ai-article-ideas-v2.title`)}
          desc={t(`${tSect}.ai-article-ideas-v2.description`)}
          route={"ai-article-ideas"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.ai-article-intros.title`)}
          desc={t(`${tSect}.ai-article-intros.description`)}
          route={"ai-article-intros"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.ai-article-outlines-v2.title`)}
          desc={t(`${tSect}.ai-article-outlines-v2.description`)}
          route={"ai-article-outlines-v2"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.ai-article-outlines.title`)}
          desc={t(`${tSect}.ai-article-outlines.description`)}
          route={"ai-article-outlines"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.ai-blog-titles.title`)}
          desc={t(`${tSect}.ai-blog-titles.description`)}
          route={"ai-blog-title"}
          isNew={true}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.ai-conclusion-writer.title`)}
          desc={t(`${tSect}.ai-conclusion-writer.description`)}
          route={"ai-conclusion-writer"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.ai-paragraph-writer.title`)}
          desc={t(`${tSect}.ai-paragraph-writer.description`)}
          route={"ai-paragraph-writer"}
          isNew={false}
        />
        <TemplateCards
          Icon={"khudi.jpg"}
          title={t(`${tSect}.ai-article-writer-5.0.title`)}
          desc={t(`${tSect}.ai-article-writer-5.0.description`)}
          route={"ai-article-writer"}
          isNew={false}
        />
      </div>
      <div className="w-full h-[10px] block"></div>
    </div>
  );
};

export default ArticleAndBlog;
