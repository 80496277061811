import React, { useEffect, useState } from "react";
import {
  clearHistoryTool,
  getHistoryTool,
  getSelectedTool,
  OpenCloseModal,
} from "../../../Redux/historySlice";
import { useSelector, useDispatch } from "react-redux";
import EditModal from "./editModal";

const Tool = ({ item, setSelectedIds, selectedIds }) => {
  const dispatch = useDispatch();

  const handleDelete = (toolName, id) => {
    const payload = {
      libraryToolHistoryIds: [id],
    };

    dispatch(clearHistoryTool(payload)).then((res) => {
      //update the tool history
      dispatch(getHistoryTool());
    });
  };

  const handleCheck = (event) => {
    const id = event.target.value;

    if (event.target.checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedTimestamp = `${month} ${day}, ${year} `;
    return formattedTimestamp;
  };

  // const handleEdit = (tool) => {
  //   dispatch(OpenCloseModal());
  //   dispatch(getSelectedTool(tool));
  // };

  return (
    <tr class="bg-white border-[1px] border-[#EAECF0] whitespace-nowrap h-[50px]   ">
      <td
        scope="row"
        class="px-6 py-4 text-[#101828] font-medium  whitespace-nowrap  "
      >
        <input
          type="checkbox"
          value={item._id}
          checked={selectedIds.includes(item._id)}
          onChange={handleCheck}
        />
      </td>
      <td className="px-6 py-4 text-[#667085] font-medium text-center ">
        <div className="flex flex-row items-center ">
          <img
            src={`/Icons/socialIcons/${item.iconName}`}
            className="h-[25px]"
          />
          <div className="flex flex-col items-start ">
            <h2 className="ltr:font-InterLight rtl:font-Cairo text-[15px] ">
              {item.documentName}
            </h2>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 text-[#667085] font-medium ">
        <div className="flex flex-row">
          <img
            src={`/Icons/socialIcons/${item.iconName}`}
            className="h-[25px]"
          />
          <div className="flex flex-col items-start ">
            <h2 className="ltr:font-InterLight rtl:font-Cairo text-[12px] ">
              {item.toolName}
            </h2>
            <h className="ltr:font-InterLight rtl:font-Cairo text-[12px] text-[#667085] flex flex-row gap-1 ">
              {" "}
              <img src="/Icons/dot.svg" /> Ads & Marketing tools
            </h>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 text-[#667085] font-medium text-center ">
      {formatTimestamp(item.createDate)}
      </td>
      <td className="px-6 py-4 text-[#667085] font-medium text-center" >
      <div className="flex flex-row gap-2 mx-auto  items-center justify-center p-2">
            <img
              src="/Icons/history/trash.svg"
              className="cursor-pointer hover:scale-125 transition-all h-[20px]"
              onClick={() => handleDelete(item.toolName, item._id)}
            />
            {/* <img
              onClick={()=>handleEdit(item)}
              src="/Icons/history/edit.svg"
              className="cursor-pointer hover:scale-125 transition-all active:scale-105 "
            /> */}
          </div>
      </td>
      {/* <td class="px-6 py-4 text-[#101828] font-medium ">
        <div className="flex flex-row flex-shrink-0 gap-2 items-center ">
          <img src={photo} className="h-[25px] rounded-full " />
          <h2>{addedBy}</h2>
        </div>
      </td>
      <td class="px-6 py-4 text-[#101828] font-medium relative   ">
        <img
          ref={expandRef}
          onClick={handleModal}
          src="/Icons/brandVoice/more-vertical.svg"
          className="ltr:float-right rtl:float-left ltr:mr-[50px] rtl:ml-[50px]  hover:bg-gray-200 p-1 rounded-full  cursor-pointer "
        />
        <div
          ref={menuRef}
          className={` 
             ${
               isModalActive
                 ? "opacity-100 w-[200px]   "
                 : "opacity-0 w-0  pointer-events-none "
             } z-50  w-[200px]  transition-all  bg-[#2B2C2E]  flex flex-col items-center   absolute ltr:right-[85px] rtl:left-[85px] top-[40px] rounded-md   `}
        >
          <div
            onClick={handleEdit}
            className=" w-full flex flex-row items-center justify-start gap-2 hover:bg-[#232324] rounded-md  cursor-pointer py-3 px-2  "
          >
            <img
              src="/Icons/brandVoice/Edit Property.svg"
              className="h-[18px]"
            />
            <span className="text-white ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[0.5px] ">
              Edit Brand Voice
            </span>
          </div>
          <div
            onClick={handleDelete}
            className="w-full flex flex-row items-center justify-start gap-2  hover:bg-[#232324] rounded-md cursor-pointer py-3 px-2 hrLine relative   "
          >
            <img src="/Icons/brandVoice/trash-2.svg" className="h-[18px]" />
            <span className="text-white ltr:font-InterLight rtl:font-Cairo  font-[300] border-top-[1px] border-white tracking-[0.5px] ">
              Delete Brand Voice
            </span>
          </div>
        </div>
      </td> */}
    </tr>
  );
};

export default Tool;
