import React, { useState, useRef } from "react";
import ChatBar from "./chatBar";
import ChatLanding from "./chatLanding";
import ChatSection from "./chatSection";
import KhudiEditor from "./Editor/khudiEditor";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentPage } from "../../../Redux/sidebarSlice";
import { use } from "i18next";
import ReactGA from "react-ga"

const Chat = () => {
  const dispatch = useDispatch();
  const isChat = useSelector((state) => state.chats.isChatting);
  const dataChat = useSelector((state) => state.chats.chats);
  const [promptValue, setPromptvalue] = useState("");
  const [isEditorActive, setisEditorActive] = useState(false);
  const [SectionHeight, setSectionHeight] = useState(110);

  useEffect(() => {
    dispatch(currentPage("chat"));
    return () => {
      dispatch(currentPage(""));
    };
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview("/chat");
  }, []);

  return (
    <div className="w-full  mx-auto h-full flex flex-row  overflow-x-hidden overflow-y-hidden ">
      <div
        className={`${
          isEditorActive ? " sm:w-[100%] lg:w-[60%]" : "w-full"
        } transition-all  ease duration-500   ${
          !isEditorActive ? "mx-auto" : ""
        } h-full block relative  `}
      >
        <div className="w-full h-[100%] flex flex-col">
          {isChat ? (
            <ChatSection dataChat={dataChat} SectionHeight={SectionHeight} />
          ) : (
            <ChatLanding
              setPromptvalue={setPromptvalue}
              isEditorActive={isEditorActive}
              SectionHeight={SectionHeight}
            />
          )}
          <ChatBar
            promptValue={promptValue}
            setSectionHeight={setSectionHeight}
            SectionHeight={SectionHeight}
          />
        </div>
      </div>
      <KhudiEditor
        isEditorActive={isEditorActive}
        setisEditorActive={setisEditorActive}
      />
    </div>
  );
};

export default Chat;
