import ReadImage from "./ReadImage/readImage";
import UploadDocument from "./UploadDoc/uploadDocument";
import BrandVoice from  "./BrandVoice/brandVoice" 
const ChatFeatures = () => {
  return (
    <>
      <div className="w-full h-full flex items-center gap-10 flex-row ltr:pl-4 rtl:pr-4  ">
        <div className=" flex-shrink-0 flex flex-row gap-2">
          <ReadImage />
        </div>
        <div className=" flex-shrink-0 flex flex-row gap-2">
          <UploadDocument />
        </div>
        <div className=" cursor-pointer flex-shrink-0  flex flex-row gap-2">
          <BrandVoice />
        </div>
      </div>
    </>
  );
};

export default ChatFeatures;
