import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga";
import Homepage from "./modules/Homepage/Home";
import Blog from "./modules/Blog/Blogs";
import BlogDetail from "./modules/BlogDetail/index";
import Feature from "./modules/Feature/index";
import Pricing from "./modules/Pricing/index";
import Logout from "./modules/Authentication/logout";
import ContactPage from "./modules/Contact/ContactPage";
import DetailFeature from "./modules/Detail Feature";
import PageLoading from "./components/Loading/PageLoading";
import Policy from "./modules/Policy";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Auth, AUTH_ROUTES } from "./routes/Auth";
import { Protected, PROTECTED_ROUTES } from "./routes/Protected";
import { store } from "./store";
import { Provider } from "react-redux";
import { GOOGLE_CLIENT_ID } from "./config/constants";
const App = () => {
  const [loading, setLoading] = useState(false);

  const TRACKING_ID = "G-2ZRB6SDBHT";
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const direction = localStorage.getItem("i18nextLng");

    if (direction === "en") {
      document.getElementsByTagName("html")[0].dir = "ltr";
    } else if (direction === "ar") {
      document.getElementsByTagName("html")[0].dir = "rtl";
    }

    ReactGA.pageview("/dashboard");
  }, []);

  return loading ? (
    <PageLoading />
  ) : (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ToastContainer autoClose={1500} />
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetail />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/detail-feature" element={<DetailFeature />} />
          <Route path="/privacy-policy" element={<Policy/>}/>

          {AUTH_ROUTES.map(({ path, component: Component }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Auth>
                  <Component />
                </Auth>
              }
            />
          ))}
          {PROTECTED_ROUTES.map(
            ({ path, component: Component, children }, index) => (
              // Maping Nested Dashboard Routes
              <Route
                key={index}
                path={path}
                element={
                  <Protected>
                    <Component />
                  </Protected>
                }
              >
                {/***Maping Nested Routes of its parent like in dashboard layout we have chat ,
                dashboard routes
                ***/}
                {children &&
                  children.map(
                    (
                      { path: pathChild, component: ComponentChild, children },
                      idx
                    ) => (
                      <Route
                        key={idx}
                        path={pathChild}
                        element={<ComponentChild />}
                      >
                        {/*****Maping Nested Routes of parent like the once we havein  library page we have child rpoutes******/}
                        {children &&
                          children.map(
                            (
                              {
                                path: pathChild,
                                component: ComponentChild,
                                children: grandchildren,
                              },
                              idx
                            ) => (
                              <Route
                                key={idx}
                                path={pathChild}
                                element={<ComponentChild />}
                              >
                                {/* Maping Nested Routes of the current child */}
                                {grandchildren &&
                                  grandchildren.map(
                                    (
                                      {
                                        path: pathGrandchild,
                                        component: ComponentGrandchild,
                                      },
                                      idx
                                    ) => (
                                      <Route
                                        key={idx}
                                        path={pathGrandchild}
                                        element={<ComponentGrandchild />}
                                      />
                                    )
                                  )}

                              
                              </Route>
                            )
                          )}
                      </Route>
                    )
                  )}
              </Route>
            )
          )}
        </Routes>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default App;
