import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { sideBarActive } from "../../Redux/sidebarSlice";
import AuthAPI from "../../services/auth";
import DropDowns from "../dropDowns/dropDowns";
import Resources_DropDowns from "../Resources DropDown/dropDowns";
import SelectLanguage from "../SelectLanguage/selectLanguage";
import SideBar from "../sidebar/sidebar";

const Header = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("token");
  const { t } = useTranslation();
  const tSect = "home.header";
  const resourcesRef = useRef();
  const featureRef = useRef();
  const dispatch = useDispatch();

  const [isDropDownActive, setIsDropDownActive] = useState(false);
  const [isResorcesActive, setResourcesActive] = useState(false);
  const [Language, setLanguage] = useState("العربية");

  const lng = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (lng == "ar") {
      setLanguage("العربية");
    } else if (lng == "en") {
      setLanguage("English");
    }
  }, []);

  const handleSignIn = () => {
    if (user) {
      AuthAPI.removeSession();
      localStorage.removeItem("token");
      navigate("/login");
    } else {
      navigate("/login");
    }
  };

  const handleMenu = () => {
    dispatch(sideBarActive(true));
    localStorage.setItem("sidebar", true);
    console.log("Clicked ");
  };

  const handleLanguage = () => {
      const path = window.location.pathname
     if(lng == "ar"){
       window.location.href = `${path}?lng=en`
      localStorage.setItem("i18nextLng","en");
     }
     else{
      window.location.href = `${path}?lng=ar`
      localStorage.setItem("i18nextLng","ar");
     }
  };

  return (
    <div className="relative w-full h-[60px]  flex flex-row  sm:justify-between justify-start  ">
      <div className="w-[15%] sm:w-[40%] md:w-[40%] lg:w-[15%]  h-full flex items-center justify-start pl-[10px] ">
        <img
          onClick={() => navigate("/")}
          src="/Icons/khudi-white.png"
          className="w-[120px] h-[35px] cursor-pointer "
          alt="ic-khudi"
        />
      </div>

      <ul className="w-[50%]  h-full flex items-center list-none  gap-x-10 flex-grow-0 sm:hidden md:hidden lg:flex justify-center   ">
        <li
          ref={featureRef}
          onClick={() => {
            navigate("/detail-feature");
          }}
          className={`inline-flex items-center gap-2 text-white text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[500] tracking-wide cursor-pointer `}
        >
          {t(`${tSect}.features`)}
        </li>
        <li
          ref={resourcesRef}
          onClick={() => {
            navigate("/pricing");
          }}
          className="inline-flex items-center gap-2 text-white text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[500] tracking-wide cursor-pointer "
        >
          {t(`${tSect}.pricing`)}
        </li>
        <li
          onClick={() => {
            navigate("/contact");
          }}
          className="inline-flex items-center text-white text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[500] tracking-wide cursor-pointer  "
        >
          {t(`${tSect}.contact-us`)}
        </li>
        <li
          onClick={() => {
            navigate("/privacy-policy");
          }}
          className="inline-flex items-center text-white text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[500] tracking-wide cursor-pointer  "
        >
          Privacy Policy
        </li>
      </ul>

      <div className="w-[35%] h-full flex items-center justify-end  flex-row gap-4 sm:hidden md:hidden lg:flex ">
        <div
          onClick={handleLanguage}
          className="w-[120px] h-full inline-flex items-center flex-row justify-center  gap-2 cursor-pointer "
        >
          <img
            src={`/Icons/flag/${Language == "English" ? "SA" : "US"}.svg`}
            className="w-[20px]"
            alt="ic-arab"
          />
          <span className="text-[16px] text-white  font-[500] ltr:font-InterLight rtl:font-Cairo  ">
            {Language == "English" ? "العربية" : "English"}
          </span>
       
        </div>
        <span
          className="text-[16px] text-white font-[500] font-InterLight tracking-wide cursor-pointer"
          onClick={handleSignIn}
        >
          {user ? t("action.sign-out") : t("action.sign-in")}
        </span>
        <span
          onClick={() => (user ? navigate("/dashboard") : navigate("/register"))}
          className="bg-[#6AAA74] text-white text-[14px] font-InterLight font-medium px-2 py-[7px] rounded-md ml-[6px] cursor-pointer "
        >
          {user ? "Dashboard" : "Get Started"}
        </span>
      </div>

      <div className="w-[30%] h-full flex items-center  justify-end lg:hidden sm:flex  ">
        {/* <span
          onClick={handleMenu}
          className="cursor-pointer material-symbols-sharp text-[25px] text-white"
        >
          menu
        </span> */}
        <img onClick={handleMenu} src="/Icons/sidebar/menu-white.svg" className=" cursor-pointer active:scale-110 " />

      </div>
      <DropDowns
        isDropDownActive={isDropDownActive}
        setIsDropDownActive={setIsDropDownActive}
        featureRef={featureRef}
      />
      <Resources_DropDowns
        isDropDownActive={isResorcesActive}
        setIsDropDownActive={setResourcesActive}
        resourcesRef={resourcesRef}
      />
      <SideBar />
    </div>
  );
};

export default Header;
