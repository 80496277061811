import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BrandVoiceAPI from "../services/brandVoice";

const initialState = {
  isSaveLoading: false,
  errorSaveVoice: {},
  isBrancVoiceNamesLoading:true,
  brandVoiceNames:null,
  brandVoices: null,
  isBrandVoicesLoading: false,
  brandVoiceByID: null,
  isbrandVoiceByIDLoading: false,
  isupdateBrandVoiceLoading: false,
  errorUpdateBrandVoice: {},
  brandVoiceId:null,
  brandvoiceLength:"",

};

export const GetBrandVoicesNames = createAsyncThunk("get-brand-voices-names",async (payload) =>{
  const res = await BrandVoiceAPI.getbrandVoicesNames();
  return res;
} )

export const SaveBrandVoice = createAsyncThunk(
  "save-brand-voice",
  async (payload) => {
    const res = await BrandVoiceAPI.saveBrandVoice(payload);
    return res;
  }
);

export const GetBrandVoicebyOffSet = createAsyncThunk(
  "get-brand-voice-by-offset",
  async (payload) => {
    const res = await BrandVoiceAPI.getBrandVoiceByOffSet(payload);
    return res;
  }
);

export const GetbrandvoiceById = createAsyncThunk(
  "get-brand-voice-by-id",
  async (payload) => {
    const res = await BrandVoiceAPI.brandvoiceById(payload);
    return res;
  }
);

export const UpdateBrandVoices = createAsyncThunk(
  "update-brand-voice",
  async (payload) => {
    const res = await BrandVoiceAPI.updateBrandVoice(payload);
    return res;
  }
);

export const DeleteBrandVoices =createAsyncThunk("delete-brand-voice",async(payload)=>{
  const res = await BrandVoiceAPI.deleteBrandVoice(payload);
  return res;
})

export const GetBrandVoiceLength =createAsyncThunk("get-brand-voice-length",async(payload)=>{
  const res = await BrandVoiceAPI.getBrandVoiceLength(payload);
  return res;
})

export const brandVoiceSlice = createSlice({
  name: "brandVoice",
  initialState,
  reducers: {
    resetCreateBrandVoice: (state, action) => {
      state.errorSaveVoice = "";
    },
    resetBrandVoiceById:(state,action)=>{
      state.brandVoiceByID = ""
    },
    setbrandVoiceID:(state,action) =>{
      state.brandVoiceId = action.payload
    },
    resetbrandVoiceID :(state,action)=>{
      state.brandVoiceByID = null
    }
  },
  extraReducers(builder) {
    builder
      /**Save Brand Voice**/
      .addCase(SaveBrandVoice.pending, (state, action) => {
        state.isSaveLoading = true;
        state.messageError = ""
      })
      .addCase(SaveBrandVoice.fulfilled, (state, action) => {
        state.isSaveLoading = false;
      })
      .addCase(SaveBrandVoice.rejected, (state, action) => {
        state.isSaveLoading = false;
        state.errorSaveVoice = {
          en: "Error While Saving Brand Voice",
          ar: "حدث خطأ أثناء حفظ صوت العلامة التجارية",
        };
      })
      .addCase(GetBrandVoicebyOffSet.pending, (state, action) => {
        state.isBrandVoicesLoading = true;
      })
      .addCase(GetBrandVoicebyOffSet.fulfilled, (state, action) => {
        state.brandVoices = action.payload;
        state.isBrandVoicesLoading = false;
      })
      .addCase(GetBrandVoicebyOffSet.rejected, (state, action) => {
        state.isBrandVoicesLoading = false;
      })
      /**Get Brand Voice By Id**/
      .addCase(GetbrandvoiceById.pending, (state, action) => {
        state.isbrandVoiceByIDLoading = true;
      })
      .addCase(GetbrandvoiceById.fulfilled, (state, action) => {
        state.isbrandVoiceByIDLoading = false;
        state.brandVoiceByID = action.payload;
      })
      .addCase(GetbrandvoiceById.rejected, (state, action) => {
        state.isbrandVoiceByIDLoading = false;
      })
      /***Get Brand Voices ***/
      .addCase(UpdateBrandVoices.pending, (state, action) => {
        state.isupdateBrandVoiceLoading = true;
      })
      .addCase(UpdateBrandVoices.fulfilled, (state, action) => {
        state.isupdateBrandVoiceLoading = false;
      })
      .addCase(UpdateBrandVoices.rejected, (state, action) => {
        state.isupdateBrandVoiceLoading = false;
        state.errorUpdateBrandVoice = {
          en: "Error While Updating Brand Voice",
          ar: "حدث خطأ أثناء تحديث صوت العلامة التجارية",
        };
      })
      /***Delete Brand Voice**/
      .addCase(DeleteBrandVoices.pending,(state,action)=>{
        state.isBrandVoicesLoading = true
      })
      .addCase(DeleteBrandVoices.fulfilled,(state,action)=>{
        state.isBrandVoicesLoading = false
       
      })
      .addCase(DeleteBrandVoices.rejected,(state,action)=>{
        state.isBrandVoicesLoading = false
      })
      /***Get All Brand Voices***/
      .addCase(GetBrandVoicesNames.pending,(state,action)=>{
        state.isBrancVoiceNamesLoading = true
      })
      .addCase(GetBrandVoicesNames.fulfilled,(state,action)=>{
        state.isBrancVoiceNamesLoading = false
        state.brandVoiceNames = action.payload
      })
      .addCase(GetBrandVoicesNames.rejected,(state,action)=>{
        state.isBrancVoiceNamesLoading = false
      })
      /***BrandVoice Length*/
      .addCase(GetBrandVoiceLength.fulfilled,(state,action)=>{
        state.brandvoiceLength = action.payload
      })
  },
});

export const { resetCreateBrandVoice,resetBrandVoiceById,setbrandVoiceID } = brandVoiceSlice.actions;

export default brandVoiceSlice.reducer;
