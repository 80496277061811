import React from "react";
import Navigation from "./navigation";
import UserProfileBar from "./userProfileBar";
import HistoryChat from "./historyChat";
import { useEffect, useRef,useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsMinimize } from "../../Redux/sidebarSlice";
import "./scrollbar.css"

const Sidebar = ({ isSideBarOpen, setIsSideBarOpen  }) => {
  const navigate = useNavigate();
  const sideNavRef = useRef(null);
  const dispatch = useDispatch()
  const userAgent = navigator.userAgent;
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const isMinimize = useSelector((state)=> state.sidebar.isMinimize)

  const currentPage = useSelector((state) => state.sidebar.currentPage);
  
  // This Function will add event listener to sidebar
  useEffect(() => {

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, []);

  // Adding Event to dom so that when mobile size is detected will run specific code

  useEffect(()=>{
    window.addEventListener("resize",handleResize)
    return () => window.removeEventListener('resize', handleResize);
  })

  // Handle Resize Funstion

  useEffect(()=>{
    if (mobileRegex.test(userAgent)) {
      dispatch(setIsMinimize(false))
    }
  },[])

  const handleResize = () => {
    if (window.innerWidth < 760) {
      dispatch(setIsMinimize(false))
    }
  }

  // This Function Will Handle Sidebar closing
  const handleClickOutside = (event) => {
    if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
      localStorage.setItem("dashboardSideBar", false);
      setIsSideBarOpen(false);
    }
  };

  let outsidewrapper = isSideBarOpen ? "w-[100%]" : "w-[0%]";
  let sidebarwrapper = isSideBarOpen  ? "w-[280px]  " : "w-[0px] ";

  const handleMinimize = () => {
    dispatch(setIsMinimize(!isMinimize))
  }

  return (
    <div
      className={`sm:${outsidewrapper}  md:w-[0px] h-[100vh] absolute ${
        isSideBarOpen ? "z-[50]" : "z-[-10]"
      } md:z-50 bg-gray-500 bg-opacity-50  `}
    >
      <div
        ref={sideNavRef}
        className={`${sidebarwrapper} ${isMinimize ? " md:w-[80px] " :"md:w-[280px]" }  h-[100vh] transition-all   sm:z-60 fixed top-0 bg-[#151718] flex flex-col gap-2 scrollbar-thin `}
      >
        <div className={`px-4 w-full h-[60px]
         flex-shrink-0 pt-2 flex flex-row gap-[70px] items-center justify-center   `}>
          <img
            onClick={() => {
              navigate("/dashboard");
              setIsSideBarOpen(false);
            }}
            src="/Icons/khudi-white.png"
            className={`w-[120px] h-[35px] cursor-pointer ${isMinimize?"hidden":"visible"} `}
          />
          {
            currentPage !== "chat" ? 
            <img onClick={handleMinimize} src="/Icons/menu.png" className=" h-[15px] cursor-pointer sm:hidden md:block " />
            :
            ""
          }
         
        </div>
        <div className=" px-4 w-ful h-auto flex flex-col overflow-y-auto  scrollbar-none  ">
          {currentPage == "chat" ? (
            <HistoryChat />
          ) : (
            <Navigation isMinimize={isMinimize} setIsSideBarOpen={setIsSideBarOpen} />
          )}
          <UserProfileBar isMinimize={isMinimize} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
