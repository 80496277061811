import React, { useState } from "react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


const Section2 = () => {
  const {t} = useTranslation();
  const tSect = "home.sect2";

  const [isActive, setisActive] = useState("writer");
  const [activeSection, setActiveSection] = useState("writer");

  const handleSetActive = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="w-full  min-h-[800px] bg-[#0A0D0E] block    ">
      <div className="w-[80%] sm:w-[90%] xs:w-[85%] md:w-[85%] lg:w-[85%] xl:w-[80%] max-w-7xl h-full mx-auto flex flex-col z-10 pt-[100px] relative  ">
        <img
          src="/Icons/bg6 (4).png"
          className="h-[360px]  absolute z-0 left-[21%] sm:left-[-6%]  md:left-[0] lg:left-[21%] sm:top-[0%] md:top-0  pointer-events-none "
          alt="bg-sect2"
        />
        {/***Main Heading Wrapper***/}
        <div className="w-full min-h-[200px]    ">
          <div className="w-[100%] h-full flex flex-col items-start gap-3 ">
            <div data-aos="fade-up" className="w-[580px] sm:w-[100%] md:w-[580px] lg:w-[580px] xl:w-[580px] h-[40px] sm:h-auto md:h-[40px] lg:h-[40px] sm:p-2 md:p-0 inline-flex sm:block md:inline-flex items-center justify-center text-center bg-[#FFFFFF] bg-opacity-10 rounded-md ">
              <span className="text-[#FFFFFF] text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium   tracking-[2px] ">
                {t(`${tSect}.unlocking-limit`)}
              </span>
              <span className="text-[#0BA5EC] ml-[5px] text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[2px] ">
              {t(`${tSect}.new-features`)}
              </span>
            </div>
            <div data-aos="fade-up" className="w-[90%] sm:w-full md:w-full lg:w-full xl:w-[90%]  min-h-[calc(100%-40px)] block ">
              <span className="text-white text-[45px] sm:text-[30px] md:text-[45px]  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[2px] ">
                {t(`${tSect}.unleash-power`)}
              </span>
              <div className="inline-flex flex-col  md:ml-[15px]  lg:ml-[10px] xl:ml-[15px] ">
                <span className=" text-[45px] sm:text-[30px] md:text-[45px]   font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[2px] bg-clip-text text-transparent bg-gradient-to-r from-[#62FE76] to-[#0BA5EC]   ">
                  {" "}
                  {t(`${tSect}.future-of-tech`)}
                </span>
                <div className="w-[100%] h-[20px] inline-flex justify-end ">
                  <img
                    src="/Icons/underline2.png"
                    className="float-right w-[200px] h-[5px] "
                    alt="ic_underline"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/***Main Scroll Section Wrapper***/}
        <div className="w-[100%] min-h-[calc(100vh-200px)]  mt-[60px] flex flex-col ">
          <div className="w-full h-full flex flex-row  ">
            <div className="w-[30%] min-h-[100%] flex md:hidden sm:hidden lg:flex  ltr:border-r-[1px] rtl:border-l-[1px] border-[#1D2939] ">
              <div className="w-[88%] lg:w-[90%] xl:w-[88%] h-[230px] flex gap-6 sticky top-[80px]  ">
                <div className="w-[4px] h-full flex bg-[#1D2939] relative ">
                  <span
                    className={`w-full h-[15%] bg-[#61FF76] transition-all absolute ${activeSection === "write" ? "top-0 " : ""
                      } ${activeSection === "ecommerce" ? "top-[30%]" : ""} ${activeSection === "marketing" ? "top-[58%]" : ""
                      } ${activeSection === "entrepreneur" ? "top-[85%]" : ""}   `}
                  ></span>
                </div>
                <div className="w-[calc(100%-4px)] h-full flex flex-col gap-6 ">
                  <Link
                    to="writer"
                    spy={true}
                    smooth={true}
                    offset={-40}
                    onSetActive={handleSetActive}
                    onClick={() => {
                      setisActive("writer");
                    }}
                    className={`w-full h-[20%] cursor-pointer  ${activeSection === "writer" ? "bg-[#FFFFFF]" : ""
                      } bg-opacity-10 rounded-md flex items-center gap-2 `}
                  >
                    <div className="w-[50px] h-full bg-[#FFFFFF] bg-opacity-20 rounded-md inline-flex items-center justify-center ">
                      <img
                        src="/Icons/245-edit-document-outline (1).gif"
                        className="h-[40px]"
                        alt="ic-writers"
                      />
                    </div>
                    <span
                      className={`${activeSection === "writer"
                          ? "text-[#61FF76]"
                          : "text-[#98A2B3]"
                        }  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide text-[20px] lg:text-[16px] xl:text-[20px] `}
                    >
                      {t(`${tSect}.writers`)}
                    </span>
                  </Link>

                  <Link
                    to="ecommerce"
                    spy={true}
                    smooth={true}
                    offset={-40}
                    onSetActive={handleSetActive}
                    onClick={() => {
                      setisActive("ecommerce");
                    }}
                    className={`w-full h-[20%] cursor-pointer  ${activeSection === "ecommerce" ? "bg-[#FFFFFF]" : ""
                      } bg-opacity-10 rounded-md flex items-center gap-2  `}
                  >
                    <div className="w-[50px] h-full bg-[#FFFFFF] bg-opacity-20 rounded-md inline-flex items-center justify-center ">
                      <img
                        src="/Icons/2370-foreign-language-outline (1).gif"
                        className="h-[40px]"
                        alt="ic-ecommerce"
                      />
                    </div>
                    <span
                      className={` ${activeSection === "ecommerce"
                          ? "text-[#61FF76]"
                          : "text-[#98A2B3]"
                        }  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide text-[20px] lg:text-[16px] xl:text-[20px]`}
                    >
                      {t(`${tSect}.ecommerce`)}
                    </span>
                  </Link>

                  <Link
                    to="marketing"
                    spy={true}
                    smooth={true}
                    offset={-40}
                    onSetActive={handleSetActive}
                    onClick={() => {
                      setisActive("marketing");
                    }}
                    className={`w-full h-[20%] cursor-pointer  ${activeSection === "marketing" ? "bg-[#FFFFFF]" : ""
                      } bg-opacity-10 rounded-md flex items-center gap-2 `}
                  >
                    <div className="w-[50px] h-full bg-[#FFFFFF] bg-opacity-20 rounded-md inline-flex items-center justify-center ">
                      <img
                        src="/Icons/1854-office-desk-outline.gif"
                        className="h-[40px]"
                        alt="ic-marketing"
                      />
                    </div>
                    <span
                      className={` ${activeSection === "marketing"
                          ? "text-[#61FF76]"
                          : "text-[#98A2B3]"
                        }  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide text-[20px] lg:text-[16px] xl:text-[20px]`}
                    >
                      {t(`${tSect}.marketing`)}
                    </span>
                  </Link>

                  <Link
                    to="entrepreneur"
                    spy={true}
                    smooth={true}
                    offset={-40}
                    onSetActive={handleSetActive}
                    onClick={() => {
                      setisActive("entrepreneur");
                    }}
                    className={`w-full h-[20%] cursor-pointer  ${activeSection === "entrepreneur" ? "bg-[#FFFFFF]" : ""
                      } bg-opacity-10 rounded-md flex items-center gap-2`}
                  >
                    <div className="w-[50px] h-full bg-[#FFFFFF] bg-opacity-20 rounded-md inline-flex items-center justify-center ">
                      <img
                        src="/Icons/935-speedometer-outline.gif"
                        className="h-[40px]"
                        alt="ic-entrepreneur"
                      />
                    </div>
                    <span
                      className={` ${activeSection === "entrepreneur"
                          ? "text-[#61FF76]"
                          : "text-[#98A2B3]"
                        }  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide text-[20px] lg:text-[16px] xl:text-[20px]`}
                    >
                      {t(`${tSect}.entrepreneur`)}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-[70%] sm:w-full md:w-full lg:w-[70%] min-h-[100%] flex flex-col pl-6 ltr:sm:pl-0 ltr:lg:pl-6 rtl:sm:pr-6 rtl:lg:pr-6  gap-y-[90px] sm:gap-y-[60px] md:gap-[90px] lg:gap-y-[120px]   ">
              <div
                data-aos="fade-up" data-aos-duration="6000" data-aos-offset="80"
                id="writer"
                className="w-full h-[400px] sm:h-auto md:h-[550px] lg:h-[400px] flex flex-col gap-4 sm:gap-6 md:gap-4 "
              >
                <Link
                  to="writer"
                  spy={true}
                  smooth={true}
                  offset={-40}
                  onSetActive={handleSetActive}
                  onClick={() => {
                    setisActive("writer");
                  }}
                  className={`w-[45%] sm:w-[60%] md:w-[45%] sm:flex md:flex lg:hidden h-[50px] cursor-pointer  ${activeSection === "writer" ? "bg-[#FFFFFF]" : ""
                    } bg-opacity-10 rounded-md flex items-center gap-2 `}
                >
                  <div className="w-[50px] h-full bg-[#FFFFFF] bg-opacity-20 rounded-md inline-flex items-center justify-center ">
                    <img
                      src="/Icons/245-edit-document-outline (1).gif"
                      className="h-[40px]"
                      alt="ic-writers"
                    />
                  </div>
                  <span
                    className={` ${activeSection === "writer"
                        ? "text-[#61FF76]"
                        : "text-[#98A2B3]"
                      }  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide text-[20px] lg:text-[16px] xl:text-[20px]`}
                  >
                    {t(`${tSect}.writers`)}
                  </span>
                </Link>
                <div className="w-full h-[50px] inline-flex items-center ">
                  <span className="text-[28px] sm:text-[20px] md:text-[25px]  lg:text-[25px] xl:text-[28px] text-[#CDDCCF] font-semibold font-InterLight ltr:font-InterLight rtl:font-Cairo  tracking-[3px] sm:tracking-[1px] md:tracking-[3px] ">
                    {t(`${tSect}.writers-header`)}{" "}
                  </span>
                </div>
                <div className="w-full h-[300px] sm:h-[200px] md:h-[300px]  bg-opacity-5 rounded-[20px] ">
                  <img
                    src="/Icons/Frames/tools.png"
                    className="w-full h-full bg-cover bg-[#68696a] rounded-[20px] border-[1px] border-[#344054] bg-opacity-10 "
                    alt="frame"
                  />
                </div>
                <div className="w-full min-h-[150px]  ">
                  <p className="text-[#F1F9F3] text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px] ">
                    {t(`${tSect}.writers-content`)}
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-up" data-aos-duration="6000" data-aos-offset="80"
                id="ecommerce"
                className="w-full h-[400px] sm:h-auto md:h-[550px] lg:h-[400px] flex flex-col gap-4 sm:gap-6  md:gap-4 "
              >
                <Link
                  to="ecommerce"
                  spy={true}
                  smooth={true}
                  offset={-40}
                  onSetActive={handleSetActive}
                  onClick={() => {
                    setisActive("ecommerce");
                  }}
                  className={`w-[45%] sm:w-[80%] md:w-[45%] sm:flex md:flex lg:hidden  h-[50px] cursor-pointer  ${activeSection === "ecommerce" ? "bg-[#FFFFFF]" : ""
                    } bg-opacity-10 rounded-md flex items-center gap-2  `}
                >
                  <div className="w-[50px] h-full bg-[#FFFFFF] bg-opacity-20 rounded-md inline-flex items-center justify-center ">
                    <img
                      src="/Icons/Frames/chat.png"
                      className="h-[40px] bg-[#344054] rounded-[20px] border-[1px] border-[#344054] bg-opacity-10 "
                      alt="ic-ecommerce"
                    />
                  </div>
                  <span
                    className={` ${activeSection === "ecommerce"
                        ? "text-[#61FF76]"
                        : "text-[#98A2B3]"
                      }  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide text-[20px] lg:text-[16px] xl:text-[20px]`}
                  >
                    {t(`${tSect}.ecommerce`)}
                  </span>
                </Link>
                <div className="w-full h-[50px] inline-flex items-center ">
                  <span className="text-[28px] sm:text-[20px] md:text-[25px]  lg:text-[25px] xl:text-[28px] text-[#CDDCCF] font-semibold font-InterLight ltr:font-InterLight rtl:font-Cairo  tracking-[3px] ">
                  {t(`${tSect}.ecommerce-header`)}{" "}
                  </span>
                </div>
                <div className="w-full h-[300px] sm:h-[200px] md:h-[300px]  bg-opacity-5 rounded-[20px] ">
                  <img
                    src="/Icons/Frames/chat.png"
                    className="w-full h-full bg-cover bg-[#68696a] rounded-[20px] border-[1px] border-[#344054] bg-opacity-10 "
                    alt="frame"
                  />
                </div>
                <div className="w-full min-h-[150px]  ">
                  <p className="text-[#F1F9F3] text-[16px] font-InterLight font-[300] tracking-[1px] ">
                    {t(`${tSect}.ecommerce-content`)}
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-up" data-aos-duration="6000" data-aos-offset="80"
                id="marketing"
                className="w-full h-[400px] sm:h-auto md:h-[550px] lg:h-[400px] flex flex-col gap-4 sm:gap-6  md:gap-4 "
              >
                <Link
                  to="marketing"
                  spy={true}
                  smooth={true}
                  offset={-40}
                  onSetActive={handleSetActive}
                  onClick={() => {
                    setisActive("marketing");
                  }}
                  className={`w-[45%] sm:w-[80%] md:w-[45%] sm:flex md:flex lg:hidden h-[50px] cursor-pointer  ${activeSection === "marketing" ? "bg-[#FFFFFF]" : ""
                    } bg-opacity-10 rounded-md flex items-center gap-2 `}
                >
                  <div className="w-[50px] h-full bg-[#FFFFFF] bg-opacity-20 rounded-md inline-flex items-center justify-center ">
                    <img
                      src="/Icons/1854-office-desk-outline.gif"
                      className="h-[40px]"
                      alt="ic-marketing"
                    />
                  </div>
                  <span
                    className={` ${activeSection === "marketing"
                        ? "text-[#61FF76]"
                        : "text-[#98A2B3]"
                      }  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide text-[20px] lg:text-[16px] xl:text-[20px]`}
                  >
                    {t(`${tSect}.marketing`)}
                  </span>
                </Link>
                <div className="w-full h-[50px] inline-flex items-center ">
                  <span className="text-[28px] sm:text-[20px] md:text-[25px]  lg:text-[25px] xl:text-[28px] text-[#CDDCCF] font-semibold font-InterLight ltr:font-InterLight rtl:font-Cairo  tracking-[3px] ">
                  {t(`${tSect}.marketing-header`)}{" "}
                  </span>
                </div>
                <div className="w-full h-[300px] sm:h-[200px] md:h-[300px]  bg-opacity-5 rounded-[20px] ">
                  <img
                    src="/Icons/Frames/library.png"
                    className="w-full h-full bg-cover bg-[#68696a] rounded-[20px] border-[1px] border-[#344054] bg-opacity-10"
                    alt="frame"
                  />
                </div>
                <div className="w-full min-h-[150px]  ">
                  <p className="text-[#F1F9F3] text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px] ">
                    {t(`${tSect}.marketing-content`)}
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-up" data-aos-duration="6000" data-aos-offset="80"
                id="entrepreneur"
                className="w-full h-[400px] sm:h-auto md:h-[550px] lg:h-[400px] flex flex-col gap-4 sm:gap-6 md:gap-4 "
              >
                <Link
                  to="entrepreneur"
                  spy={true}
                  smooth={true}
                  offset={-40}
                  onSetActive={handleSetActive}
                  onClick={() => {
                    setisActive("entrepreneur");
                  }}
                  className={`w-[45%] sm:w-[60%] md:w-[45%] sm:flex md:flex lg:hidden h-[50px] cursor-pointer  ${activeSection === "entrepreneur" ? "bg-[#FFFFFF]" : ""
                    } bg-opacity-10 rounded-md flex items-center gap-2`}
                >
                  <div className="w-[50px] h-full bg-[#FFFFFF] bg-opacity-20 rounded-md inline-flex items-center justify-center ">
                    <img
                      src="/Icons/935-speedometer-outline.gif"
                      className="h-[40px]"
                      alt="ic-entrepreneur"
                    />
                  </div>
                  <span
                    className={` ${activeSection === "entrepreneur"
                        ? "text-[#61FF76]"
                        : "text-[#98A2B3]"
                      }  font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-wide text-[20px] lg:text-[16px] xl:text-[20px]`}
                  >
                    {t(`${tSect}.entrepreneur`)}
                  </span>
                </Link>
                <div className="w-full h-[50px] inline-flex items-center ">
                  <span className="text-[28px] sm:text-[20px] md:text-[25px]  lg:text-[25px] xl:text-[28px] text-[#CDDCCF] font-semibold font-InterLight  tracking-[3px] ">
                  {t(`${tSect}.entrepreneur-header`)}{" "}
                  </span>
                </div>
                <div className="w-full h-[300px] sm:h-[200px] md:h-[300px]  bg-opacity-5 rounded-[20px] ">
                  <img
                    src="/Icons/Frames/image.png"
                    className="w-full h-full bg-cover bg-[#68696a] rounded-[20px] border-[1px] border-[#344054] bg-opacity-10 "
                    alt="frame"
                  />
                </div>
                <div className="w-full min-h-[150px]  ">
                  <p className="text-[#F1F9F3] text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px] ">
                    {t(`${tSect}.entrepreneur-content`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[100px] sm:h-[100px] md:h-[100px] lg:h-[150px] block "></div>
      </div>
    </div>
  );
};

export default Section2;
