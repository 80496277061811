import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setIsUpgradeActive } from "../../../Redux/subscriptionSlice";
import UserProfile from "../../../services/userProfile";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
const userProfile = new UserProfile().getUserProfile();

const HomeDashboard = () => {

  useEffect(() => {
    localStorage.setItem("ActiveBtn", "all");

    ReactGA.pageview("/dashboard");
  }, []);
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tSect = "dashboard.Home-Dashboard";

  const direction = localStorage.getItem("i18nextLng");

  const handleUpgradePlan = () => {
    dispatch(setIsUpgradeActive(true));
  };

  const now = new Date();

  const currentHour = now.getHours();

  const morningStart = 6; // 6:00 AM
  const morningEnd = 11; // 11:59 AM
  const eveningStart = 18; // 6:00 PM
  const eveningEnd = 20; // 8:59 PM

  let timeOfDay;

  if (currentHour >= morningStart && currentHour <= morningEnd) {
    timeOfDay = direction == "en" ? "Good Morning" : "صباح الخير";
  } else if (currentHour > eveningStart || currentHour < morningStart) {
    timeOfDay = direction == "en" ? "Good Night" : "طاب مساؤك";
  } else {
    timeOfDay = direction == "en" ? "Good Evening" : "مساء الخير";
  }

  return (
    <div className="w-full min-h-[100vh] rounded-[20px] flex flex-col gap-y-4  sm:p-8 lg:px-12  pt-6 ">
      {/**Dashboard Khudi AI**/}
      <div className="w-full h-auto flex flex-col gap-2 ">
        <h2 className="flex flex-row gap-2">
          <span className="text-[24px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px]">
            {t(`${tSect}.dashboard`)}
          </span>
          <span className="text-[24px] text-[#175B22] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px]">
            {t(`${tSect}.khudi-ai`)}
          </span>
        </h2>
        <span className="text-[16px] text-[#101828] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.3px] ">
          {timeOfDay}, {userProfile?.fullName} 👋
        </span>

        {/* <div
          className="w-full h-[140px] flex flex-row  rounded-md mt-[20px] p-2  "
          style={{
            backgroundImage: "url(/Icons/bg-image.svg)",
            backgroundSize: "cover",
          }}
        >
          <div className="w-[60%] sm:w-[100%] md:w-[100%] lg:w-[60%] h-full flex flex-col p-5 sm:p-2 md:p-5  relative  ">
            <span className="text-[18px] sm:text-[15px] md:text-[18px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px]   ">
            {t(`${tSect}.special-offer`)}
            </span>
            <div className="flex items-center gap-2 md:gap-14 lg:gap-2 ">
              <span className="text-[18px] sm:text-[15px] md:text-[18px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium  tracking-[0.5px] ">
              {t(`${tSect}.easter-offer`)}
              </span>
              <span className="text-[20px] sm:text-[17px] md:text-[20px] sm:ml-[20px] md:ml-0  bg-[#ADADAD] bg-opacity-60 px-2 py-1 border-dashed border-[1px] rounded-md text-white font-InterLight ltr:font-InterLight rtl:font-Cairofont-medium  tracking-[0.5px] ">
              {t(`${tSect}.easter-20`)}
              </span>
            </div>
            <span className="text-[18px] sm:text-[15px] md:text-[18px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-light  tracking-[0.5px] ">
            {t(`${tSect}.30-off`)}
            </span>
          </div>
          <div className="w-[40%] h-full flex items-end justify-end sm:hidden md:hidden lg:flex  ">
            <div className="bg-[#101828] bg-opacity-80 h-[60px] px-4 py-2 flex flex-col  rounded-md ">
              <span className="text-[16px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] ">
              {t(`${tSect}.use-coupon`)}
              </span>
              <span className="text-[12px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px] ">
              {t(`${tSect}.valid`)}
              </span>
            </div>
          </div>
        </div> */}
      </div>
      {/**Create Your Creativity**/}
      <div className="w-full h-auto flex flex-col gap-2 mt-[25px] ">
        <div className="w-full h-[30px] block">
          <h2 className="text-[18px] text-[#101828] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px]  ">
            {t(`${tSect}.heading-create-creativity`)}{" "}
          </h2>
        </div>

        <div className="w-full h-auto flex flex-row gap-4 sm:flex-col md:flex-col lg:flex-row lg:flex-wrap gap-  ">
          <div
            onClick={() => Navigate("/chat")}
            className="relative  py-5 px-5 gap-2 cursor-pointer border border-[#EAECF0] rounded-lg font-semibold"
          >
            <img
              src="/Icons/KhudiBot.svg"
              alt="1"
              className="mr-4 inline-block h-[30px] "
            />
            <div className="inline-flex flex-col gap-[2px] pr-[15px] lg:mt-2 xl:m-0  ">
              <span className="text-[15px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px] ">
                {t(`${tSect}.chat`)}
              </span>
              <span className="text-[14px] text-[#667085] font-normal font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
                {t(`${tSect}.p-chat`)}{" "}
              </span>
            </div>
            <img
              src="/Icons/assets/arrow.svg"
              className="ml-8 inline-block absolute right-4 sm:top-[25px] lg:top-[25px] xl:top-[20px]  "
            />
          </div>
          <div
            onClick={() => Navigate("/khudi-photo")}
            className="relative  py-5 px-5 gap-2 cursor-pointer border border-[#EAECF0] rounded-lg font-semibold"
          >
            <img
              src="/Icons/ai-images.png"
              alt="1"
              className="mr-4 inline-block h-[30px] "
            />
            <div className="inline-flex flex-col gap-[2px] pr-[15px] lg:mt-2 xl:m-0  ">
              <span className="text-[15px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px] ">
                {t(`${tSect}.generate-images`)}
              </span>
              <span className="text-[14px] text-[#667085] font-normal font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
                {t(`${tSect}.p-images`)}{" "}
              </span>
            </div>
            <img
              src="/Icons/assets/arrow.svg"
              className="ml-8 inline-block absolute right-4 sm:top-[25px] lg:top-[25px] xl:top-[20px]  "
            />
          </div>

          <div
            onClick={() => Navigate("/library/all")}
            className="relative  py-5 px-5 gap-2 cursor-pointer border border-[#EAECF0] rounded-lg font-semibold"
          >
            <img
              src="/Icons/assets/creativity2.png"
              alt="1"
              className="mr-4 inline-block"
            />
            <div className="inline-flex flex-col gap-[2px] pr-[15px] lg:mt-2 xl:m-0  ">
              <span className="text-[15px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px] ">
                {t(`${tSect}.browse-tools`)}
              </span>
              <span className="text-[14px] text-[#667085] font-normal font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
                {t(`${tSect}.p-browse`)}{" "}
              </span>
            </div>
            <img
              src="/Icons/assets/arrow.svg"
              className="ml-8 inline-block absolute right-4 sm:top-[25px] lg:top-[25px] xl:top-[20px]  "
            />
          </div>

          <div
            onClick={handleUpgradePlan}
            className="relative cursor-pointer   py-5 px-5 gap-2 border border-[#EAECF0] rounded-lg font-semibold"
          >
            <img
              src="/Icons/assets/creativity3.png"
              alt="1"
              className="mr-4 inline-block"
            />
            <div className="inline-flex flex-col gap-[2px] pl-4 lg:mt-2 xl:m-0 ">
              <span className="text-[15px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px] ">
                {t(`${tSect}.upgrade-plan`)}
              </span>
              <span className="text-[14px] text-[#667085] font-normal font-InterLight ltr:font-InterLight rtl:font-Cairotracking-[0.5px] ">
                {t(`${tSect}.p-upgrade`)}
              </span>
            </div>
            <img
              src="/Icons/assets/arrow.svg"
              className="ml-8 inline-block absolute right-4 sm:top-[25px] lg:top-[25px] xl:top-[20px] "
            />
          </div>
        </div>
      </div>
      {/**Hot Features In Khudi Ai**/}
      <div className="w-full h-auto flex flex-col gap-2 mt-[25px] ">
        <div className="w-full h-[30px] block">
          <h2 className="text-[18px] text-[#101828] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px]  ">
            {t(`${tSect}.heading-hot-feature`)}
          </h2>
        </div>

        <div className="w-full h-auto flex flex-row gap-4 sm:flex-col md:flex-col lg:flex-row ">
          <div
            onClick={() => Navigate("/chat")}
            className=" cursor-pointer w-[48%] sm:w-[100%] md:w-[100%] lg:w-[48%] relative py-2 px-5 gap-2 border flex flex-row border-[#EAECF0] rounded-lg font-semibold"
          >
            <div className="w-auto h-full inline-flex items-center ">
              <img
                src="/Icons/assets/AI-robot.svg"
                className=" w-20 sm:w-16 md:w-16 lg:w-14 xl:w-20  h-[120px]"
                alt=""
              />
            </div>
            <div className="w-auto h-full inline-flex items-center ">
              <div className="inline-flex flex-col gap-[2px] ">
                <span className="text-[18px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px] ">
                  {t(`${tSect}.khudi-chat`)}{" "}
                </span>
                <span className="text-[14px] text-[#667085] font-normal font-InterLight ltr:font-InterLight rtl:font-Cairotracking-[0.5px] ">
                  {t(`${tSect}.p-khudi-chat`)}
                </span>
              </div>
            </div>
            <div className=" p-2 bg-[#FFF1F3] cursor-pointer hover:bg-[#e9d0d3]  inline-flex items-center gap-2 absolute ltr:right-[20px] rtl:left-[20px] top-[10px] sm:top-[6px] md:top-[10px] ltr:sm:right-[10px] rtl:sm:left-[10px]  ltr:md:right-[20px] rtl:md:left-[20px] rounded-full sm:scale-75 md:scale-75 lg:scale-75 xl:scale-100  ">
              <img src="/Icons/Layers.svg" className="h-[15px]" />
              <span className="text-[12px] text-[#C01048] font-InterLight ltr:font-InterLight rtl:font-Cairofont-medium ">
                {t(`${tSect}.hot-feature`)}
              </span>
            </div>
          </div>
          <div
            onClick={() => Navigate("/templates/ai-article-writer")}
            className=" cursor-pointer w-[48%] sm:w-[100%] md:w-[100%] lg:w-[48%] relative py-2 px-5 gap-4 border flex flex-row border-[#EAECF0] rounded-lg font-semibold"
          >
            <div className="w-auto h-full inline-flex items-center ">
              <img
                src="/Icons/assets/AI-robot2.svg"
                className=" w-20 sm:w-16 md:w-16 lg:w-14 xl:w-20 h-[120px] mr-2"
                alt=""
              />
            </div>
            <div className="w-auto h-full inline-flex items-center ">
              <div className="inline-flex flex-col gap-[2px] ">
                <span className="text-[18px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px] ">
                  {t(`${tSect}.ai-article-writer`)}
                </span>
                <span className="text-[14px] text-[#667085] font-normal font-InterLight ltr:font-InterLight rtl:font-Cairotracking-[0.5px] ">
                  {t(`${tSect}.p-ai-writer`)}
                </span>
              </div>
            </div>
            <div className=" p-2 bg-[#FFF1F3] cursor-pointer hover:bg-[#e9d0d3]  inline-flex items-center gap-2 absolute ltr:right-[20px] rtl:left-0 top-[10px] sm:top-[6px] md:top-[10px] ltr:sm:right-[10px] rtl:sm:left-[10px]  ltr:md:right-[20px] rtl:md:left-[20px]  rounded-full sm:scale-75 md:scale-75 lg:scale-75 xl:scale-100 ">
              <img src="/Icons/Layers.svg" className="h-[15px]" />
              <span className="text-[12px] text-[#C01048] font-InterLight ltr:font-InterLight rtl:font-Cairofont-medium ">
                {t(`${tSect}.hot-feature`)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/**Bookmark Tools**/}
      <div className="w-full h-auto flex flex-col gap-2 mt-[25px] ">
        <div className="w-full h-[30px] flex flex-row justify-between pr-[30px] ">
          <h2 className="text-[18px] text-[#101828] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px]  ">
            {t(`${tSect}.book-tools`)}
          </h2>
          <div
            onClick={() => Navigate("/library/all")}
            className=" cursor-pointer inline-flex items-center gap-2 hover:scale-105 transition-all "
          >
            <span className="text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
              See all Tools
            </span>
            <img src="/Icons/assets/arrow-r.svg" className="h-[15px]" />
          </div>
        </div>
        <div className="w-full h-[calc(100%-30px)] mt-[40px] inline-flex flex-row gap-2 sm:flex-col md:flex-col lg:flex-row ">
          <div
            onClick={() => Navigate("/templates/ai-paragraph-writer")}
            className="w-[32%] cursor-pointer sm:w-[100%] md:w-[100%] lg:w-[32%] h-auto inline-flex flex-row gap-4"
          >
            <div className="w-full p-4 border rounded-md inline-flex flex-col gap-4 ">
              <div className="w-full h-[50px] inline-flex flex-row justify-between ">
                <span className="p-2 inline-flex items-center rounded-md bg-[#F2F4F7]">
                  <img
                    src="/Icons/socialIcons/khudi.jpg"
                    className="h-[40px]"
                  />
                </span>
                <span className=" px-2 h-[30px] inline-flex items-center  rounded-md bg-[#FDB022] bg-opacity-10 cursor-pointer ">
                  <img src="/Icons/badge.svg" className="h-[20px]" />
                </span>
              </div>
              <div className="w-full h-[calc(100%-50px)] flex flex-col gap-4 ">
                <h1 className=" font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold  text-[17px] tracking-[0.8px] ">
                  {t(`${tSect}.tool1.heading`)}{" "}
                </h1>
                <p className="text-[#667085] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] font-normal text-[13px]">
                  {t(`${tSect}.tool1.paragraph`)}
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => Navigate("/templates/email-v2")}
            className="w-[32%] cursor-pointer sm:w-[100%] md:w-[100%] lg:w-[32%] h-auto inline-flex flex-row gap-4"
          >
            <div className="w-full p-4 border rounded-md inline-flex flex-col gap-4 ">
              <div className="w-full h-[50px] inline-flex flex-row justify-between ">
                <span className="p-2 inline-flex items-center rounded-md bg-[#F2F4F7]">
                  <img
                    src="/Icons/socialIcons/khudi.jpg"
                    className="h-[40px]"
                  />
                </span>
                <span className=" px-2 h-[30px] inline-flex items-center  rounded-md bg-[#FDB022] bg-opacity-10 cursor-pointer ">
                  <img src="/Icons/badge.svg" className="h-[20px]" />
                </span>
              </div>
              <div className="w-full h-[calc(100%-50px)] flex flex-col gap-4 ">
                <h1 className=" font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold  text-[17px] tracking-[0.8px] ">
                  {t(`${tSect}.tool2.heading`)}{" "}
                </h1>
                <p className="text-[#667085] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] font-normal text-[13px]">
                  {t(`${tSect}.tool2.paragraph`)}
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => Navigate("/templates/cold-email-v2")}
            className="w-[32%] cursor-pointer sm:w-[100%] md:w-[100%] lg:w-[32%] h-auto inline-flex flex-row gap-4"
          >
            <div className="w-full p-4 border rounded-md inline-flex flex-col gap-4 ">
              <div className="w-full h-[50px] inline-flex flex-row justify-between ">
                <span className="p-2 inline-flex items-center rounded-md bg-[#F2F4F7]">
                  <img
                    src="/Icons/socialIcons/khudi.jpg"
                    className="h-[40px]"
                  />
                </span>
                <span className=" px-2 h-[30px] inline-flex items-center  rounded-md bg-[#FDB022] bg-opacity-10 cursor-pointer ">
                  <img src="/Icons/badge.svg" className="h-[20px]" />
                </span>
              </div>
              <div className="w-full h-[calc(100%-50px)] flex flex-col gap-4 ">
                <h1 className=" font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold  text-[17px] tracking-[0.8px] ">
                  {t(`${tSect}.tool3.heading`)}
                </h1>
                <p className="text-[#667085] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] font-normal text-[13px]">
                  {t(`${tSect}.tool3.paragraph`)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-auto flex flex-col gap-2 mt-[40px] ">
          <h2 className="text-[18px] text-[#101828] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px]  ">
            {t(`${tSect}.khudi-ai-generated`)}
          </h2>
          <div className="w-full inline-flex h-[170px] flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
            <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none  ltr:animate-infinite-scroll-ltr rtl:animate-infinite-scroll-rtl  ">
              <img
                src="/media/Masonry/1.svg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/2.svg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/3.svg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/4.svg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/women.jpg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/cat.jpg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
            </ul>
            <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none  ltr:animate-infinite-scroll-ltr rtl:animate-infinite-scroll-rtl    ">
              <img
                src="/media/Masonry/1.svg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/2.svg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/3.svg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/4.svg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/women.jpg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
              <img
                src="/media/Masonry/cat.jpg"
                className="h-[130px] my-0 mx-[40px]  animate "
                alt="image"
              />
            </ul>
          </div>
        </div>
        <div className="w-full h-[30px] inline-flex"></div>
      </div>
      {/**Bottom Space Div**/}
    </div>
  );
};

export default HomeDashboard;
