import React from "react";
import { useTranslation } from 'react-i18next';

const Section4 = () => {
  const { t } = useTranslation();
  const tSect = "home.sect4";

  const currentPage = window.location.pathname !== "/feature" || "/pricing";

  return (
    <div className={`w-full min-h-[500px]  flex  ${currentPage ? "bg-black" : "bg-[#0A0D0E]"} `}>
      <div className="w-[80%] sm:w-[95%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] max-w-7xl min-h-full flex  flex-col items-center justify-center mx-auto  ">
        <div className="w-full sm:w-[300px]  md:w-full h-[60%] sm:h-[830px] md:h-[60%] flex flex-row sm:flex-col md:flex-row gap-2 relative sm:sticky md:relative sm:items-center sm:justify-start md:items-center md:justify-center lg:items-start lg:justify-start  ">
          <div data-aos="fade-up" data-aos-offset="50" className="w-[23%] sm:w-[250px] md:w-[32%] lg:w-[23%] h-[100%] flex flex-col gap-2 ">
            <div className="w-full h-[50%] flex items-center justify-center ">
              <img src="/Icons/Lapotp 1.png" className="h-[135px]" alt="ic-laptop" />
            </div>
            <div className="w-full h-[45%] flex flex-wrap mt-[10px] ">
              <div className="w-[30%] h-full flex flex-col gap-2  ">
                <span className="text-[#344054] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.device-label`)}
                </span>
                <span className="text-[#344054] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.name-label`)}
                </span>
                <span className="text-[#344054] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.question-label`)}
                </span>
              </div>
              <div className="w-[70%] h-full flex flex-col items-end gap-2  ">
                <span className="text-[#667085] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.device-value`)}
                </span>
                <span className="text-[#667085] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.name-value`)}
                </span>
                <span className="text-[#667085] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.question-value`)}
                </span>
              </div>
            </div>
          </div>
          <div className="w-[56%] sm:w-[220px] md:w-[43%] lg:w-[53%] ltr:xl:w-[56%] trl:xl:w-[55%] h-[10%]  bg-opacity-25  flex flex-row items-center absolute sm:left-[10%]  ltr:md:left-[27.5%] rtl:md:left-[29.5%] ltr:lg:left-[21.5%] rtl:lg:left-[25.5%] ltr:xl:left-[20%] rtl:xl:left-[25%] top-[12%] sm:top-[43%] md:top-[20%] lg:top-[21%] xl:top-[19%] sm:rotate-90 md:rotate-0  ">
            <span className="w-[48%] h-[4px] block bg-gradient-to-t from-[#62FE76] to-[#0BA5EC] "></span>
            <img
              src="/Icons/Check icon.png"
              className="h-[60px] sm:rotate-[265deg] md:rotate-0 "
              alt="ic-check"
            />
            <span className="w-[48%] h-[4px] block bg-gradient-to-t from-[#62FE76] to-[#0BA5EC] "></span>
          </div>
          <div className="w-[50%] sm:h-[350px] md:h-[100%] md:w-[40%] lg:w-[50%] h-[100%] flex flex-col gap-4 items-center justify-center pt-[9%] lg:pt-[9%] xl:pt-[9%]   ">
            <span data-aos="zoom-in-up" data-aos-offset="50" className="text-white text-[36px] lg:text-[30px] xl:text-[36px] text-center font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px] sm:hidden lg:block ">
              {t(`${tSect}.heading-1`)} <br /> {t(`${tSect}.heading-2`)}{" "}
              <br /> {t(`${tSect}.heading-3`)}
            </span>
          </div>
          <div data-aos="fade-up" data-aos-offset="50" className="w-[23%] sm:w-[250px] md:w-[32%] lg:w-[23%] h-[100%] flex flex-col gap-2 ">
            <div className="w-full h-[50%] flex items-center justify-center ">
              <img src="/Icons/Group.png" className="h-[135px]" alt="ic-dataset" />
            </div>
            <div className="w-full h-[45%] flex flex-wrap mt-[10px] ">
              <div className="w-[30%] h-full flex flex-col gap-2  ">
                <span className="text-[#344054] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.dataset-label`)}
                </span>
                <span className="text-[#344054] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.processing-label`)}
                </span>
                <span className="text-[#344054] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.answer-label`)}
                </span>
              </div>
              <div className="w-[70%] h-full flex flex-col items-end gap-2  ">
                <span className="text-[#667085] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.dataset-value`)}
                </span>
                <span className="text-[#667085] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.processing-value`)}
                </span>
                <span className="text-[#667085] text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo ">
                  {t(`${tSect}.answer-value`)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full min-h-[100px] flex items-center justify-center sm:flex lg:hidden mb-[20px] ">
          <div className="w-[85%] flex items-center justify-center mx-auto  ">
            <span data-aos="fade-up" data-aos-offset="50" className="text-white text-[36px] sm:text-[32px] md:text-[36px]  lg:text-[30px] xl:text-[36px] text-center font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-[1px] ">
              {t(`${tSect}.heading-1`)}{" "}
              <br className="sm:hidden md:block" /> {t(`${tSect}.heading-2`)}{" "}
              <br className="sm:hidden md:block" /> {t(`${tSect}.heading-3`)}
            </span>
          </div>
        </div>

        <div className="w-full min-h-[60px] sm:h-auto lg:min-h-[60px] flex flex-col items-center mt-[30px]  ">
          <div className="w-[85%] sm:w-[100%] lg:w-[95%] xl:w-[85%] min-h-full flex flex-row gap-4 sm:flex-col lg:flex-row  mx-auto sm:items-center sm:justify-center ">
            <div data-aos="fade-up" data-aos-offset="50" className=" flex flex-row   gap-2 items-center justify-center p-2 bg-[#FFFFFF] bg-opacity-10 rounded-md ">
              <img
                src="/Icons/799-cash-only-outline (2).gif"
                className="h-[25px]"
                alt="ic-offer-1"
              />
              <span className="text-[16px] text-white ">
                {t(`${tSect}.offer-1`)}
              </span>
            </div>

            <div data-aos="fade-up" data-aos-offset="50" className=" flex flex-row gap-2 items-center justify-center p-2 bg-[#FFFFFF] bg-opacity-10 rounded-md ">
              <img src="/Icons/28-calendar-outline.gif" className="h-[25px]" alt="ic-offer-2" />
              <span className="text-[16px] text-white ">
                {t(`${tSect}.offer-2`)}
              </span>
            </div>

            <div data-aos="fade-up" data-aos-offset="50" className=" flex flex-row gap-2 items-center justify-center p-2 bg-[#FFFFFF] bg-opacity-10 rounded-md ">
              <img
                src="/Icons/1027-marketing-campaign-outline (1).gif"
                className="h-[25px]"
                alt="ic-offer-3"
              />
              <span className="text-[16px] text-white ">
                {t(`${tSect}.offer-3`)}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full h-[50px] block"></div>
      </div>
    </div>
  );
};

export default Section4;
