import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../../../shared/Header2/header";
import "./style.css";
import { TypeAnimation } from "react-type-animation";
import AOS from "aos";
import "aos/dist/aos.css";
import AnimatedScroll from "./animatedScroll";
AOS.init();

const LandingSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tSect = "home.landing-sect";
  const language = localStorage.getItem("i18nextLng");

  return (
    <div className="w-full  min-h-[1000px] bg-black block overflow-hidden  ">
      <div className="w-full h-full bg-gradient-to-b from-[#121617] to-[#000000e7]  ">
        {/***Background Patterns***/}
        <div className="absolute z-0 top-[200px]  block w-[750px] sm:w-[90%] md:w-[80%] lg:w-[750px] h-[400px]   opacity-70 ">
          <div className="w-full h-full flex flex-col gap-[60px] md:gap-[45px] lg:gap-[60px] translate-y-[20px]  ">
            <span className="line " />
            <span className="line " />
            <span className="line " />
            <span className="line " />
            <span className="line " />
          </div>
        </div>
        <div className="w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[80%] h-[100%] max-w-6xl  mx-auto flex flex-col pt-[20px] z-10 relative ">
          {/***Background Patterns***/}
          <div className="absolute z-0 top-[200px] block w-[750px] sm:w-[90%] md:w-[80%] lg:w-[750px] h-[400px] opacity-70 ">
            <div className=" w-[400px] h-full flex flex-col gap-[70px] sm:gap-[50px] md:gap-[80px] lg:gap-[70px]  rotate-90  translate-x-[-120px] translate-y-[-50px]  ">
              <span className="line " />
              <span className="line " />
              <span className="line " />
              <span className="line " />
              <span className="line " />
            </div>
          </div>
          {/***Header Section***/}
          <Header />
          {/***Hero Section***/}
          <div className="w-full h-[calc(100%-60px)]  block pt-[76px] sm:pt-[40px] md:pt-[60px] lg:pt-[75px]  z-50 relative ">
            <div className="w-full h-full flex flex-col ">
              {/***Landing Text Section ***/}
              <div className="w-full min-h-[55%]  flex flex-col gap-2 ">
                <div className="w-full h-[40px]  bg-opacity-25 inline-flex items-center pl-[10px] ">
                  <span className="text-[#E1F2E4] text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light  tracking-[2px]  ">
                    {t(`${tSect}.khudi-can-assist`)}
                  </span>
                </div>

                <div className="w-full min-h-[200px] flex flex-grow-0 ">
                  <div className="w-full h-full  ">
                    <span className="text-[60px] sm:text-[40px] xs:text-[50px] md:text-[65px] lg:text-[60px] sm:leading-[60px] md:leading-[80px]   lg:leading-[94px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[600] tracking-[3px] ">
                      {" "}
                      {t(`${tSect}.with-the-power`)}{" "}
                      <br className="sm:block md:block lg:hidden " />
                    </span>
                    <TypeAnimation
                      sequence={[
                        t(`${tSect}.email`),
                        3000,
                        t(`${tSect}.social-media`),
                        4000,
                      ]}
                      wrapper="span"
                      cursor={true}
                      repeat={Infinity}
                      speed={40}
                      className='text-[60px] sm:text-[40px] xs:text-[50px] md:text-[65px] lg:text-[60px] sm:leading-[60px] md:leading-[90px]   lg:leading-[94px] ml-[10px] leading-[94px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[600] tracking-[3px] bg-clip-text text-transparent bg-gradient-to-r from-[#62FE76] to-[#0BA5EC] after:content-["|"] after:bg-clip-text after:text-transparent after:bg-gradient-to-r after:from-[#62FE76] after:to-[#0BA5EC] after:font-[100]   '
                    />
                    <br className="sm:block md:block lg:hidden " />
                    <span className="text-[60px] sm:text-[40px] xs:text-[50px] md:text-[65px] lg:text-[60px] sm:leading-[60px] md:leading-[90px]   lg:leading-[94px]  text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[600] tracking-[3px] ">
                      {t(`${tSect}.10x-faster`)}{" "}
                    </span>
                  </div>
                </div>

                <div className="w-full min-h-[80px]  flex  ">
                  <span className=" font-Inter ltr:font-InterLight rtl:font-Cairo text-[23px] sm:text-[16px] md:text-[23px] text-white font-[300] leading-9 sm:leading-7 ">
                    {t(`${tSect}.reach-new-horizons`)}
                  </span>
                </div>
              </div>
              {/***Try it Section***/}
              <div className="w-full h-[80px] flex flex-row gap-2 ">
                <div className="w-auto sm:w-[45%] md:w-auto h-full flex flex-col items-center ltr:justify-start  rtl:justify-center ">
                  <div
                    onClick={() => navigate("/dashboard")}
                    className="w-full h-[70%] inline-flex items-center gap-2 pl-[25px] cursor-pointer  "
                  >
                    <img
                      src="/Icons/rocket.gif"
                      className="h-[35px]"
                      alt="ic-rocket"
                    />
                    <span className="text-[#61FF76] text-[20px] sm:text-[14px] md:text-[20px] font-InterLight ltr:font-InterLight rtl:font-Cairo   ">
                      {t(`${tSect}.try-it-now`)}
                    </span>
                  </div>
                  <span className="w-full h-[30%] ">
                    <img src="/Icons/underline.png" alt="ic-underline" />
                  </span>
                </div>
                <div className="w-auto  sm:w-[60%] md:w-auto h-full flex flex-row items-center justify-center gap-[10px] pb-[15px] ">
                  <img
                    src="/Icons/credit.gif"
                    className="h-[25px]"
                    alt="ic-cc"
                  />
                  <span className="text-white text-[16px] sm:text-[12px] md:text-[20px] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-wide ">
                    {t(`${tSect}.no-credit-card`)}
                  </span>
                </div>
              </div>
              {/***Landing Page Video Section***/}
              <div
                data-aos="zoom-in-up"
                data-aos-offset="50"
                className="w-full h-[650px] sm:h-[300px] xs:h-[350px] md:h-[500px] lg:h-[600px] xl:[650px]  mt-[20px] block mx-auto "
              >
                <div className="w-full h-full bg-[#011813] border-2 border-[#5F6660] rounded-[35px] sm:rounded-[25px] md:rounded-md-[35px]  p-4 sm:p-3 md:p-4  ">
                  <div className="w-full h-full bg-[#011813] rounded-[35px] sm:rounded-[25px] relative ">
                    <img
                      src="/media/image 8.png"
                      className="bg-cover w-full h-full"
                      alt="media-video"
                    />
                    <div className="w-[70px] h-[70px] sm:w-[50px] sm:h-[50px] md:h-[70px] md:w-[70px] inline-flex items-center justify-center bg-gradient-to-r from-[#11491A] to-[#054E31] cursor-pointer absolute top-[47%] left-[45%] rounded-full transition-all hover:scale-125 ">
                      <span className="material-symbols-sharp text-[50px] sm:text-[30px] md:text-[50px] text-white  ">
                        play_arrow
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/***Landing Page Companies Branding Section***/}
              <div className="w-[100vw] h-[50px] sm:h-[50px] md:h-[50px] lg:h-[50px]  mt-[40px] static  ">
                <div className="w-full h-full flex flex-col ">
                  <div
                    data-aos={`${
                      language == "ar" ? "slide-left" : "slide-right"
                    }`}
                    data-aos-offset="50"
                    className="w-[50%] sm:w-[90%] md:w-[90%] h-[50px]   "
                  >
                    <span className="text-[18px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300]  tracking-wide ">
                      {t(`${tSect}.trusted-by`)}
                    </span>
                    <span className="w-[105px] inline-flex flex-col  items-center ">
                      <span className="text-[18px] text-[#61FF76] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300]  tracking-wide ">
                        1,000,000+
                      </span>
                      <img
                        src="/Icons/underline.png"
                        className="w-full"
                        alt="ic-underline"
                      />
                    </span>
                    <span className="text-[18px] text-white font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300]  tracking-wide ">
                      {t(`${tSect}.marketing-teams`)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
              <AnimatedScroll />
      </div>
    </div>
  );
};

export default LandingSection;
