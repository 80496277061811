import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Select from "../../../../../shared/Select/select";
import { TypeAnimation } from 'react-type-animation';
import { sendPrompt , getToDo,resetTools } from "../../../../../Redux/librarySlice";
import {sendChat,setSessionId} from "../../../../../Redux/chatsSlice"
import { getProfile } from "../../../../../Redux/profileSlice";
import { LANGUAGE } from "../../../../../config/constants";
import { ReactComponent as Spinner } from '../../../../../assets/icons/spinner.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast} from 'react-toastify';
import { result } from "lodash";
import { useTranslation } from "react-i18next";

const { ARABIC, ENGLISH } = LANGUAGE;

const TextExtender = () => {

  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();
  const tSect = "templates.general-Writing.text-extender";

  const dispatch = useDispatch();
  const lng = localStorage.getItem('i18nextLng');

  const countWordLeft = useSelector(state => state.profile.countWordLeft);
  const selectedTool = useSelector(state => state.library.selectedTool);
  const results = useSelector(state => state.library.results);
  const isLoading = useSelector(state => state.library.loadingSendPrompt);
  const loadingDetail = useSelector(state => state.library.loadingDetail);


  const [formData, setFormData] = useState({
    documentName: "",
    originalText:"",
    keywords:"",
    iconName:"khudi.jpg",
    tone:"Excited",
  });


  console.log(results)

  const [resultLng, setResultLng] = useState(ENGLISH);
  // Put Put Langugae count
  const [Value,setValue] = useState(1)
  const [numberLines,setNumberLines] = useState(1);
  const [outputLng1, setOutputLng1] = useState('English');
  const [message,setMessage] = useState()


  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleGenerate = (e) => {

    const paragraph =`As a copywriting expert, give me (${Value}) 
    for the extended version of the following text (${formData.originalText}). The focus keywords 
    are (${formData.keywords}). The tone of voice is (${formData.tone}) .The number of lines 
    are (${numberLines}). Reply your answer in (${outputLng1}) STRICTLY KEEP THE ANSWER SHORT AND SUMMARISE) .`

    e.preventDefault();

    const payload = {
      iconName:formData.iconName,
      documentName:formData.documentName,
      prompt : paragraph , 
      toolName : "Text Expander"
    }
  
    dispatch(sendPrompt(payload)).then(res => {
      //update count words left 
      dispatch(getProfile());
    });
  };

  useEffect(()=>{
    dispatch(resetTools())
  },[])
 
  const Copied = () => {
    toast.success(`${"Response Copy To Clipboard! "}`,{
      position: 'top-right',
   })
  }
  
  const handleNumber = (event,state,max) => {
    if (event.target.value.length <= 2  && event.target.value <= max ){
      state(event.target.value)
    }

  }

  
  const disabled = !formData.documentName
    || !formData.originalText
    || !formData.keywords
    || !outputLng1

  return (
    <div className="w-[calc(100%-250px)] sm:w-full md:w-[calc(100%-250px)] sm:h-auto lg:h-full flex flex-row sm:ml-0 md:ml-[250px] md:ltr:ml-[250px] md:rtl:mr-[250px] sm:flex-col lg:flex-row sm:overflow-y-auto lg:overflow-hidden  ">
      {loadingDetail ?
        <div className='m-auto'><Spinner className='animate-spin h-10 w-10' /></div> :
        <>
          {/****Template Form*****/}
          <div className="w-[40%] sm:w-[95%] lg:w-[40%] mx-auto sm:h-auto lg:h-full sm:border-0  lg:border-r-[2px] border-[#EAECF0] pt-6 px-6  lg:overflow-y-auto ">
            {/***Heading***/}
            <div className="w-full h-[auto] flex flex-row gap-2">
              <div className="w-[40px] h-[40px] min-w-[40px] mt-4 inline-flex items-center justify-center rounded-md bg-[#E0F2FE]">
                <img src={`/Icons/socialIcons/${formData.iconName}`} className="h-[20px]" alt='' />
              </div>
              <div className="inline-flex  flex-col">
                <h2 className="text-[#101828] text-[24px] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.2px]">
                 {t(`${tSect}.title`)}
                </h2>
                <p className="text-[#101828] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.6px]">
                 {t(`${tSect}.description`)} 
                </p>
              </div>
            </div>
            {/***Form***/}
            <form className="w-full h-auto flex flex-col gap-7 mt-[20px] ">
              <div className="w-full h-auto flex flex-col gap-2 ">
                <h3>
                  <span className="text-[#B42318] text-[14px] ">*</span>
                  <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
                  {t(`${tSect}.document-name`)}
                  </span>
                </h3>
                <input
                  onChange={handleInput}
                  type="text"
                  name="documentName"
                  value={formData.documentName}
                  maxLength={100}
                  placeholder={t(`${tSect}.placeholder-document-name`)}
                  className="shadow-sm border-[1px] font-InterLight  border-[#D0D5DD] outline-none py-2 px-2 rounded-md placeholder:text-[#667085] placeholder:font-light placeholder:ltr:font-Cairo   rtl:font-Cairo  placeholder:tracking-[0.5px] "
                />
              </div>
              <div className="w-full h-[45px] rounded-md cursor-pointer flex flex-row items-center justify-center bg-[#EAECF0]  gap-2 p-2 ">
                <img src="/Icons/templates/current.svg" alt='' />
                <h2 className="inline-flex gap-2">
                  <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-bold tracking-[1px] ">
                    {countWordLeft}
                  </span>
                  <span className="text-[16px] text-[#667085] ltr:font-InterLight rtl:font-Cairo font-light tracking-[1px] ">
                    Words Left
                  </span>
                </h2>
              </div>
              <div className="w-full h-auto flex flex-col gap-2 ">
                <h3>
                  <span className="text-[#B42318] text-[14px] ">*</span>
                  <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ">
                  {t(`${tSect}.originalText`)}
                  </span>
                </h3>
                <textarea placeholder={t(`${tSect}.placeholder-original-text`)} value={formData.originalText} onChange={handleInput} maxLength={600} name="originalText" className="shadow-sm border-[1px] font-InterLight  border-[#D0D5DD] outline-none py-2 px-2 rounded-md placeholder:text-[#667085] placeholder:font-light placeholder:ltr:font-Cairo   rtl:font-Cairo  placeholder:tracking-[0.5px]" >
                
                </textarea>
              </div>

              <div className="w-full h-auto flex flex-col gap-2 ">
                <h3>
                  <span className="text-[#B42318] text-[14px] ">*</span>
                  <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
                  {t(`${tSect}.keywords`)}
                  </span>
                </h3>
                <input
                  onChange={handleInput}
                  type="text"
                  name="keywords"
                  value={formData.keywords}
                  maxLength={100}
                  placeholder={t(`${tSect}.placeholder-keywords`)}
                  className="shadow-sm border-[1px] font-InterLight  border-[#D0D5DD] outline-none py-2 px-2 rounded-md placeholder:text-[#667085] placeholder:font-light placeholder:ltr:font-Cairo   rtl:font-Cairo  placeholder:tracking-[0.5px]"
                />
              </div>


              <div className="w-full h-auto flex flex-col gap-2 ">
                <h3>
                  <span className="text-[#B42318] text-[14px] ">*</span>
                  <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ">
                  {t(`${tSect}.tone-of-voice`)}
                  </span>
                </h3>

                <select name="tone" value={formData.tone} onChange={handleInput} className=" outline-none p-2 placeholder:text-[#667085] placeholder:ltr:font-InterLight rtl:font-Cairo  placeholder:tracking-[0.5px] placeholder:font-regular shadow-sm border-[1px]  border-[#D0D5DD] rounded-md " >
                  <option value="excited" >{t(`${tSect}.tone-excited`)}</option>
                  <option value="professional" >{t(`${tSect}.tone-professional`)}</option>
                  <option value="encouraging" >{t(`${tSect}.tone-encouraging`)}</option>
                  <option value="funny" >{t(`${tSect}.tone-funny`)}</option>                  
                  <option value="dramatic" >{t(`${tSect}.tone-dramatic`)}</option>                  
                  <option value="witty" >{t(`${tSect}.tone-witty`)}</option>                  
                  <option value="sarcastic" >{t(`${tSect}.tone-sarcastic`)}</option>                  
                  <option value="creative" >{t(`${tSect}.tone-creative`)}</option>                  
                </select>
               
              </div>

              <div className="w-full h-auto flex flex-col gap-2 ">
                <h3>
                  <span className="text-[#B42318] text-[14px] ">*</span>
                  <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
                  {t(`${tSect}.number-of-lines`)}
                  </span>
                </h3>
                <input
                  onChange={(event)=>handleNumber(event,setNumberLines,15)}
                  type="number"
                  value={numberLines}
                  max={15}
                  min={1} 
                  maxLength={2}
                  placeholder={t(`${tSect}.number-of-lines`)}
                  className="shadow-sm border-[1px] border-[#D0D5DD] outline-none py-2 px-2 rounded-md placeholder:text-[#667085] placeholder:ltr:font-InterLight rtl:font-Cairo  placeholder:tracking-[0.5px] placeholder:font-medium "
                />
              </div>
                      
              <div className="w-full h-auto flex flex-row gap-2">
                <div className="w-[45%] h-auto flex flex-col gap-2  ">
                  <h3>
                    <span className="text-[#B42318] text-[14px] ">*</span>
                    <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ">
                    {t(`${tSect}.output-language`)}
                    </span>
                  </h3>
                  <Select defaultLanguage={"English"} defaultFlag={"US"} setLanguages={setOutputLng1} />
                </div>
                <div className="w-[45%] h-auto flex flex-col gap-2  ">
                  <h3>
                    <span className="text-[16px] text-[#344054] ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ">
                    {t(`${tSect}.outputs`)}
                    </span>
                  </h3>
                    <input className=" p-2 outline-none w-full h-[40px] relative  shadow-sm border-[1px] border-[#D0D5DD] rounded-md text-[16px] ltr:font-InterLight rtl:font-Cairo font-normal " type="number" onChange={(event)=>handleNumber(event,setValue,5)}  value={Value} max={5} min={1} maxLength={1}   /> 
                </div>
              </div>
             
              <button
                onClick={handleGenerate}
                className="w-full h-[45px] cursor-pointer flex items-center justify-center bg-[#1D742C] gap-2 rounded-md
                  hover:enabled:bg-[#175B22]
                  disabled:bg-[#98C5A0] disabled:cursor-not-allowed
                "
                disabled={isLoading || disabled}
              >
                <img src="/Icons/templates/curly-arrow.svg" alt='' />
                <span className="text-white ltr:font-InterLight rtl:font-Cairo font-medium tracking-[0.5px] ">
                {t(`${tSect}.generate`)}
                </span>
              </button>
              <div className="w-full h-[10px] block"></div>
            </form>
          </div>
          {/****Template Chat*****/}
          <div className="w-[60%] sm:w-[95%] lg:w-[60%] mx-auto sm:h-auto lg:h-full  flex flex-col   " >
            <div className="w-full h-auto flex flex-col gap-2 p-4 pt-8 " >
              <div className="w-full h-[60px] flex flex-col gap-1 " >
                <h2 className="text-[#101828] text-[18px] ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[0.5px]  " >{t(`${tSect}.results`)}</h2>
                <p className="text-[#101828] text-[14px] font-normal ltr:font-InterLight rtl:font-Cairo tracking-[0.2px] " >{t(`${tSect}.paragraph`)}</p>
              </div>
             
            </div>
            <div className="w-full h-auto flex flex-col gap-2 overflow-y-auto p-4 pt-4 " >
              {/*****Chat Box*/}
              {results &&
                <div className="w-full h-auto flex flex-col rounded-md bg-[#EAECF0] p-3" >
                  <div className="w-full h-[40px] flex flex-row justify-between gap-2  " >
                    <div className="w-[40%] flex flex-row items-center ">
                      <h3 className="text-[#475467] text-[12px] ltr:font-InterLight rtl:font-Cairo font-normal">Just Now</h3>
                    </div>
                    <div className="w-[60%] flex flex-row items-center justify-end gap-3 " >
                    <CopyToClipboard text={results?.content} onCopy={() => setCopied(true)}    >         
                      <img src="/Icons/templates/capture.svg" className="cursor-pointer hover:scale-110 active:scale-125 transition-all " alt='' onClick={Copied} />
                   </CopyToClipboard>
                    </div>
                  </div>
                  <div className="w-full h-auto flex pt-[10px] " >
                    
                    <p className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal whitespace-pre-wrap" >
                      {results.content}
                    </p>
                  </div>
                </div>
              }
              {isLoading &&
                <div className="w-full h-auto flex flex-col rounded-md bg-[#EAECF0] p-3" >
                  <div className="w-full h-auto flex pt-[10px] " >
                    <p className="text-[#344054] text-[14px] ltr:font-InterLight rtl:font-Cairo font-normal " >
                      <TypeAnimation
                        sequence={['...', 500, '', 500]}
                        repeat={Infinity}
                        speed={40}
                      />
                    </p>
                  </div>
                </div>
              }
            
              <div className="w-full h-[30px] block" >
                
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default TextExtender ;
