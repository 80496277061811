import React ,{useRef}from 'react'
import Wrapper from './wrapper'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

const DropDowns = ({isDropDownActive,setIsDropDownActive,featureRef}) => {

  const {t} = useTranslation();
  const tSect = "home-dropDown";
  const menuRef = useRef()


  useEffect(()=>{

    let handler = (e) => {
      if(!featureRef.current.contains(e.target) && !menuRef.current.contains(e.target)){
        setIsDropDownActive(false)
      }
    }

    document.addEventListener("mousedown",handler)

    return()=>{
      document.removeEventListener("mousedown",handler)
    }
  })

  
  return (
    <div ref={menuRef}  className={`w-[100%] sm:hidden md:hidden lg:flex transition-all  lg:${isDropDownActive ? "scale-100 opacity-100" : " scale-50 opacity-0 pointer-events-none"}  bg-[#0E0F11] absolute top-[60px] left-0  rounded-md shadow-md flex-row `} style={{zIndex:"100"}}  >
      <div className='w-[70%] h-auto flex flex-row flex-wrap gap-2 p-6  ' >
         <Wrapper
          icon={"book"}
          heading={t(`${tSect}.AI-Article-Blog`)}
          paragraph={t(`${tSect}.AI-Articel-Paragraph`)}
         />
         <Wrapper
          icon={"book"}
          heading={t(`${tSect}.surter-seo-integration`)}
          paragraph={t(`${tSect}.surter-seo-paragraph`)}
         />
         <Wrapper
          icon={"current"}
          heading={t(`${tSect}.Paraphrasing-Tool`)}
          paragraph={t(`${tSect}.Paragraph-Paragraph`)}
         />
         <Wrapper
          icon={"current"}
          heading={t(`${tSect}.facebook-ads`)}
          paragraph={t(`${tSect}.facebook-ads-paragraph`)}
         />
         <Wrapper
          icon={"play"}
          heading={t(`${tSect}.Text-Expander`)}
          paragraph={t(`${tSect}.text-expander-paragraph`)}
         />
         <Wrapper
          icon={"play"}
          heading={t(`${tSect}.google-ads`)}
          paragraph={t(`${tSect}.google-ads-paragraph`)}
         />
         <Wrapper
          icon={"play"}
          heading={t(`${tSect}.articel-summerize`)}
          paragraph={t(`${tSect}.articel-summerize-paragraph`)}
         />
         <Wrapper
          icon={"play"}
          heading={t(`${tSect}.landing-pages`)}
          paragraph={t(`${tSect}.landing-pages-paragraph`)}
         />
         <Wrapper
          icon={"play"}
          heading={t(`${tSect}.product-description`)}
          paragraph={t(`${tSect}.product-desc-paragraph`)}
         />
         <Wrapper
          icon={"play"}
          heading={t(`${tSect}.ai-articel-ideas`)}
          paragraph={t(`${tSect}.ai-article-ideas-paragraph`)}
         />
      </div>
      <div className='w-[30%] h-auto bg-[#282a2d] flex flex-col p-6 pt-8  ' >
        <div className='w-full h-[160px] rounded-md relative ' >
            <img src='/Icons/dropdown/image.svg' className='w-full h-full bg-cover '/>
            <img src='/Icons/dropdown/button.svg ' className='absolute  bottom-4 left-2 cursor-pointer hover:scale-105 transition-all  ' />
        </div>
        <div className='w-full h-auto flex flex-col gap-3 pt-4 ' >
          <h3 className=' text-[16px] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] text-white  ' >{t(`${tSect}.video-heading1`)}</h3>
          <p className='text-[14px] text-[#D0D5DD] font-InterLight ltr:font-InterLight rtl:font-Cairo  font-light tracking-[1px] ' >{t(`${tSect}.video-heading2`)}</p>
          <Link to="/feature" className='text-[14px] text-[#61FF76] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[1px] cursor-pointer ' >{t(`${tSect}.see-all-feature`)}</Link>
        </div>
      </div>
    </div>
  )
}

export default DropDowns
