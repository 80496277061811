import React from "react";
import { useTranslation } from "react-i18next";

const Conclusion = () => {

  const {t} = useTranslation();
  const tSect = "blog-details.conclusion";

  return (
    <div style={{ background: "#0A0D0E" }}>
      <h1
        class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold text-2xl text-white pt-8"
      >
        {t(`${tSect}.heading`)}
      </h1>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-4">
      {t(`${tSect}.paragraph1`)}
      </p>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base	text-[#d0d5dd]  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-3">
      {t(`${tSect}.paragraph2`)}
      </p>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-3">
      {t(`${tSect}.paragraph3`)}
      </p>

      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-3">
        {t(`${tSect}.paragraph4`)}
      </p>

      <div class="w-720 h-px bg-gray-400 mt-8"></div>

      <span class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed border border-gray-300 text-gray-300 inline-block text-center py-0.5 px-2 rounded-full mt-4 me-3">
      {t(`${tSect}.btn1`)}
      </span>
      <span class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed border border-gray-300 text-gray-300 inline-block text-center py-0.5 px-2 rounded-full mt-4 me-3">
      {t(`${tSect}.btn2`)}
      </span>
      <span class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed border border-gray-300 text-gray-300 inline-block text-center py-0.5 px-2 rounded-full mt-4 me-3">
      {t(`${tSect}.btn3`)}
      </span>
    </div>
  );
};
export default Conclusion;
