import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setIsUpgradeActive } from "../../Redux/subscriptionSlice";
import { Navigate, useNavigate } from "react-router-dom";

const UserProfileBar = ({isMinimize}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const profile = useSelector((state) => state.profile);
  const widthProgBar = Math.round(profile.countWordLeft * 100 / profile.wordLimit);

  const { t } = useTranslation();
  const tSect = "dashboard.sidebar";

  const handleUpgradePlan = () => {
    dispatch(setIsUpgradeActive(true));
  }

  const handleLogout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user-profile")
    window.location.replace("/login")
  }

  return (
    <div className={`w-full h-auto flex flex-col gap-5 mt-[40px] ${isMinimize?"mt-[20px]":"mt-[40px]"} `}>
      <div className={`w-full px-2 flex  items-center justify-between gap-2 ${isMinimize? "flex-col h-auto gap-[20px]":"flex-row h-[60px] gap-[20px]" } `}>
        <div className="inline-flex gap-2">
          <div className="relative">
            <img
              src={
                profile?.photoProfile
                  ? profile.photoProfile
                  : "/media/Ellipse 14.png"
              }
              className={` ${isMinimize?"h-[35px]":"h-[40px]"} relative rounded-full`}
              alt="pic-profile"
            />
            <img
              src="/Icons/dot.png "
              className="rounded-full absolute top-7 right-[-1px]"
              alt="dot-status"
            />
          </div>
          <div className={`inline-flex flex-col w-[120px] ${isMinimize ? " hidden " : "visible " } `}>
            <span className="text-[#FCFCFD] text-[14] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[1px] truncate">
              {profile.fullName}
            </span>
            <span className="text-[#FCFCFD] text-[12px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light tracking-[1px] truncate">
              {profile.email}
            </span>
          </div>
        </div>
        <img onClick={()=>{navigate("/settings/profile-details")}} src="/Icons/sidebar/settings-deactive.svg" className="cursor-pointer" alt="ic-settings" />
      </div>

      <div className={`w-full p-2 pt-3  gap-3 flex flex-col items-center overflow-hidden  ${!isMinimize?"bg-[#101828]":""} rounded-md `}>
        <div className={`w-full h-auto inline-flex gap-2 ${isMinimize ? " hidden " : "visible " } flex-grow-0   `}>
          <span className="text-[#F79009] text-[12px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px] ">
            {t(`${tSect}.free-forever`)}
          </span>
          <h2 className="inline-flex items-center gap-2 text-[#F79009] text-[12px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[0.5px]  ">
            {profile.countWordLeft}
            <span className="text-[#98A2B3]">{t(`${tSect}.words-left`)}</span>
          </h2>
        </div>
        <div className={`w-full bg-white rounded-full h-2 ${isMinimize ?"hidden":"visible"} `}>
          <div className="bg-[#1D742C] h-2 rounded-full" style={{ width: `${widthProgBar}%` }}></div>
        </div>
        <div className="w-full h-auto inline-flex flex-shrink-0 ">
          <div className={`w-full ${isMinimize ?"p-[8px]" : "py-3 px-4"}   bg-[#027A48] inline-flex flex-row items-center justify-center gap-2 rounded-md cursor-pointer
            hover:bg-[#175B22]
            `}
            onClick={handleUpgradePlan}
          >
            <img src="/Icons/sidebar/current.svg" alt="ic-current"  />
            <span className={`text-white text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px] ${isMinimize?"hidden":"visible"} `}>
              {t(`${tSect}.upgrade`)}
            </span>
          </div>
        </div>
        <div onClick={handleLogout} className="w-full h-auto inline-flex flex-shrink-0 ">
          <div className={`w-full ${isMinimize ?"p-[8px]" : "py-3 px-4"}   bg-gray-300 inline-flex flex-row items-center justify-center gap-2 rounded-md cursor-pointer
            hover:bg-gray-400
            `}
          >
           <span   className="cursor-pointer material-symbols-sharp text-[25px] text-black">logout</span>
            <span className={`text-black text-[14px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold tracking-[1px] ${isMinimize?"hidden":"visible"} `}>
              {t(`${tSect}.logout`)}
            </span>
          </div>
        </div>
       
      </div>

      <div className="w-full h-[20px] block "> </div>
    </div>
  );
};

export default UserProfileBar;
