import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContactAPI from "../services/contact"
const initialState = {
  loadingSendComplain: false,
  message:""
}


export const SendComplain = createAsyncThunk("sendComplain",async(payload)=>{
    const res = await ContactAPI.sendComplain(payload);
    return res;
})


export const contactSlice = createSlice({
  name: 'Contact',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(SendComplain.pending,(state,action)=>{
        state.loadingSendComplain = true
      })
      .addCase(SendComplain.fulfilled,(state,action)=>{
        state.message = action.payload
        state.loadingSendComplain = false
      })
      .addCase(SendComplain.rejected,(state,action)=>{
        state.message = action.payload
        state.loadingSendComplain = false
      })
   
  },
})


export default contactSlice.reducer