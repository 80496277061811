import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const RegisterPage = ({children}) => {
  const { t } = useTranslation();
  const tSect = "auth.login";
  const Navigate = useNavigate("")

  return (
    <div
      className="w-full min-h-[100vh] flex bg-black items-center justify-center overflow-hidden "
    >
      <div className="xl:w-full xl:min-h-[100vh] flex sm:w-full md:min-h-[100vh] sm:min-h[100vh] ">
        <div className="w-full h-[95%] overflow-hidden m-auto flex lg:flex-row xl:flex-row items-center justify-between sm:flex-col-reverse md:flex-col-reverse">
          <div className="w-full sm:w-[100%] h-[100vh] overflow-hidden md:w-full 2xl:w-full hidden lg:flex flex-col gap-2 xl:bg-center bg-no-repeat bg-cover bg-[#16191D]"
            style={{ backgroundImage: "url(/media/bg-login.png)" }}
          >
              <div
              id="text-header"
              className="md:w-[740px] mx-auto mt-[60px] flex flex-row "
            >
              <div className="flex flex-row mr-8 mt-[8px]  ">
                <img onClick={()=>Navigate("/")} title="Home" src="/Icons/home-white.png" className="h-[30px] active:scale-95 transition-all hover:scale-110 cursor-pointer  " />
              </div>
              <div className="flex flex-col">
                <p className="text-white sm:text-[25px] md:text-[30px] 2xl:text-[30px] font-InterLight font-[500] tracking-[1.5px] mt-[0px]">
                  {t(`${tSect}.cover-title`)}
                </p>
                <p className="text-[#98A2B3] sm:text-[12px] md:text-[14px] 2xl:text-[14px] font-InterLight font-[400]">
                  {t(`${tSect}.cover-subtitle`)}
                </p>
              </div>
            </div>
            <div id="img-review" className="w-full h-full 2xl:w-[991px] mx-auto">
              <img
                src="/media/bg-login-review.png" alt='img-review'
                className="w-full ml-4"
              />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
