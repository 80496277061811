import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetImages } from "../../../Redux/imageAISlice";
import GeneratedImage from "./generatedImage";

const PhotoHistory = () => {
  const dispatch = useDispatch();

  const Images = useSelector((state) => state.imageAI.imageHistory);
  const isLoading = useSelector((state) => state.imageAI.ishistoryLoading);


  useEffect(() => {
    dispatch(GetImages());
  },[]);

  return (
    <div className="w-full h-auto sm:flex-col lg:columns-4 gap-3 space-y-3 pb-28 pt-10 ">
        
      {Images.lenght !== 0 ? Images.map((item,index) => {
        return(
            <GeneratedImage
            key={index}
            src={item && item.attachmentURL}
            attachmentName={item.attachmentName}
          />
        )
      }) 
      : 
        "No Record Found"
      }
    </div>
  );
};

export default PhotoHistory;
