import React from "react";
import { useTranslation } from "react-i18next";

const Resources = () => {

  const {t} = useTranslation();
  const tSect = "blog-details.software";

  return (
    <div>
      <h1 class="font-InterLight ltr:font-InterLight rtl:font-Cario font-semibold text-2xl text-white pt-8">
      {t(`${tSect}.heading-1`)}
      </h1>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-4">
      {t(`${tSect}.paragraph1`)}
      </p>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-5">
      {t(`${tSect}.paragraph2`)}
      </p>

      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-2">
      {t(`${tSect}.paragraph3`)}
      </p>

      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-2">
      {t(`${tSect}.paragraph4`)}
      </p>
      <div class="mt-8 h-64 sm:h-[30rem] md:h-[30rem] lg:h-[30rem]  ">
        <img
          src="https://images.unsplash.com/photo-1542315204-8b368472a4e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          class="h-full w-full object-cover"
          alt="Image description"
        />
      </div>
      <p class="font-normal font-InterLight ltr:font-InterLight rtl:font-Cario text-xs leading-5 mt-4 text-[#d0d5dd] ">
      {t(`${tSect}.paragraph5`)}
        <span class="underline text-[#d0d5dd] ">{t(`${tSect}.paragraph6`)}</span>
      </p>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-6">
      {t(`${tSect}.paragraph7`)}
      </p>
      <p class="font-InterLight ltr:font-InterLight rtl:font-Cario font-normal text-base md:text-base text-[#d0d5dd]	  lg:text-xl leading-relaxed text-base leading-6 dark-gray pt-4">
      {t(`${tSect}.paragraph8`)}
      </p>
    </div>
  );
};
export default Resources;
