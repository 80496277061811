import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {updateUserName} from "../../../../../Redux/profileSlice"
import { toast } from "react-toastify";

const UserName = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tSect = "Settings.profile-Details";
  const firstName = useRef()
  const lastName = useRef()

  const isLoading = useSelector((state) => state.profile.loadingEmail);
  const status = useSelector((state) => state.profile.status);

  const [formData, setFormData] = useState({
     firstName:"",
     lastName:""
  });

  const handleInput = (event) => {
    const { value , name } = event.target;
    setFormData({...formData,[name]:value})
  };


  const handleName = (event) => {
    event.preventDefault();

    const payload = {
      fullName : formData.firstName + " "+ formData.lastName
    };
    dispatch(updateUserName(payload)).then(() => {
      status &&
        toast.success(`${status}`, {
          position: "top-right",
        });
        setFormData({...formData,firstName:""})
        firstName.current.value = ""
        setFormData({...formData,lastName:""})
        lastName.current.value = ""
    });
  };

  const disabled = !formData.firstName || !formData.lastName;

  return (
    <>
      <div className="w-full h-auto flex sm:flex-col lg:flex-row items-end  sm:gap-2 lg:gap-4">
        <div className=" flex flex-col gap-2 sm:w-[100%] lg:w-[38%]    ">
          <label className="text-[16px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
            {t(`${tSect}.Input-heading-First-Name`)}
          </label>
          <input
            type="text"
            onChange={handleInput}
            ref={firstName}
            name="firstName"
            placeholder={t(`${tSect}.Input-first-name-placeholder`)}
            className=" outline-none shadow-sm p-2 border-[1px] border-[#D0D5DD] rounded-md text-black font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] "
          />
        </div>

        {/****Input Last Name***/}
        <div className=" flex flex-col gap-2 sm:w-[100%] lg:w-[38%] ">
          <label className="text-[16px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
            {t(`${tSect}.Input-heading-last-name`)}
          </label>
          <input
            type="text"
            name="lastName"
            ref={lastName}
            onChange={handleInput}
            placeholder={t(`${tSect}.Input-last-name-placeholder`)}
            className=" outline-none shadow-sm p-2 border-[1px] border-[#D0D5DD] rounded-md text-black font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] "
          />
        </div>

        <button
            disabled={disabled || isLoading}
            onClick={handleName}
            className="text-white lg:w-[145px] cursor-pointer sm:w-full  disabled:cursor-not-allowed bg-[#1D742C] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] rounded-md p-[12px] text-[14px] hover:bg-[#1b902e]  "
          >
            {isLoading ? <h1>Loading</h1> : t(`${tSect}.btn-change-name`)}
          </button>
      </div>
      {/* <div className="w-full h-auto flex flex-col gap-4 items-start mt-[10px]  ">
        <img src="/Icons/settings/mail.svg" className="h-[18px]" />
        <h2 className="text-[24px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-normal ">
          {t(`${tSect}.heading-Email`)}
        </h2>
        <label className="text-[16px] text-[#344054] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] ">
          {t(`${tSect}.label-your-email`)}
        </label>
        <div className="w-full flex sm:flex-col lg:flex-row gap-4  ">
          <input
            type="text"
            placeholder={t(`${tSect}.placeholder-your-email`)}
            onChange={handleInput}
            className=" sm:w-full lg:w-[80%] outline-none shadow-sm p-2 border-[1px] border-[#D0D5DD] rounded-md text-black font-medium font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] "
          />
          <button
            disabled={disabled || isLoading}
            onClick={handleEmail}
            className="text-white cursor-pointer disabled:cursor-not-allowed bg-[#1D742C] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] rounded-md px-4 py-[10px] text-[14px] hover:bg-[#1b902e]  "
          >
            {isLoading ? <h1>Loading</h1> : t(`${tSect}.btn-change-email`)}
          </button>
        </div>
      </div> */}
    </>
  );
};

export default UserName;
