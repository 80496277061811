import React,{useState,useEffect} from 'react'
import KhudiPhotoSearch from "./khudiPhotoSearch"
import MasonryLayout from "./masonryLayout"
import ReactGA from "react-ga"

const KhudiPhoto = () => {

  const [keywords, setKeywords] = useState("");
  const [activeTab, setActiveTab] = useState("home")

  useEffect(()=>{
    ReactGA.pageview("/khudi-photo");

  },[])

  return (
    <div className='w-full h-[100vh] p-4  '>
      <KhudiPhotoSearch  setKeywords={setKeywords} keywords={keywords} setActiveTab={setActiveTab} activeTab={activeTab}  />
      <MasonryLayout  setKeywords={setKeywords} activeTab={activeTab} />
    </div>
  )
}

export default KhudiPhoto
