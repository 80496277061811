const Photos = [
    {
        id:"1",
        Photo:"1"
    },
    {
        id:"2",
        Photo:"2"
    },
    {
        id:"3",
        Photo:"3"
    },
    {
        id:"4",
        Photo:"4"
    },
    {
        id:"5",
        Photo:"5"
    },
    {
        id:"6",
        Photo:"6"
    },
    {
        id:"7",
        Photo:"7"
    },
    {
        id:"8",
        Photo:"8"
    },
]

export default Photos