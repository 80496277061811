import React from 'react'
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom"

const Tools = () => {

  const {t} = useTranslation();
  const tSect = "detail-feature.tools";
  const Navigate = useNavigate()

  return (
    <div className='w-full h-auto bg-gradient-to-b from-[#0A0D0E] to-[#0a0d0e00] '  >
     <div className="w-[80%] sm:w-[90%] xs:w-[85%] md:w-[90%] lg:w-[85%] xl:w-[85%] h-[100%] max-w-6xl  mx-auto flex flex-col pt-[60px] ">
        <div className='flex flex-row sm:flex-col md:flex-row sm:gap-0  items-start justify-start '>
            <span className='text-[36px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium '>{t(`${tSect}.experience-suite`)}</span>
            <div className=' flex flex-col gap-2 items-center sm:items-start md:items-center justify-center sm:justify-start md:justify-center  ' >
               <span className='text-[36px] text-[#61FF76] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium '>{t(`${tSect}.free-tools`)}</span>
               <img src='/Icons/detail-feature/green-line.svg'/>
            </div>
        </div>
        <div className='w-full h-full flex flex-row sm:flex-col xl:flex-row gap-4 mt-[50px] ' >
         {/***Wrapper Left***/}
         <div  className='w-[30%]  sm:w-full xl:w-[30%] h-auto border-[1px] border-[#1D2939] flex flex-col justify-between gap-4 rounded-md p-4  ' >
          <div className='w-full h-auto flex flex-col gap-4 ' >
            <div className='w-full h-auto flex flex-row items-center justify-between  ' >
                <img src='/Icons/detail-feature/group-doc.svg' className='h-[45px]' />
                <div className='flex flex-row gap-4 ' >
                <span onClick={()=>Navigate("/templates/content-shorten")} className='text-[14px] cursor-pointer text-[#61FF76] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ' >{t(`${tSect}.see-all-feature`)}</span>
                <img src="/Icons/detail-feature/uplift-arrow.svg"/>
                </div>
            </div>
            <div className='flex flex-col gap-4 mt-[15px] ' >
                <h2 className='text-[18px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[1px] '>{t(`${tSect}.tools1.heading`)}</h2>
                <p className='text-[14px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px]  ' >{t(`${tSect}.tools1.paragraph`)}</p>
            </div>   
          </div>
          <div className='w-full h-auto flex flex-row items-center justify-center gap-4' >
           <div onClick={()=>Navigate("/pricing")} className='px-6 py-2 flex flex-row gap-2 border-[1px] border-[#1D2939] rounded-md cursor-pointer '>
            <img src='/Icons/detail-feature/double-rings.svg' />
            <span className='text-[16px] text-[#45B554] font-InterLight ltr:font-InterLight rtl:font-Cairo  ' >{t(`${tSect}.pricing`)}</span>
           </div>
           <div onClick={()=>Navigate("/templates/content-shorten")} className='px-6 py-2 flex flex-row gap-2 border-[1px] border-[#1D2939] rounded-md cursor-pointer  bg-[#1D742C] '>
            <img src='/Icons/detail-feature/current.svg' />
            <span  className='text-[16px] text-[#ffffff] font-InterLight ltr:font-InterLight rtl:font-Cairo '>{t(`${tSect}.lets-try`)}</span>
           </div>
          </div>
         </div>
          {/***Wrapper Right***/}
          <div className='w-[68%]  sm:w-full xl:w-[68%] h-auto flex flex-wrap gap-6 '>
            <div  className='w-[48%]  sm:w-full md:w-[48%] h-auto flex flex-col gap-2 border-[1px] border-[#1D2939] rounded-md p-4  ' >
              <div className='w-full h-auto flex flex-row items-center justify-between  ' >
                <img src='/Icons/detail-feature/single-doc.svg' className='h-[45px] bg-[#FFFFFF] bg-opacity-20 rounded-md  ' />
                <div className='flex flex-row gap-4 ' >
                <span onClick={()=>Navigate("/templates/tiktok-video-script")} className='text-[14px] text-[#61FF76] cursor-pointer font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ' >{t(`${tSect}.see-all-feature`)}</span>
                <img src="/Icons/detail-feature/uplift-arrow.svg"/>
                </div>
              </div>
              <div className='flex flex-col gap-4 mt-[15px] ' >
                <h2 className='text-[18px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[1px] '>{t(`${tSect}.tools2.heading`)}</h2>
                <p className='text-[14px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px]  ' >{t(`${tSect}.tools2.paragraph`)}</p>
              </div>
            </div>
            <div  className='w-[48%]  sm:w-full md:w-[48%] h-auto flex flex-col gap-2 border-[1px] border-[#1D2939] rounded-md p-4  ' >
              <div className='w-full h-auto flex flex-row items-center justify-between  ' >
                <img src='/Icons/detail-feature/single-doc.svg' className='h-[45px] bg-[#FFFFFF] bg-opacity-20 rounded-md  ' />
                <div className='flex flex-row gap-4 ' >
                <span onClick={()=>Navigate("/templates/youtube-description-v2")} className='text-[14px] text-[#61FF76] cursor-pointer font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ' >{t(`${tSect}.see-all-feature`)}</span>
                <img src="/Icons/detail-feature/uplift-arrow.svg"/>
                </div>
              </div>
              <div className='flex flex-col gap-4 mt-[15px] ' >
                <h2 className='text-[18px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[1px] '>{t(`${tSect}.tools3.heading`)}</h2>
                <p className='text-[14px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px]  ' >{t(`${tSect}.tools3.heading`)}</p>
              </div>
            </div>
            <div  className='w-[48%]  sm:w-full md:w-[48%] h-auto flex flex-col gap-2 border-[1px] border-[#1D2939] rounded-md p-4  ' >
              <div className='w-full h-auto flex flex-row items-center justify-between  ' >
                <img src='/Icons/detail-feature/single-doc.svg' className='h-[45px] bg-[#FFFFFF] bg-opacity-20 rounded-md  ' />
                <div className='flex flex-row gap-4 ' >
                <span onClick={()=>Navigate("/templates/app-and-sms-notifications")} className='text-[14px] text-[#61FF76] cursor-pointer font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ' >{t(`${tSect}.see-all-feature`)}</span>
                <img src="/Icons/detail-feature/uplift-arrow.svg"/>
                </div>
              </div>
              <div className='flex flex-col gap-4 mt-[15px] ' >
                <h2 className='text-[18px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[1px] '>{t(`${tSect}.tools4.heading`)}</h2>
                <p className='text-[14px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px]  ' >{t(`${tSect}.tools4.paragraph`)}</p>
              </div>
            </div>
            <div  className='w-[48%]  sm:w-full md:w-[48%] h-auto flex flex-col gap-2 border-[1px] border-[#1D2939] rounded-md p-4  ' >
              <div className='w-full h-auto flex flex-row items-center justify-between  ' >
                <img src='/Icons/detail-feature/single-doc.svg' className='h-[45px] bg-[#FFFFFF] bg-opacity-20 rounded-md  ' />
                <div className='flex flex-row gap-4 ' >
                <span onClick={()=>Navigate("/templates/ai-article-ideas")} className='text-[14px] text-[#61FF76] cursor-pointer font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal ' >{t(`${tSect}.see-all-feature`)}</span>
                <img src="/Icons/detail-feature/uplift-arrow.svg"/>
                </div>
              </div>
              <div className='flex flex-col gap-4 mt-[15px] ' >
                <h2 className='text-[18px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[400] tracking-[1px] '>{t(`${tSect}.tools5.heading`)}</h2>
                <p className='text-[14px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-[300] tracking-[1px]  ' >{t(`${tSect}.tools5.paragraph`)}</p>
              </div>
            </div>
            
          </div>
        </div>
        <div className='w-full h-[80px] block' >
        </div>
      </div>
    </div>
  )
}

export default Tools
