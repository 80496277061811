import React from "react";
import { FaStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ReviewSideCard = ({userName,position,heading1,paragraph,brand}) => {

  const {t} = useTranslation();
  const tSect = "pricing.review";

  return (
    <>
      <div class="w-88 lg:h-auto md:auto h-auto review-card-back rounded-2xl p-8 overflow-hidden ">
        <div class="flex justify-between text-center items-center ">
          <div class="flex items-center space-x-4 ">
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
              alt="User Avatar"
              class="rounded-full w-10 h-10"
            />
            <div class="flex flex-col">
              <span class="text-white">{userName}</span>
              <span class="text-sm text-gray-400">{position}</span>
              <div className="flex items-center pt-2">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} className="w-4 h-4 text-yellow-400" />
                ))}
              </div>
            </div>
          </div>
          <div class="">
            <img
              src={brand}
              alt="User Avatar"
              class="rounded-full w-10 h-10"
            />
          </div>
        </div>

        <h1 class="font-InterLight font- ltr:font-InterLight rtl:font-Cairo font-500 text-base text-white pt-3">
        {heading1}
        </h1>
        <p class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal  md:text-sm	line-clamp-9  lg:text-sm  leading-relaxed text-sm leading-6 font-gray pt-2 overflow-hidden">
        {paragraph}
        </p>
      </div>
    </>
  );
};
export default ReviewSideCard;
