import React from "react";
import { useTranslation } from "react-i18next";
import UpgradeTeam from "./upgradeTeam";
import TeamModel from "./teamModel";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Spinner } from "../../../../../assets/icons/spinner.svg";

const Team = () => {
  const { t } = useTranslation();
  const tSect = "Settings.team";

  const isLoading = useSelector((state) => state.team.isGetUserLoading);

  return (
    <UpgradeTeam/>
    // <div className={`w-full ${isLoading ? "min-h-[100vh]":"h-[100vh]"}  overflow-y-auto relative  `}>
    //   {isLoading && (
    //     <div className="w-full h-[100vh] flex items-center justify-center absolute top-0 left-0 z-20 bg-gray-300 bg-opacity-30  ">
    //       <Spinner className="animate-spin  h-6 w-6 ltr:float-right rtl:float-left mr-2 mt-1 " />
    //     </div>
    //   )}

    //   <div className="w-full min-h-[100vh]  flex flex-col sm:p-8 lg:p-12 pt-[50px] gap-6 rounded-[40px]    ">
    //     <TeamModel />
    //     <div className="w-full h-[30px] block"></div>
       
    //   </div>
    // </div>
  );
};

export default Team;
