import React from "react";
import TemplateCards from "../Cards/templateCard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tools from "../data/tools.js";

const AdsMarketingTemplate = () => {
  const { t } = useTranslation();
  const tSect = "templates.ads-and-marketing";

  const [tools, setTools] = useState([]);

  useEffect(() => {
    const filtered = Tools.filter((item) => {
      return item.category == "ads-and-marketing";
    });

    setTools(filtered);
  }, []);

  return (
    <div className="w-full h-auto flex flex-col ">
      <div className="w-full h-auto flex flex-row flex-wrap gap-4 ">
      {tools &&
          tools.map((item) => {
            return (
              <TemplateCards
                Icon={item.icon}
                title={t(`${item.title}`)}
                desc={t(`${item.description}`)}
                route={item.route}
                isNew={item.isNew}
              />
            );
          })}
      </div>
      <div className="w-full h-[10px] block"></div>
    </div>
  );
};

export default AdsMarketingTemplate;
