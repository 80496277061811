import React from "react";
import { useTranslation } from "react-i18next";

const Section3 = () => {
  const { t } = useTranslation();
  const tSect = "home.sect3";

  return (
    <div className="w-full min-h-[800px] bg-black block overflow-hidden  ">
      <div className="w-[80%] sm:w-[90%] md:w-[80%] max-w-7xl mx-auto h-full block  relative">
      <img
        src="/Icons/bg6 (5).png"
        className="h-[500px] sm:h-[450px] md:h-[500px]  absolute z-0 left-[15%] sm:left-[-4%]  md:left-[1%] top-[-56px] sm:top-[-29px] md:top-[-56px] mx-auto  pointer-events-none "
        alt="bg-sect3"
      />
        {/**SECTION HEADING TEXT**/}
        <div className="w-full h-full flex flex-col ">
          <div className="w-full min-h-[300px] flex items-center justify-center mt-[50px]  ">
            <div className="w-[80%] sm:w-[95%] md:w-[80%] h-[60%] flex flex-col items-center justify-center gap-4 ">
              <div data-aos="slide-left" data-aos-duration="6000" data-aos-offset="50" className="w-[700px] sm:w-[100%] md:w-[680px] lg:w-[700px] h-[40px] text-center p-1 bg-[#FFFFFF] bg-opacity-10 rounded-md  px-4 inline-flex items-center justify-center sm:block  md:flex sm:h-auto md:h-[40px]   ">
                <span className="text-[#FFFFFF] text-[18px] sm:text-[16px] md:text-[16px] lg:text-[18px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[1.5px]   ">
                  {t(`${tSect}.unlock-the-power`)}
                </span>
                <span className="text-[#61FF76] ml-[10px] sm:text-[16px] text-[18px] md:text-[16px] lg:text-[18px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[1.5px]  ">
                  {t(`${tSect}.boost-your-sales`)}
                </span>
                <span className="text-[#FFFFFF] ml-[10px] sm:text-[16px] text-[18px] md:text-[16px] lg:text-[18px] font-InterLight ltr:font-InterLight rtl:font-Cairo font-normal tracking-[1.5px]  ">
                  {t(`${tSect}.with-compelling-ai`)}
                </span>
              </div>
              <div data-aos="slide-right"  data-aos-duration="9000"  className="w-[100%] h-[80%] block text-center ">
                <span className="text-[40px]  sm:text-[30px] md:text-[45px]   text-white font-InterLight ltr:font-InterLight rtl:font-Cairo">
                  {t(`${tSect}.unleash-potential-ai`)}
                </span>
                <div className="inline-flex flex-col  md:ml-[15px]  lg:ml-[10px] xl:ml-[15px] ">
                  <span className=" text-[45px] sm:text-[30px] md:text-[45px]   font-InterLight ltr:font-InterLight rtl:font-Cairo  bg-clip-text text-transparent bg-gradient-to-r from-[#62FE76] to-[#0BA5EC]   ">
                    {" "}
                    {t(`${tSect}.edge-tech`)}
                  </span>
                  <div className="w-[100%] h-[20px] inline-flex justify-end ">
                    <img
                      src="/Icons/underline2.png"
                      className="float-right w-[200px] h-[5px] "
                      alt="ic-underline"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full min-h-auto  flex flex-row justify-between gap-10 flex-wrap md:flex-nowrap lg:flex-wrap sm:flex-col md:flex-col lg:flex-row  md:gap-4 lg:gap-10 ">
            <div data-aos="zoom-in-up"   data-aos-offset="50"  className="w-[45%] sm:w-full md:w-full lg:w-[45%] h-[120px] sm:h-auto md:h-[120px] bg-opacity-10 flex flex-row sm:flex-col md:flex-row sm:gap-[15px] md:gap-0 ">
              <div className="w-[80px] h-full flex items-start  ">
                <img
                  src="/Icons/245-edit-document-outline (1).gif"
                  className="h-[60px] bg-[#FFFFFF] bg-opacity-20 rounded-md p-1 "
                  alt="ic-feature1"
                />
              </div>
              <div className="w-[calc(100%-80px)] sm:w-full md:w-[calc(100%-80px)] h-full flex flex-col gap-2 ">
                <span className="text-[20px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wide ">
                  {t(`${tSect}.feature1-header`)}
                </span>
                <span className="text-[16px] text-[#A0ACA2] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light tracking-wide ">
                  {t(`${tSect}.feature1-desc`)}
                </span>
              </div>
            </div>
            <div data-aos="zoom-in-up"   data-aos-offset="50" className="w-[45%] sm:w-full md:w-full lg:w-[45%] h-[120px] sm:h-auto md:h-[120px] bg-opacity-10 flex flex-row sm:flex-col md:flex-row sm:gap-[15px] md:gap-0 ">
              <div className="w-[80px] h-full flex items-start  ">
                <img
                  src="/Icons/2370-foreign-language-outline (1).gif"
                  className="h-[60px] bg-[#FFFFFF] bg-opacity-20 rounded-md p-1 "
                  alt="ic-feature2"
                />
              </div>
              <div className="w-[calc(100%-80px)] sm:w-full md:w-[calc(100%-80px)] h-full flex flex-col gap-2 ">
                <span className="text-[20px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wide ">
                  {t(`${tSect}.feature2-header`)}
                </span>
                <span className="text-[16px] text-[#A0ACA2] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light tracking-wide ">
                  {t(`${tSect}.feature2-desc`)}
                </span>
              </div>
            </div>
            <div data-aos="zoom-in-up"   data-aos-offset="50" className="w-[45%] sm:w-full md:w-full lg:w-[45%] h-[120px] sm:h-auto md:h-[120px]  bg-opacity-10 flex flex-row sm:flex-col md:flex-row sm:gap-[15px] md:gap-0 ">
              <div className="w-[80px] h-full flex items-start  ">
                <img
                  src="/Icons/1854-office-desk-outline.gif"
                  className="h-[60px] bg-[#FFFFFF] bg-opacity-20 rounded-md p-1 "
                  alt="ic-feature3"
                />
              </div>
              <div className="w-[calc(100%-80px)] sm:w-full md:w-[calc(100%-80px)] h-full flex flex-col gap-2 ">
                <span className="text-[20px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wide ">
                  {t(`${tSect}.feature3-header`)}
                </span>
                <span className="text-[16px] text-[#A0ACA2] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light tracking-wide ">
                  {t(`${tSect}.feature3-desc`)}
                </span>
              </div>
            </div>
            <div data-aos="zoom-in-up"   data-aos-offset="50" className="w-[45%] sm:w-full md:w-full lg:w-[45%] h-[120px] sm:h-auto md:h-[120px] bg-opacity-10 flex flex-row sm:flex-col md:flex-row sm:gap-[15px] md:gap-0 ">
              <div className="w-[80px] h-full flex items-start  ">
                <img
                  src="/Icons/935-speedometer-outline.gif"
                  className="h-[60px] bg-[#FFFFFF] bg-opacity-20 rounded-md p-1 "
                  alt="ic-feature4"
                />
              </div>
              <div className="w-[calc(100%-80px)] sm:w-full md:w-[calc(100%-80px)] h-full flex flex-col gap-2 ">
                <span className="text-[20px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wide ">
                  {t(`${tSect}.feature4-header`)}
                </span>
                <span className="text-[16px] text-[#A0ACA2] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light tracking-wide ">
                  {t(`${tSect}.feature4-desc`)}
                </span>
              </div>
            </div>
            <div data-aos="zoom-in-up"   data-aos-offset="50" className="w-[45%] sm:w-full md:w-full lg:w-[45%]  h-[120px] sm:h-auto md:h-[120px] bg-opacity-10 flex flex-row sm:flex-col md:flex-row sm:gap-[15px] md:gap-0 ">
              <div className="w-[80px] h-full flex items-start  ">
                <img
                  src="/Icons/461-robot-outline.gif"
                  className="h-[60px] bg-[#FFFFFF] bg-opacity-20 rounded-md p-1 "
                  alt="ic-feature5"
                />
              </div>
              <div className="w-[calc(100%-80px)] sm:w-full md:w-[calc(100%-80px)] h-full flex flex-col gap-2 ">
                <span className="text-[20px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wide ">
                  {t(`${tSect}.feature5-header`)}{" "}
                </span>
                <span className="text-[16px] text-[#A0ACA2] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light tracking-wide ">
                  {t(`${tSect}.feature5-desc`)}
                </span>
              </div>
            </div>
            <div data-aos="zoom-in-up"   data-aos-offset="50" className="w-[45%] sm:w-full md:w-full lg:w-[45%] h-[120px] sm:h-auto md:h-[120px] bg-opacity-10 flex flex-row sm:flex-col md:flex-row sm:gap-[15px] md:gap-0 ">
              <div className="w-[80px] h-full flex items-start  ">
                <img
                  src="/Icons/426-brain-outline.gif"
                  className="h-[60px] bg-[#FFFFFF] bg-opacity-20 rounded-md p-1 "
                  alt="ic-feature6"
                />
              </div>
              <div className="w-[calc(100%-80px)] sm:w-full md:w-[calc(100%-80px)] h-full flex flex-col gap-2 ">
                <span className="text-[20px] text-[#E1F2E4] font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium tracking-wide ">
                  {t(`${tSect}.feature6-header`)}
                </span>
                <span className="text-[16px] text-[#A0ACA2] font-InterLight ltr:font-InterLight rtl:font-Cairo font-light tracking-wide ">
                  {t(`${tSect}.feature6-desc`)}
                </span>
              </div>
            </div>
          </div>

          <div className="w-full h-[100px]"></div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
