import React from "react";
import { useNavigate } from "react-router-dom";

const TemplateCards = ({ Icon, title, desc,route,isNew}) => {

  const Navigate = useNavigate()

  const handleClickTool = () => {
    Navigate(`/templates/${route}`)
  };

  return (
    <div onClick={handleClickTool} className="w-[23%] cursor-pointer sm:w-[100%] md:w-[100%] lg:w-[31%] xl:w-[23%] 2xl:w-[280px] h-auto inline-flex flex-row gap-4">
      <div className="w-full p-4 border rounded-md inline-flex flex-col gap-4 ">
        <div className="w-full h-[50px] inline-flex flex-row justify-between ">
          <span className="p-2 inline-flex items-center rounded-md bg-[#F2F4F7]">
            <img src={`/Icons/socialIcons/${Icon}`} className="h-[32px]" alt="" />
          </span>

        </div>
        <div className="w-full h-[calc(100%-50px)] flex flex-col gap-4 ">
          <h1 className=" font-InterLight ltr:font-InterLight rtl:font-Cairo font-semibold  text-[16px] tracking-[0.8px] ">
            {title}{" "}
            {isNew &&
              <img
                src="/Icons/assets/new.svg"
                alt="new"
                className="inline-block ml-2 "
              />
            }
            {" "}
          </h1>
          <p className="text-[#667085] font-InterLight ltr:font-InterLight rtl:font-Cairo tracking-[0.5px] font-normal text-[13px]">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TemplateCards;
