import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Pricing } from "../../../Redux/TogglerSlice";

const PricingHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tSect = "pricing.landing";

  const [activeBtn, setActiveBtn] = useState("yearly");

  const handleBilled = (billed) => {
    setActiveBtn(billed);
    dispatch(Pricing(billed));
  };

  return (
    <div className="py-20 w-full flex justify-center items-center">
      <div className="text-center">
        <label class="font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium text-white px-4 py-1 text-lg rounded-lg bg-white bg-opacity-10  flex-inline justify-center">
          {t(`${tSect}.pricing-plans`)}
        </label>
        <h1 class="font-InterLight  ltr:font-InterLight rtl:font-Cairo  font-semibold text-center text-white text-3xl sm:text-4xl md:text-5xl lg:text-5xl tracking-[1px] my-4  ">
          {t(`${tSect}.landing-text1`)}
          <span className="font-InterLight ltr:font-InterLight rtl:font-Cairo  font-semibold text-center text-transparent  text-3xl sm:text-4xl md:text-5xl lg:text-5xl tracking-[1px]  bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text ">
            {t(`${tSect}.landing-text2`)}
          </span>
        </h1>
        <div className="max-w-sm w-full relative mt-16  mx-auto border-lg border-first flex justify-between p-2 border-radius-100 items-center">
          <span
            onClick={() => handleBilled("monthly")}
            class={`font-InterLight ltr:font-InterLight rtl:font-Cairo font-medium cursor-pointer light-green text-base ms-3 tracking-[1px] px-3 py-1 border-radius-100 ${
              activeBtn == "monthly" ? "bg-black-dark" : ""
            } `}
          >
            {t(`${tSect}.billed-mounthly`)}
          </span>
          <span
            onClick={() => {
              handleBilled("yearly");
            }}
            class={` tracking-[0.5px] font-InterLight ltr:font-InterLight rtl:font-Cairo cursor-pointer font-medium light-green text-base  px-3 py-1 border-radius-100 ${
              activeBtn == "yearly" ? "bg-black-dark" : ""
            } `}
          >
            {t(`${tSect}.billing-yearly`)}
            <span className="text-green tracking-[0.5px] mx-1 ">
              {t(`${tSect}.off`)}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PricingHeader;
